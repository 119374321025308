import { Store } from '../data/store.js';
import { RalColorAsset3D } from '../draw3d/assets/RalColorAsset3D.js';
import { Color } from './color.js';
import { Configuration } from './configuration.js';

export class Colors {
	objectName = 'Colors';
	objectName3d = 'Colors3D';
	_onChange = null;

	defaultColor = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});

	columns = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});
	profiles = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});
	stairs = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});
	bracing = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});
	bracingColumns = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});
	handRail = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});
	palletGates = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});
	tiltGates = new Color((type) => {
		this.onChange(type);
		this.onSave();
	});
	constructor(onSave) {
		this._onSave = onSave;
	}

	changeColorsToDefaults(ral) {
		Object.keys(Configuration.CURRENT.colors).forEach((object, index) => {
			if (typeof this[object] === 'object' && this[object] !== null && typeof this[object] !== 'undefined') {
				if (!this[object].overrideDefault) {
					this[object].ralColor = ral;
				}
			}
		});
	}

	getDefaultList() {
		Store.CURRENT.finishDefaults.list(null, true, null, '', (res) => {
			this.setDefaults(res);
		});
	}

	getColors() {
		let items = {};
		Object.keys(Configuration.CURRENT.colors).forEach((object, index) => {
			if (typeof this[object] === 'object' && this[object] !== null && typeof this[object] !== 'undefined') {
				items[object] = this[object].getData();
			}
		});
		return items;
	}

	getChangedColors() {
		let items = {};
		Object.keys(Configuration.CURRENT.colors).forEach((object, index) => {
			if (typeof this[object] === 'object' && this[object] !== null && typeof this[object] !== 'undefined') {
				items[object] = this[object];
			}
		});
		return items;
	}

	setDefaults(defaultsList) {
		defaultsList.forEach((item) => {
			if (typeof this[item.type] !== 'undefined') {
				this[item.type].SetOwnDefaults(item);
			}
		});

		this.defaultColor._ralColor = '7016';
		this.defaultColor._type = 'default';
	}
	onChange(type) {
		// Wanneer de onchange type null is gebruiken we de defaulcolor dus moeten we de andere kleuren updaten naar eigen waarden.

		if (type === 'default') {
			Object.keys(Configuration.CURRENT.colors).forEach((object, index) => {
				if (Configuration.CURRENT.colors[object] !== null && typeof Configuration.CURRENT.colors[object] === 'object') {
					if (Configuration.CURRENT.colors[object].overrideDefault === false && Configuration.CURRENT.colors[object].colorPossible === true && Configuration.CURRENT.colors[object].changed === false) {
						//* * Speciale functie aanroepen, want deze gebruitk de SET van ralcolor die dan een onchange doet op een eigen type en dan Changed op true zet,
						//* * maar dat is niet nodig bij een verandering van de defaultkleur.
						Configuration.CURRENT.colors[object].setDefaultRal(this.defaultColor.ralColor);
					}
				}
			});
			this.onSave();
		}
	}
	onSave() {
		if (typeof this._onSave === 'function') {
			this._onSave(null, true);
		}
	}

	setReferences(params) {
		params.onChange = this.onChange;
		this.onSave = params.save;

		params.colorSave = (type) => {
			this.onChange(type);
			this.onSave();
		};

		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].setReferences === 'function') {
				this[object].setReferences(params);
			}
		});
	}
	removeReferences() {
		this._onChange = null;
		this._onSave = null;
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].removeReferences === 'function') {
				this[object].removeReferences();
			}
		});
	}
	create3DAssets(Canvas3D) {
		Object.keys(this).forEach((object, index) => {
			if (typeof this[object] === 'object' && this[object] !== null) {
				if (typeof this[object].ralColor !== 'undefined' && this[object].ralColor !== null) {
					Canvas3D.addAsset(new RalColorAsset3D(this[object].ralColor.toString()));
				}
			}
		});
		// Overige kleuren toevoegen die we als default gebruiken.
		Canvas3D.addAsset(new RalColorAsset3D('5000'));

		// Invisible box
		Canvas3D.addAsset(new RalColorAsset3D('10002'));
	}
}
