import { Service } from './service';
import { Configuration } from './configuration';

export class SiteSurvey extends Service {
	objectName = 'SiteSurvey';
	siteSurveyPossible = [];

	get possible() {
		let address = Configuration.CURRENT.deliveryAddress.getFullAddress();
		return this.siteSurveyPossible.filter((p) => p.code === address.countryCode).length > 0; // code gevonden
	}
	update(serviceData) {
		super.update(serviceData);
		this.defaultToolsActive = serviceData.data.toolsRequired;
		this.siteSurveyPossible = serviceData.data.siteSurveyPossible;
	}
}
