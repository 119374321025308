import { HandRails } from './handRails';
import { RemoveRaster } from './removeRaster';
import { Configuration } from './configuration';

export class HandRail {
	objectName = 'HandRail';
	rasterX = -1;
	rasterY = -1;
	position = -1;
	_active = true;
	etageId = '';
	get active() {
		return this._active;
	}
	set active(value) {
		this._active = value;
	}

	constructor(rasterX, rasterY, position, etageId) {
		this.rasterX = rasterX;
		this.rasterY = rasterY;
		this.position = position;
		this.etageId = etageId;
	}
	equals(rasterX, rasterY, position) {
		return this.rasterX === rasterX && this.rasterY === rasterY && this.position === position;
	}
	onRasterChanged(params, etage) {
		const huidigRasterActief = etage.isActiveRaster(new RemoveRaster(this.rasterX, this.rasterY));

		switch (this.position) {
			case HandRails.TOP:
				if (
					huidigRasterActief === true &&
					etage.isActiveRaster(new RemoveRaster(this.rasterX, this.rasterY - 1) === false) &&
					Configuration.CURRENT.overhang.getPosition(this.rasterX, this.rasterY, this.position, etage) !== null
				) {
					this.active = true;
				} else {
					this.active = false;
				}

				break;
			case HandRails.BOTTOM:
				if (
					huidigRasterActief === true &&
					etage.isActiveRaster(new RemoveRaster(this.rasterX, this.rasterY + 1) === false) &&
					Configuration.CURRENT.overhang.getPosition(this.rasterX, this.rasterY, this.position, etage) !== null
				) {
					this.active = true;
				} else {
					this.active = false;
				}

				break;
			case HandRails.LEFT:
				if (
					huidigRasterActief === true &&
					etage.isActiveRaster(new RemoveRaster(this.rasterX - 1, this.rasterY) === false) &&
					Configuration.CURRENT.overhang.getPosition(this.rasterX, this.rasterY, this.position, etage) !== null
				) {
					this.active = true;
				} else {
					this.active = false;
				}

				break;
			case HandRails.RIGHT:
				if (
					huidigRasterActief === true &&
					etage.isActiveRaster(new RemoveRaster(this.rasterX + 1, this.rasterY) === false) &&
					Configuration.CURRENT.overhang.getPosition(this.rasterX, this.rasterY, this.position, etage) !== null
				) {
					this.active = true;
				} else {
					this.active = false;
				}

				break;
		}
	}
	length(etage) {
		if (this.active === false) {
			return 0;
		}
		const handRailPosition = Configuration.CURRENT.overhang.getPosition(this.rasterX, this.rasterY, this.position, etage);
		let length = 0;

		// gevonden
		if (handRailPosition !== null) {
			switch (this.position) {
				case HandRails.TOP:
				case HandRails.BOTTOM:
					length = handRailPosition.endX.value - handRailPosition.startX.value;
					break;
				case HandRails.RIGHT:
				case HandRails.LEFT:
					length = handRailPosition.endY.value - handRailPosition.startY.value;
					break;
			}
		}
		if (etage !== null) {
			let lengthInterruptions = this.getLengthInterruptions(etage);
			if (lengthInterruptions.length > 0) {
				length = 0;
				switch (this.position) {
					case HandRails.TOP:
					case HandRails.BOTTOM:
						if (handRailPosition.startX.value < lengthInterruptions[0].startX) {
							// voor de interrupties nog ruimte meetellen
							length = lengthInterruptions[0].startX - handRailPosition.startX.value;
						}

						if (lengthInterruptions.length > 1) {
							// meer dan 1 tussen interuptions checken
							for (let i = 0; i < lengthInterruptions.length - 1; i++) {
								if (lengthInterruptions[i].endX < lengthInterruptions[i + 1].startX) {
									length += lengthInterruptions[i + 1].startX - lengthInterruptions[i].endX;
								}
							}
						}
						if (lengthInterruptions[lengthInterruptions.length - 1].endX < handRailPosition.endX.value) {
							// als aan het eind nog ruimte dan meetellen
							length += handRailPosition.endX.value - lengthInterruptions[lengthInterruptions.length - 1].endX;
						}

						break;
					case HandRails.RIGHT:
					case HandRails.LEFT:
						if (handRailPosition.startY.value < lengthInterruptions[0].startY) {
							// voor de interrupties nog ruimte meetellen
							length = lengthInterruptions[0].startY - handRailPosition.startY.value;
						}
						if (lengthInterruptions.length > 1) {
							// meer dan 1 tussen interuptions checken
							for (let i = 0; i < lengthInterruptions.length - 1; i++) {
								if (lengthInterruptions[i].endY < lengthInterruptions[i + 1].startY) {
									length += lengthInterruptions[i + 1].startY - lengthInterruptions[i].endY;
								}
							}
						}
						if (lengthInterruptions[lengthInterruptions.length - 1].endY < handRailPosition.endY.value) {
							// als aan het eind nog ruimte dan meetellen
							length += handRailPosition.endY.value - lengthInterruptions[lengthInterruptions.length - 1].endY;
						}
						break;
				}
			}
		}
		return length;
	}
	getLengthInterruptions(etage) {
		const handRailPosition = Configuration.CURRENT.overhang.getPosition(this.rasterX, this.rasterY, this.position, null);

		let lengthInterruptions = [];
		if (handRailPosition !== null) {
			etage.palletGates
				.findByHandrailPosition(handRailPosition)

				.forEach((pg) => {
					let edgePosition = pg.getEdgePosition(handRailPosition);
					lengthInterruptions.push(edgePosition);
				});

			etage.stairs
				.findByHandrailPosition(handRailPosition)

				.forEach((s) => {
					let edgePosition = s.getEdgePosition(handRailPosition);
					lengthInterruptions.push(edgePosition);
				});
			lengthInterruptions.sort((a, b) => {
				// sorteren in juiste volgorde van links naar rechts

				if (Math.round(a.endY - a.startY) > Math.round(a.endX - a.startX)) {
					if (a.startY < b.startY) {
						return -1;
					}
					if (a.startY > b.startY) {
						return 1;
					}
					return 0;
				} else {
					if (a.startX < b.startX) {
						return -1;
					}
					if (a.startX > b.startX) {
						return 1;
					}
					return 0;
				}
			});
		}

		return lengthInterruptions;
	}
	addDrawObjects(canvas, params, createHandRail, etage) {
		if (this.active === true) {
			const drawObject = createHandRail(this.rasterX, this.rasterY, this.position, false, this.getLengthInterruptions(etage));

			if (typeof drawObject !== 'undefined' && drawObject !== null) {
				if (Array.isArray(drawObject)) {
					canvas.addDrawObjects(drawObject);
				} else {
					canvas.addDrawObject(drawObject);
				}
			}
		}
	}
}
