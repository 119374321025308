import * as pc from 'playcanvas';
import { Store } from '../../data/store';
import { Canvas3D } from '../Canvas3D';
import { Asset3D } from './Asset3D';

export class RalColorAsset3D extends Asset3D {
	objectName = 'ralColorAsset3D';
	type = 'material';
	constructor(ralColor) {
		super(ralColor, null, null);

		this.ralColor = ralColor;
		this.assetName = ralColor + '.json';
	}
	load(thenCallBack) {
		Store.CURRENT.ralMaterials.getByName(
			this.ralColor,
			false,
			this.ralColor,
			(response) => {
				this.handleResponse(response, thenCallBack);
			},
			(error) => {
				console.log(error);
			},
		);
	}

	handleResponse(response, thenCallBack) {
		try {
			if (typeof Canvas3D.CURRENT.app.assets !== 'undefined') {
				if (response.material !== null) {
					// Model vanuit ERP en materiaal beschikbaar dan laden en stoppen met handleresponse
					this.loadRalColor(this.assetName, response.material, thenCallBack);
					return;
				}
			}
		} catch (exception) {
			if (typeof thenCallBack === 'function') {
				thenCallBack();
			}
		}
		// als response uit erp maar niet alles gevuld dan ook thencallback
		thenCallBack();
	}
	loadRalColor(name, material, thenCallBack) {
		let asset = new pc.Asset(name, 'material', null, JSON.parse(material));

		Canvas3D.CURRENT.app.assets.add(asset);
		Canvas3D.CURRENT.app.assets.load(asset);

		if (typeof thenCallBack === 'function') {
			thenCallBack();
		}
	}
}
