import { Configuration } from '../../../../configurator/configuration';
import { HandRails } from '../../../../configurator/handRails';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class HandrailMiddlePlateHolderCorner3D extends Model3D {
	objectName = 'HandrailMiddlePlateHolderCorner3D';
	assetName = 'handrail_middleplate_holder';
	fallBackName = 'handrail_middleplate_holder';
	fallBackData = { height: 60, depth: 25, width: 40 };
	// De haakjes aan de paaltjes die de plaat op de helft van het paaltjes hoogte vastmaakt, dit zijn alleen de hoekstukken, dus niet begin van een paaltjesreeks en aan het eind ervan.
	draw(app) {
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');
		let plateDepth = Canvas3D.CURRENT.getModelData('handrail_middleplate_holder', 'depth');
		let thicknessMiddlePlateTopPart = 5;

		this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;

		if (this.position === HandRails.BOTTOM) {
			this.z += poleWidth - plateDepth - thicknessMiddlePlateTopPart;
			// Eerste (linkse) paaltje houder voor middelplaat
			if (this.pole === 1) {
				this.x += poleWidth;
			}
			// Laatste (rechts) paaltje houder voor middelplaat
			if (this.pole === 2) {
				this.rotationY = 180;
				this.z += plateDepth;
			}
		} else if (this.position === HandRails.TOP) {
			this.z += thicknessMiddlePlateTopPart;
			// Eerste (linkse) paaltje houder voor middelplaat
			if (this.pole === 1) {
				this.x += poleWidth;
			}
			// Laatste (rechts) paaltje houder voor middelplaat
			if (this.pole === 2) {
				this.rotationY = 180;
				this.z += plateDepth;
			}
		} else if (this.position === HandRails.LEFT) {
			this.x += thicknessMiddlePlateTopPart;
			// Eerste (bovenste) paaltje houder voor de middelplaat
			if (this.pole === 1) {
				this.rotationY = -90;
				this.x += plateDepth;
				this.z += poleWidth;
			}
			// Laatste (onderste) paaltje houder voor de middelplaat
			if (this.pole === 2) {
				this.rotationY = 90;
			}
		} else if (this.position === HandRails.RIGHT) {
			this.x += poleWidth - thicknessMiddlePlateTopPart;
			// Eerste (bovenste) paaltje houder voor de middelplaat
			if (this.pole === 1) {
				this.rotationY = -90;
				this.z += poleWidth;
			}
			// Laatste (onderste) paaltje houder voor de middelplaat
			if (this.pole === 2) {
				this.rotationY = 90;
				this.x -= plateDepth;
			}
		}

		this.y += this.cornerPoleLength / 2;
		super.draw(app);
	}
}
