import { Configuration } from './configuration';

export class Errors {
	static ERRORTYPE = {
		collision: 1,
		validate: 2,
	};
	objectName = 'Errors';
	errors = [];
	firstEtageWithError = -1;
	oldActiveEtage = 0;
	add(error, type) {
		if (typeof type !== 'undefined' && type !== null) {
			error.type = type;
		}
		this.errors.push(error);
	}
	get(id) {
		if (typeof id !== 'undefined' && id !== null) {
			let findErrors = this.errors.filter(function (error) {
				return error.id === id;
			});

			if (findErrors.length > 0) {
				return findErrors[0];
			}
			return {
				error: '',
			};
		}

		return this.errors;
	}
	hasErrorsOverTheEtages() {
		let etageWithErrors = [];
		Configuration.CURRENT.etages.etages.forEach((etage, index) => {
			if (etage.hasBraceErrors === true) {
				etageWithErrors.push(etage);
				if (this.firstEtageWithError === -1) {
					this.firstEtageWithError = index + 1;
					this.oldActiveEtage = Configuration.CURRENT.etages.activeEtageIndex;
				}
				if (this.oldActiveEtage !== Configuration.CURRENT.etages.activeEtageIndex) {
					this.oldActiveEtage = Configuration.CURRENT.etages.activeEtageIndex;
					this.firstEtageWithError = 1;
				}
			}
		});
		return etageWithErrors.length > 0;
	}
	getAll() {
		return Configuration.CURRENT.getErrorList(this.errors);
	}
	push(error) {
		this.errors.push(error);
	}
	addErrorText(errorText, type) {
		let error = {
			errorText: errorText,
		};
		if (typeof type !== 'undefined' && type !== null) {
			error.type = type;
		}
		this.errors.push(error);
	}
	clear(type) {
		this.errors = this.errors.filter(function (error) {
			return error.type !== type;
		});
	}
	get length() {
		return this.errors.length;
	}
}
