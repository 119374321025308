import { Data } from './data';
import { Store } from './store';
import { Languages } from './languages';

export class PalletGates extends Data {
	objectName = 'PalletGates';
	static CURRENT = null;
	url = 'PalletGates';
	getHeightList(group, force, thenCallBack) {
		if (typeof group === 'undefined' || group === '') {
			return [];
		}
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && !force) {
			// alle palletgates filteren op basis van groep
			let groupFiltered = this._list.list.filter(function (palletGate) {
				return palletGate.groupName === group;
			});

			// sorteren op hoogte
			let distinctHeight = [...new Set(groupFiltered.map((x) => x.height))].sort(function (a, b) {
				return a - b;
			});

			// mappen van waarden
			return distinctHeight.map((height) => {
				return { value: height, caption: height };
			});
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			return this.list(null, true, 'list', null, () => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(this.getHeightList(group));
				}
			});
		}
		// geen lijst dan lege array
		return [];
	}

	getWidthList(group, force, thenCallBack) {
		if (typeof group === 'undefined' || group === '') {
			return [];
		}
		let currentLanguage = 'EN';
		if (Languages.CURRENT !== null) {
			currentLanguage = Languages.CURRENT.currentLanguage;
		}
		// Kijk of lijst al opgehaald is
		if (
			typeof this._list.list !== 'undefined' &&
			this._list.list !== null &&
			typeof this.lastRequest.list !== 'undefined' &&
			this.lastRequest.list !== null &&
			typeof this.lastRequest.list.data !== 'undefined' &&
			this.lastRequest.list.data !== null &&
			this.lastRequest.list.data.language === currentLanguage &&
			!force
		) {
			// alle palletgates filteren op basis van groep
			let groupFiltered = this._list.list.filter(function (palletGate) {
				return palletGate.groupName === group;
			});

			// unieke breedtes
			let distinctWidth = [...new Set(groupFiltered.map((x) => x.width))].sort(function (a, b) {
				return a - b;
			});

			// weergeven
			return distinctWidth.map((width) => {
				return { value: width, caption: width };
			});
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			return this.list(null, true, 'list', null, () => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(this.getWidthList(group));
				}
			});
		}
		// geen lijst dan lege array
		return [];
	}

	find(group, width, height) {
		if (typeof group === 'undefined' || group === '' || typeof width === 'undefined' || width === '' || width === 0 || typeof height === 'undefined' || height === '' || height === 0) {
			return { id: -1, caption: '', name: '', value: '', width: '', depth: '', height: '' };
		}
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// zoek palletgate op basis van group, width en height
			let filtered = this._list.list.filter(function (palletGate) {
				return palletGate.groupName === group && palletGate.width === width && palletGate.height === height;
			});

			// als gevonden dan teruggeven
			if (filtered.length > 0) {
				return filtered[0];
			}
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return { id: -1, caption: '', name: '', value: '', width: '', depth: '', height: '' };
	}
	getGroupList(thenCallBack) {
		let currentLanguage = 'EN';
		if (Languages.CURRENT !== null) {
			currentLanguage = Languages.CURRENT.currentLanguage;
		}
		// Kijk of lijst al opgehaald is
		if (
			typeof this._list.list !== 'undefined' &&
			this._list.list !== null &&
			typeof this.lastRequest.list !== 'undefined' &&
			this.lastRequest.list !== null &&
			typeof this.lastRequest.list.data !== 'undefined' &&
			this.lastRequest.list.data !== null &&
			this.lastRequest.list.data.language === currentLanguage
		) {
			// alleen de unieke groepen ophalen
			let distinctGroup = [...new Set(this._list.list.map((x) => x.groupName))].sort(function (a, b) {
				return a - b;
			});
			let returnList = [];
			distinctGroup.forEach((group) => {
				let findItem = this._list.list.filter((l) => l.groupName === group);
				if (findItem.length > 0) {
					returnList.push({ value: findItem[0].groupName, caption: findItem[0].caption });
				}
			});
			if (typeof thenCallBack === 'function') {
				thenCallBack(returnList);
			} else {
				return returnList;
			}
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object
		return [];
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		data.language = Languages.CURRENT.currentLanguage;

		super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}
