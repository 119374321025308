import { SizeHandle } from './sizeHandle';

export class SizeHandleTotal extends SizeHandle {
	objectName = 'SizeHandleTotal';
	referenceHandle = '';
	typeSizeHandle = SizeHandle.TOTAL;
	constructor(id, order, resizable) {
		if (typeof resizable === 'undefined' || resizable === null) {
			resizable = false;
		}
		super(id, order, resizable);
	}
	setReferenceHandle(referenceHandle) {
		this.referenceHandle = referenceHandle;
	}
	addDrawObjects(canvas, params) {
		super.addDrawObjects(canvas, params);
	}
}
