import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Bracing3D } from './Bracing3D';

export class BracingVerticalTop3D extends Bracing3D {
	objectName = 'BracingVerticalTop3D';
	rotationX = 90;
	rotationZ = 90;
	constructor(x, y, params) {
		super(x, y, params);
		this.rotationY = params.rotationY;
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;

		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		let bracingStripHeight = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'height');
		let mainBeamHeight = Canvas3D.CURRENT.getBeamData(this.mainBeamOid, 'height');

		this.rotationY = 90 - -this.getRotation(app, this.calculationData, mainBeamHeight, deckingFinishHeight);
		this.width = this.getWidth(app, this.calculationData, mainBeamHeight, deckingFinishHeight);

		this.y -= deckingFinishHeight;
		this.y -= mainBeamHeight;

		// In het midden van de kolom, op de Z as gezien. (Y in 2D)
		this.x += columnWidth / 2;

		// Het model is naar boven getekend, dus moeten we de hoogte van de strip bij de Y eraf halen.
		this.y -= bracingStripHeight;

		super.draw(app);
	}
}
