import { Store } from '../data/store.js';
import { Service } from './service.js';
import { ConstructionTool } from './constructionTool.js';
import { Configuration } from './configuration.js';

export class Construction extends Service {
	objectName = 'Construction';

	_toolsActive = false;

	_congestedSite = false;
	toolsRequired = true;

	tools = [];
	allTools = [];
	_onChange = null;

	// congestedSite
	get congestedSite() {
		return this._congestedSite;
	}
	set congestedSite(value) {
		this._congestedSite = value;
		this.onChange();
	}

	// ToolsActive
	get toolsActive() {
		return this._toolsActive;
	}
	set toolsActive(value) {
		if (this._toolsActive !== value) {
			this._toolsActive = value;
			this.onChange();
		}
	}

	// Active
	get active() {
		return this._active;
	}
	set active(value) {
		if (this._active !== value) {
			this._active = value;
			if (value === true) {
				//	als montage aangezet wordt. Altijd ook montagetools
				this.toolsActive = this.toolsRequired;
			}
			this.onChange();
		}
	}

	constructor(onChange, serviceData) {
		super(onChange, serviceData);
		if (typeof onChange === 'function') {
			this._onChange = onChange;
		}
		this.getAllTools();
	}
	setTools() {
		if (typeof this.allTools !== 'undefined' && this.allTools !== null && this.allTools.length > 0) {
			this.allTools.forEach((tool, index) => {
				let findTool = this.tools.find((t) => t.id === tool.id);
				if (typeof findTool === 'undefined' || findTool === null) {
					// Als er een tool word toegevoegd, dan deze meenemen in de lijst.
					this.tools.push(new ConstructionTool(tool.id, tool.name, tool.defaultAmount > 0, tool.defaultAmount, this._onChange));
				} else if (tool.name !== findTool.name) {
					findTool.name = tool.name; // door vertaling kan dit afwijken
				}
			});
		}
	}

	setReferences(params) {
		super.setReferences(params);
		this.tools.forEach((tool, index) => {
			if (typeof tool.setReferences === 'function') {
				tool.setReferences(params);
			}
		});
	}
	removeReferences() {
		super.removeReferences();
		this.tools.forEach((tool, index) => {
			if (typeof tool.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				tool.removeReferences();
			}
		});
		this._onChange = null;
	}

	getAllTools() {
		Store.CURRENT.services.list(
			null,
			true,
			-1,
			null,
			(response) => {
				let serviceConstruction = response.filter((obj) => obj.serviceType === 0);
				if (serviceConstruction.length > 0) {
					this.allTools = serviceConstruction[0].tools;
					this.setTools();
				}
			},
			(exception) => {
				console.log(exception);
			},
		);
	}

	getAmount() {
		let amount = { id: this.id, active: this.active, optional: this.optional, toolsActive: this.toolsActive, congestedSite: this.congestedSite, tools: [] };

		if (typeof this.allTools !== 'undefined' && this.allTools !== null && this.allTools.length > 0) {
			this.tools.forEach((tool, index) => {
				if (tool.count > 0) {
					let findTool = this.allTools.filter((a) => a.id === tool.id);
					if (findTool.length > 0) {
						amount.tools.push(tool.getAmount());
					}
				}
			});
		}

		return amount;
	}

	updateConstruction(serviceData) {
		super.update(serviceData);
		this.toolsRequired = serviceData.data.toolsRequired;
		this.getAllTools();
	}
}
