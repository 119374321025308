// import { Canvas3D } from '../../../Canvas3D';
import { jsonProfile } from '../../../../configurator/hicad/jsonProfile';
import { Canvas3D } from '../../../Canvas3D';
import { ProfilesHorizontal3D } from '../../ProfilesHorizontal3D';

export class MainBeamBottom3D extends ProfilesHorizontal3D {
	objectName = 'MainBeamBottom3D';
	profileType = 'mainbeam';
	rotationX = -180;
	// eslint-disable-next-line no-useless-constructor
	constructor(x, y, length, params) {
		super(x, y, length, params);
		this.depth = 1;
	}
	draw(app) {
		let mainBeamHeight = Canvas3D.CURRENT.getBeamData(this.oid, 'height');
		this.y += mainBeamHeight;
		super.draw(app);
	}

	// Convert MainBeamBottom3D naar HiCADProfile, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_MAINBEAM, direction: jsonProfile.DIRECTION_HORIZONTAL, position: jsonProfile.POSITION_BOTTOM });
	}
}
