/* eslint-disable no-useless-constructor */
import { Configuration } from '../../../configurator/configuration';
import { jsonProfile } from '../../../configurator/hicad/jsonProfile';
import { Raster } from '../../../configurator/raster';
import { Canvas3D } from '../../Canvas3D';
import { ProfilesHorizontal3D } from '../ProfilesHorizontal3D';
export class HorizontalMainBeamTop3D extends ProfilesHorizontal3D {
	objectName = 'HorizontalMainBeamTop3D';
	constructor(x, y, length, params) {
		params.profilePosition = 'top';
		super(x, y, length, params);
		this.RecalculateProfilePositionByMultipleFloor();
		this.rotationX = 0;
		this.rotationY = 0;
		this.rotationZ = 0;

		this.parentEntityName = params.profileType + '_' + this.profilePosition + '_' + params.indexX + '_' + params.indexY;
	}
	draw(app) {
		super.draw(app, 'mainbeam');
	}

	RecalculateProfilePositionByMultipleFloor() {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		// Doorbouw kolom
		if (this.multipleFloors) {
			if (this.rowPositions.includes(Raster.POSITION_TOP)) {
				this.z += columnWidth;
			} else {
				this.z += columnWidth / 2;
			}
		}
	}

	// Convert naar HiCADProfile van HorizontalMainBeamTop3D, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_MAINBEAM, direction: jsonProfile.DIRECTION_HORIZONTAL, position: jsonProfile.POSITION_TOP });
	}
}
