import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { ColumnProtector3D } from './ColumnProtector3D';
import {Columns} from "@/configurator/columns";

export class ProtectorBottom3D extends ColumnProtector3D {
  objectName = 'ProtectorBottom3D';
  rotationY = 180;
  draw(app) {
    let modelWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
    let modelDepth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'depth');

    let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
    this.x += modelWidth;
    this.x -= (modelWidth - columnWidth) / 2;
    this.z += modelDepth;
    this.z += columnWidth;

    super.draw(app);
  }
}
