import { Functions } from '../helpers/functions';

export class RemoveRasters {
	objectName = 'RemoveRasters';
	rasters = [];
	_onChange = null;
	constructor(value, onChange) {
		this._onChange = onChange;
		this.id = Functions.uuidv4();
		if (typeof value !== 'undefined' && value !== null) {
			value.forEach(
				function (value) {
					this.push(value);
				}.bind(this),
			);
		}
	}
	onChange(status) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	get() {
		return this.rasters;
	}
	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
	}
	toggleRaster(raster) {
		const toRemove = [];
		this.rasters.forEach((removeRaster, index) => {
			if (removeRaster.x === raster.x && removeRaster.y === raster.y) {
				toRemove.push(index);
			}
		});
		if (toRemove.length === 0) {
			this.rasters.push(raster);
		} else {
			toRemove.forEach((item, index) => {
				this.rasters.splice(item - index, 1);
			});
		}
		this.onChange();
	}

	push(value) {
		const result = this.rasters.push(value);

		this.onChange();

		return result;
	}
	splice(index, count = this.rasters.length - index) {
		const result = this.rasters.splice(index, count);
		this.onChange();

		return result;
	}
	isActive(raster) {
		const foundRaster = this.rasters.find((rst) => rst.x === raster.x && rst.y === raster.y);
		if (typeof foundRaster === 'undefined') {
			return true;
		}
		return false;
	}
}
