import { Canvas3D } from '../../draw3d/Canvas3D';
import { Configuration } from '../configuration';
import { HicadDrawObject } from './HicadDrawObject';

export class jsonFinish extends HicadDrawObject {
	type = 1;

	static TYPE_GRATING = 0;
	static TYPE_WOODPLATE = 1;

	constructor(deckingFinish, type) {
		super(deckingFinish);
		this.type = type;
		this.height = Configuration.CURRENT.finish.height;
		this.oid = Configuration.CURRENT.finish.selected.id;
		this.hoverTags = ['deckingfinish'];
	}
}
