import { HicadDrawObject } from './HicadDrawObject';

export class JsonColumnProtector extends HicadDrawObject {
	protectorId = 't';

	constructor(cp) {
		super(cp);
		this.protectorId = cp.info.id;
	}
}
