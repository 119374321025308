import { Data } from './data';

export class FallingProtectionStandards extends Data {
	objectName = 'FallingProtectionStandards';
	static CURRENT = null;
	url = 'FallingProtectionStandards';
	getStandard(name) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			return this._list.list.filter((fps) => fps.value === name)[0];
		} else {
			this.list();
		}
		return null;
	}
}
