import { HicadDrawObject } from './HicadDrawObject';

export class jsonBuildingColumn extends HicadDrawObject {
	width = 0;
	depth = 0;
	height = 0;
	hicadPropperties = {};
	constructor(bc) {
		super(bc);
		this.width = bc.width;
		this.depth = bc.depth;
		this.height = bc.height;

		this.hicadPropperties.width = bc.width * 1000;
		this.hicadPropperties.depth = bc.depth * 1000;
		this.hicadPropperties.height = bc.height * 1000;
		this.oid = 0;
		this.hicadPosition.z = 0;
	}
}
