import * as pc from 'playcanvas';
import { HicadBox } from '../configurator/hicad/HicadBox';
import { DrawObjects3D } from './DrawObjects3D';

export class Box3D extends DrawObjects3D {
	objectName = 'Box3D';

	rotationX = 0;
	rotationY = 0;
	rotationZ = 0;
	constructor(x, y, params) {
		super(x, params.drawY, y, params);
	}
	draw(app) {
		this.x = this.x / 1000;
		this.y = this.y / 1000;
		this.z = this.z / 1000;

		this.width = this.width / 1000;
		this.depth = this.depth / 1000;
		this.height = this.height / 1000;

		// Waarom dit is, geen idee
		this.y += this.height / 2;
		this.x += this.width / 2;
		this.z += this.depth / 2;

		let box = new pc.Entity(this.objectName);

		if (this.invisible === false && this.layers !== null && typeof this.layers !== 'undefined') {
			box.addComponent('model', {
				type: 'box',
				layers: this.layers,
			});
		} else {
			box.addComponent('model', {
				type: 'box',
			});
		}

		// Set model scale
		box.setLocalScale(this.width, this.height, this.depth);

		// Set position
		box.setPosition(this.x, this.y, this.z);

		// Kleur toevoegen aan box
		if (this.ralColor !== null && typeof this.ralColor !== 'undefined') {
			let colorAsset = app.assets.find(this.ralColor + '.json', 'material');
			if (colorAsset !== null && typeof colorAsset !== 'undefined' && colorAsset.resources.length > 0) {
				box.model.meshInstances[0].material = colorAsset.resources[0];
			}
		}

		app.root.addChild(box);
	}
	convert() {
		return new HicadBox(this);
	}
}
