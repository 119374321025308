import { Filters } from '../configurator/filters';

import { Configuration } from '../configurator/configuration';
import { Functions } from '../helpers/functions';
import { SizeHandles } from './sizeHandles';
import { DrawObjects } from './drawObjects';

import { SizeHandleManual } from './sizeHandleManual';
import { SizeHandleTotal } from './sizeHandleTotal';
import { Store } from '../data/store';

export class Canvas {
	objectName = 'Canvas';
	_canvas = null;
	interval = 0;
	onDraw = null;
	colors = { background: '#ffffff' };
	scaleFactor = 0;
	offsetLeft = 0;
	offsetTop = 0;
	offset = 30;
	offsetWallTop = 0;
	offsetWallLeft = 0;
	columnSize = 6;
	min = { x: -1, y: -1 };
	max = { x: -1, y: -1 };
	get width() {
		return this.max.x - this.min.x;
	}
	get height() {
		return this.max.y - this.min.y;
	}
	drag = false;
	dragPositions = null;
	dragStart = { x: 0, y: 0 };
	sizeHandles = new SizeHandles([new SizeHandleManual('raster', 1), new SizeHandleManual('object', 2)], () => {
		this.draw();
	});

	dragLastPosition = { x: 0, y: 0 };
	mouseDown = false;
	lastMouseMovePositions = [];
	mouseDragStartTime = 0;
	filters = new Filters();
	drawObjects = new DrawObjects();
	etages = null; // geef etages vanuit configuration mee om die met de mouseclick mee te kunnen geven. Maar nog niet helemaal gelukkig dat dit nodig is

	get canvas() {
		if (this._canvas === null) {
			this._canvas = document.getElementById(this.canvasId);
		}
		return this._canvas;
	}
	set canvas(canvas) {
		this._canvas = canvas;
	}
	setMinMax() {
		this.min = { x: -1, y: -1 };
		this.max = { x: -1, y: -1 };
		let minMax = this.drawObjects.minMax();

		if (this.min.x === -1 || this.min.x > minMax.min.x) {
			this.min.x = minMax.min.x;
		}
		if (this.min.y === -1 || this.min.y > minMax.min.y) {
			this.min.y = minMax.min.y;
		}
		if (this.max.x === -1 || this.max.x < minMax.max.x) {
			this.max.x = minMax.max.x;
		}
		if (this.max.y === -1 || this.max.y < minMax.max.y) {
			this.max.y = minMax.max.y;
		}
	}

	_ctx = null;
	get ctx() {
		if (this._ctx === null && this.canvas !== null) {
			this._ctx = this.canvas.getContext(this.canvasId);
		}
		return this._ctx;
	}
	set ctx(ctx) {
		this._ctx = ctx;
	}
	constructor() {
		this.id = Functions.uuidv4();
	}
	onMouseLeave(evt) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}
		// final check nog
		this.lastMouseMovePositions = [];
	}
	onClick(evt) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}

		// this.drag houd bij wanneer na het slepen word losgelaten is het een klik dus komt hij hierin
		// Als de actie die bezig was (drag) is, dan voeren we geen onclick logica uit.
		if (this.drag === true) {
			// dragstart wordt bij mousedown gezet. Als niet meer zelfde positie dan drag ipv click
			this.drag = false;
			this.dragStart = { x: 0, y: 0 };
			return;
		}

		let positions = this.drawObjects.find(evt.layerX, evt.layerY);
		positions = positions.sort(this.drawObjects.compare);

		let propagation = true;
		let contextMenuItems = [];
		let showContextMenu = false;
		let configurationObject = null;
		let minMaxCoordinates = null;

		positions.forEach((position, index) => {
			if (propagation === true && typeof position.object !== 'undefined' && position.object != null && typeof position.object.onClick === 'function') {
				configurationObject = position.object;

				let result = configurationObject.onClick(evt, position, this, { etages: this.etages });
				if (typeof result !== 'undefined' && result !== null) {
					if (typeof result.stopPropagation !== 'undefined' && result.stopPropagation !== null) {
						propagation = result.stopPropagation === false;
					}

					if (typeof configurationObject.selected !== 'undefined' && configurationObject.selected !== null) {
						showContextMenu = configurationObject.selected;
						// Als de functie bestaat om de het contextmenu op te halen.
						if (typeof configurationObject.getContextMenu === 'function') {
							contextMenuItems = configurationObject.getContextMenu();
						}

						if (configurationObject.selected === true) {
							minMaxCoordinates = position.minMaxCoordinatesScaled();
						}
					}
				}
			}
		});

		// Bij selecteren kolom is er geen contextMenu maar is hij wel geselecteerd.
		// Als er dus geen items zijn het menu niet laten zien.
		if (contextMenuItems.length === 0) {
			showContextMenu = false;
		}

		Configuration.CURRENT.contextMenu.setItems(contextMenuItems);
		Configuration.CURRENT.contextMenu.visible = showContextMenu;

		if (minMaxCoordinates !== null) {
			// Positie updaten van contextmenu nadat de gebruiker een object aanklikt.
			// DIt is nodig omdat we dan kunnen slepen van objecten zonder dat ze geselecteerd zijn.
			// Dat is dus vorige positie en dan de hoeveelheid verschuiving erbij opgeteld.
			let newX = minMaxCoordinates.max.x;
			let newY = minMaxCoordinates.max.y;
			Configuration.CURRENT.contextMenu.updatePosition(newX, newY);
		}

		// Na onClick selecteren we een element, dat veranderd van kleur
		// Dan forceren van nieuwe draw.
		this.draw();
	}
	onMouseDown(evt) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}

		this.dragStart = { x: evt.layerX, y: evt.layerY };
		this.dragLastPosition = { x: evt.layerX, y: evt.layerY };
		this.mouseDown = true;
		evt.positions = this.drawObjects.find(evt.layerX, evt.layerY);
		evt.positions = evt.positions.sort(this.drawObjects.compare);
		let propagation = true;
		this.dragPositions = [];
		evt.positions.forEach((position, index) => {
			if (propagation === true && typeof position.object !== 'undefined' && position.object != null && typeof position.object.onMouseDown === 'function') {
				let result = position.object.onMouseDown(evt, position, this, { etages: this.etages });
				this.dragPositions.push(position);
				if (typeof result !== 'undefined' && typeof result.stopPropagation !== 'undefined') {
					propagation = result.stopPropagation === false;
				}
			}
		});
	}
	onMouseUp(evt) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}
		if (this.drag === false) {
			this.mouseDown = false;
			return; // als geen drag en onmouseup dan is het onclick. Wordt daar afgehandeld
		}

		// de vraag of onderstaande voldoende is. Miniem verschuiven zorgt al voor drag=true. Anders kijken naar hoeveelheid verschuiven en tijd. (this.MouseDownTime)
		// ook rekening houden dat verschuiven klein kan zijn maar dat heen en terug is dus toch van betekenins.
		evt.delta = { x: evt.layerX - this.dragLastPosition.x, y: evt.layerY - this.dragLastPosition.y };
		evt.deltaStart = { x: evt.layerX - this.dragStart.x, y: evt.layerY - this.dragStart.y };
		this.mouseDown = false;
		let positions = this.drawObjects.find(evt.layerX, evt.layerY);
		positions = positions.sort(this.drawObjects.compare);
		if (this.drag === true) {
			evt.positions = this.dragPositions;
			evt.newPositions = positions;
		} else {
			evt.positions = positions;
		}

		let propagation = true;
		let configurationObject = null;
		let minMaxCoordinates = null;
		let contextMenuItems = [];
		evt.positions.forEach((position, index) => {
			if (propagation === true && typeof position.object !== 'undefined' && position.object != null && typeof position.object.onMouseUp === 'function') {
				configurationObject = position.object;
				let result = configurationObject.onMouseUp(evt, position, this, { etages: this.etages });
				if (typeof result !== 'undefined' && result !== null) {
					if (typeof result.stopPropagation !== 'undefined' && result.stopPropagation !== null) {
						propagation = result.stopPropagation === false;
					}
					if (typeof configurationObject.getContextMenu === 'function') {
						contextMenuItems = configurationObject.getContextMenu();
					}
					// Wanneer object geselecteerd is dan de van het drawobject de minMax ophalen.
					if (configurationObject.selected === true) {
						minMaxCoordinates = position.minMaxCoordinatesScaled();
					}
				}
			}
		});

		if (minMaxCoordinates !== null) {
			// Na slepen contextmenu weer zichtbaar maken.
			// Alleen als er items inzitten en hij al open was voor het slepen.
			if (Configuration.CURRENT.contextMenu.wasOpen === true && contextMenuItems.length > 0) {
				Configuration.CURRENT.contextMenu.show();
				Configuration.CURRENT.contextMenu.setItems(contextMenuItems);
			}
			// Positie updaten van contextmenu op basis van dragLastPosition
			// Dat is dus vorige positie en dan de hoeveelheid verschuiving erbij opgeteld.
			let newX = (minMaxCoordinates.max.x += evt.deltaStart.x);
			let newY = (minMaxCoordinates.max.y += evt.deltaStart.y);
			Configuration.CURRENT.contextMenu.updatePosition(newX, newY);
		}

		this.draw();
	}
	onMouseDrag(evt) {
		if (Configuration.CURRENT.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL === true) {
			return; // readonly modus geen acties
		}

		let timeNow = new Date();
		// Als 25 miliseconden gepasseerd is dan pas opnieuw een drag
		if ((timeNow.getTime() - this.mouseDragStartTime.getTime()) / 1000 > 0.03) {
			this.mouseDragStartTime = timeNow;
			// Verschuiving in vergelijking met vorige verschuiving.
			evt.delta = { x: evt.layerX - this.dragLastPosition.x, y: evt.layerY - this.dragLastPosition.y };
			// Totale verschuiving.
			evt.deltaStart = { x: evt.layerX - this.dragStart.x, y: evt.layerY - this.dragStart.y };

			this.dragLastPosition = { x: evt.layerX, y: evt.layerY };
			evt.newPositions = this.drawObjects.find(evt.layerX, evt.layerY);
			evt.newPositions.sort(this.drawObjects.compare);
			evt.positions = this.dragPositions;

			let gewijzigd = false;
			let propagation = true;
			evt.positions.forEach((position, index) => {
				if (propagation === true && typeof position.object !== 'undefined' && position.object != null && typeof position.object.onMouseDrag === 'function') {
					// Contextmenu niet zichbaar maken voor makkelijk slepen zonder dat je door het menutje heen gaat met de muis.
					Configuration.CURRENT.contextMenu.hide(true);

					let result = position.object.onMouseDrag(evt, position, this, { etages: this.etages });
					if (typeof result !== 'undefined') {
						if (typeof result.stopPropagation !== 'undefined') {
							propagation = result.stopPropagation === false;
						}
					}
					gewijzigd = true;
				}
			});
			if (gewijzigd === true) {
				this.draw();
			}
		}
	}
	onMouseMove(evt) {
		if (Configuration.CURRENT.readOnly === true) {
			// if (Configuration.CURRENT.readOnly === true) {
			return; // readonly modus geen acties
		}

		// zoek alle posities op die overeenkomen met x,y
		if (this.mouseDown === true) {
			this.drag = true;
			// Bijhouden of drag start tijd al is
			if (this.mouseDragStartTime === 0) {
				this.mouseDragStartTime = new Date();
			}
			this.onMouseDrag(evt);
			return;
		}
		this.drag = false;
		const positions = this.drawObjects.find(evt.layerX, evt.layerY);
		positions.sort(this.drawObjects.compare);

		let gewijzigd = false;
		let propagation = true;
		// controleer of er posities zijn die nu de vorige move gevonden waren en nu niet meer. die moeten een mouseleave krijgen
		this.lastMouseMovePositions.forEach((lastPosition, index) => {
			let gevonden = false;
			positions.forEach((position, index) => {
				if (position === lastPosition) {
					gevonden = true;
				}
			});
			if (gevonden === false) {
				if (propagation === true && typeof lastPosition.object !== 'undefined' && lastPosition.object != null && typeof lastPosition.object.onMouseLeave === 'function') {
					let result = lastPosition.object.onMouseLeave(evt, lastPosition, this, { etages: this.etages });
					if (typeof result !== 'undefined' && typeof result.stopPropagation !== 'undefined') {
						propagation = result.stopPropagation === false;
					}
					gewijzigd = true;
				}
			}
		});

		propagation = true;
		positions.forEach((position, index) => {
			if (typeof position.object !== 'undefined' && position.object != null && typeof position.object.onMouseMove === 'function') {
				let result = true;
				if (propagation === true) {
					result = position.object.onMouseMove(evt, position, this, { etages: this.etages });
				} else {
					result = position.object.onMouseLeave(evt, position, this, { etages: this.etages });
				}
				if (propagation === true && typeof result !== 'undefined' && typeof result.stopPropagation !== 'undefined') {
					propagation = result.stopPropagation === false;
				}
				gewijzigd = true;
			}
		});
		this.lastMouseMovePositions = positions;
		if (gewijzigd === true) {
			this.draw();
		}
	}
	reconstruct() {
		// na reconstruct (JSON inlezen en opnieuw objecten van maken) canvasId leeg maken zodat bij een eerste init opnieuw canvas wordt opgezocht
		this.canvasId = null;
	}
	afterReconstruct() {
		// Waardes resetten.
		this.drag = false;
		this.mouseDown = false;
		this.canvasId = null;
		this.mouseDragStartTime = 0;
		let params = {
			redraw: () => {
				this.draw();
			},
		};
		this.sizeHandles.setReferences(params);
	}
	removeReferences() {
		if (typeof this.sizeHandles.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.sizeHandles.removeReferences();
		}
	}

	// Init functie die maar 1x word uitgevoerd.
	// Verteld de applicatie in welk canvas hij moet tekenen.
	init(id = '2d') {
		this.canvasId = id;
		this.canvas = document.getElementById(id);
		this.ctx = this.canvas.getContext(id);

		// even groot maken als beschikbare scherm
		window.onresize = this.onWindowResize.bind(this);
		this.onWindowResize();
		this.draw();
	}
	// Bij resizen van scherm canvas rescalen.
	onWindowResize() {
		this.canvas.width = window.innerWidth - 100;
		this.canvas.height = window.innerHeight;

		this.setScaleFactor();
		this.setOffset();
		this.draw();
	}
	redraw() {
		// tekenvlak gereed maken en achtergrondkleur geven.
		// Dit altijd uitvoeren voordat we drawObjects tekenen.
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
		this.ctx.fillStyle = this.colors.background;
		this.drawRectScaled(0, 0, this.canvas.width, this.canvas.height);
	}
	draw(type = null) {
		// Leegmaken huidige canvas.
		this.redraw();

		// Minmax en scalefactor bepalen op basis van drawobject minimale x en y en maximale x en y.
		this.setMinMax();
		this.setScaleFactor();

		// Offset begin van canvas op basis van breedte en scalefactor etc.
		this.setOffset();

		this.drawObjects.draw(this);

		this.drawObjects.id = Functions.uuidv4();
		this.ctx.stroke();
	}
	drawRectScaled(x, y, w, h) {
		this.ctx.beginPath();
		this.ctx.rect(x, y, w, h);
		this.ctx.closePath();
		this.ctx.fill();
	}
	addDrawObjects(objects) {
		objects.forEach((object, index) => {
			this.drawObjects.push(object);
		});
	}
	addDrawObject(object) {
		this.drawObjects.push(object);
	}
	scaled(value) {
		return value * this.scaleFactor;
	}
	setScaleFactor() {
		// bepaal de kleinste schaling
		this.scaleFactor = Math.min(
			(this.canvas.width - this.sizeHandles.totalSpace() - this.offset * 2) / this.width,
			(this.canvas.height - this.sizeHandles.totalSpace() - this.offset * 2) / this.height,
		);

		if (this.scaleFactor > 0.1) {
			// Niet groter dan 0.1
			this.scaleFactor = 0.1;
		}
		if (this.scaleFactor < 0) {
			// Niet negatief dan wordt de tekening gespiegeld
			this.scaleFactor = 0;
		}
	}
	setOffset() {
		// Tekening op het midden van het scherm
		this.offsetLeft = (this.canvas.width - this.width * this.scaleFactor) / 2;
		this.offsetTop = (this.canvas.height - this.height * this.scaleFactor) / 2 + 10;
		let wallWidthLeft = 20;
		let wallWidthTop = 10;

		this.offsetWallLeft = (this.canvas.width - (this.width - wallWidthLeft) * this.scaleFactor) / 2 + wallWidthLeft;
		this.offsetWallTop = (this.canvas.height - (this.height - wallWidthTop) * this.scaleFactor) / 2 + 10 + wallWidthTop;

		if (this.min.y < 0) {
			this.offsetTop -= this.min.y * this.scaleFactor;
		}
		if (this.min.x < 0) {
			this.offsetLeft -= this.min.x * this.scaleFactor;
			if (this.scaleFactor < 0.1) {
				this.offsetLeft += this.sizeHandles.totalSpace() / 4;
			}
		}
	}
}
