import Vue from 'vue';
import { Data } from './data';
import * as Sentry from '@sentry/browser';

export class Calculate extends Data {
	objectName = 'Calculate';
	static CURRENT = null;
	url = 'Calculate';
	retryLoadFloor(request) {
		request.retry = true;
		switch (request.cacheId) {
			case 'calculateFloor':
				// this.floor(
				// 	request.data,

				// 	result => {
				// 		if (typeof request.thenCallBack === 'function') {
				// 			request.thenCallBack(result);
				// 		}
				// 	},
				// 	exception => {
				// 		if (typeof request.catchCallBack === 'function') {
				// 			request.catchCallBack(exception);
				// 		}
				// 	},
				// );
				break;
			case 'maxOverSpan':
				// this.maxOverSpan(
				// 	request.data,

				// 	result => {
				// 		if (typeof request.thenCallBack === 'function') {
				// 			request.thenCallBack(result);
				// 		}
				// 	},
				// 	exception => {
				// 		if (typeof request.catchCallBack === 'function') {
				// 			request.catchCallBack(exception);
				// 		}
				// 	},
				// );
				break;
		}
	}
	getProfiles(data, thenCallBack, catchCallBack) {
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}
		// eerst check op variabelen en eventuele default-waarden
		let cacheId = 'maxOverSpan';

		// this.post(data, cacheId, this.baseUrl + 'calculate/maxoverspan', true, thenCallBack, catchCallBack, 'maxOverSpan');
		this.post(data, cacheId, this.baseUrl + 'calculate/maxoverspanMultipleProfiles', true, thenCallBack, catchCallBack, 'maxOverSpan');
		return this.listRequest[cacheId];
	}
	calculateMaxBeamLength(data, thenCallBack, catchCallBack) {
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}
		// eerst check op variabelen en eventuele default-waarden
		let cacheId = 'calculateMaxBeamLength';

		// this.post(data, cacheId, this.baseUrl + 'calculate/maxoverspan', true, thenCallBack, catchCallBack, 'maxOverSpan');
		this.post(data, cacheId, this.baseUrl + 'calculate/calculateMaxBeamLength', true, thenCallBack, catchCallBack, 'calculateMaxBeamLength');
		return this.listRequest[cacheId];
	}
	selectColumn(data, thenCallBack, catchCallBack) {
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}
		// eerst check op variabelen en eventuele default-waarden
		let cacheId = 'selectColumn';

		this.post(data, cacheId, this.baseUrl + 'calculate/selectcolumn', false, thenCallBack, catchCallBack, 'selectColumn');
		return this.listRequest[cacheId];
	}
	floor(data, thenCallBack, catchCallBack) {
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}
		// eerst check op variabelen en eventuele default-waarden
		let cacheId = 'calculateFloor';

		return this.post(data, cacheId, this.baseUrl + 'calculate/floor', true, thenCallBack, catchCallBack, 'floor');
	}
	post(data, cacheId, url, force, thenCallBack, catchCallBack, objectName) {
		if (this.listRequest === null) {
			this.listRequest = {}; // in dit geval geen null maar object nodig omdat ieder cacheId zijn eigen listrequest kan hebben
		}

		if (typeof this.lastRequest[cacheId] !== 'undefined' && this.lastRequest[cacheId] !== null && this.lastRequest[cacheId].retryWatch !== null && this.lastRequest[cacheId].retry !== true) {
			// als er al een keer een verzoek is geweest waarbij het fout is gegaan (retryWatch !== null) die wacht dan x tijd voor de volgende poging. Als het niet de volgende poging is (volgende poging krijgt retry=true)
			// dan negeren.
			return;
		}

		// als nog niet bestaat de cacheId als list dan toevoegen zodat hij reactive is
		if (typeof this._list[cacheId] === 'undefined' || this._list[cacheId] === null) {
			Vue.set(this._list, cacheId, []);
		}
		// lijst alleen opnieuw ophalen als:
		// - nog niet is opgehaald (undefind en null)
		// - het geforceerd moet
		// - het request (de data) afwijkend is
		// - het request de vorige keer niet geladen kon worden (in de catch kwam)
		// hierdoor wordt niet bij ieder request de lijst opgehaald van de server maar staat hij gecached

		if (
			typeof this._list[cacheId] === 'undefined' ||
			this._list[cacheId] === null ||
			this.sameRequest(data, cacheId) === false ||
			force === true ||
			(typeof this.lastRequest[cacheId] !== 'undefined' && this.lastRequest[cacheId] !== null && this.lastRequest[cacheId].loaded === false)
		) {
			if (typeof this.listRequest[cacheId] === 'undefined' || this.listRequest[cacheId] === null) {
				// als data meegegeven
				// verstuur het verzoek om de lijst op te halen
				this.listRequest[cacheId] = this.store.dataAccess
					.post(url, data, null, null, null, objectName)
					.then((result) => {
						Vue.set(this._list, cacheId, result);
						this.listRequest = null;

						if (typeof this.lastRequest[cacheId] === 'undefined' || this.lastRequest[cacheId] === null) {
							this.lastRequest[cacheId] = { retries: 0 };
						}
						this.lastRequest[cacheId].data = typeof data === 'undefined' || data === null ? {} : data;
						this.lastRequest[cacheId].cacheId = cacheId;

						if (this.lastRequest[cacheId].retry !== true) {
							// als het de retry is dan bevat de callback ook de functie naar de retry. De oude is de juiste
							this.lastRequest[cacheId].thenCallBack = thenCallBack;
							this.lastRequest[cacheId].catchCallBack = catchCallBack;
						}

						this.lastRequest[cacheId].retryWatch = null;
						if (typeof thenCallBack === 'function') {
							thenCallBack(result);
						}
						this.lastRequest[cacheId].retry = false;
						this.lastRequest[cacheId].loaded = true;

						// retry hier resetten voor volgende geforceerde verzoek
						this.lastRequest[cacheId].retries = 0;

						return this._list[cacheId];
					})
					.catch((exception) => {
						if (this.logError === true) {
							Sentry.captureMessage('url: ' + url + ', data: ' + JSON.stringify(data) + ', objectName: ' + this.objectName + ', error: ' + exception.message);
						}
						// bij catch wordt het verzoek ook opgeslagen. Alleen loaded op false en het aantal retries bijgehouden
						if (typeof this.lastRequest[cacheId] === 'undefined' || this.lastRequest[cacheId] === null) {
							this.lastRequest[cacheId] = { retries: 0 };
						}
						// als er een retry wacht hier deze resetten
						clearTimeout(this.lastRequest[cacheId].retryWatch);
						this.lastRequest[cacheId].data = typeof data === 'undefined' || data === null ? {} : data;

						this.lastRequest[cacheId].cacheId = cacheId;

						if (this.lastRequest[cacheId].retry !== true) {
							// als het de retry is dan bevat de callback ook de functie naar de retry. De oude is de juiste. Anders veroorzaakt dit een loop
							this.lastRequest[cacheId].thenCallBack = thenCallBack;
							this.lastRequest[cacheId].catchCallBack = catchCallBack;
						}
						this.lastRequest[cacheId].cacheId = cacheId;

						this.lastRequest[cacheId].loaded = false;
						// retry wordt hier op false gezet en zodra de retry wordt afgetrapt na timeout wordt hij op true gezet
						this.lastRequest[cacheId].retry = false;
						this.lastRequest[cacheId].retries++;

						// als nog niet maximaal retries dan timeout en verzoek opnieuw aftrappen
						if (this.lastRequest[cacheId].retries < this.maxRetries) {
							this.lastRequest[cacheId].retryWatch = setTimeout(() => {
								this.retryLoadFloor(this.lastRequest[cacheId]);
							}, 1000);
						}

						this.listRequest = null;
						if (typeof catchCallBack === 'function') {
							catchCallBack(exception);
						}
						return this._list[cacheId];
					});
			}
		} else if (typeof thenCallBack === 'function') {
			thenCallBack(this._list[cacheId]);
		}

		// geef de lijst terug.
		return this._list[cacheId];
	}
}
