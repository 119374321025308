import { Configuration } from '../configurator/configuration';
import { Dimensions } from './dimensions';
import { DrawValue } from './drawValue';
import { ObjectGroup } from './objectGroup';
import { DrawText } from './drawText';
import { SizeHandle } from './sizeHandle';
import { Line } from './line';

export class DimensionsVertical extends Dimensions {
	objectName = 'DimensionsVertical';

	addDrawObjects(canvas, params) {
		// maattekst
		let posX = params.sizeHandleSpace;

		let posY = 0;
		if (typeof params.startPosition !== 'undefined' && params.startPosition !== null) {
			posY = params.startPosition;
		}
		const sizeHandleGroup = new ObjectGroup(SizeHandle.COLOR, null, null, false, this, { type: 'sizeHandle' });
		this.dimensions.forEach((dimension, index) => {
			const drawGroup = new ObjectGroup(SizeHandle.COLOR, null, null, false, this, { direction: 'vertical', pos: index, type: 'sizeGroup' }); // direction is richting van waarin de sizehandle beweegt.
			drawGroup.push(
				new DrawText(
					new DrawValue(canvas.min.x, -posX),
					new DrawValue(posY),
					dimension,
					new DrawValue(dimension),
					null,
					null,
					null,
					null,
					'center',
					true,
					params.resizable === true && this.isSizeHandleResizable(canvas, dimension),
					this,
					{
						type: 'text',
						position: index,
					},
				),
			);
			drawGroup.push(
				new Line(new DrawValue(canvas.min.x, -posX + 5), new DrawValue(posY), new DrawValue(canvas.min.x, -posX + 5), new DrawValue(posY + dimension), null, null, null, null, null, false, this, {
					type: 'size',
				}),
			);
			drawGroup.push(
				new Line(new DrawValue(canvas.min.x, -posX), new DrawValue(posY), new DrawValue(canvas.min.x, -posX + 10), new DrawValue(posY), null, null, null, null, null, false, this, {
					type: 'sizeHandleStart',
				}),
			);
			drawGroup.push(
				new Line(
					new DrawValue(canvas.min.x, -posX),
					new DrawValue(posY + dimension),
					new DrawValue(canvas.min.x, -posX + 10),
					new DrawValue(posY + dimension),
					null,
					null,
					null,
					null,
					null,
					params.resizable === true && index < this.dimensions.length - 1 && this.isSizeHandleEndResizable(canvas, dimension),
					this,
					{
						type: 'sizeHandleEnd',
						position: index,
					},
				),
			);

			sizeHandleGroup.push(drawGroup);

			posY += dimension;
		});
		canvas.addDrawObject(sizeHandleGroup);

		return sizeHandleGroup;
	}
	onMouseDrag(evt, object, canvas, params) {
		const moveY = evt.delta.y / canvas.scaleFactor;
		let position = object.objectParams.position;

		let newLengthRaster = 0;
		let newLengthRasterAbove = 0;
		if (position < this.dimensions.length) {
			newLengthRasterAbove = Math.round(this.dimensions[position] + moveY);
		}
		if (position + 1 < this.dimensions.length) {
			newLengthRaster = Math.round(this.dimensions[position + 1] - moveY);
		}
		let parameters = { x: -1, y: position + 1, mainBeamDirection: Configuration.CURRENT.profiles.mainBeamDirection, newLengthRasterAbove: newLengthRasterAbove, newLengthRaster: newLengthRaster };

		if (this.movePossible(parameters) === false) {
			return;
		}

		if (position < this.dimensions.length) {
			this.dimensions[position] = parameters.newLengthRasterAbove;
		}
		if (position + 1 < this.dimensions.length) {
			this.dimensions[position + 1] = parameters.newLengthRaster;
		}
		params.move = moveY;
		params.raster = position + 1;
		params.lengthRaster = this.dimensions[position + 1];
		params.lengthRasterTop = this.dimensions[position];
		this.onChange(evt, object, canvas, params);
		return { stopPropagation: true };
	}

	onClick(evt, object, canvas, params) {
		let betweenMouseArea = evt.layerX < object.mouseArea.maxX && evt.layerX > object.mouseArea.minX;
		if (object.objectName === 'DrawText' && betweenMouseArea) {
			let position = object.objectParams.position;
			let newLengthNextRaster = 0;
			let newLengthCurrentRaster = 0;
			let newLengthPreviousRaster = 0;
			if (position < this.dimensions.length) {
				newLengthCurrentRaster = this.dimensions[position];
			}
			if (position + 1 < this.dimensions.length) {
				newLengthNextRaster = this.dimensions[position + 1];
			}

			if (position - 1 < this.dimensions.length && position - 1 >= 0) {
				newLengthPreviousRaster = this.dimensions[position - 1];
			}

			params.raster = position;
			params.newLengthNextRaster = newLengthNextRaster;
			params.newLengthCurrentRaster = newLengthCurrentRaster;
			params.newLengthPreviousRaster = newLengthPreviousRaster;

			Configuration.CURRENT.Dimensioning = {
				object: object,
				direction: 'vertical',
				params: params,
				evt: evt,
				change: (evt, object, canvas, params) => {
					this.onChanged(evt, object, canvas, params);
				},
			};
			Configuration.CURRENT.showDimensioning = true;
		}

		return { stopPropagation: true };
	}
	updateDrawObjects(groupObjectVertical, dimensions) {
		groupObjectVertical.drawObjects.forEach((drawGroup, index) => {
			let drawText = drawGroup.drawObjects.find((drawobject) => drawobject instanceof DrawText);
			let endPart = drawGroup.drawObjects.find((drawobject) => drawobject.objectParams.type === 'sizeHandleEnd');
			let beginPart = drawGroup.drawObjects.find((drawobject) => drawobject.objectParams.type === 'sizeHandleStart');
			// !! Aanpassen verticale lijntje einde 1e dimentie.
			if (index === 0) {
				if (endPart !== null && typeof endPart !== 'undefined') {
					endPart.y = new DrawValue(dimensions[0]);
					endPart.eindY = new DrawValue(dimensions[0]);
				}
				if (drawText !== null && typeof drawText !== 'undefined') {
					if (dimensions[0] < 0) {
						drawText.hide();
					} else {
						// Wanneer dan weer in het raster gesleept word weer laten zien.
						drawText.show();
					}
					drawText.width = new DrawValue(dimensions[0]);
					drawText.text = dimensions[0];
				}
			}

			// !! Aanpassen verticale lijntjes tussendimenties, dus bij slepen de breedte van het gesleepte element.
			if (index === 1) {
				if (beginPart !== null && typeof beginPart !== 'undefined') {
					beginPart.y = new DrawValue(dimensions[0]);
					beginPart.eindY = new DrawValue(dimensions[0]);
				}
				if (endPart !== null && typeof endPart !== 'undefined') {
					endPart.y = new DrawValue(dimensions[0] + dimensions[1]);
					endPart.eindY = new DrawValue(dimensions[0] + dimensions[1]);
				}
				if (drawText !== null && typeof drawText !== 'undefined') {
					// Bij slepen hoeven we geen drawText van het gesleepte object aan te passen.
					drawText.y = new DrawValue(dimensions[0]);
				}
			}
			// !! Aanpassen verticale lijntje laatste dimentie.
			if (index === 2) {
				if (beginPart !== null && typeof beginPart !== 'undefined') {
					beginPart.y = new DrawValue(dimensions[0] + dimensions[1]);
					beginPart.eindY = new DrawValue(dimensions[0] + dimensions[1]);
				}
				if (drawText !== null && typeof drawText !== 'undefined') {
					if (dimensions[2] < 0) {
						drawText.hide();
					} else {
						// Bij terugslepen dan weer laten zien.
						drawText.show();
					}
					drawText.y = new DrawValue(dimensions[0] + dimensions[1]);
					drawText.width = new DrawValue(dimensions[2]);
					drawText.text = dimensions[2];
				}
			}
		});
	}
}
