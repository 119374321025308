import { DimensionHorizontal3D } from './dimensionHorizontal3D';

export class DimensionHorizontalBottom3D extends DimensionHorizontal3D {
	objectName = 'DimensionHorizontalBottom3D';
	rotationX = -90;
	draw() {
		super.draw();
		super.addEntities();
	}
}
