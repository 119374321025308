import { Data } from './data';
import { IntroImage } from './introImage';

export class IntroImages extends Data {
	url = 'IntroImages';
	objectName = 'introImages';
	loadedImages = [];
	currentImageId = 0;
	loaded = false;
	get currentImage() {
		return this.loadedImages.find((item) => item.id === this.currentImageId);
	}
	load(salesLocation, force) {
		this.list({ salesLocation }, force, 'introImages-' + salesLocation, '', (data) => {
			this.currentImageId = data[0].id;
			data.forEach((element) => {
				this.loadedImages.push(this.fetchListItem(element));
			});
		});
	}

	fetchListItem(item) {
		var image = new IntroImage();
		image.fetch(item);
		return image;
	}
}
