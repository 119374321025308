import { Configuration } from '../configurator/configuration';
import { Country } from '../data/country';
import { Store } from '../data/store';

export class Address {
	objectName = 'Address';
	oid = 0;
	address = '';
	zip = '';
	city = '';
	country = new Country();
	countryCode = '';
	_deliveryAddressDifferent = false;

	get deliveryAddressDifferent() {
		return this._deliveryAddressDifferent;
	}
	set deliveryAddressDifferent(value) {
		if (this._deliveryAddressDifferent !== value) {
			this._deliveryAddressDifferent = value;
			this.onChange();
		}
	}
	constructor(onChange) {
		this._onChange = onChange;
	}
	getFullAddress() {
		if (this.deliveryAddressDifferent === true) {
			return this;
		}
		let visitAddress = Store.CURRENT.companies.selectedCompany.getCompanyAddressById(Store.CURRENT.companies.selectedCompany.visitAddress);
		if (typeof visitAddress !== 'undefined' && visitAddress !== null) {
			let country = new Country();
			if (visitAddress.country !== '') {
				let obj = Store.CURRENT.countries.getById(visitAddress.country);
				if (typeof obj !== 'undefined' && obj !== null) {
					country = obj;
				}
			}
			return { address: visitAddress.address, zip: visitAddress.zip, city: visitAddress.city, countryCode: country.code };
		}
	}
	onChange(status) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	setReferences(params) {
		this._onChange = params.save;
	}
	removeReferences() {
		this._onChange = null;
	}
	save(address) {
		this.oid = address.oid;
		this.address = address.address;
		this.zip = address.zip;
		this.city = address.city;
		this.country = address.country;
		this.countryCode = address.country.Code;
		this.onChange();
	}
	setDefaultDeliveryAddress() {
		if (
			Configuration.CURRENT.deliveryAddress.oid === 0 &&
			typeof Store.CURRENT.companies._selectedCompany.defaultDeliveryAdress !== 'undefined' &&
			Store.CURRENT.companies._selectedCompany.defaultDeliveryAdress !== null
		) {
			this.oid = Store.CURRENT.companies._selectedCompany.defaultDeliveryAdress.oid;
			this.address = Store.CURRENT.companies._selectedCompany.defaultDeliveryAdress.addressLine;
			this.zip = Store.CURRENT.companies._selectedCompany.defaultDeliveryAdress.zip;
			this.city = Store.CURRENT.companies._selectedCompany.defaultDeliveryAdress.city;
			this.country = Store.CURRENT.companies._selectedCompany.defaultDeliveryAdress.country;
			this.countryCode = Store.CURRENT.companies._selectedCompany.defaultDeliveryAdress.country.code;
		}
	}

	getSpecifications() {
		if (this.deliveryAddressDifferent === false) {
			return null;
		}
		return { addressLine: this.address, zip: this.zip, city: this.city, place: this.place, country: this.country, countryCode: this.countryCode, oid: this.oid === 0 ? -1 : this.oid };
	}

	setReferences(params) {
		if (typeof this.country === 'string') {
			this.country = new Country();
		}
	}
}
