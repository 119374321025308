<template>
	<div :class="inputField.inputClassName" v-show="visible">
		<div class="fields">
			<div>{{ description }}</div>
			<a-input :id="name" v-model="oValue" class="pwInput" :disabled="disabled" :placeholder="placeholder" autocomplete="off" @blur="blur" />
		</div>

		<div v-if="inputField.valid === false" class="error">{{ $translate(inputField.errorText, inputField.errorParameters) }}</div>
	</div>
</template>

<script>
export default {
	inject: ['inputFields'],
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		inputClassName: {
			type: String,
			default: 'col-md-6',
		},
		errorText: {
			type: String,
			default: '',
		}, // errorText is de tekst die verschijnt bij de defaultValidation
		error: {
			type: String,
			default: '',
		}, // error is een tekst die vanaf buiten ingesteld kan worden
		disabled: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	data: () => {
		return {
			oValue: '',
			inputField: {},
			valid: true,
		};
	},

	watch: {
		value: {
			handler() {
				this.oValue = this.value;
			},
			immediate: true,
		},
		error: {
			handler() {
				if (typeof this.inputFields[this.id] !== 'undefined') {
					this.inputFields[this.id].errorText = this.error;
					this.inputFields[this.id].error = this.error;
					this.inputFields[this.id].valid = this.error === '';
				}
			},
			immediate: true,
		},
		oValue: {
			handler() {
				this.$emit('update:value', this.oValue);
				this.validateValue();
			},
		},
	},

	mounted() {
		this.id = 'inputFieldNumber_' + this._uid;
		this.inputField = this.$set(this.inputFields, this.id, {
			id: this.id,
			name: this.name,
			visible: this.visible,
			description: this.description,
			errorText: this.error,
			error: this.error,

			inputClassName: this.inputClassName,
			validate: this.validateValue,
			placeholder: this.placeholder,
			valid: this.error === '',
		});
	},

	beforeDestroy() {
		this.$delete(this.inputFields, this.id);
	},
	destroyed() {},
	methods: {
		validateValue() {
			if (this.required === true && this.oValue === '') {
				this.inputFields[this.id].errorText = this.errorText;
				this.inputFields[this.id].errorParameters = { minGrade: this.min, maxGrade: this.max };
				this.inputFields[this.id].valid = false;
			} else {
				this.inputFields[this.id].errorText = this.inputFields[this.id].error;
				this.inputFields[this.id].valid = this.inputFields[this.id].error === '';
			}
			return this.inputFields[this.id].valid;
		},

		validate(evt) {
			this.$emit('validate');
		},
		click() {},
		blur() {
			this.$emit('blur');
		},
	},
};
</script>

<style lang="less" scoped>
@font-face {
	font-family: 'dotsfont';
	src: url('~/static/password-font/dotsfont.eot');
	src: url('~/static/password-font/dotsfont.eot?#iefix') format('embedded-opentype'), url('~/static/password-font/dotsfont.svg#font') format('svg'),
		url('~/static/password-font/dotsfont.woff') format('woff'), url('~/static/password-font/dotsfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.error {
	color: #b50000;
}
.tiny {
	.fields {
		.ant-input-number {
			margin: 0;
			width: 50px !important;
		}
	}
}
.ant-input:focus {
	box-shadow: none;
}
.ant-input:hover {
	border-color: #fcc43e;
}
.pwInput {
	font-family: dotsfont;
	font-size: 9px;
}
</style>
