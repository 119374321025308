import { Configuration } from './configuration';

export class OverSpan {
	objectName = 'OverSpan';
	childbeamLength = 0;
	mainbeamLength = 0;

	getRasters() {
		///
		let spansX = Configuration.CURRENT.raster.spansX.getSpans();
		let spansY = Configuration.CURRENT.raster.spansY.getSpans();
		let mainBeamDirectionHorizontal = Configuration.CURRENT.profiles.mainBeamDirection === 'x';
		let list = [];

		spansX.forEach((spanX) => {
			spansY.forEach((spanY) => {
				list.push({
					childbeamLength: mainBeamDirectionHorizontal ? spanY.value : spanX.value,
					mainbeamLength: mainBeamDirectionHorizontal ? spanX.value : spanY.value,
					amount: 1,
				});
			});
		});

		return list;
	}

	distinct() {
		let uniqueList = [];
		this.getRasters().forEach((overspan) => {
			let contains = uniqueList.find((o) => o.childbeamLength === overspan.childbeamLength && o.mainbeamLength === overspan.mainbeamLength);
			if (typeof contains === 'undefined') {
				uniqueList.push(overspan);
			} else {
				contains.amount++;
			}
		});
		return uniqueList;
	}
}
