import { Country } from './country';
import { Data } from './data';
import { Configuration } from './configuration';
import { Store } from './store';
import * as Sentry from '@sentry/browser';
export class Countries extends Data {
	objectName = 'Countries';
	static CURRENT = null;
	url = 'Countries';
	listOpen = true;
	countriesSearchList = [];

	_selectedCountryCode = '';
	get selectedCountryCode() {
		if (this._selectedCountryCode === '') {
			if (typeof Configuration.CURRENT === 'undefined' || Configuration.CURRENT === null) {
				return Store.CURRENT.companies.getDefaultCountryCode();
			}
			return Configuration.CURRENT.countryCode;
		}
		return this._selectedCountryCode;
	}
	set selectedCountryCode(value) {
		this._selectedCountryCode = value;
		let findCountry = this.getItem(value);
		if (findCountry.code !== '') {
			this._selectedCountry = findCountry;
		}
	}
	_selectedCountry = new Country();

	set selectedCountry(value) {
		this._selectedCountry = value;
	}
	get selectedCountry() {
		// selected country wordt nu gebruikt voor new-configuration daarom niet helemaal terecht. Maar dit was de makkelijkste manier om het goed aan de praat te krijgen
		if (this._selectedCountry.code === '') {
			let findCountry = this.getItem(this.selectedCountryCode);
			if (findCountry.code !== '') {
				this._selectedCountry = findCountry;
			}
		}

		return this._selectedCountry;
	}

	getMaxCtC(currentCountry) {
		if (typeof currentCountry === 'undefined' || currentCountry === null) {
			return 0;
		}
		let countries = this._list.list.filter((country) => country.code === currentCountry);
		if (typeof countries === 'undefined' || countries.length === 0 || typeof countries[0].zones === 'undefined') {
			return 0;
		}

		return countries[0].maxCtC;
	}

	zoneList(currentCountry) {
		if (typeof currentCountry === 'undefined' || currentCountry === null) {
			return [];
		}

		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let countries = this._list.list.filter((country) => country.code === currentCountry);
			if (typeof countries === 'undefined' || countries.length === 0 || typeof countries[0].zones === 'undefined') {
				return [];
			}
			return countries[0].zones.map((zone) => {
				return { value: zone.value, caption: zone.name };
			});
		} else if (this.listRequest === null) {
			this.list();
		}
		return [];
	}

	fullCountryList() {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			return this._list.list;
		}
	}

	get countryList() {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// lijst omzetten naar value/caption lijst voor het component
			let onalfabeticCountryList = this._list.list.map((country) => {
				return { value: country.code, caption: country.name };
			});

			// Lijst in alfabetische volgorde zetten
			let alfabeticCountryList = this.sortList(onalfabeticCountryList);
			return alfabeticCountryList;
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		}
		return []; // geen lijst dan lege array
	}
	getItem(currentCountry) {
		if (typeof currentCountry === 'undefined' && currentCountry === null) {
			return { code: '', name: '', fallingProtectionStandard: '' };
		}
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// lijst omzetten naar value/caption lijst voor het component
			let countries = this._list.list.filter((country) => country.code === currentCountry);
			if (typeof countries !== 'undefined' && countries.length > 0 && typeof countries[0].zones !== 'undefined') {
				return countries[0];
			}
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		}
		return { code: '', name: '', fallingProtectionStandard: '' }; // geen lijst of niet gevonden dan leeg object
	}
	getById(currentCountryId) {
		if (typeof currentCountryId === 'undefined' && currentCountryId === null) {
			return { code: '', name: '', fallingProtectionStandard: '' };
		}

		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// lijst omzetten naar value/caption lijst voor het component
			let countries = this._list.list.filter((country) => country.id === currentCountryId);
			if (typeof countries !== 'undefined' && countries.length > 0 && typeof countries[0].zones !== 'undefined') {
				return countries[0];
			}
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		}
		return { code: '', name: '', fallingProtectionStandard: '' }; // geen lijst of niet gevonden dan leeg object
	}

	sortList(list) {
		let newList = list.sort(function (a, b) {
			let x = a.caption.toLowerCase();
			let y = b.caption.toLowerCase();
			if (x < y) {
				return -1;
			}
			if (x > y) {
				return 1;
			}
			return 0;
		});

		return newList;
	}
	setSelectedValue(country, thenCallBack) {
		this.selectedCountry = country;

		if (typeof thenCallBack === 'function') {
			thenCallBack();
		}
		this.saveToLocalStorage();
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		data.language = Store.CURRENT.languages.currentLanguage;

		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
	searchCountries(data, urlExtension, thenCallBack, catchCallBack) {
		let language = this.store.languages.currentLanguage;
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof urlExtension === 'undefined' || urlExtension === null) {
			urlExtension = '?searchText=' + data + '&language=' + language;
		}
		if (data === '') {
			this.countriesSearchList = this.fullCountryList();
			if (typeof thenCallBack === 'function') {
				thenCallBack(result);
			}
			return;
		}
		let url = this.baseUrl + this.url + urlExtension;

		this.store.dataAccess
			.get(url)
			.then((result) => {
				this.countriesSearchList = result;
				if (typeof thenCallBack === 'function') {
					thenCallBack(result);
				}
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
				if (typeof catchCallBack === 'function') {
					catchCallBack(error);
				}
			});
	}
}
