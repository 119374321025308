import { Configuration } from '../../../../configurator/configuration';
import { jsonFinish } from '../../../../configurator/hicad/jsonFinish';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class LandingWoodPlate3D extends Model3D {
	objectName = 'LandingWoodPlate3D';
	hoverTags = ['deckingfinish', 'stairs'];
	constructor(x, y, params) {
		super(x, params.drawHeight, y, params);
		this.oid = params.stairData.material.landingFinish;
		this.texture = { name: 'woodplate-1000.jpg' };
	}
	draw(app) {
		this.y -= Configuration.CURRENT.finish.height;
		super.draw(app);
	}
	convert() {
		return new jsonFinish(this, jsonFinish.TYPE_WOODPLATE);
	}
}
