import { PalletGate3D } from './PalletGate3D';

export class PalletGateBottom3D extends PalletGate3D {
	objectName = 'PalletGateBottom3D';
	draw(app) {
		this.z += this.info.depth;
		// Dikte omgekeerde strip aan de buitenkant.
		this.z += 5;
		super.draw(app);
	}
}
