import { Data } from './data';

export class ConsequenceClasses extends Data {
	objectName = 'ConsequenceClasses';
	static CURRENT = null;
	url = 'Consequence';
	// voorlopig haalt hij nog niets op en laat hij alleen zien
	list() {
		return super.list();
	}
}
