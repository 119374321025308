import { Store } from '../data/store';
import { Construction } from './construction';
import { Transport } from './transport';
import { SiteSurvey } from './siteSurvey';
import { Service } from './service';

export class Services {
	objectName = 'Services';

	services = [];
	constructor(onChange) {
		this._onChange = onChange; // Onchange = delayedSave
		this.setServices();
	}
	onChange(status) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	setServices() {
		// ophalen services
		// op basis van type juiste constructor
		// als lijst leeg kijken of properties construction, transport en sitesurvey zijn gezet. Dan die overnemen. Historie
		// in constructor en afterconstruct aanroepen
		// niet meer naar data maar naar deze lijst kijken in index
		Store.CURRENT.services.list(
			null,
			false,
			null,
			'',
			(servicesData) => {
				if (typeof servicesData !== 'undefined' && servicesData !== null && servicesData.length > 0) {
					servicesData.forEach((serviceData, index) => {
						switch (serviceData.serviceType) {
							case 0:
								let findService = this.services.filter((s) => s.id === serviceData.id);
								let construction;

								if (findService.length > 0) {
									construction = findService[0];
									construction.updateConstruction(serviceData);
								} else {
									construction = new Construction(this.onChange.bind(this), serviceData);
									this.services.push(construction);
								}

								if (typeof this.construction !== 'undefined' && this.construction !== null) {
									construction._active = this.construction.active;
									construction._toolsActive = this.construction.toolsActive;
									construction._tools = this.construction.tools;
									construction._congestedSite = this.construction.congestedSite;
									this.construction = null;
								}
								break;
							case 1:
								findService = this.services.filter((s) => s.id === serviceData.id);
								let transport;
								if (findService.length > 0) {
									transport = findService[0];
									transport.update(serviceData);
								} else {
									transport = new Transport(this.onChange.bind(this), serviceData);
									this.services.push(transport);
								}

								if (typeof this.transport !== 'undefined' && this.transport !== null) {
									transport._active = this.transport.active;
									this.transport = null;
								}

								break;
							case 2:
								findService = this.services.filter((s) => s.id === serviceData.id);
								let siteSurvey;
								if (findService.length > 0) {
									siteSurvey = findService[0];
									siteSurvey.update(serviceData);
								} else {
									siteSurvey = new SiteSurvey(this.onChange.bind(this), serviceData);
									this.services.push(siteSurvey);
								}
								if (typeof this.siteSurvey !== 'undefined' && this.siteSurvey !== null) {
									siteSurvey._active = this.siteSurvey.active;
									this.siteSurvey = null;
								}

								break;
							default:
								findService = this.services.filter((s) => s.id === serviceData.id);
								let service;
								if (findService.length > 0) {
									service = findService[0];
									service.update(serviceData);
								} else {
									service = new Service(this.onChange.bind(this), serviceData);
									this.services.push(service);
								}

								break;
						}
					});
					let toRemove = [];
					this.services.forEach((service, index) => {
						let findService = servicesData.filter((s) => s.id === service.id);
						if (findService.length === 0) {
							toRemove.push(index);
						}
					});
					// toRemove.forEach((item, index) => {
					// 	this.services.splice(item + index, 1);
					// });
				}
			},
			(err) => {
				console.log('Probleem ophalen services', err);
			},
		);
	}
	setReferences(params) {
		this._onChange = params.save;
		this.services.forEach((service) => {
			if (typeof service.setReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				service.setReferences(params);
			}
		});
	}
	removeReferences() {
		this._onChange = null;
		this.services.forEach((service) => {
			if (typeof service.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				service.removeReferences();
			}
		});
	}
	getAmountServices() {
		let servicesAmount = [];

		this.services.forEach((service) => {
			servicesAmount.push(service.getAmount());
		});

		return servicesAmount;
	}
}
