import { PalletGate3D } from './PalletGate3D';

export class PalletGateLeft3D extends PalletGate3D {
	objectName = 'PalletGateLeft3D';
	rotationY = -90;
	draw(app) {
		// Buitenkant grijze strip.
		this.x -= 5;
		super.draw(app);
	}
}
