import { Canvas } from '../draw/canvas.js';
import { Store } from '../data/store.js';

import { Functions } from '../helpers/functions.js';
import { Canvas3D } from '../draw3d/Canvas3D';
import { DefaultFloor3D } from '../draw3d/DefaultFloor3D.js';
import { DeckingFinishDynamic3D } from '../draw3d/deckingFinish/DeckingFinishDynamic3D.js';
import { MiscellaneousArticles } from './miscellaneousArticles.js';
import { Raster } from './raster.js';
import { Etages } from './etages.js';
import { Etage } from './etage.js';
import { Profiles } from './profiles.js';
import { Notification } from './notification.js';

import { Overhang } from './overhang.js';

import { Columns } from './columns.js';
import { DeckingFinish } from './deckingFinish.js';
import { Serializer } from './serializer.js';
import { Tour } from './tour.js';
import { BuildingColumns } from './buildingColumns.js';

import { Colors } from './colors.js';
import { Services } from './services.js';
import { Address } from './address.js';
import { Errors } from './errors.js';
import { ContextMenu } from './contextmenu.js';
import { Building } from './building.js';
import { CustomError } from './CustomError.js';
import { Logging3D } from './logging3D.js';
import { SizeHandle } from '../draw/sizeHandle.js';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D.js';

export class Configuration {
	objectName = 'Configuration';
	canvasType = '2d';
	currentLoading3dAssetCount = 0;
	currentLoading3dAssetCountAfter = 0;
	information = {
		visible: false,
		type: 'info',
		title: '',
		message: '',
	};
	viewType = 0;
	static CURRENT = null;
	static DefaultColor = '7016';
	notification = new Notification();
	canvas = new Canvas();
	raster = new Raster(this.rasterChanged.bind(this));
	profiles = new Profiles(this.rasterChanged.bind(this));
	buildingColumns = new BuildingColumns(
		this.rasterChanged.bind(this),
		(boundaries, self) => {
			return this.checkCollisions(boundaries, self);
		},
		this.recreateDrawing.bind(this),
	);
	columns = new Columns(this.rasterChanged.bind(this));
	afterLoadAssets = [];
	contextMenu = new ContextMenu();
	services = new Services(this.delayedSave.bind(this));
	miscellaneousArticles = new MiscellaneousArticles(this.delayedSave.bind(this));
	deliveryAddress = new Address(this.delayedSave.bind(this));
	building = new Building(this.recreateDrawing.bind(this));

	// Save
	saveAfterFinish = false;
	saveAfterFinishSendStatus = null; // Bijhouden wat de status is van het nieuwe save request als er nog 1 bezig is.
	saveStarted = false; // Savestarted houd bij of er al een request loopt.
	delayedSaveStarted = false; // Houd bij of er al een delayedsaverequest (Save + SaveConfiguration) loopt.
	startRasterChangeAfterFinish = false;
	delayedSaveAfterFinish = false;

	colors = new Colors(this.delayedSave.bind(this));
	tour = new Tour();
	requestQuotationSaving = false;
	logging3D = new Logging3D();
	_contactPerson = '';

	get contactPerson() {
		return this._contactPerson;
	}
	set contactPerson(value) {
		if (this._contactPerson !== value) {
			this._contactPerson = value;
			this.rasterChanged();
		}
	}

	_consequenceClass = 'CC1';
	get consequenceClass() {
		return this._consequenceClass;
	}
	set consequenceClass(value) {
		this._consequenceClass = value;
	}
	_redrawIsReady = false;
	get redrawIsReady() {
		return this._redrawIsReady;
	}
	set redrawIsReady(value) {
		this._redrawIsReady = value;
	}

	consequenceClassOnClick(oldValue, newValue) {
		let floorApplication = Store.CURRENT.floorApplications.getByConsequenceClass(newValue);
		if (typeof floorApplication !== 'undefined' && floorApplication !== null && typeof floorApplication.defaultDeflection !== 'undefined' && floorApplication.defaultDeflection !== null) {
			this.profiles.centerToCenter = floorApplication.defaultCenterToCenterDistance.distance;
			this.profiles.deflection = floorApplication.defaultDeflection.deflection;
		}
	}
	_debugMode = false;
	_calculateWithNotInContract = false;
	reference = '';
	readOnly = false;
	waitForComplete = false;
	waitForCompleteTimer = null;
	inEditModus = false;
	editType = '';
	showEditModus = false;
	Dimensioning = null;
	showDimensioning = false;
	showColumnError = false;
	columnError = {};
	get hasErrors() {
		return this.errors.length > 0;
	}
	set hasErrors(value) {
		// voor historie. Eerst was hasError geen getter maar een variabele.
	}
	priceInfoMessages = { services: [] };
	errors = new Errors();
	preLoading = true;
	customerComments = '';
	newConfig = { length: 1000, width: 1000, height: 3000, minRaster: 4000 };
	debugInfo = { mainBeam: { name: '', amount: 0, doubleDouble: false, maxLength: 0 }, childBeam: { name: '', amount: 0, doubleDouble: false, maxLength: 0 }, scenarios: null };
	priceDebugInfo = { column: [], profile: [], deckingFinish: [] };
	rasterChangedStarted = false;

	recalculate = false;
	recalculateCount = 0;
	maxRecalculateCount = 3;
	priceInfo = {};
	objects3dAmount = 0;
	draw3D = false;
	MainBeamERP = false;
	ChildBeamERP = false;
	noMainbeamFound = false;
	noChildbeamFound = false;
	takingScreenshot = false;
	jsonDrawing = [];

	_showDimensioning3D = true;
	_drawDimensioning3DEachRaster = false;
	_drawDimensioningAllRasters = true;

	set showDimensioning3D(value) {
		this._showDimensioning3D = value;
		if (this.canvasType === '3d' && Canvas3D.CURRENT !== null && typeof Canvas3D.CURRENT !== 'undefined') {
			Canvas3D.CURRENT.toggleDimensioning(value);
		}
	}

	get showDimensioning3D() {
		return this._showDimensioning3D;
	}

	set drawDimensioning3DEachRaster(value) {
		this._drawDimensioning3DEachRaster = value;
		if (this.canvasType === '3d' && Canvas3D.CURRENT !== null && typeof Canvas3D.CURRENT !== 'undefined') {
			Canvas3D.CURRENT.drawNewDimesioning();
		}
	}
	get drawDimensioning3DEachRaster() {
		return this._drawDimensioning3DEachRaster;
	}

	set drawDimensioningAllRasters(value) {
		this._drawDimensioningAllRasters = value;
		if (this.canvasType === '3d' && Canvas3D.CURRENT !== null && typeof Canvas3D.CURRENT !== 'undefined') {
			Canvas3D.CURRENT.drawNewDimesioning();
		}
	}
	get drawDimensioningAllRasters() {
		return this._drawDimensioningAllRasters;
	}

	// Multiple Profiles mogelijk bij het verzoek 'maxoverspanMultipleProfiles'.
	// Afhankelijk van user rechten (zie user.js / multiProfilesPossible en AfterReconstruct in deze file).
	_multipleProfiles = null;
	set multipleProfiles(value) {
		if (this._multipleProfiles !== value) {
			this._multipleProfiles = value;
			this.rasterChanged();
		}
	}
	get multipleProfiles() {
		return this._multipleProfiles;
	}

	get floorApplication() {
		let oid = 0;

		Store.CURRENT.floorApplications.getByConsequenceClass(this.consequenceClass, (floorApplication) => {
			if (typeof floorApplication !== 'undefined' && floorApplication !== null) {
				oid = floorApplication.oid;
			}
		});

		return oid;
	}

	getDeckingFinishType() {
		if (Configuration.CURRENT.finish.articleGroup === 'DeckingFinishSteelGrating') {
			return DeckingFinishDynamic3D.TYPE_GRATING;
		} else {
			return DeckingFinishDynamic3D.TYPE_WOODPLATE;
		}
	}
	_handRailType = -1;
	get handRailType() {
		// Kijken op basis van huidig opgeslagen item of deze wel in de lijst zit, en niet null -1 of undefined is. Als hij niet in de lijst zit dan updaten naar de standaard waarde.
		if (typeof this._handRailType === 'undefined' || this._handRailType === -1 || this._handRailType === null || Store.CURRENT.handRails.inList(this._handRailType) === false) {
			this._handRailType = Store.CURRENT.handRails.getDefaultItem().oid;
		}
		return this._handRailType;
	}
	set handRailType(value) {
		this._handRailType = value;
	}
	get debugMode() {
		return this._debugMode;
	}
	set debugMode(value) {
		if (this._debugMode !== value) {
			this._debugMode = value;
			this.rasterChanged();
		}
	}
	get calculateWithNotInContract() {
		return this._calculateWithNotInContract;
	}
	set calculateWithNotInContract(value) {
		if (this._calculateWithNotInContract !== value) {
			this._calculateWithNotInContract = value;
			this.rasterChanged();
		}
	}

	_ledOptionType = -1;
	get ledOptionType() {
		if (typeof this._ledOptionType === 'undefined' || this._ledOptionType === -1) {
			this._ledOptionType = Store.CURRENT.ledOptions.defaultItem.oid;
		}
		return this._ledOptionType;
	}
	set ledOptionType(value) {
		this._ledOptionType = value;
	}

	calculationTotal = '€ 0,00';
	newConfiguration = true;
	_variableLoad = 350;
	get variableLoad() {
		return this._variableLoad;
	}
	set variableLoad(value) {
		let maxCtC = Store.CURRENT.floorVariableLoads.getMaxCtC(value);
		if (maxCtC > 0 && this.profiles.centerToCenter > maxCtC) {
			let newCenterToCenter = Store.CURRENT.centerToCenterDistances.getLower(maxCtC);
			window.Vue.$confirm({
				isHtml: true,
				title: window.Vue.$translate('confirm.title.selectedValueNotValid'),
				content: window.Vue.$translate('confirm.content.variableLoad', {
					currentCenterToCenter: this.profiles.centerToCenter,
					newCenterToCenter: newCenterToCenter,
				}),
				okText: window.Vue.$translate('confirm.button.yes'),
				cancelText: window.Vue.$translate('confirm.button.no'),
			})
				.then((success) => {
					// Ok button pressed
					this._variableLoad = value;
					this.profiles.centerToCenter = newCenterToCenter;
					this.rasterChanged();
				})
				.catch((cancel) => {
					// Cancel button pressed
				});
		} else {
			this._variableLoad = value;
			this.rasterChanged();
		}
	}

	showNewConfiguration = false;

	_earthQuakeZone = '';
	get earthQuakeZone() {
		return this._earthQuakeZone;
	}
	set earthQuakeZone(value) {
		this._earthQuakeZone = value;
	}
	etages = new Etages(
		[new Etage('above', 2500, true)],

		() => {
			this.setAccessoriesType('');
			this.recreateDrawing();
		},
		this.rasterChanged.bind(this),
		this.raster,
		this.recreateDrawing.bind(this),
	);

	calculationRequest = [];
	activeEtage = 1;
	rasterChangeTimeOut = 250;
	rasterChangeWaiting = null;
	saveTimeOut = 50;
	_initializing = true;

	// Loading bar for 3D
	_loadingbar3D = false;
	_loadingbarText = 'Loading the 3D canvas';

	// If switch is available, only when not saving or changing important configuration data.
	_switch3DAvailable = false;

	_draw3DAvailable = false;
	loadingNewFloor = false;
	get initializing() {
		return this._initializing || this.loadingNewFloor;
	}
	set initializing(value) {
		this._initializing = value;
	}

	get loadingbar3D() {
		return this._loadinbar3D;
	}
	set loadingbar3D(value) {
		this._loadingbar3D = value;
	}

	get loadingbarText() {
		return this._loadingbarText;
	}
	set loadingbarText(value) {
		this._loadingbarText = value;
	}

	get switch3DAvailable() {
		return this._switch3DAvailable;
	}
	set switch3DAvailable(value) {
		this._switch3DAvailable = value;
	}

	get draw3DAvailable() {
		return this._draw3DAvailable;
	}
	set draw3DAvailable(value) {
		this._draw3DAvailable = value;
	}

	finish = new DeckingFinish(this.rasterChanged.bind(this));
	overhang = new Overhang(this.rasterChanged.bind(this));

	totalWeight = 0;
	step = 50; // voorkomt te kleine stapjes in tekenvel
	accessoriesType = '';

	_countryCode = '';
	get countryCode() {
		// Geen countrycode gekozen dan kijken of er een defaultCountry is.
		if (typeof this._countryCode === 'undefined' || this._countryCode === null || this._countryCode === '') {
			this._countryCode = Store.CURRENT.companies.getDefaultCountryCode();
		}
		if (this._countryCode === '') {
			// geen country nog gekozen en default is (nog) leeg dan NL teruggeven. Bij volgende keer opvragen wordt alsnog gekeken of er een default is
			return 'NL';
		}
		return this._countryCode;
	}
	set countryCode(value) {
		let maxCtC = Store.CURRENT.countries.getMaxCtC(value);

		if (maxCtC > 0 && this.profiles.centerToCenter > maxCtC && this.preLoading === false) {
			let newCenterToCenter = Store.CURRENT.centerToCenterDistances.getLower(maxCtC);
			window.Vue.$confirm({
				isHtml: true,
				title: window.Vue.$translate('confirm.title.selectedValueNotValid'),
				content: window.Vue.$translate('confirm.content.country', {
					currentCenterToCenter: this.profiles.centerToCenter,
					newCenterToCenter: newCenterToCenter,
				}),
				okText: window.Vue.$translate('confirm.button.yes'),
				cancelText: window.Vue.$translate('confirm.button.no'),
			})
				.then((success) => {
					// Ok button pressed
					this._countryCode = value;
					this.profiles.centerToCenter = newCenterToCenter;
					this.rasterChanged();
				})
				.catch((cancel) => {
					// Cancel button pressed
				});
		} else {
			this._countryCode = value;
			this.rasterChanged();
		}
	}

	reconstruct() {
		Configuration.CURRENT = this;
		Configuration.Canvas3D = null; // voor oude configuraties. Eerst werd 3d opgeslagen maar zorgt bij opslaan voor circulair
	}
	checkCollisions(boundaries, self) {
		// objecten die hier staan controleren met andere objecten per etage
		if (typeof boundaries === 'undefined' || boundaries === null) {
			return;
		}
		let checkCollisions = this.etages.checkCollisions(boundaries, self);

		return checkCollisions;
	}
	removeReferences() {
		clearTimeout(this.rasterChangeWaiting);
		this.onLastCalculationRequestCompleted = null;
		if (typeof this.etages.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.etages.removeReferences();
		}
		if (typeof this.raster.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.raster.removeReferences();
		}
		if (typeof this.profiles.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.profiles.removeReferences();
		}
		if (typeof this.finish.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.finish.removeReferences();
		}
		if (typeof this.overhang.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.overhang.removeReferences();
		}
		if (typeof this.columns.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.columns.removeReferences();
		}
		if (typeof this.buildingColumns.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.buildingColumns.removeReferences();
		}
		if (typeof this.services.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.services.removeReferences();
		}
		if (typeof this.miscellaneousArticles.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.miscellaneousArticles.removeReferences();
		}
		if (typeof this.colors.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.colors.removeReferences();
		}
		if (typeof this.deliveryAddress.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.deliveryAddress.removeReferences();
		}
		if (typeof this.building.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.building.removeReferences();
		}

		if (typeof this.canvas.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.canvas.removeReferences();
		}
	}

	afterReconstruct() {
		this.canvas.init('2d');
		this.calculationRequest = [];
		this.rasterChangedStarted = false;
		this.delayedSaveStarted = false;
		this.showEditModus = false;
		this.saveStarted = false;
		this.accessoriesType = '';
		this.calculationRequest = [];
		this.requestQuotationSaving = false;
		this.loadingbar3D = false;
		this.switch3DAvailable = false;
		this.showNewConfiguration = false;
		this.takingScreenshot = false;
		this.noMainbeamFound = false;
		this.noChildbeamFound = false;
		this.showDimensioning = false;
		this.showDimensioning3D = true;
		this.saveAfterFinishSendStatus = null;
		this.draw3DAvailable = false;

		// Wanneer de waarde op true staat (Door eventueel eerdere keer inladen configuratie of zelf aangepast)
		// En de rechten zijn veranderd van de user dan updaten naar false.
		if (Store.CURRENT.user.multiProfilesPossible === false && this.multipleProfiles === true) {
			this.multipleProfiles = false;
		}
		// Wanneer gebruiker multipleprofiles mag en de setting in configuration op null staat (Dus nog nooit is aangepast), dan updaten we deze naar true.
		if (Store.CURRENT.user.multiProfilesPossible === true && this.multipleProfiles === null) {
			this.multipleProfiles = true;
		}

		if (this.lastSaveTime !== null) {
			this.lastSaveTime = new Date(this.lastSaveTime);
		}
		this.etages.setReferences({
			onChange: () => {
				this.setAccessoriesType('');
				this.recreateDrawing();
			},
			redraw: this.recreateDrawing.bind(this),
			rasterChanged: this.rasterChanged.bind(this),
			raster: this.raster,
		});

		let params = {
			onChange: this.rasterChanged.bind(this),
			redraw: this.recreateDrawing.bind(this),
			save: this.delayedSave.bind(this),
			checkCollisions: (boundaries, self) => {
				return this.checkCollisions(boundaries, self);
			},
		};

		this.raster.setReferences(params);
		this.profiles.setReferences(params);
		this.finish.setReferences(params);
		this.overhang.setReferences(params);
		this.columns.setReferences(params);
		if (typeof this.building !== 'undefined') {
			if (typeof this.building.setReferences === 'function') {
				this.building.setReferences(params);
			}
		}

		if (typeof this.buildingColumns.setReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.buildingColumns.setReferences(params);
		}
		if (typeof this.services.setReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.services.setReferences(params);
		}
		if (typeof this.miscellaneousArticles.setReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.miscellaneousArticles.setReferences(params);
		}
		if (typeof this.colors.setReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.colors.setReferences(params);
		}
		if (typeof this.deliveryAddress.setReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.deliveryAddress.setReferences(params);
		}

		this.deselectAll();
		this.recreateDrawing();
	}
	constructor(settings) {
		Store.CURRENT.configurations.in3DDrawState = false;
		Store.CURRENT.configurations.initializeCanvas2dDone = false;
		Store.CURRENT.configurations.initializeCanvasddDone = false;

		Configuration.CURRENT = this;
		this.id = Functions.uuidv4();
		if (typeof settings !== 'undefined' && settings !== null) {
			this.profiles.maxHeight = settings.maxHeight;
			this.step = settings.step;
			this.profiles.centerToCenter = settings.centerToCenterDistance;
			if (this.profiles.centerToCenter > Store.CURRENT.countries.getMaxCtC(this.countryCode)) {
				this.profiles.centerToCenter = Store.CURRENT.countries.getMaxCtC(this.countryCode);
			}
			this.profiles.deflection = settings.deflection;

			this.variableLoad = settings.variableLoad;
			this.countryCode = settings.countryCode;
			this.profiles.hotFormedPossible = settings.useHotFormed;
			this.profiles.doubleDoublePossible = settings.useDoubleDouble;
			this.profiles.sameHeight = settings.sameHeight;
			this.mainBeamDirection = settings.mainBeamDirection;
			this.debugMode = settings.debugMode;
			this.calculateWithNotInContract = settings.calculateWithNotInContract;
			this.application = settings.application;
			this.contactPerson = settings.contactPerson;
			this.multipleProfiles = settings.multipleProfiles;
		}
	}
	startCalculationRequest(uid) {
		this.calculationRequest.push(uid);
	}
	onLastCalculationRequestCompleted = null;
	endCalculationRequest(uid) {
		let index = this.calculationRequest.indexOf(uid);
		if (index !== -1) {
			this.calculationRequest.splice(index, 1);
		}
		if (this.calculationRequest.length === 0 && this.onLastCalculationRequestCompleted !== null && typeof (this.onLastCalculationRequestCompleted === 'function')) {
			this.onLastCalculationRequestCompleted();
		}
	}
	// DelayedSave puur voor save en saveconfiguration aan te roepen, geen rasterchanged of redraw dingen.
	delayedSave(status = null) {
		if (this.delayedSaveStarted === true) {
			this.delayedSaveAfterFinish = true;
			return;
		}

		if (!this.delayedSaveStarted) {
			this.delayedSaveStarted = true;
			this.save(status);
			this.saveConfiguration();
		}
	}
	save(status) {
		if (this.saveStarted === true) {
			// niet opnieuw starten als hij al loopt.
			this.saveAfterFinish = true; // wel bijhouden dat hij het nog een keer moet starten omdat er toch nog wat gewijzigd is.
			if (typeof status !== 'undefined' && status !== null) {
				this.saveAfterFinishSendStatus = status;
			}
			return;
		}

		if (this.readOnly === true || Store.CURRENT.configurations.readOnlyFromURL) {
			return; // niet opslaan bij readonly
		}
		if (this.raster.getSizeX() === 0 || this.raster.getSizeY() === 0) {
			return; // als object niet goed is binnengekomen niet opslaan
		}
		this.saveStarted = true;
		this.saveFinised = false;

		try {
			// voorkom fouten bij grote vloeren
			localStorage.setItem('configuration', json);
		} catch (exception) {}
		let request = {
			reference: this.reference,
			articles: this.getAmount(),
			services: this.services.getAmountServices(),
			specifications: this.getSpecifications(),
			debugMode: Configuration.CURRENT.debugMode,
			colors: this.colors.getColors(),
		};

		if (typeof status !== 'undefined' && status !== null) {
			request.status = status;
		}
		if (status === 'Quotation Request') {
			// We vragen de configuratie aan.
			request.quotation = 'Quotation Request';
			this.requestQuotationSaving = true;

			// const Canvas = document.getElementById('2d');
			// const canvasImage = Canvas.toDataURL('image/png');
			const serializer = new Serializer();
			const json = serializer.stringify(request);

			request.json = json;
			// request.drawing = canvasImage;
		}
		Store.CURRENT.configurations.save(
			request,
			null,
			(result) => {
				this.priceInfo = result.priceInfo;
				this.priceDebugInfo = result.priceDebugInfo;
				this.calculationTotal = result.calculationTotal;
				this.readOnly = result.readOnly;
				if (status === 20) {
					Configuration.CURRENT.notification.hide();
					Configuration.CURRENT.notification.show(window.Vue.$translate('saving.quotationRequestSaved'), 5000);
				}
				this.drawObjects3d = [];
				if (typeof result.messages === 'undefined' || result.messages === null) {
					this.priceInfoMessages = { services: [] };
				} else {
					this.priceInfoMessages = result.messages;
				}
				this.saveStarted = false;
				this.saveFinised = true;
				this.requestQuotationSaving = false;
				this.delayedSaveStarted = false;
				if (this.saveAfterFinish === true) {
					this.saveAfterFinish = false;
					this.save(this.saveAfterFinishSendStatus);
				} else if (this.delayedSaveAfterFinish === true) {
					this.delayedSaveAfterFinish = false;
					this.delayedSave(this.saveAfterFinishSendStatus);
				}
			},
			(exception) => {
				console.log(exception);
				if (status === 20) {
					Configuration.CURRENT.notification.hide();
					Configuration.CURRENT.notification.show(window.Vue.$translate('saving.quotationRequestNotSaved'), 5000);
				}
				this.saveFinised = false;
				this.drawObjects3d = [];
				this.requestQuotationSaving = false;
				this.saveStarted = false;
				this.delayedSaveStarted = false;
				if (this.saveAfterFinish === true) {
					this.saveAfterFinish = false;
					this.save(this.saveAfterFinishSendStatus);
				} else if (this.delayedSaveAfterFinish === true) {
					this.delayedSaveAfterFinish = false;
					this.delayedSave(this.saveAfterFinishSendStatus);
				}
			},
		);
	}

	getSpecifications() {
		let specifications = {
			length: this.etages.floor.length + this.overhang.size * 2,
			width: this.etages.floor.width + this.overhang.size * 2,
			totalHeight: this.etages.totalHeight,
			amountEtages: this.etages.etages.length,
			customerComments: this.customerComments,
			centerToCenter: this.profiles.centerToCenter,
			earthQuakeZone: this.earthQuakeZone,
			maxLoadOnFloor: this.variableLoad,
			maxColumnPressure: this.columns.getMaxColumnPressure(),
			deflection: this.profiles.deflection,
			consequenceClass: this.consequenceClass,
			totalSurface: 0,
			floors: [],
			address: this.deliveryAddress.getSpecifications(),
			gridHorizontal: this.raster.getMaxSpanvalue(this.raster.spansX.spans),
			gridVertical: this.raster.getMaxSpanvalue(this.raster.spansY.spans),
			contactPerson: this.contactPerson,
		};

		let etages = this.etages.getEtages();
		if (etages.length > 0) {
			specifications.finishedFloorLevel = etages[0].getHeight(true);
			specifications.freeHeight = etages[0].getHeight(false);

			this.etages.getEtages().forEach((etage, index) => {
				specifications.floors.push({ floorId: index, freeHeight: etage.getHeight(false), packetHeight: etage.getPacketHeight(), surface: etage.getSurface() });
				specifications.totalSurface += etage.getSurface();
			});
		}
		return specifications;
	}

	getAmount() {
		// dubbel met calculateAmount. Voorlopig onderscheiden en is dit alleen de verzameloptie en bij calculate berekent hij het.
		// get amount zou dan ook in de ui gebruikt kunnen worden
		let amountObject = {};
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].getAmount === 'function') {
				let amount = this[object].getAmount(amountObject);
				Object.keys(amount).forEach((object) => {
					amountObject[object] = amount[object];
				});
			}
		});
		this.detectErrors();

		return amountObject;
	}

	init(id, newCanvas = false) {
		if (newCanvas) {
			Canvas3D.CURRENT = null;
		}
		// extra functie init in plaats van constructor omdat constuctor in data-structuur staat en init pas na rendering plaats vindt ivm beschikbaarheid canvas-element
		this.canvas.etages = this.etages;
		this.canvas.outerMargin = this.overhang.size;
		if (typeof id !== 'undefined') {
			// this.canvas.init(id);

			this.raster.setStep(this.step);

			// hier al kolombeschermers ophalen zodat ids bij save beschikbaar zijn
			Store.CURRENT.columnProtectors.list();
		}
		if (this.deliveryAddress.oid === null || this.deliveryAddress.oid === -1) {
			this.deliveryAddress.setDefaultDeliveryAddress();
		}
	}
	setPanel(panel = null) {
		if (panel === null) {
			if (typeof this.openedPanel !== 'undefined') {
				this.openedPanel.active = false;
				this.openedPanel = {};
			}
		} else {
			this.openedPanel = panel;
		}
	}

	openPanel(type, toggle = false) {
		this.accessoriesType = toggle ? (this.accessoriesType === type ? '' : type) : (this.accessoriesType = type);
		this.inEditModus = true;
		this.notification.text = type.toString() + '.position';
		this.showEditModus = true;
		if (typeof this.openedPanel !== 'undefined') {
			this.openedPanel.open = false;
		}
	}
	closePanel() {
		this.accessoriesType = '';
		this.inEditModus = false;
		this.notification.text = '';
		this.showEditModus = false;

		if (this.columns !== null && typeof this.columns !== 'undefined') {
			this.columns.closedPanel();
		}

		if (typeof this.openedPanel !== 'undefined') {
			this.openedPanel.open = true;
		}
	}

	setAccessoriesType(type, toggle) {
		let old = this.accessoriesType;

		if (type === '') {
			this.closePanel();
		}

		// Panel wat in het midden onderaan staat
		if ((type !== this.accessoriesType || toggle) && type !== '') {
			this.openPanel(type, toggle);
		}

		if (old !== this.accessoriesType) {
			this.redraw();
		}
	}

	createConfiguration(object) {
		this.initializing = true;
		this.preLoading = false;
		this.newConfiguration = false;
		this.loadingNewFloor = true;
		this.profiles.centerToCenter = object.selectedCenterToCenter;
		this.variableLoad = object.selectedVariableLoad;
		this.profiles.deflection = object.selectedDeflection;

		this.countryCode = object.selectedCountryCode;
		this.earthQuakeZone = object.selectedEarthQuake;
		this.finish.selected = object.selectedFinishObject.id;
		this.newConfig.length = object.selectedLength;
		this.newConfig.width = object.selectedWidth;
		this.newConfig.height = object.selectedHeight;
		this.newConfig.minRaster = object.selectedMinRaster;

		this.etages.get(0).height = object.selectedHeight;
		this.reference = object.reference;
		this.consequenceClass = object.selectedConsequenceClass;
		this.colors.getDefaultList();
		let deckingFinishId = -1;
		if (this.finish != null) {
			deckingFinishId = this.finish.id;
		}

		const data = {
			countryCode: this.countryCode,
			maxHeight: this.profiles.maxHeight,
			step: this.step,
			deflection: this.profiles.deflection,
			variableLoad: this.variableLoad,
			centerToCenterDistance: this.profiles.centerToCenter,
			useHotFormed: this.profiles.hotFormedPossible,
			useDoubleDouble: this.profiles.doubleDoublePossible,
			profileSameHeight: this.profiles.sameHeight,
			calculateFloorType: object.activeTab.name,
			width: this.newConfig.width - this.overhang.size * 2,
			length: this.newConfig.length - this.overhang.size * 2,
			mainBeamDirection: '',
			height: this.newConfig.height,
			debugMode: this.debugMode,
			calculateWithNotInContract: this.calculateWithNotInContract,
			companyId: Store.CURRENT.companies.selectedCompany.id,
			consequenceClass: this.consequenceClass,
			DeckingFinishId: deckingFinishId,
			minRaster: this.newConfig.minRaster,
		};
		Store.CURRENT.calculate.floor(
			data,
			(response) => {
				this.canvas.init('2d');
				Object.keys(this).forEach((object, index) => {
					// tijdelijke oplossing. toevoegen raster om die uit te sluiten omdat die ook via de actieve etage getekend wordt
					if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].createNewConfiguration === 'function') {
						this[object].createNewConfiguration(response);
					}
				});

				setTimeout(() => Store.CURRENT.configurations.resetCache(), 60000); // na minuut configurations opnieuw ophalen. Lijst dan juist gevuld
				this.showNewConfiguration = false;
				this.loadingNewFloor = false;
				this.initializing = true;

				if (this.debugMode === true) {
					this.debugInfo = {
						mainBeam:
							response.mainBeam !== null
								? {
										name: response.mainBeam.profile !== null ? response.mainBeam.profile.description : '',
										amount: response.mainBeam.amount,
										doubleDouble: response.mainBeam.doubleDouble,
										maxLength: response.maxMainBeam,
								  }
								: { name: '', amount: 0, doubleDouble: false, maxLength: 0 },
						childBeam:
							response.childBeam !== null
								? {
										name: response.childBeam.profile !== null ? response.childBeam.profile.description : '',
										amount: response.childBeam.amount,
										doubleDouble: response.childBeam.doubleDouble,
										maxLength: response.maxChildBeam,
								  }
								: { name: '', amount: 0, doubleDouble: false, maxLength: 0 },
						scenarios: response.scenarios,
					};
				} else {
					this.debugInfo = { mainBeam: { name: '', amount: 0, doubleDouble: false, maxLength: 0 }, childBeam: { name: '', amount: 0, doubleDouble: false, maxLength: 0 }, scenarios: {} };
				}
			},
			(error) => {
				console.log(error);
				this.notification.show('alert.wrong', 5000, null, false);
				this.initializing = false;

				this.showNewConfiguration = true;
				this.loadingNewFloor = false;
			},
		);
	}
	select(parameters, canvas) {
		// Nieuw element selecteren = sizehandles leeggooien, in nieuwe object worden ze dan weer toegevoegd.
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].select === 'function') {
				this[object].select(parameters, canvas);
			}
		});

		this.redraw();
	}

	deselectAll() {
		// Na inladen configuratie alles deselecteren.
		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].select === 'function') {
				this[object].select({ id: null });
			}
		});

		// Na inladen configuratie menu hiden en alle items weggooien.
		this.contextMenu.hide();
		this.contextMenu.setItems();
	}
	rasterChanged(params) {
		if (this.preLoading === true || this.raster.spansX.length === 0 || this.raster.spansY.length === 0) {
			return;
		}

		this.switch3DAvailable = false;

		this.redraw();
		if (this.rasterChangedStarted === true) {
			// niet opnieuw starten als hij al loopt.
			this.startRasterChangeAfterFinish = true; // wel bijhouden dat hij het nog een keer moet starten omdat er toch nog wat gewijzigd is.
			return;
		}
		// timeout voorkomt bij wijzigingen achter elkaar dat hij steeds afgetrapt wordt.
		clearTimeout(this.rasterChangeWaiting);
		this.rasterChangeWaiting = setTimeout(() => {
			this.rasterChangedStarted = true;
			this.profiles.setMaxOverSpan(() => {
				Object.keys(this).forEach((object, index) => {
					if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].onRasterChanged === 'function') {
						this[object].onRasterChanged({ etages: this.etages, raster: this.raster, centerToCenter: this.profiles.centerToCenter, profiles: this.profiles, changedParams: params });
					}
				});
				Object.keys(this).forEach((object, index) => {
					if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].calculateAmount === 'function') {
						this[object].calculateAmount({ etages: this.etages, raster: this.raster, centerToCenter: this.profiles.centerToCenter, profiles: this.profiles });
					}
				});
				if (this.calculationRequest.length === 0) {
					this.afterCalculationRequest();
				} else {
					// als nog niet alle verzoeken afgehandeld zijn. Timer gebruiken die wacht tot 1 minuut. Als dan nog niet afgehandeld wel afterSaveRequest uitvoeren
					this.waitForComplete = true;
					this.waitForCompleteTimer = setTimeout(() => {
						if (this.waitForComplete === true) {
							this.afterCalculationRequest();
						}
					}, 60000);
					this.onLastCalculationRequestCompleted = () => this.afterCalculationRequest();
				}
			}, params);
		}, this.rasterChangeTimeOut);
	}
	// Opnieuw tekenen van het canvas,
	recreateDrawing() {
		this.redrawIsReady = false;
		this.totalWeight = 0;

		Object.keys(this).forEach((object, index) => {
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].getTotalWeight === 'function') {
				this.totalWeight += this[object].getTotalWeight();
			}
		});

		// We maken alvast de objecten, later kijken of dit performance wijs snel genoeg is anders objecten pas creëren bij het switchen van viewmode.
		this.collisionCheck();
		this.createDrawObjects();
		this.detectErrors();
		this.canvas.draw();

		if (this.initializing === true && this.preLoading === false) {
			// eerste keer tekenen of eerste keer draw na laden dan uitzetten
			this.initializing = false;
		}

		this.redrawIsReady = true;
	}
	// After calculationrequest wanneer de rasterchanged klaar is en alles opnieuw berekent is komt hij hierin, vanuit hier dan ook save aanroepen.
	afterCalculationRequest() {
		this.recreateDrawing();
		this.delayedSave(null);

		this.waitForComplete = false;
		this.rasterChangedStarted = false;
		this.delayedSaveStarted = false;

		if (this.startRasterChangeAfterFinish === true || this.recalculate === true) {
			// recalculate wordt gezet als het tijdens het rekenproces duidelijk wordt dat er opnieuw gerekend moet worden. Om een loop te voorkomen een count bijhouden
			if (this.recalculate === true) {
				this.recalculate = false;
				this.recalculateCount++;
				if (this.recalculateCount > this.maxRecalculateCount) {
					// voorkom oneindige loop van steeds berekenen
					this.recalculateCount = 0;
					return;
				}
			} else {
				this.recalculateCount = 0;
			}

			// wijzigingen tijdens raster changed proces dan nogmaals starten
			this.startRasterChangeAfterFinish = false;
			this.rasterChanged();
		} else {
			// Bij rasterchanged niet mogen switchen totdat dit helemaal is afgehandeld.
			// Bij deze verandering is er andere data nodig voor 3D.
			// Als we te snel switchen gaat 3D stuk.
			// Op deze manier na het calculation request en er is geen change meer nodig pas 3D beschikbaar stellen.
			// Op deze manier weten we zeker dat alles is ingeladen.
			this.switch3DAvailable = true;
		}
	}
	takeScreenshot(multiple) {
		Canvas3D.CURRENT.takeScreenshot(multiple);
	}
	takeScreenshot3d() {
		Canvas3D.CURRENT.takeScreenshotPuppeteer();
		return new Promise((resolve) => {
			const runner = () => {
				const timeout = setTimeout(runner, 1000);
				if (Canvas3D.CURRENT.pictures3D.length === 5) {
					clearTimeout(timeout);
					Configuration.CURRENT.takingScreenshot = false;
					resolve({
						screenshots: Canvas3D.CURRENT.pictures3D.map((screenshot) => ({
							image: screenshot.drawing,
							floor: screenshot.floor,
							type: screenshot.type,
							calculationId: Store.CURRENT.configurations.activeConfigId,
						})),
					});
					return;
				}
			};
			runner();
		});
	}
	takeScreenshot2d(floor = 0) {
		return new Promise((resolve) => {
			const runner = () => {
				const timeout = setTimeout(runner, 1000);
				Configuration.CURRENT.etages.activeIndex = floor;
				if (Configuration.CURRENT.redrawIsReady) {
					clearTimeout(timeout);
					var canvas = document.getElementById('2d');
					var screenshot = canvas.toDataURL('image/png');
					resolve({
						calculationId: Store.CURRENT.configurations.activeConfigId,
						image: screenshot,
						floor: floor,
					});
					return;
				}
			};
			runner();
		});
	}

	redraw() {
		this.redrawIsReady = false;
		// Dit is voor 2d.
		this.drawObjects3d = [];
		this.createDrawObjects();
		this.canvas.draw();
		this.redrawIsReady = true;
	}
	startLoading3dAsset() {
		this.currentLoading3dAssetCount++;
	}
	stopLoading3dAsset() {
		this.currentLoading3dAssetCount--;
		if (this.currentLoading3dAssetCount === 0 && this.readytoload3dAssets === true) {
			// Aftrappen van load assets
			this.startLoadingAssets();
		}
	}

	create3DAssets() {
		// Forceer op 0 zetten want standaard saved de configuratie ook deze setting
		this.currentLoading3dAssetCount = 0;
		this.readytoload3dAssets = false;

		// Globale stairsettings items toevoegen. Specifiek per trap (trede bijvoorbeeld) word in object zelf gedaan.
		const handRailSettings = Store.CURRENT.handRails.getMaterials();

		// Voor elk type handrail.
		handRailSettings.forEach((item) => {
			if (item !== null) {
				// Voor elk materiaal per type.
				Object.keys(item).forEach((object, index) => {
					if (item[object] !== null && typeof item[object] !== 'undefined') {
						Canvas3D.CURRENT.addAsset(new ModelAsset3D(object, item[object]));
					}
				});
			}
		});

		// registreer next step
		Object.keys(this).forEach((object, index) => {
			if (
				(this[object] !== null &&
					typeof this[object] === 'object' &&
					this[object].objectName3d !== null &&
					this[object].objectName3d !== 'undefined' &&
					typeof this[object].create3DAssets === 'function') ||
				this[object] === 'etages'
			) {
				this[object].create3DAssets(Canvas3D.CURRENT, this.raster, this.etages.activeEtage(), this.etages.activeEtageIndex);
			}
		});

		this.readytoload3dAssets = true;
	}
	startLoadingAssets() {
		Canvas3D.CURRENT.loadAssets(this.draw3D);
	}
	create3DObjects(draw = true) {
		Canvas3D.CURRENT.emptyDrawObjects();

		// Loop over configuratie voeg 3d objecten toe aan array via canvas3D.addDrawObject
		// canvas3D.addAsset om asset in te laden van het object
		// Etage objecten zijn te vinden in etages.js -> etage.js

		Object.keys(this).forEach((object, index) => {
			if (
				(this[object] !== null &&
					typeof this[object] === 'object' &&
					this[object].objectName3d !== null &&
					this[object].objectName3d !== 'undefined' &&
					typeof this[object].addDrawObjects3d === 'function') ||
				this[object] === 'etages'
			) {
				this[object].addDrawObjects3d(Canvas3D.CURRENT, this.raster, this.etages.activeEtage(), this.etages.activeEtageIndex);
			}
		});

		// DefaultFloor
		let width = Configuration.CURRENT.etages.floor.width;
		let depth = Configuration.CURRENT.etages.floor.length;

		Canvas3D.CURRENT.addDrawObject(new DefaultFloor3D(0, 0, 0, { width: width / 1000, depth: depth / 1000, height: 0.05, texture: { name: 'ground.jpg', folder: '' } }), Canvas3D.TYPE_IGNOREHICAD);

		// Wanneer drawobjecten klaar zijn dan pas tekenen in Playcanvas
		this.draw3DAvailable = true;
		this.logging3D.drawObjectsCreatedDone = true;
	}

	switchCanvas3d() {
		let attempts = 0;
		this.switchCanvas('3d');
		return new Promise((resolve) => {
			const runner = () => {
				attempts = attempts + 1;
				const timeout = setTimeout(runner, 1000);
				if (attempts === 25) {
					resolve(false);
				}
				if (typeof this._loadingbar3D !== 'undefined' && !this._loadingbar3D) {
					clearTimeout(timeout);
					resolve(true);
					return;
				}
			};
			runner();
		});
	}

	switchCanvas(type) {
		this.canvasType = type;
		if (type === '3d') {
			this.contextMenu.hide();
			this.contextMenu.setItems();

			this.loadingbar3D = true;

			let check3DAvailable = setInterval(() => {
				if (this.switch3DAvailable) {
					// eslint-disable-next-line no-new
					new Canvas3D();
					Canvas3D.CURRENT.init();
					this.create3DAssets();

					let checkSetting = setInterval(() => {
						if (this.draw3DAvailable) {
							Canvas3D.CURRENT.draw();
							clearInterval(checkSetting);
						} else {
							this.loadingbarText = 'loading.configuration3D';
						}
					}, 500);
					clearInterval(check3DAvailable);
				} else {
					this.loadingbarText = 'loading.updating3DData';
				}
			}, 250);
		} else if (type === '2d') {
			// Disablen van renderen ook als 2d alleen actief is, renderen maakt wanneer terug naar 2d is geswitcht erg traag.
			if (Canvas3D.CURRENT !== null && Canvas3D.CURRENT.app !== null) {
				Canvas3D.CURRENT.app.autoRender = false;
				this.switch3DAvailable = false;
				this.draw3DAvailable = false;

				Canvas3D.CURRENT.resetCanvas();
				Canvas3D.CURRENT.app.destroy();
				Canvas3D.CURRENT = null;
			}
			// Voorkomen dat wanneer je in 2d werkt hij 3d niet update, dit maakt het geheel erg traag, zodra er op 3d geklikt word update hij dan het 3d canvas.
			Store.CURRENT.configurations.in3DDrawState = false;
			this.init('2d');
			this.rasterChanged();
			this.recreateDrawing();
			Store.CURRENT.configurations.initializeCanvas2dDone = true;
		}
	}

	saveConfiguration() {
		if (!Store.CURRENT.configurations.readOnlyFromURL && !this.readOnly) {
			if (Store.CURRENT.configurations.activeConfigId === -1 || this.raster.spansX.length === 0 || this.raster.spansY.length === 0) {
				// Bij nieuwe configuratie dan wacht hij niet op save totdat hij de data heeft, dat is voor nu even lastig in te bouwen,
				// Daarom voor nu even checken of de activeConfig word gezet, en dan zichzelf uitvoeren.
				let maxAttempts = 6;
				let attempts = 0;
				let waitForActiveConfigId = setInterval(() => {
					attempts++;
					if (Store.CURRENT.configurations.activeConfigId !== -1) {
						this.saveConfiguration();
						clearInterval(waitForActiveConfigId);
					}
					if (attempts === maxAttempts) {
						clearInterval(waitForActiveConfigId);
					}
				}, 1250);

				return;
			}

			const serializer = new Serializer();
			const json = serializer.stringify(this);
			const drawing = serializer.stringify(this.jsonDrawing);

			let request = {
				calculationId: Store.CURRENT.configurations.activeConfigId,
				configuration: json,
				jsonDrawing: drawing,
			};
			Store.CURRENT.configurations.saveJsonConfiguration(request, '/saveConfiguration');
		}
	}

	createDrawObjects() {
		// Leeggooien van drawObject zelf de sizehandle
		this.canvas.drawObjects.clear();
		this.canvas.sizeHandles.clear();

		// Genereren van sizehandle voor object, deze vullen we niet in een object maar los.
		if (this.canvas.sizeHandles.get('object') === null || typeof this.canvas.sizeHandles.get('object') === 'undefined') {
			let sizeHandleObject = new SizeHandle('object', 2, true);
			this.canvas.sizeHandles.push(sizeHandleObject);
		}

		let regenerate = false;

		// Globale sizehandles dus niet per object zelf.
		Object.keys(this).forEach((object, index) => {
			// tijdelijke oplossing. toevoegen raster om die uit te sluiten omdat die ook via de actieve etage getekend wordt
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].addDrawObjects === 'function' && object !== 'canvas' && object !== 'raster') {
				let result = this[object].addDrawObjects(this.canvas, {
					actieveEtage: this.etages.activeEtage(),
					actieveEtageIndex: this.etages.activeEtageIndex,
					raster: this.raster,
					centerToCenter: this.profiles.centerToCenter,
					accessoriesType: this.accessoriesType,
				});
				if (typeof result !== 'undefined' && result !== null) {
					if (result.regenerate === true) {
						regenerate = true;
					}
				}
			}
		});

		const accessories = this[this.accessoriesType];
		if (typeof accessories !== 'undefined' && typeof accessories.addPossiblePositions === 'function') {
			let result = accessories.addPossiblePositions(this.canvas, {
				actieveEtage: this.etages.activeEtage(),
				actieveEtageIndex: this.etages.activeEtageIndex,
				raster: this.raster,
				centerToCenter: this.profiles.centerToCenter,
			});
			if (typeof result !== 'undefined' && result !== null) {
				if (result.regenerate === true) {
					regenerate = true;
				}
			}
		}

		// tijdelijke oplossing. Bovenstaande zou weg moeten en tekenen vanuit active etage geinitieerd worden
		let result = this.etages.activeEtage().createDrawObjects(
			this.canvas,
			{
				actieveEtage: this.etages.activeEtage(),
				actieveEtageIndex: this.etages.activeEtageIndex,
				raster: this.raster,
				centerToCenter: this.profiles.centerToCenter,
			},
			this.accessoriesType,
		);

		// Voor nu roepen we ook voor elke stair de addDrawobjects aan, zonder die dan te tekenen.
		// Er zitten berekeningen in addDrawobjects die nodig zijn om in 3D ook goed te tekenen.
		// Dus wel de functie uitvoeren maar niet toevoegen aan het canvas.
		this.etages.redrawStairs();

		if (typeof result !== 'undefined' && result !== null) {
			if (result.regenerate === true) {
				regenerate = true;
			}
		}

		// Sizehandles toevoegen hier gebeurd vanuit objecten.
		// Dat was alleen vullen,
		// Nu op basis van ingevulde waarden toevoegen aan canvas.
		this.canvas.sizeHandles.addDrawObjects(this.canvas);

		// Sizehandles gevuld, nu toevoegen aan het canvas.
		return regenerate; // eigenlijk gebruiken om opnieuw aan te roepen
	}
	collisionCheck() {
		Object.keys(this).forEach((object, index) => {
			// tijdelijke oplossing. toevoegen raster om die uit te sluiten omdat die ook via de actieve etage getekend wordt
			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].collisionCheck === 'function') {
				this[object].collisionCheck();
			}
		});
		this.etages.activeEtage().collisionCheck();
	}

	getErrorList(array) {
		let newArray = [];
		let grouped = this.groupBy(array, (error) => error.text);
		grouped.forEach((errors, index) => {
			if (!this.containsObject(errors, newArray)) {
				newArray.push(errors[0]);
			}
		});

		return newArray;
	}

	getGroupedErrors() {
		return this.getErrorList(this.errors.errors);
	}

	containsObject(obj, list) {
		let i;
		for (i = 0; i < list.length; i++) {
			if (list[i] === obj) {
				return true;
			}
		}

		return false;
	}

	groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}

	getErrorByEditType(type) {
		// TODO: POP toevoegen aan deze lijst + POP ziet geen collission en wordt niet toegevoegd aan new CustomError
		if (type === 'bracings' || type === 'portalBracings' || type === 'braceColumns') {
			return this.errors.errors.filter((error) => error.source.toLowerCase() === 'bracings');
		}
		return [];
	}

	detectErrors() {
		this.errors = new Errors();
		Object.keys(this).forEach((object, index) => {
			// Loop over de errors van alle objecten heen en als er fouten zijn dan kan de configuratie niet worden aangevraagd.

			if (this[object] !== null && typeof this[object] === 'object' && typeof this[object].getErrors === 'function') {
				let objectErrors = this[object].getErrors();
				if (typeof objectErrors !== 'undefined' && objectErrors !== null && objectErrors.length > 0) {
					objectErrors.getAll().forEach((error) => {
						this.errors.push(error);
					});
				}
			}
		});
		if (this.noMainbeamFound || this.noChildbeamFound) {
			let beamError = new CustomError(window.Vue.$translate('alert.title.noBeamFound'), Errors.ERRORTYPE.validate, '', this.objectName, this.objectName);
			this.errors.push(beamError);
		}
	}
	takePicture2D(calculationId, currentEtage) {
		let Canvas = document.getElementById('2d');
		let canvasImage = Canvas.toDataURL('image/png');

		let data = { calculationId: calculationId, drawings: [{ type: 0, floor: currentEtage, drawing: canvasImage }] };
		Store.CURRENT.configurations.takePicture(
			data,
			null,
			(result) => {
				console.log(result);
			},
			(error) => {
				console.log(error);
			},
		);
	}
}
