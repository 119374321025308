import Vue from 'vue';
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';

Vue.use(vuescroll, {
	ops: {
		bar: {
			showDelay: 500,
			onlyShowBarOnScroll: false,
			keepShow: false,
			background: 'rgba(0,0,0,0.75)',
			opacity: 1,
			hoverStyle: false,
			specifyBorderRadius: false,
			minSize: false,
			size: '6px',
		},
	},
	name: 'sa-scroll',
});
