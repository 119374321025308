import * as pc from 'playcanvas';
import { DrawObjects3D } from '../DrawObjects3D';
import { Canvas3D } from '../Canvas3D';
import { Configuration } from '../../configurator/configuration';

export class Dimension3D extends DrawObjects3D {
	objectName = 'Dimension3D';

	// Handle width en depth.
	// Vanuit horizontaal bekijken is width de breedte.
	// En depth de diepte.
	handleWidth = 0.05;
	handleDepth = 0.25;
	draw() {
		this.x = this.x / 1000;
		this.y = this.y / 1000;
		this.z = this.z / 1000;

		const fontAsset = Canvas3D.CURRENT.app.assets._assets.find((asset) => asset.name === 'roboto.json');

		let dimensionEntity = new pc.Entity(this.objectName);
		dimensionEntity.tags.add('dimensioning-3d');
		dimensionEntity.setLocalScale(0.1, 0.1, 0.1);
		dimensionEntity.setLocalPosition(this.x, this.y, this.z);
		dimensionEntity.rotateLocal(this.rotationX, this.rotationY, this.rotationZ);

		dimensionEntity.addComponent('element', {
			text: this.text + '' + 'mm',
			fontAsset: fontAsset,
			type: pc.ELEMENTTYPE_TEXT,
			pivot: new pc.Vec2(0, 0),
			alignment: new pc.Vec2(0.5, 0.5),
			fontSize: 4,
			layers: [pc.LAYERID_WORLD],
			autoFitWidth: false,
			autoFitHeight: false,
			autoHeight: true,
			autoWidth: false,
			width: this.width / 100,
			anchor: new pc.Vec4(0, 1, 0, 1),
		});

		this.dimensionEntity = dimensionEntity;
		this.startHandle = this.createBoxEntity(this.objectName + 'startHandle-' + this.indexX + '-' + this.indexY);
		this.endHandle = this.createBoxEntity(this.objectName + 'endHandle-' + this.indexX + '-' + this.indexY);
		this.barEntity = this.createBoxEntity(this.objectName + '-bar-' + this.indexX + '-' + this.indexY);
	}
	createBoxEntity(name) {
		let boxEntity = new pc.Entity(name);
		boxEntity.addComponent('render', {
			type: 'box',
		});
		boxEntity.tags.add('dimensioning-3d');

		return boxEntity;
	}
	addEntities() {
		// Meegeven als disabled zodat ze niet geredederd worden als dit niet moet.
		if (Configuration.CURRENT.showDimensioning3D === false) {
			this.dimensionEntity.enabled = false;
			this.barEntity.enabled = false;
			this.startHandle.enabled = false;
			this.endHandle.enabled = false;
		}
		Canvas3D.CURRENT.app.root.addChild(this.dimensionEntity);
		Canvas3D.CURRENT.app.root.addChild(this.barEntity);
		Canvas3D.CURRENT.app.root.addChild(this.startHandle);
		Canvas3D.CURRENT.app.root.addChild(this.endHandle);
	}
}
