import { Data } from '../data';
export class Models extends Data {
	objectName = 'Models';
	url = 'Models';
	getById(id, force, thenCallBack, catchCallBack, cacheId) {
		if (typeof id === 'undefined' || id === null || id === -1) {
			return;
		}

		this.lastId = id;
		// samenstellen url
		let url = this.baseUrl + this.url + '?id=' + id;

		this.get(url, true, thenCallBack, catchCallBack, cacheId + '_' + id, false);
	}
}
