export class Mathematic {
	static overlapRectangles(LeftTop1, RightBottom1, LeftTop2, RightBottom2) {
		// Zie voor berekening https://www.geeksforgeeks.org/find-two-rectangles-overlap/
		// twee vierkanten pak van beide linksboven en Rechtsonder. Noem bij vierkant 1 Linksboven L1 en rechtsonder R1 en bij tweede vierkant L2 en R2
		// Vervolgens geldt dat als: L1.x > R2.x of L2.x > R1.x hij niet overlapt (maar naast elkaar staan) en als L1.y>R2.y of L2.y>R1.y hij ookt niet overlapt (Ze staan boven er)
		// In het voorbeeld staat overigens een fout daar staat < ipv > bij de laatste if

		// If one rectangle is on left side of other
		if (LeftTop1.x > RightBottom2.x || LeftTop2.x > RightBottom1.x) {
			return false;
		}

		// If one rectangle is above other
		if (LeftTop1.y > RightBottom2.y || LeftTop2.y > RightBottom1.y) {
			return false;
		}
		return true;
	}
	static calculateDepth(height, angle) {
		return height / Math.tan((angle * Math.PI) / 180);
	}

	static _2ndPower(integer) {
		return Math.pow(integer, 2);
	}

	static degreesToRadians(degrees) {
		return degrees * (Math.PI / 180);
	}

	static radiansToDegrees(radians) {
		return radians * (180 / Math.PI);
	}

	static pyth(args) {
		let known = ['a', 'b', 'c'].filter((key) => typeof args[key] !== 'undefined');

		if (known.length !== 2) {
			console.log(known, args);
			throw Error('You should know 2 of the 3 sides');
		}

		if (known.includes('c')) {
			const otherSide = args.a || args.b;
			return Math.sqrt(this._2ndPower(args.c) - this._2ndPower(otherSide));
		}

		return Math.sqrt(this._2ndPower(args.a) + this._2ndPower(args.b));
	}

	// TODO uitgebreide versies maken van hoekberekening voor niet-rechthoekige driehoeken
	// https://owlcation.com/stem/Everything-About-Triangles-and-More-Isosceles-Equilateral-Scalene-Pythagoras-Sine-and-Cosine#:~:text=Answer%3A%20If%20you%20know%20two,%2C%20so%20b%20%3D%20cCos%20A.
	static soscastoa(args) {
		let known = ['a', 'o', 's', 'angle'].filter((key) => typeof args[key] !== 'undefined');

		if (known.length < 2) {
			console.log(known, args);
			throw Error('You should know at least 2 properties of the triangle');
		}

		if (!known.includes('angle')) {
			if (known.includes('o') && known.includes('s')) {
				args.angleRad = Math.asin(args.o / args.s);
				args.angle = this.radiansToDegrees(args.angleRad);
				args.a = Math.cos(args.angleRad) * args.s;
				known.push('angle', 'a');
			}

			if (known.includes('a') && known.includes('s')) {
				args.angleRad = Math.acos(args.a / args.s);
				args.angle = this.radiansToDegrees(args.angleRad);
				args.o = Math.sin(args.angleRad) * args.s;
				known.push('angle', 'o');
			}

			if (known.includes('o') && known.includes('a')) {
				args.angleRad = Math.atan(args.o / args.a);
				args.angle = this.radiansToDegrees(args.angleRad);
				args.s = args.o / Math.sin(args.angleRad);
				known.push('angle', 's');
			}
		} else {
			args.angleRad = this.degreesToRadians(args.angle);

			if (known.includes('a')) {
				args.o = Math.tan(args.angleRad) * args.a;
				args.s = args.a / Math.cos(args.angleRad);
				known.push('o', 's');
			}

			if (known.includes('o')) {
				args.a = args.o / Math.tan(args.angleRad);
				args.s = args.o / Math.sin(args.angleRad);
				known.push('a', 's');
			}

			if (known.includes('s')) {
				args.a = Math.cos(args.angleRad) * args.s;
				args.o = Math.sin(args.angleRad) * args.s;
				known.push('a', 'o');
			}
		}

		args.otherAngle = 90 - args.angle;
		args.otherAngleRad = this.degreesToRadians(args.otherAngle);
		return args;
	}
}
