import axios from 'axios';
import { Configuration } from '../configurator/configuration';
import { Data } from './data';
import { Store } from './store';

export class Building extends Data {
	objectName = 'Building';
	static CURRENT = null;
	url = 'Buildings/GetDrawing'; // nog maken

	// Functie om locale json op te halen zodat we kunnen testen
	getStaticList(thenCallBack) {
		axios.get('building.json').then(function (response) {
			if (typeof thenCallBack === 'function') {
				thenCallBack(response.data);
			}
		});
	}

	getBuilding(thenCallBack = null, catchCallBack = null) {
		if (Store.CURRENT.configurations.activeConfigId !== null && typeof Store.CURRENT.configurations.activeConfigId !== 'undefined') {
			let url = this.baseUrl + this.url + '?calculationId=' + Store.CURRENT.configurations.activeConfigId;
			this.store.dataAccess.get(url).then(
				(result) => {
					if (typeof thenCallBack === 'function' && thenCallBack !== null) {
						thenCallBack(result);
					}
				},
				(exception) => {
					if (typeof catchCallBack === 'function' && catchCallBack !== null) {
						catchCallBack(exception);
					}
				},
			);
		}
	}
}
