import { ObjectGroup } from '../draw/objectGroup';
import { Store } from '../data/store';

import { Functions } from '../helpers/functions';
import { Configuration } from './configuration';
import { Column } from './column';
export class Columns {
	objectName = 'Columns';
	objectName3d = 'column';

	static COLUMN_SIZE = 6;
	static COLOR = { column: '#000000', pressure: '#000', onMove: 'green', selected: 'blue' };
	static GENERATED = 0;
	static USERADDED = 1;
	static BUILDING_COLUMN = 2;
	static STAIR_TRIMMING_COLUMN = 3;

	static TYPE_MIDDLECOLUMN = 0;
	static TYPE_BORDER = 1;
	static TYPE_MULTILEVELCOLUMN = 4;

	static POSITION_TOP = 0;
	static POSITION_BOTTOM = 1;
	static POSITION_LEFT = 2;
	static POSITION_RIGHT = 3;

	defaultBasePlate = { name: '', height: 10 };
	defaultBracket = { name: '', height: 10 };
	defaultGussetPlate = { name: '', height: 220 };
	debugInfo = {
		variableLoad: '',
		safetyFactorPermanentLoad: '',
		safetyFactorVariableLoad: '',
		deflection: '',
		weightPm2: '',
		columnsToCalculate: {},
		heightColumn: '',
		permanentLoad: '',
		materialFactorHf1: '',
		profilesDouble: '',
	};
	collisions(boundaries, self) {
		let collisionsDetect = false;
		let errorResult = [];
		this.columns.forEach((column) => {
			let columnResult = column.collisions(boundaries, self);
			if (columnResult.result === true) {
				collisionsDetect = true;
				columnResult.errors.forEach((error) => {
					errorResult.push(error);
				});
			}
		});
		return { result: collisionsDetect, errors: errorResult, objectName: this.objectName };
	}
	columnProtectorChange(value) {
		if (value.selected === true) {
			const selected = this.getSelected();
			if (selected.length > 0) {
				const column = selected[0];
				column.columnProtectors.removeSamePositions(value.id);
			}
		}
	}
	setColumnProtectorsActive() {
		Configuration.CURRENT.setAccessoriesType('columnProtectors', true);
	}

	maxPressure = 0;
	amountColumnProtectors = [];
	amount = {};
	column = { name: '', kilogramPerMeter: 0, basePlate: { name: '', height: 10 }, bracket: { name: '', height: 10 }, width: 0 };
	static PRESSUREOFFSET = { x: 0, y: 18 };
	static TYPEOFFSET = { x: 0, y: 4 };
	length = 0; // kolomlengte over alle verdiepingen heen
	columns = [];
	columnProtectors = [];

	_showColumnPressure = true;
	get basePlate() {
		// om onverklaarbare wijze wordt baseplate null
		if (typeof this.column === 'undefined' || this.column === null || typeof this.column.basePlate === 'undefined' || this.column.basePlate === null) {
			return this.defaultBasePlate;
		}
		return this.column.basePlate;
	}
	select(parameters, canvas) {
		this.columns.forEach((column) => {
			column.select(parameters, canvas);
		});
	}
	get bracket() {
		// om onverklaarbare wijze wordt baseplate null
		if (typeof this.column === 'undefined' || this.column === null || typeof this.column.bracket === 'undefined' || this.column.bracket === null) {
			return this.defaultBracket;
		}
		return this.column.bracket;
	}
	get gussetPlate() {
		return this.column.gussetPlate;
	}
	get showColumnPressure() {
		return this._showColumnPressure;
	}
	set showColumnPressure(value) {
		if (value !== this._showColumnPressure) {
			this._showColumnPressure = value;
			this.onChange();
		}
	}

	_showColumnType = false;
	get showColumnType() {
		return this._showColumnType;
	}
	set showColumnType(value) {
		this._showColumnType = value;

		this.onChange();
	}
	_onChange = null;
	constructor(onChange) {
		this._onChange = onChange;
		setTimeout(() => {
			this.init();
		});
	}
	init() {
		if (typeof this.onMainBeamDirectionChange === 'function') {
			Configuration.CURRENT.profiles.onMainBeamDirectionChangeEvents.push((oldValue, newValue) => {
				this.onMainBeamDirectionChange(oldValue, newValue);
			});
		}
	}
	onMainBeamDirectionChange(oldValue, newValue) {
		this.columns.forEach((column) => {
			column.positionOffset = { x: 0, y: 0 };
		});
	}
	setReferences(params) {
		this._onChange = params.onChange;

		this.columns.forEach((column) => {
			column.setReferences({
				onChange: this.onChange.bind(this),
			});
		});
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	groupBy(list) {
		const map = new Map();
		list.forEach((item) => {
			const key = item.numberOfLevels + '-' + item.type + '-' + item.height;
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}
	getAmount() {
		let amount = { Columns: [], ColumnProtectors: [] };

		let columns = this.getGroupedColumns();
		columns.forEach((value, key) => {
			amount.Columns.push({
				id: this.column.salesProductId,
				name: this.column.name,
				amount: value.length,
				length: value[0].column.height,
				columnType: value[0].column.columnType,
				numberOfFloors: value[0].column.numberOfLevels,
			});
		});

		this.amountColumnProtectors.forEach((cp) => {
			// Voor nu gewoon 1 uit de lijst ophalen omdat dit waarschijnlijk salesproducts zijn.
			// Sales afdeling PRN houd nu geen rekening met afmetingen
			// later moment op basis van id (vanuit selectcolumn -> cp) de juiste ophalen en meesturen naar ERP.
			// Dan is dat ook de goeie qua afmetingen hicad/viwer etc.
			amount.ColumnProtectors.push({ id: Store.CURRENT.columnProtectors.getColumnProtector(cp.position.length > 1 ? 'quarter' : 'half').id, amount: cp.amount, isCorner: cp.isCorner });
		});
		return amount;
	}
	getGroupedColumns() {
		let columns = [];

		this.columns
			.filter((c) => c.columnActive === true)
			.forEach((column) => {
				columns.push({
					type: column.columnType,
					numberOfLevels: column.numberOfLevels,
					height: column.height,
					column: column,
				});
			});

		return this.groupBy(columns);
	}

	get amountColumns() {
		return this.columns.filter((c) => c.columnActive === true).length;
	}
	getHeight(etage, heightOnTop) {
		if (typeof heightOnTop === 'undefined' || heightOnTop === null) {
			heightOnTop = true;
		}
		const etages = Configuration.CURRENT.etages.getEtages();
		if (typeof etage === 'undefined' || etage === null) {
			let height = 0;
			etages.forEach((etage, index) => {
				if (heightOnTop === false) {
					// als niet hoogte bovenkant vloer maar onderkant vloer dan bij alle tussenverdiepingen wel de vloer meetellen. Alleen bij bovenste niet
					height += etage.getHeight(index !== etages.length - 1);
				} else {
					height += etage.getHeight(heightOnTop);
				}
			});
			return height;
		} else if (etage < etages.length) return etages[etage].getHeight(heightOnTop);
	}
	getPositionX(x, y) {
		let columnX = Configuration.CURRENT.raster.getSizeX(x - 1);
		let column = this.find(x, y);

		if (column !== null) {
			columnX += column.positionOffset.x;
		}

		return columnX;
	}
	getPositionY(x, y) {
		let columnY = Configuration.CURRENT.raster.getSizeY(y - 1);
		let column = this.find(x, y);

		if (column !== null) {
			columnY += column.positionOffset.y;
		}
		return columnY;
	}
	getPosition(x, y) {
		return { x: this.getPositionX(x, y), y: this.getPositionY(x, y) };
	}
	getRowPositions(x, y) {
		let foundColumn = this.find(x, y);
		return foundColumn.rowPositions;
	}
	getPlacement(x, y) {
		let foundColumn = this.find(x, y);
		return foundColumn.placement;
	}
	find(x, y) {
		let gevonden = null;
		this.columns.forEach((column, index) => {
			if (column.equals(x, y)) {
				gevonden = column;
			}
		});
		return gevonden;
	}
	contains(x, y) {
		let gevonden = null;
		this.columns.forEach((column, index) => {
			if (column.equals(x, y)) {
				gevonden = column;
			}
		});
		return gevonden != null;
	}
	findIndex(x, y) {
		let gevonden = -1;
		this.columns.forEach((column, index) => {
			if (column.equals(x, y)) {
				gevonden = index;
			}
		});
		return gevonden;
	}
	getMaxColumnPressure() {
		let maxPressure = 0;
		this.columns.forEach((column, index) => {
			if (column.pressure > maxPressure) {
				maxPressure = column.pressure;
			}
		});
		return maxPressure;
	}
	getByName(name) {
		let found = null;
		this.columns.forEach((column, index) => {
			if (column.name === name) {
				found = column;
				return column;
			}
		});
		// Kolom niet gevonden.
		return found;
	}
	getById(id) {
		// door gebouwkolom kan getbyname meerdere resultaten opleveren
		let found = null;
		this.columns.forEach((column, index) => {
			if (column.id === id) {
				found = column;
				return column;
			}
		});
		return found;
	}
	getColumns() {
		return this.columns;
	}
	getSelected() {
		const selected = [];
		this.columns.forEach((column, index) => {
			if (column.selected === true) {
				selected.push(column);
			}
		});

		return selected;
	}

	getColumnProtectors() {
		const selected = this.getSelected();
		if (selected.length > 0) {
			this.columnProtectors.get().forEach((columnProtector, index) => {
				columnProtector.toggle = (columnProtector) => {
					selected[0].columnProtectors.toggleColumnProtector(columnProtector);
				};
				columnProtector.selected = false;
				columnProtector.disabled = false;
				columnProtector.samePositions.forEach((position) => {
					if (selected[0].columnProtectors.isSelected(position)) {
						columnProtector.disabled = true;
						// columnProtector.isCorner = false;
					}
				});
				selected[0].columnProtectors.get().forEach((columnProtectorColumn, index) => {
					if (columnProtector.id === columnProtectorColumn.id) {
						columnProtector.selected = true;
					}
				});
			});
		}
		return this.columnProtectors.get();
	}

	push(x, y, name, type, position) {
		if (typeof name === 'undefined' || name === null) {
			name = 'x' + x + 'y' + y;
		}
		let column = this.columns.push(new Column(x, y, name, type, position));
		this.columns[column - 1].setReferences({
			onChange: this.onChange.bind(this),
		});

		return this.columns[column - 1];
	}
	pushUnique(x, y, name, type) {
		let column = this.find(x, y);
		// als niet gevonden
		if (column === null) {
			column = this.push(x, y, name, type);
		}
		return column;
	}
	markAllNotFound() {
		this.columns.forEach((column, index) => {
			column.found = false;
		});
	}
	removeNotFound() {
		const toSlice = [];
		this.columns.forEach((column, index) => {
			if (column.found === false && column.type === Columns.GENERATED) {
				toSlice.push(index);
			}
		});

		toSlice.forEach((item, index) => {
			this.columns.splice(item - index, 1);
		});
	}
	removeByName(name) {
		const toSlice = [];
		this.columns.forEach((column, index) => {
			if (column.name === name) {
				toSlice.push(index);
			}
		});
		toSlice.forEach((item, index) => {
			this.columns.splice(item - index, 1);
		});
	}
	AssignBeamsToColumn(raster, mainBeamDirection) {
		this.columns.forEach((column) => {
			let position = column.getPosition();
			let rasterPosition = raster.getRasterByCoordinate(position.startX.value, position.startY.value);

			let spanX = raster.spansX.getSpans()[rasterPosition.x];
			let spanY = raster.spansY.getSpans()[rasterPosition.y];

			if (typeof spanX === 'undefined' || typeof spanY === 'undefined') {
				spanX = raster.spansX.getSpans()[rasterPosition.x > -1 ? rasterPosition.x : raster.spansX.getSpans().length - 1];
				spanY = raster.spansY.getSpans()[rasterPosition.y > -1 ? rasterPosition.y : raster.spansY.getSpans().length - 1];
			}

			if (mainBeamDirection === 'x') {
				column.childBeamId = spanY.beam.oid;
				column.mainBeamId = spanX.beam.oid;
				column.mainbeamHeight = spanX.beam.height;
			} else {
				column.childBeamId = spanX.beam.oid;
				column.mainBeamId = spanY.beam.oid;
				column.mainbeamHeight = spanY.beam.height;
			}
		});
	}

	calculate(raster, etages, mainBeamDirection) {
		// verzamel eerst alle kolommen
		this.markAllNotFound();
		// per raster een kolom toevoegen. Kolom links boven
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			raster.spansY.getSpans().forEach((spanY, indexY) => {
				const column = this.pushUnique(indexX, indexY);

				column.found = true;
				column.setActive(etages);
				column.setTypeAndPosition();
			});
		});

		// Voor totaal raster per horizontale rij een extra kolom toevoegen. X-kolom is gelijk aan lengte array raster.spansX (array is van 0 - length - 1)
		raster.spansY.getSpans().forEach((spanY, indexY) => {
			const column = this.pushUnique(raster.spansX.length, indexY);
			column.found = true;
			column.offset = { x: -Columns.COLUMN_SIZE / 2, y: 0 };
			column.setActive(etages);
			column.setTypeAndPosition();
		});
		// Voor totaal raster per verticale rij een extra kolom toevoegen. y-kolom is gelijk aan lengte array raster.spansY (array is van 0 - length - 1)
		raster.spansX.getSpans().forEach((spanX, indexX) => {
			const column = this.pushUnique(indexX, raster.spansY.length);
			column.found = true;

			column.setActive(etages);
			column.setTypeAndPosition();
		});

		// laatste hoekkolom
		const column = this.pushUnique(raster.spansX.length, raster.spansY.length);
		column.found = true;
		column.offset = { x: -Columns.COLUMN_SIZE / 2, y: 0 };

		column.setActive(etages);
		column.setTypeAndPosition();
		this.removeNotFound();
		this.length = this.getHeight(null, true);
		let requestID = Functions.uuidv4();
		Configuration.CURRENT.startCalculationRequest(requestID);
		this.AssignBeamsToColumn(raster, mainBeamDirection);

		this.AssignBeamsToColumn(raster, mainBeamDirection);

		// let mainBeamOid = Configuration.CURRENT.profiles.mainBeam.oid;
		// let childBeamOid = Configuration.CURRENT.profiles.childBeam.oid;

		if (typeof mainBeamOid === 'undefined' && typeof childBeamOid === 'undefined') {
			// mainBeamOid = Configuration.CURRENT.profiles.amounts.mb[0].oid;
			// childBeamOid = Configuration.CURRENT.profiles.amounts.sb[0].oid;
		}

		// this.AssignBeamsToColumn(raster, mainBeamDirection);

		let data = {
			countryCode: Configuration.CURRENT.countryCode,
			maxHeight: Configuration.CURRENT.profiles.maxHeight,
			step: Configuration.CURRENT.step,
			deflection: Configuration.CURRENT.profiles.deflection,
			variableLoad: Configuration.CURRENT.variableLoad,
			centerToCenterDistance: Configuration.CURRENT.profiles.centerToCenter,
			useHotFormed: Configuration.CURRENT.profiles.hotFormedPossible,
			useDoubleDouble: Configuration.CURRENT.profiles.doubleDoublePossible,
			profileSameHeight: Configuration.CURRENT.profiles.sameHeight,
			etages: [],
			raster: { removeRasters: etages.getRemoveRasters(), rasterX: Configuration.CURRENT.raster.spansX.getSpans(), rasterY: Configuration.CURRENT.raster.spansY.getSpans() },
			columns: this.columns,
			profilesDouble: Configuration.CURRENT.profiles.doubleDouble,
			heightColumn: this.length,
			debugMode: Configuration.CURRENT.debugMode,
			calculateWithNotInContract: Configuration.CURRENT.calculateWithNotInContract,
			companyId: Store.CURRENT.companies.selectedCompany.id,
			consequenceClass: Configuration.CURRENT.consequenceClass,
			mainBeamDirection: Configuration.CURRENT.profiles.mainBeamDirection,
			deckingFinishId: Configuration.CURRENT.finish.selected.id,
			floorApplicationsOid: Configuration.CURRENT.floorApplication,
			// mainBeamId: mainBeamOid,
			// childBeamId: childBeamOid,
		};
		etages.getEtages().forEach((etage) => {
			data.etages.push(etage.getSpecifications());
		});
		Store.CURRENT.calculate.selectColumn(
			data,
			(response) => {
				if ((response.column === null || response.column.column === null) && Configuration.CURRENT.newConfiguration === false) {
					window.Vue.$alert({ isHtml: true, title: window.Vue.$translate('alert.title.noColumnFound'), content: window.Vue.$translate('alert.content.noColumnFound') });
				} else {
					this.maxPressure = response.maxPressure;

					this.column = response.column.column;
					this.column.width = response.column.column._h_col;

					this.column.salesProductId = response.column.salesProductId;

					if (typeof this.column.basePlate === 'undefined' || this.column.basePlate === null) {
						this.column.basePlate = this.defaultBasePlate;
					}
					if (typeof this.column.bracket === 'undefined' || this.column.bracket === null) {
						this.column.bracket = this.defaultBracket;
					}
					if (typeof this.column.gussetPlate === 'undefined' || this.column.gussetPlate === null) {
						this.column.gussetPlate = this.defaultGussetPlate;
					}
					if (Configuration.CURRENT.debugMode === true) {
						this.debugInfo = {
							variableLoad: response.variableLoad,
							safetyFactorPermanentLoad: response.safetyFactorPermanentLoad,
							safetyFactorVariableLoad: response.safetyFactorVariableLoad,
							weightDeckingFinish: response.weightDeckingFinish,
							aSecondary: response.aSecondary,
							aMainBeam: response.aMainBeam,
							centerTocenter: response.centerTocenter,
							mSteel: response.mSteel,
							heightColumn: response.heightColumn,
							mainBeamHeight: response.mainBeamHeight,
							moduleOfElasiticity: response.moduleOfElasiticity,
							materialFactorHF: response.materialFactorHF,
							materialFactorHf1: response.materialFactorHf1,
							mainBeamDirection: response.mainBeamDirection,
							columnsToCalculate: response.columnsToCalculate,
						};
					} else {
						this.debugInfo = {
							variableLoad: '',
							safetyFactorPermanentLoad: '',
							safetyFactorVariableLoad: '',
							deflection: '',
							weightPm2: '',
							columnsToCalculate: {},
							heightColumn: '',
							permanentLoad: '',
							materialFactorHf1: '',
							materialFactorCf: '',
							materialFactorHf: '',
							profilesDouble: '',
						};
					}
				}
				let pressureChange = false;
				response.columns.forEach((columnsResponse, indexResponse) => {
					this.columns.forEach((columns, index) => {
						if (columns.name === columnsResponse.name) {
							if (columns.pressure !== columnsResponse.pressure) {
								pressureChange = true;
							}
							columns.pressureType = columnsResponse.pressureType;
							columns.pressure = columnsResponse.pressure;
						}
					});
				});

				if (pressureChange === true) {
					// eigenlijk niet de juiste oplossing. Kijken naar iets generieks
					Configuration.CURRENT.redraw();
				}
				Configuration.CURRENT.endCalculationRequest(requestID);
			},
			(error) => {
				console.log(error);
				Configuration.CURRENT.endCalculationRequest(requestID);
			},
		);
		this.amount = {};
		let withoutLength = 0;
		// bereken lengte kolommen
		this.columns
			.filter((c) => c.columnActive === true)
			.forEach((column) => {
				let height = column.height;
				if (height !== 0) {
					if (height === -1) {
						// als er ook extra geplaatste kolommmen zijn die hebben (nog) geen active raster dus apart optellen
						withoutLength++;
						return;
					}
					// 0 geeft aan geen verdiepingen actief
					if (typeof this.amount[height] === 'undefined') {
						this.amount[height] = 1;
					} else {
						this.amount[height]++;
					}
				}
			});

		if (withoutLength > 0) {
			// als er ook extra geplaatste kolommmen zijn die hebben (nog) geen active raster dus optellen bij de langste

			let heighest = 0;
			Object.keys(this.amount).forEach((key) => {
				if (key > heighest) {
					heighest = key;
				}
			});
			this.amount[heighest] += withoutLength;
		}
		// bereken kolombeschermers
		this.amountColumnProtectors = [];
		this.columns.forEach((column, index) => {
			column.columnProtectors.get().forEach((columnProtector, index) => {
				let gevonden = false;
				this.amountColumnProtectors.forEach((amount, index) => {
					if (amount.type === columnProtector.position) {
						amount.amount++;
						gevonden = true;
					}
				});
				if (gevonden === false) {
					this.amountColumnProtectors.push({ position: columnProtector.position, amount: 1, isCorner: columnProtector.isCorner });
				}
			});
		});
	}
	getTotalWeight() {
		if (this.column !== null) {
			return this.length * this.columns.length * (this.column.KilogramPerMeter / 1000);
		}
	}
	onRasterChanged(params) {}
	closedPanel() {
		this.columns.forEach((column, index) => {
			column.columnProtectors.calculateColumnProtectors(column);
		});
	}
	calculateAmount(params) {
		this.calculate(params.raster, params.etages, params.profiles.mainBeamDirection);
	}
	addDrawObjects(canvas, params) {
		this.columns.forEach((column, index) => {
			const drawGroup = new ObjectGroup(column.selected === true ? Columns.COLOR.selected : Columns.COLOR.column, null, null, true, column, null);

			const columnDraw = column.addDrawObjects(canvas, params.actieveEtageIndex);

			if (typeof columnDraw !== 'undefined') {
				drawGroup.push(columnDraw);
			}

			if (this.showColumnPressure === true && params.actieveEtageIndex === 0 && params.accessoriesType !== 'columnProtectors') {
				const columnPressureDraw = column.addPressure(canvas, params.raster.getSizeX(column.x - 1), params.raster.getSizeY(column.y - 1), params.actieveEtageIndex);
				if (typeof columnPressureDraw !== 'undefined') {
					drawGroup.push(columnPressureDraw);
				}
			}
			if (this.showColumnType === true && params.actieveEtageIndex === 0 && params.accessoriesType !== 'columnProtectors') {
				const columnTypeDraw = column.addType(canvas, params.raster.getSizeX(column.x - 1), params.raster.getSizeY(column.y - 1), params.actieveEtageIndex);
				if (typeof columnTypeDraw !== 'undefined') {
					drawGroup.push(columnTypeDraw);
				}
			}

			if (params.accessoriesType === 'columnProtectors') {
				if (typeof column.columnProtectors !== 'undefined' && typeof column.columnProtectors.addPossiblePositions === 'function') {
					let result = column.columnProtectors.addPossiblePositions(canvas, params, this.columns, Columns.COLUMN_SIZE, column);
					if (typeof result !== 'undefined' && result !== null) {
						if (result.regenerate === true) {
							regenerate = true;
						}
					}
				}
			}

			canvas.addDrawObject(drawGroup);
		});
	}
	addDrawObjects3d(canvas3d, raster, activeEtage, actieveEtageIndex) {
		this.columns.forEach((column, index) => {
			column.addDrawObjects3d(canvas3d, this.column, column.height);
		});
	}
	create3DAssets(canvas3d) {
		this.columns.forEach((column, index) => {
			column.create3DAssets(canvas3d, this.column);
		});
	}
}
