import { Configuration } from '../../../configurator/configuration';
import { Stair } from '../../../configurator/stair';
import { BasePlate3D } from '../../baseplate/basePlate3D';
import { Canvas3D } from '../../Canvas3D';
import { LandingColumn3D } from './objects/LandingColumn3D';

export class LandingColumns3D {
	objectName = 'LandingColumns3D';
	constructor(x, z, params, endLanding) {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		let columnInfo = Configuration.CURRENT.columns.column;
		let ralColor = Configuration.CURRENT.colors.columns.ralColor;

		let paramsBasePlate = {
			oid: columnInfo.basePlate.oid,
			assetName: columnInfo.basePlate.name,
			ralColor: ralColor,
		};

		let paramsColumn = {
			oid: columnInfo.columnOid,
			assetName: columnInfo.name,
			drawHeight: params.drawHeight,
			mainBeamHeight: params.mainBeamHeight,
			ralColor: ralColor,
		};

		// Bij endlanding alleen 2 kolommen op buitenhoeken tekenen.
		if (endLanding === true) {
			// Kolom linksboven van endlanding.
			if (params.stairData.position === Stair.UPCOMING_TOP || params.stairData.position === Stair.UPCOMING_LEFT) {
				Canvas3D.CURRENT.addDrawObject(new LandingColumn3D(x, 0, z, paramsColumn, new BasePlate3D(x, 0, z, paramsBasePlate)), Canvas3D.TYPE_LANDINGCOLUMN);
			}

			// Kolom rechtsboven van endlanding.
			if (params.stairData.position === Stair.UPCOMING_TOP || params.stairData.position === Stair.UPCOMING_RIGHT) {
				Canvas3D.CURRENT.addDrawObject(
					new LandingColumn3D(x + params.width - columnWidth, 0, z, paramsColumn, new BasePlate3D(x + params.width - columnWidth, 0, z, paramsBasePlate)),
					Canvas3D.TYPE_LANDINGCOLUMN,
				);
			}

			// Kolom linksonder van endlanding.
			if (params.stairData.position === Stair.UPCOMING_BOTTOM || params.stairData.position === Stair.UPCOMING_LEFT) {
				Canvas3D.CURRENT.addDrawObject(
					new LandingColumn3D(x, 0, z + params.depth - columnWidth, paramsColumn, new BasePlate3D(x, 0, z + params.depth - columnWidth, paramsBasePlate)),
					Canvas3D.TYPE_LANDINGCOLUMN,
				);
			}

			// Kolom rechtsonder van endlanding.
			if (params.stairData.position === Stair.UPCOMING_BOTTOM || params.stairData.position === Stair.UPCOMING_RIGHT) {
				Canvas3D.CURRENT.addDrawObject(
					new LandingColumn3D(
						x + params.width - columnWidth,
						0,
						z + params.depth - columnWidth,
						paramsColumn,
						new BasePlate3D(x + params.width - columnWidth, 0, z + params.depth - columnWidth, paramsBasePlate),
					),
					Canvas3D.TYPE_LANDINGCOLUMN,
				);
			}
		}
		// Bij normale landing dan op elke hoek een kolom tekenen.
		else {
			// Linkerbovenhoek
			Canvas3D.CURRENT.addDrawObject(new LandingColumn3D(x, 0, z, paramsColumn, new BasePlate3D(x, 0, z, paramsBasePlate), true), Canvas3D.TYPE_LANDINGCOLUMN);

			// Rechterbovenhoek
			Canvas3D.CURRENT.addDrawObject(
				new LandingColumn3D(x + params.width - columnWidth, 0, z, paramsColumn, new BasePlate3D(x + params.width - columnWidth, 0, z, paramsBasePlate), true),
				Canvas3D.TYPE_LANDINGCOLUMN,
			);

			// Linkeronderhoek
			Canvas3D.CURRENT.addDrawObject(
				new LandingColumn3D(x, 0, z + params.depth - columnWidth, paramsColumn, new BasePlate3D(x, 0, z + params.depth - columnWidth, paramsBasePlate), true),
				Canvas3D.TYPE_LANDINGCOLUMN,
			);

			// Rechteronderhoek
			Canvas3D.CURRENT.addDrawObject(
				new LandingColumn3D(
					x + params.width - columnWidth,
					0,
					z + params.depth - columnWidth,
					paramsColumn,
					new BasePlate3D(x + params.width - columnWidth, 0, z + params.depth - columnWidth, paramsBasePlate),
					true,
				),
				Canvas3D.TYPE_LANDINGCOLUMN,
			);
		}
	}
}
