import { Configuration } from '../../configurator/configuration';
import { jsonBuildingColumn } from '../../configurator/hicad/jsonBuildingColumn';
import { Box3D } from '../box3D';

export class BuildingColumn3D extends Box3D {
	objectName = 'BuildingColumn3D';
	hoverTags = ['buildingcolumns'];
	ralColor = 7016;
	/* eslint-disable no-useless-constructor */
	constructor(x, y, params) {
		super(x, y, params);
	}
	draw(app) {
		// Apply logic
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		this.height -= deckingFinishHeight;

		// Vrije hoogte, moet namelijk altijd boven de vloer uitkomen voor duidelijkheid.
		this.y -= deckingFinishHeight;
		this.ralColor = 7016;
		super.draw(app);
	}

	convert() {
		return new jsonBuildingColumn(this);
	}
}
