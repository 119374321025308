import { jsonProfile } from '../../../../configurator/hicad/jsonProfile';
import { Canvas3D } from '../../../Canvas3D';
import { ProfilesVertical3D } from '../../ProfilesVertical3D';

export class MainBeamRight3D extends ProfilesVertical3D {
	objectName = 'MainBeamRight3D';
	profileType = 'mainbeam';
	rotationY = -90;
	// eslint-disable-next-line no-useless-constructor
	constructor(x, y, length, params) {
		super(x, y, length, params);
		this.depth = 1;
	}
	draw(app) {
		super.draw(app);
	}
	// Convert MainBeamRight3D naar HiCADProfile, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_MAINBEAM, direction: jsonProfile.DIRECTION_VERTICAL, position: jsonProfile.POSITION_RIGHT });
	}
}
