import { Stair } from '../../../configurator/stair';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';

export class KickEdgeStair3D extends Model3D {
	objectName = 'KickEdgeStair3D';
	hoverTags = ['stairs'];
	constructor(x, y, params) {
		super(x, 0, y, params);
	}
	draw(app) {
		let stingerHeight = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'height');
		let stingerDepth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.handRailPost, 'width');

		this.y += stingerHeight;

		if (this.shared.upComing === Stair.UPCOMING_TOP || this.shared.upComing === Stair.UPCOMING_BOTTOM) {
			this.width -= poleWidth;
			if (this.trapboomPosition === 'left') {
				if (this.shared.upComing === Stair.UPCOMING_TOP) {
					this.x += stingerDepth;
				}
			}
		} else if (this.shared.upComing === Stair.UPCOMING_LEFT || this.shared.upComing === Stair.UPCOMING_RIGHT) {
			this.width -= poleWidth;
			this.y -= poleWidth;
			if (this.shared.upComing === Stair.UPCOMING_LEFT) {
				this.x -= poleWidth;
			} else {
				this.x += poleWidth;
			}

			if (this.trapboomPosition === 'right') {
				this.z -= poleWidth;
			}
		}
		super.draw(app);
	}
}
