export default ({ store, redirect }) => {
	if (!process.server) {
		const url = window.location.pathname;

		store
			.dispatch('user/validateToken')
			.then((res) => {})
			.catch((err) => {
				console.log(err);
				store.commit('user/setCallBackUrl', url);
				// redirect('/login');
			});
	}
};
