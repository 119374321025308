import { Configuration } from '../../../../configurator/configuration';
import { Model3D } from '../../../Model3D';

export class LandingColumn3D extends Model3D {
	objectName = 'LandingColumn3D';
	hoverTags = ['columns', 'stairs'];
	width = 1;
	depth = 1;
	constructor(x, y, z, params, basePlate) {
		super(x, y, z, params);
		this.basePlate = basePlate;
		this.oid = Configuration.CURRENT.columns.column.oid;
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		this.height = this.drawHeight;
		this.height -= this.mainBeamHeight;
		this.height -= deckingFinishHeight;
		this.basePlate.draw(app);
		super.draw(app);
	}
}
