import { Data } from './data';
import { Store } from './store';

export class Languages extends Data {
	objectName = 'Languages';
	static CURRENT = null;
	url = 'Languages';
	languages = [];
	locales = ['en', 'nl', 'de', 'fr'];
	locale = 'EN';
	currentLanguageRequest = false;
	_currentLanguage = '';
	// Bijhouden of de localStorage is gerestored, anders wil hij al saven en default waardes in localstorage opslaan.
	localStorageRestored = false;
	// Opslaan of gebruiker zijn eigen language in de configurator heeft aangepast.
	// Dit saven we ook in localstorage en halen we op bij F5.
	languageSetByUser = null;
	constructor(force) {
		if (typeof force === 'undefined' || force === null) {
			force = false;
		}
		if (Languages.CURRENT === null || force === true) {
			super();
			Languages.CURRENT = this;
		}
		return Languages.CURRENT;
	}
	get currentLanguage() {
		let language = 'EN';
		if (this._currentLanguage === '') {
			if (this.store != null && this.store.user.language !== '') {
				language = this.store.user.language.code;
			}
			// als user een default company heeft deze instellen en gegevens van die company ophalen
			if (language > '') {
				this._currentLanguage = language;
				return this._currentLanguage;
			}
			return language; // voorkomt fouten nuxt. Altijd een language.json
		}

		return this._currentLanguage;
	}
	set currentLanguage(value) {
		this._currentLanguage = value;
		Store.CURRENT.translations.list();
		// Alleen wanneer localstorage restored is dan nieuwe waarden opslaan.
		if (this.localStorageRestored) {
			this.saveToLocalStorage();
		}
	}
	updateLanguageByUser(value) {
		this.currentLanguage = value.toUpperCase();
		this.languageSetByUser = true;
		if (this.localStorageRestored) {
			this.saveToLocalStorage();
		}
	}
	getCurrentLanguageItem() {
		// Wanneer lijst nog nooit opgehaald List uitvoeren en dan op in de callback de eigen functie aanroepen.
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			this.list(null, false, 'list', null, () => {
				return this.getCurrentLanguageItem();
			});
		}

		const items = this.languages.filter((item) => item !== null && item.code === this.currentLanguage.toUpperCase());
		if (items.length > 0) {
			return items[0];
		}

		return { code: '', name: '', flag: '' };
	}
	restoreFromLocalStorage() {
		this.localStorage.restore(this);
		this.localStorageRestored = true;
	}
	persistentData() {
		// welke velden worden er lokaal opgeslagen al er ingelogd is.
		return { currentLanguage: this.currentLanguage, languageSetByUser: this.languageSetByUser };
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (this._list.list !== null && typeof this._list.list !== 'undefined') {
			return this._list.list;
		}
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		data.language = this.currentLanguage;
		super.list(
			data,
			force,
			cacheId,
			urlExtension,
			(res) => {
				if (res !== null && typeof res !== 'undefined') {
					this.languages = res;
					return this.languages;
				}
			},
			catchCallBack,
		);
	}
}
