import { Data } from './data';
import { Store } from './store';

export class ContactPersons extends Data {
	objectName = 'Finishes';
	static CURRENT = null;
	url = 'companies/getContactPersons';
	list(id, force, thenCallBack) {
		let data = {};

		if (typeof force === 'undefined' || force === null) {
			force = false;
		}

		if (id === null || typeof id === 'undefined') {
			data.Oid = Store.CURRENT.companies.selectedCompany.id;
		} else {
			data.Oid = id;
		}

		super.list(data, force, null, '', (res) => {
			if (typeof thenCallBack === 'function') {
				thenCallBack(res);
			}
		});
	}
	listBox(id, force, thenCallBack) {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0 && !force) {
			// dan lijst teruggeven opgemaakt zoals ze in de listbox verschijnen
			if (typeof thenCallBack === 'function') {
				thenCallBack(this._list.list);
			}
		} else if (this.listRequest === null && Store.CURRENT.companies.selectedCompany.id > -1 && force) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list(id, force, (res) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(this._list.list);
				}
			});
		} else {
			this.list(id, force, (res) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(this._list.list);
				}
			});
		}
	}
}
