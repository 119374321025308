import axios from 'axios';

export class DataAccess {
	objectName = 'DataAccess';
	static CURRENT = null;
	queue = [];
	store = null;
	preLoad = false;
	tokenBasedAuthentication = false;
	setStore(store) {
		this.store = store;
	}

	async get(url, showProgressBar, useToken, config, objectName, mockupDataNotFound) {
		if (typeof mockupDataNotFound === 'undefined' || mockupDataNotFound === null) {
			mockupDataNotFound = false;
		}

		if (mockupDataNotFound === false && this.store.mockupData.useMockupData === true && typeof objectName !== 'undefined' && objectName !== null) {
			return new Promise((resolve, reject) => {
				// tot hier gaat hij gewoon door. Daarna wacht hij op vorige verzoek. Kijken hoe dit met axios.all voorkomen kan worden
				// verzoek versturen en resultaat teruggeven
				axios
					.get('/mockupData/' + objectName + '.json', config)
					.then(function (response) {
						resolve(response.data);
					})
					.catch((error) => {
						if (error.response.status === 404) {
							// als mockupdata niet beschikbaar dan real data proberen
							this.get(url, showProgressBar, useToken, config, objectName, true)
								.then((response) => {
									resolve(response);
								})
								.catch((exception) => {
									reject(exception);
								});
						} else {
							reject(error);
						}
					});
			});
		}

		// eerst check op variabelen en eventuele default-waarden
		if (typeof useToken === 'undefined' || useToken === null) {
			useToken = this.tokenBasedAuthentication;
		}
		if (typeof config === 'undefined' || config === null) {
			config = {};
		}
		if (typeof showProgressBar === 'undefined' || showProgressBar === null) {
			showProgressBar = false;
		}
		// als het een geautoriseerd verzoek betreft en de gebruiker is aanwezig
		if (this.tokenBasedAuthentication === true && useToken === true && this.store !== null && this.store.user !== null) {
			// kijk of de token aanwezig is en nog geldig is
			if (this.store.user.token.value === '' || (this.store.user.token.expires !== '' && new Date(this.store.user.token.expires) < Date.now())) {
				// als token niet meer aanwezig is of niet geldig is dan url opslaan en doorsturen naar login
				this.store.user.callBackUrl = window.location.href;
				window.location.href = '/login';
			}
			// kijk of de token al gevalideerd is en de user in het systeem nog actief is
			if (this.store.user.validated === false) {
				// als nog niet gevalideerd en het verzoek nog niet loopt
				if (this.store.user.validateRequest === null) {
					// verstuur verzoek en wacht op antwoord
					await this.store.user.validate();
				} else {
					// wacht op antwoord

					await this.store.user.validateRequest;
				}
			}

			// voeg token toe
			config.headers = { Authorization: 'bearer ' + this.store.user.token.value };
		}
		let store = this.store;

		if (this.tokenBasedAuthentication === false) {
			config.withCredentials = true;
		}
		return new Promise((resolve, reject) => {
			// tot hier gaat hij gewoon door. Daarna wacht hij op vorige verzoek. Kijken hoe dit met axios.all voorkomen kan worden
			// verzoek versturen en resultaat teruggeven
			axios
				.get(url, config)
				.then(function (response) {
					if (mockupDataNotFound === true) {
						store.mockupData.save({ objectName: objectName, json: JSON.stringify(response.data) });
					}
					resolve(response.data);
				})
				.catch((error) => {
					if (error.message === 'Network Error') {
						// geen verbinding
						// alert('Geen verbinding met de API');
						return;
					}
					if (typeof error.response !== 'undefined' && error.response !== null && typeof error.response.data !== 'undefined' && error.response.status === 401) {
						if (this.store !== null) {
							this.store.user.callBackUrl = window.location.href;
						}

						window.location.href = '/login';
					} else {
						reject(error);
					}
				});
		});
	}
	async post(url, data, showProgressBar, useToken, config, objectName, mockupDataNotFound) {
		if (typeof mockupDataNotFound === 'undefined' || mockupDataNotFound === null) {
			mockupDataNotFound = false;
		}
		if (mockupDataNotFound === false && this.store !== null && this.store.mockupData.useMockupData === true && typeof objectName !== 'undefined' && objectName !== null) {
			return new Promise((resolve, reject) => {
				// tot hier gaat hij gewoon door. Daarna wacht hij op vorige verzoek. Kijken hoe dit met axios.all voorkomen kan worden
				// verzoek versturen en resultaat teruggeven
				axios
					.get('/mockupData/' + objectName + '.json')
					.then(function (response) {
						resolve(response.data);
					})
					.catch((error) => {
						if (error.response.status === 404) {
							this.post(url, data, showProgressBar, useToken, config, objectName, true)
								.then((response) => {
									resolve(response);
								})
								.catch((exception) => {
									reject(exception);
								});
						} else {
							reject(error);
						}
					});
			});
		}
		// eerst check op variabelen en eventuele default-waarden
		if (typeof useToken === 'undefined' || useToken === null) {
			useToken = true;
		}
		if (typeof config === 'undefined' || config === null) {
			config = {};
		}
		if (typeof showProgressBar === 'undefined' || showProgressBar === null) {
			showProgressBar = false;
		}
		// als het een geautoriseerd verzoek betreft en de gebruiker is aanwezig
		if (this.tokenBasedAuthentication === true && useToken === true && this.store.user !== null) {
			// kijk of de token aanwezig is en nog geldig is
			if (this.store.user.token.value === '' || (this.store.user.token.expires !== '' && new Date(this.store.user.token.expires) < Date.now())) {
				// als token niet meer aanwezig is of niet geldig is dan url opslaan en doorsturen naar login
				this.store.user.callBackUrl = window.location.href;
				window.location.href = '/login';
			}
			// kijk of de token al gevalideerd is en de user in het systeem nog actief is
			if (this.store.user.validated === false) {
				// als nog niet gevalideerd en het verzoek nog niet loopt
				if (this.store.user.validateRequest === null) {
					// verstuur verzoek en wacht op antwoord
					await this.store.user.validate();
				} else {
					// wacht op antwoord
					await this.store.user.validateRequest;
				}
			}

			// voeg token toe
			config.headers = { Authorization: 'bearer ' + this.store.user.token.value };
		}
		if (this.tokenBasedAuthentication === false) {
			config.withCredentials = true;
		}
		let store = this.store;
		return new Promise((resolve, reject) => {
			// tot hier gaat hij gewoon door. Daarna wacht hij op vorige verzoek. Kijken hoe dit met axios.all voorkomen kan worden
			// verzoek versturen en resultaat teruggeven
			axios
				.post(url, data, config)
				.then(function (response) {
					if (mockupDataNotFound === true) {
						store.mockupData.save({ objectName: objectName, json: JSON.stringify(response.data) });
					}
					resolve(response.data);
				})
				.catch((error) => {
					console.log(url, error);
					reject(error);
				});
		});
	}
	postForm(url, formData) {
		// postform wordt nu alleen gebruikt voor inlog. Zit nog geen token in. Mogelijk ook nog toevoegen
		// formdata is anders opgemaakt dan gewoon Json. de json omzetten naar het juiste formaat
		const transformedRequest = Object.entries(formData)
			.map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
			.join('&');

		// verstuur verzoek en resultaat teruggeven
		return new Promise((resolve, reject) => {
			axios({ method: 'post', url: url, data: transformedRequest, progress: false, withCredentials: true, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
}
