import { CustomError } from '../CustomError';
import { Errors } from '../errors';

import { Store } from '../../data/store';

export class Standard {
	objectName = 'Standard';
	standardData = null;
	standardName = '';
	constructor(standardName) {
		this.standardData = Store.CURRENT.fallingProtectionStandards.getStandard(standardName);
		this.standardName = standardName;
	}
	get minMaxGoingRise() {
		if (this.standardData === null || this.standardData.minGoingRise === 0 || this.standardData.maxGoingRise === 0) {
			return { min: 600, max: 660 };
		}
		return { min: this.standardData.minGoingRise, max: this.standardData.maxGoingRise };
	}
	getDefaultAngle(typeStep, width, defaultAngle) {
		if (typeof defaultAngle === 'undefined' || defaultAngle === null) {
			if (this.standardData != null) {
				defaultAngle = this.standardData.defaultStairAngle;
			} else {
				defaultAngle = 45;
			}
		}
		let minAngle = this.getMinAngle(typeStep, width);
		let maxAngle = this.getMaxAngle(typeStep, width);
		if (defaultAngle < minAngle) {
			defaultAngle = minAngle;
		}
		if (defaultAngle > maxAngle) {
			defaultAngle = maxAngle;
		}
		return defaultAngle;
	}
	getMinAngle(typeStep, width) {
		let minAngle = 30;
		let minAngleStep = Store.CURRENT.stairSteps.getMinAngle(typeStep, width);

		if (minAngleStep > minAngle && minAngleStep > -1) {
			minAngle = minAngleStep;
		}
		return minAngle;
	}
	getMaxAngle(typeStep, width) {
		let maxAngle = 60;
		let maxAngleStep = Store.CURRENT.stairSteps.getMaxAngle(typeStep, width);

		if (maxAngleStep > maxAngle && maxAngleStep > -1) {
			maxAngle = maxAngleStep;
		}
		return maxAngle;
	}
	getMinWidthLanding(stepWidth) {
		if (this.standardData === null || typeof this.standardData === 'undefined') {
			// geen data uit fallingprotectionstandard erp dan trapbreedte
			return stepWidth;
		}
		// anders space erbij optellen
		return stepWidth + this.standardData.intermidateLandingSpace;
	}
	getMinDepthLanding(stepWidth) {
		if (this.standardData == null || typeof this.standardData === 'undefined' || this.standardData.minDepthLanding === 0) {
			// geen data uit fallingprotectionstandard erp of geen minimale diepte dan de breedte van de tree
			return stepWidth;
		} else {
			// anders minimaal de minimum breedte. Als de trede breder is dan is dan de minimale breedte dan breedte trede aanhouden
			return Math.max(stepWidth, this.standardData.minDepthLanding);
		}
	}
	getMaxHeightLanding(etageHeight, angle) {
		if (this.standardData == null || typeof this.standardData === 'undefined' || (this.standardData.maxHeightLanding === 0 && this.standardData.maxRiseLanding === 0)) {
			// geen data of geen maxheightlanding en maxriselanding dan de hoogte van de etage

			return etageHeight;
		} else if (this.standardData.maxHeightLanding > 0) {
			// als absolute hoogte opgegeven dan houden we die aan en nooit hoger dan de etage

			return Math.min(etageHeight, this.standardData.maxHeightLanding);
		} else {
			// als aantal optreden opgegeven dan bereken we op basis daarvan de hoogte

			return Math.min(etageHeight, this.standardData.maxRiseLanding * this.getMaxRise(angle));
		}
	}
	getMaxHeight(etageHeight, angle) {
		if (this.standardData == null || typeof this.standardData === 'undefined' || (this.standardData.maxHeightStair === 0 && this.standardData.maxRiseStair === 0)) {
			// geen data of geen maxheightlanding en maxriselanding dan de hoogte van de etage
			return etageHeight;
		} else if (this.standardData.maxHeightStair > 0) {
			// als absolute hoogte opgegeven dan houden we die aan en nooit hoger dan de etage
			return Math.min(etageHeight, this.standardData.maxHeightStair);
		} else {
			// als aantal optreden opgegeven dan bereken we op basis daarvan de hoogte
			return Math.min(etageHeight, this.standardData.maxRiseStair * this.getMaxRise(angle));
		}
	}

	getMaxRise(angle) {
		if (this.standardData !== null && typeof this.standardData !== 'undefined') {
			let findMaxRise = this.standardData.fallingProtectionStandardMaxRises.filter(
				(item) => (item.minDegrees === 0 || item.minDegrees <= angle) && (item.maxDegrees === 0 || item.maxDegrees >= angle),
			);

			if (findMaxRise.length > 0) {
				return findMaxRise[0].maxRise;
			}
		}

		return 0;
	}
	defaultYellowNose() {
		if (this.standardData !== null && typeof this.standardData !== 'undefined') {
			return this.standardData.defaultYellowNose;
		}
		return false;
	}
	defaultStepWidth() {
		if (this.standardData !== null && typeof this.standardData !== 'undefined' && this.standardData.defaultStepWidth > 0) {
			return this.standardData.defaultStepWidth;
		}
		return 1000;
	}
	defaultRiser() {
		if (this.standardData !== null && typeof this.standardData !== 'undefined') {
			return this.standardData.defaultRiser;
		}
		return false;
	}
	defaultCoated() {
		if (this.standardData !== null && typeof this.standardData !== 'undefined') {
			return this.standardData.defaultCoated;
		}
		return false;
	}
	defaultStepType() {
		if (this.standardData !== null && typeof this.standardData !== 'undefined' && this.standardData.defaultStepType !== '') {
			return this.standardData.defaultStepType;
		}
		return 'Grating';
	}
	validate(stair) {
		let errors = [];
		if (stair.endLanding.active === true && stair.endLanding.width < this.getMinWidthLanding(stair.stepWidth)) {
			errors.push(
				new CustomError(
					window.Vue.$translate('stair.error.endLandingWidth', {
						minWidth: this.getMinWidthLanding(stair.stepWidth),
					}),
					Errors.ERRORTYPE.validate,
					'standard',
					'landing.end.width',
				),
			);
		}

		stair.intermediateLandings.getAll().forEach((landing, index) => {
			if (landing.width < this.getMinWidthLanding(stair.stepWidth)) {
				errors.push(
					new CustomError(
						window.Vue.$translate('stair.error.landingWidth', {
							minWidth: this.getMinWidthLanding(stair.stepWidth),
						}),
						Errors.ERRORTYPE.validate,
						'standard',
						'landing.' + landing.id + '.width',
					),
				);
			}
			if (landing.height - 1 >= stair.etageHeight) {
				errors.push(new CustomError(window.Vue.$translate('stair.error.landingHeightEtage'), Errors.ERRORTYPE.validate, 'standard', 'landing.' + landing.id + '.height'));
			}
		});

		let countLandings = stair.intermediateLandings.get().length;
		if (stair.endLanding.active === true && stair.endLanding.depth < this.getMinDepthLanding(stair.stepWidth)) {
			errors.push(
				new CustomError(
					window.Vue.$translate('stair.error.endLandingDepth', {
						minDepth: this.getMinDepthLanding(stair.stepWidth),
					}),
					Errors.ERRORTYPE.validate,
					'NenEnIso14122',
					'landing.end.depth',
				),
			);
		}
		if (
			stair.etageHeight > this.getMaxHeight(stair.etageHeight, stair.angle) &&
			(countLandings === 0 || countLandings < Math.ceil(stair.etageHeight / this.getMaxHeightLanding(stair.etageHeight, stair.angle)) - 1)
		) {
			errors.push(
				new CustomError(
					window.Vue.$translate('stair.error.missingLanding', {
						countLandings: countLandings,
						minLandings: Math.ceil(stair.etageHeight / this.getMaxHeightLanding(stair.etageHeight, stair.angle)) - 1, // berekent aantal trappen dus aantal tussenbordessen is 1 minder
					}),
					Errors.ERRORTYPE.validate,
					'NenEnIso14122',
					'minLanding',
				),
			);
		}

		stair.intermediateLandings.getAll().forEach((landing, index) => {
			if (landing.depth < this.getMinDepthLanding(stair.stepWidth)) {
				errors.push(
					new CustomError(
						window.Vue.$translate('stair.error.landingDepth', {
							minDepth: this.getMinDepthLanding(stair.stepWidth),
						}),
						Errors.ERRORTYPE.validate,
						'NenEnIso14122',
						'landing.' + landing.id + '.depth',
					),
				);
			}
			let height = landing.height;
			let nextLanding = stair.intermediateLandings.get(index + 1);
			if (typeof nextLanding !== 'undefined' && nextLanding !== null) {
				height -= nextLanding.height;
			}

			if (height > this.getMaxHeightLanding(stair.etageHeight, stair.angle)) {
				errors.push(
					new CustomError(
						window.Vue.$translate('stair.error.landingHeight', {
							maxHeight: this.getMaxHeightLanding(stair.etageHeight, stair.angle),
						}),
						Errors.ERRORTYPE.validate,
						'NenEnIso14122',
						'landing.' + landing.id + '.height',
					),
				);
			}
			if (index === 0) {
				if (stair.etageHeight - landing.height > this.getMaxHeightLanding(stair.etageHeight, stair.angle)) {
					errors.push(
						new CustomError(
							window.Vue.$translate('stair.error.landingHeightLow', {
								minHeight: this.getMaxHeightLanding(stair.etageHeight, stair.angle),
							}),
							Errors.ERRORTYPE.validate,
							'NenEnIso14122',
							'landing.' + landing.id + '.height',
						),
					);
				}
			}
		});
		return errors;
	}
}
