import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class Riser3D extends Model3D {
	objectName = 'Riser3D';
	hoverTags = ['stairs'];
	assetName = 'riser';
	fallBackName = 'riser';
	fallBackData = { width: 0, depth: 0, height: 0 };
	constructor(x, y, params) {
		super(x, params.shared.yPos, y, params);
		this.oid = null;
	}
	draw(app) {
		let stepHeight = Canvas3D.CURRENT.getModelDataByOid(this.stairData.step.articleId, 'height');
		this.y += stepHeight;
		super.draw(app);
	}
}
