export class SizeHandles {
	objectName = 'SizeHandles';
	sizeHandles = [];
	distance = 25;
	constructor(sizeHandles, redraw) {
		if (typeof sizeHandles !== 'undefined' && sizeHandles !== null) {
			sizeHandles.forEach((sizeHandle) => {
				this.sizeHandles.push(sizeHandle);
			});
			this.setReferences({
				redraw: () => {
					this.redraw();
				},
			});
		}
		this.sizeHandles = this.sizeHandles.sort((a, b) => {
			return a.order - b.order;
		});
		this._redraw = redraw;
	}
	redraw() {
		if (typeof this._redraw === 'function') {
			this._redraw();
		}
	}
	setReferences(parameters) {
		this.sizeHandles.forEach((sizeHandle) => {
			sizeHandle.setReferences(parameters);
		});
		this._redraw = parameters.redraw;
	}
	removeReferences() {
		this._redraw = null;
		this.sizeHandles.forEach((sizeHandle) => {
			if (typeof sizeHandle.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				sizeHandle.removeReferences();
			}
		});
	}
	get(id) {
		if (id === null || typeof id === 'undefined') {
			return null;
		}

		let sh = this.sizeHandles.find((sh) => sh.id === id);
		if (sh !== null && typeof sh !== 'undefined') {
			return sh;
		}

		return null;
	}
	clear() {
		this.sizeHandles = [];
	}
	push(sizeHandle) {
		sizeHandle.setReferences({
			redraw: () => {
				this.redraw();
			},
		});
		this.sizeHandles.push(sizeHandle);
		this.sizeHandles = this.sizeHandles.sort((a, b) => {
			return a.order - b.order;
		});
	}
	get length() {
		return this.sizeHandles.length;
	}
	totalSpace() {
		let space = 0;

		this.sizeHandles.forEach((sizeHandle) => {
			space += sizeHandle.space;
		});

		return space + 50;
	}
	addDrawObjects(canvas) {
		let sizeHandleSpace = 2 * this.distance; // bij start 2*distance om wat afstand te creeeren tov tekening
		// Toevoegen aan de array
		this.sizeHandles
			.sort((b, a) => {
				return a.order - b.order;
			})
			.forEach((sizeHandle, index) => {
				let params = { sizeHandleSpace: sizeHandleSpace };
				if (sizeHandle.visible === true) {
					sizeHandle.addDrawObjects(canvas, params);
					sizeHandleSpace += this.distance;
				}
			});
	}
}
