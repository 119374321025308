import { Data } from './data';

export class Finish extends Data {
	objectName = 'Finish';
	static CURRENT = null;
	url = 'Finishes';
	name = '';
	oid = -1;
	get translateName() {
		return 'colors.finish.' + this.name;
	}
}
