export class AssetManifest3D {
	objectName = 'AssetManifest3D';
	assetManifest = [];
	assetAmount = 0;
	loadedAssetAmount = 0;

	addAsset(asset) {
		if (this.checkExists(asset) === false) {
			this.assetAmount++;
			this.assetManifest.push(asset);
		}
	}

	checkExists(checkAsset) {
		let assetExists = [];
		if (checkAsset.oid === null) {
			// Locale assets die nog niet uit erp komen checken op naam.
			let checkList = this.assetManifest.filter((asset) => asset.oid === null);
			assetExists = checkList.filter((asset) => checkAsset.assetName === asset.assetName && checkAsset.type === asset.type);
		} else {
			// Anders checken op OID of die al in de lijst zit.
			// let checkList = this.assetManifest.filter((asset) => asset.oid === checkAsset.oid);
			assetExists = this.assetManifest.filter((asset) => asset.oid === checkAsset.oid);
		}
		return assetExists.length > 0;
	}

	removeAllAssets() {
		this.assetManifest = [];
		this.assetAmount = 0;
		this.loadedAssetAmount = 0;
	}

	load(thenCallBack) {
		this.assetManifest.forEach((asset, index) => {
			asset.load(() => {
				this.loadedAssetAmount++;
				// Als de laatste asset is geladen de callback uitvoeren
				if (this.assetAmount === this.loadedAssetAmount) {
					if (typeof thenCallBack === 'function') {
						thenCallBack();
					}
				}
			});
		});
	}
}
