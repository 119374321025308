import Vue from 'vue';

Vue.filter('toNumber', (value) => {
	const v = value.toString().replace(',', '').split('.');
	return v[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('toCurrency', (value, currency) => {
	if (typeof value !== 'number') {
		return value;
	}
	const formatter = new Intl.NumberFormat('nl-NL', {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: 2,
	});
	return formatter.format(value);
});

Vue.filter('toNumberSuffix', (number) => {
	let suffix = 'th';
	switch (number % 10) {
		case 1:
			suffix = 'st';
			break;
		case 2:
			suffix = 'nd';
			break;
		case 3:
			suffix = 'rd';
			break;
	}
	return suffix.toString();
});
