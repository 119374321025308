export class TourStep {
	objectName = 'TourStep';
	stepName = '';
	get target() {
		return '#tour-' + this.stepName;
	}
	get content() {
		return window.Vue.$translate('tour.' + this.stepName);
	}
	params = {
		placement: 'right',
	};
	constructor(stepName, placement) {
		if (typeof stepName !== 'undefined' && stepName !== null) {
			this.stepName = stepName;
		}

		if (typeof placement !== 'undefined' && placement !== null) {
			this.params.placement = placement;
		}
	}
}
