import { Data } from './data';
import { Languages } from './languages';

export class Services extends Data {
	objectName = 'Services';
	static CURRENT = null;
	url = 'services';
	getSiteSurvey() {
		if (typeof this._list.list !== 'undefined' || this._list.list !== null) {
			let siteSurvey = this._list.list.filter((l) => l.serviceType === 2);
			if (siteSurvey.length > 0) {
				return siteSurvey[0];
			}
		}
		return null;
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			thenCallBack(this._list.list);
		}
		if (this.store.companies.selectedCompany.id === -1) {
			return null;
		}
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		data.companyId = this.store.companies.selectedCompany.id;
		data.language = Languages.CURRENT.currentLanguage;
		super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}
