import { Data } from './data';
import { Store } from './store';
import { Languages } from './languages';
import { HandRail } from './handRail';

export class HandRails extends Data {
	objectName = 'HandRails';
	static CURRENT = null;
	url = 'HandRails';

	// Vanuit data word deze functie aangeroepen om de list te setten.
	fetchListItem(item) {
		let returnItem = new HandRail();
		returnItem.setStore(this.store);
		returnItem.fetch(item);
		return returnItem;
	}
	getDefaultItem() {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// zoek trap die als defaultkeuze is aangevinkt
			let defaultItem = this._list.list.filter(function (item) {
				return item.defaultChoice === true;
			});
			if (defaultItem.length > 0) {
				// als defaultStair gevonden
				// return { caption: '', value: '' };
				return defaultItem[0];
			}
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list();
		} // geen lijst of niet gevonden dan leeg object

		return { oid: -1, handrailOid: -1, description: '' };
	}
	isChildFriendly(id) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let handrail = this._list.list.find((h) => h.oid === id);

			if (typeof handrail !== 'undefined' && typeof handrail.childFriendly !== 'undefined') {
				return handrail.childFriendly;
			}
		}

		return false;
	}
	getMaterials(id = null, byOid = false) {
		// Wanneer geen id opgegeven alle materialen teruggeven.
		if (id === null) {
			let allMaterials = [];
			this._list.list.forEach((item) => {
				allMaterials.push(item.material);
			});

			return allMaterials;
		}
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			// Op basis van geselecteerde id in configuratie object juiste item ophalen.
			let itemByOid = this._list.list.find((item) => item.oid === id);
			if (itemByOid !== null && typeof itemByOid !== 'undefined') {
				return itemByOid.material;
			}

			// Voor oude configuraties of waar verkeerde id's opgeslagen staan door een programming fout.
			// Dan ophalen bij handrailOid.
			let itemByBaseArticleOid = this._list.list.find((item) => item.handrailOid === id);
			if (itemByBaseArticleOid !== null && typeof itemByBaseArticleOid !== 'undefined') {
				return itemByBaseArticleOid.material;
			}

			// Anders alles returnen.
			return this.getMaterials();
		}
	}

	inList(oid) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			return this._list.list.filter((hr) => hr.oid === oid).length > 0;
		}
		return false;
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		data.language = Languages.CURRENT.currentLanguage;
		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}
