import { Data } from './data';

export class RalMaterials extends Data {
	objectName = 'RalMaterials';
	url = 'RalMaterials';
	getByName(name, force, id, thenCallBack, catchCallBack) {
		// samenstellen url
		let url = this.baseUrl + this.url + '?color=' + name;
		this.get(url, true, thenCallBack, catchCallBack, 'ralcolor' + '_' + id);
	}
}
