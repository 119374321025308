export class LocalStorage {
	restore(object) {
		if (typeof localStorage !== 'undefined') {
			// voorkom foutmeldingen bij serverside als geen localstorage is het serverside dus niet uitvoeren
			// ophalen uit localstorage waarbij het opgeslagen is onder de objectnaam
			let savedJson = localStorage.getItem(object.objectName);
			// als er een json is dan was wer wat opgeslagen
			if (savedJson !== null) {
				// terugbrengen naar een object
				let restoredObject = JSON.parse(savedJson);
				// object langslopen
				for (let key in restoredObject) {
					// als veld aanwezig dan ook waarde terugzetten
					if (typeof object[key] !== 'undefined') {
						object[key] = restoredObject[key];
					}
				}
			}
		}
	}
	save(object) {
		if (typeof localStorage !== 'undefined') {
			// voorkom foutmeldingen bij serverside als geen localstorage is het serverside dus niet uitvoeren

			// Object als tekst opslaan onder objectnaam
			localStorage.setItem(object.objectName, JSON.stringify(object.persistentData()));
		}
	}
	remove(object) {
		if (typeof localStorage !== 'undefined') {
			// voorkom foutmeldingen bij serverside als geen localstorage is het serverside dus niet uitvoeren
			// betreffende item verwijderen
			localStorage.removeItem(object.objectName);
		}
	}
}
