import { Dimension3D } from '../dimension3D';

export class DimensionHorizontal3D extends Dimension3D {
	objectName = 'DimensionHorizontal3D';
	draw() {
		super.draw();
		// Omdat barEntity een box is dan deze goed positioneren op X as.
		this.barEntity.setLocalPosition(this.x + this.width / 1000 / 2, this.y, this.z);
		this.barEntity.setLocalScale(this.width / 1000, 0.05, 0.05);

		// StartHandle altijd begin van raster.
		this.startHandle.setLocalPosition(this.x, this.y, this.z);
		this.startHandle.setLocalScale(this.handleWidth, 0.05, this.handleDepth);

		// Endhandle altijd aan het einde van het huidige raster.
		this.endHandle.setLocalPosition(this.x + this.width / 1000, this.y, this.z);
		this.endHandle.setLocalScale(this.handleWidth, 0.05, this.handleDepth);
	}
}
