// Brace Columns
import { BraceColumnBottom3D } from '../draw3d/bracecolumns/braceColumnBottom3D';
import { BraceColumnLeft3D } from '../draw3d/bracecolumns/BraceColumnLeft3D';
import { BraceColumnRight3D } from '../draw3d/bracecolumns/braceColumnRight3D';
import { BraceColumnTop3D } from '../draw3d/bracecolumns/BraceColumnTop3D';

import { Mathematic } from '../helpers/mathematic';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { Canvas3D } from '../draw3d/Canvas3D';
import { BraceColumns } from './braceColumns';
import { Configuration } from './configuration';
import { CustomError } from './CustomError';
import { Errors } from './errors';
import { Profiles } from './profiles';
import { BasePlate3D } from '../draw3d/baseplate/basePlate3D';

export class BraceColumn {
	objectName = 'BraceColumn';
	objectName3d = 'BraceColumn3D';
	x = -1;
	y = -1;
	direction = 0;
	_braceColumnActive = true;
	_onChange = null;

	get braceColumnActive() {
		return this._braceColumnActive;
	}

	set braceColumnActive(value) {
		this._braceColumnActive = value;
	}

	constructor(column, direction) {
		if (typeof column !== 'undefined' && column !== null) {
			this.x = column.x;
			this.y = column.y;
		}
		if (typeof direction !== 'undefined' && direction !== null) {
			this.direction = direction;
		}
	}
	onColumnChange(x, y, evt, canvas, params, actualSizeBefore, actualSizeAfter, drawObject) {
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			let deltaX = evt.delta.x / canvas.scaleFactor;
			if (this.x === x && this.y === y && this.direction === BraceColumns.LEFT) {
				drawObject.x.value += deltaX;
				drawObject.eindX.value += deltaX;
			}
			if (this.x === x && this.y === y && this.direction === BraceColumns.RIGHT) {
				drawObject.x.value += deltaX;
				drawObject.eindX.value += deltaX;
			}
			if (this.x === x && this.y === y && this.direction === BraceColumns.TOP) {
				drawObject.x.value += deltaX;
				drawObject.eindX.value += deltaX;
			}
			if (this.x === x && this.y === y && this.direction === BraceColumns.BOTTOM) {
				drawObject.x.value += deltaX;
				drawObject.eindX.value += deltaX;
			}
		} else {
			let deltaY = evt.delta.y / canvas.scaleFactor;
			if (this.x === x && this.y === y && this.direction === BraceColumns.TOP) {
				drawObject.y.value += deltaY;
				drawObject.eindY.value += deltaY;
			}
			if (this.x === x && this.y === y && this.direction === BraceColumns.BOTTOM) {
				drawObject.y.value += deltaY;
				drawObject.eindY.value += deltaY;
			}
			if (this.x === x && this.y === y && this.direction === BraceColumns.LEFT) {
				drawObject.y.value += deltaY;
				drawObject.eindY.value += deltaY;
			}
			if (this.x === x && this.y === y && this.direction === BraceColumns.RIGHT) {
				drawObject.y.value += deltaY;
				drawObject.eindY.value += deltaY;
			}
		}
	}
	get depth() {
		let heightColumn =
			Configuration.CURRENT.columns.getHeight(0, false) -
			BraceColumns.MOUNTING_MATERIAL_HEIGHT -
			BraceColumns.PROFILE_FREE_SIZE -
			Configuration.CURRENT.columns.basePlate.height -
			Configuration.CURRENT.columns.bracket.height;
		return parseFloat((heightColumn / Math.tan((BraceColumns.ANGLE * Math.PI) / 180)).toFixed(2));
	}
	get length() {
		let heightColumn =
			Configuration.CURRENT.columns.getHeight(0, false) -
			BraceColumns.MOUNTING_MATERIAL_HEIGHT -
			BraceColumns.PROFILE_FREE_SIZE -
			Configuration.CURRENT.columns.basePlate.height -
			Configuration.CURRENT.columns.bracket.height;
		return parseFloat((heightColumn / Math.sin((BraceColumns.ANGLE * Math.PI) / 180)).toFixed(2));
	}
	collisions(boundaries, self) {
		if (!this.braceColumnActive) {
			return { result: false, errors: [], objectName: this.objectName };
		}
		// voorlopig ervan uitgaand dat het een vierkant betreft
		let overlap = false;
		let positionStart = { x: Configuration.CURRENT.raster.getSizeX(this.x - 1), y: Configuration.CURRENT.raster.getSizeY(this.y - 1) };
		let positionEnd = { x: Configuration.CURRENT.raster.getSizeX(this.x - 1), y: Configuration.CURRENT.raster.getSizeY(this.y - 1) };

		switch (this.direction) {
			case BraceColumns.LEFT:
				positionStart.x -= this.depth;
				break;
			case BraceColumns.TOP:
				positionStart.y -= this.depth;
				break;
			case BraceColumns.RIGHT:
				positionEnd.x += this.depth;
				break;
			case BraceColumns.BOTTOM:
				positionEnd.y += this.depth;
				break;
		}
		boundaries.forEach((boundary) => {
			if (Mathematic.overlapRectangles(boundary.topLeft, boundary.bottomRight, positionStart, positionEnd) === true) {
				if (self.objectName !== 'PalletGate' && self.objectName !== 'StairOutSide') {
					// bij palletgate is geen overlap met windverband (staat er boven) // zou met hoogte te doen zijn maar wordt nu niet naar gekeken
					overlap = true;
				}
			}
		});
		if (overlap === true) {
			return { result: true, errors: [new CustomError(window.Vue.$translate('collision.braceColumn', { x: this.x, y: this.y }), Errors.ERRORTYPE.collision, this.objectName)] };
		}
		return { result: false, errors: [], objectName: this.objectName };
	}
	equals(column, direction) {
		return this.x === column.x && this.y === column.y && this.direction === direction;
	}
	addDrawObjects(canvas, params, createBraceColumn) {
		if (!this.braceColumnActive) {
			return;
		}
		const column = Configuration.CURRENT.columns.find(this.x, this.y);
		if (column !== null) {
			canvas.addDrawObject(createBraceColumn(column, this.direction, BraceColumns.COLORS.braceColumn, this));
		}
	}
	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		if ((raster.x > -1 && raster.x === drawObject.objectParams.column.x) || (raster.y > -1 && raster.y === drawObject.objectParams.column.y)) {
			drawObject.x.value += delta.x;
			drawObject.y.value += delta.y;
			drawObject.eindX.value += delta.x;
			drawObject.eindY.value += delta.y;
		}
	}
	addDrawObjects3d(canvas3d, etage, raster, posY) {
		if (!this.braceColumnActive || Configuration.CURRENT.columns.find(this.x, this.y) === null) {
			return;
		}
		const column = Configuration.CURRENT.columns.find(this.x, this.y);
		let columnPosition = column.getPosition3D(column);

		let height = etage.getHeight(true);

		let positionX = this.x;
		let positionY = this.y;

		let mainBeamHeight;
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			let spansX = raster.spansX.getSpans();
			if (this.x === spansX.length) {
				positionX -= 1;
			}
			mainBeamHeight = Canvas3D.CURRENT.getBeamData(spansX[positionX].beam.oid, 'height');
		} else {
			let spansY = raster.spansY.getSpans();
			if (this.y === spansY.length) {
				positionY -= 1;
			}
			mainBeamHeight = Canvas3D.CURRENT.getBeamData(spansY[positionY].beam.oid, 'height');
		}

		let basePlateData = Configuration.CURRENT.columns.column.basePlate;
		let paramsBasePlate = {
			drawHeight: 0,
			oid: basePlateData.oid,
			assetName: basePlateData.name,
			ralColor: Configuration.CURRENT.colors.bracingColumns.finish === 1 || Configuration.CURRENT.colors.bracingColumns.finish === 2 ? 7036 : Configuration.CURRENT.colors.bracingColumns.ralColor,
		};

		let paramsObject = {
			height: height,
			assetName: Configuration.CURRENT.columns.column.name,
			oid: Configuration.CURRENT.columns.column.oid,
			basePlate: new BasePlate3D(columnPosition.x, 0, columnPosition.z, paramsBasePlate),
			mainBeamHeight: mainBeamHeight,
			info: this,
			columnWidth: Configuration.CURRENT.columns.column.width,
			columnThickness: Configuration.CURRENT.columns.column.thickness,
			ralColor: Configuration.CURRENT.colors.bracingColumns.finish === 1 || Configuration.CURRENT.colors.bracingColumns.finish === 2 ? 7036 : Configuration.CURRENT.colors.bracingColumns.ralColor,
		};

		switch (this.direction) {
			case BraceColumns.LEFT:
				canvas3d.addDrawObject(new BraceColumnLeft3D(columnPosition.x, height, columnPosition.z, paramsObject), Canvas3D.TYPE_BRACECOLUMN);
				break;
			case BraceColumns.TOP:
				canvas3d.addDrawObject(new BraceColumnTop3D(columnPosition.x, 0, columnPosition.z, paramsObject), Canvas3D.TYPE_BRACECOLUMN);
				break;
			case BraceColumns.RIGHT:
				canvas3d.addDrawObject(new BraceColumnRight3D(columnPosition.x, height, columnPosition.z, paramsObject), Canvas3D.TYPE_BRACECOLUMN);
				break;
			case BraceColumns.BOTTOM:
				canvas3d.addDrawObject(new BraceColumnBottom3D(columnPosition.x, 0, columnPosition.z, paramsObject), Canvas3D.TYPE_BRACECOLUMN);
				break;
		}
	}
	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
	}
	onChange() {
		if (typeof this._onChange !== 'undefined') {
			this._onChange();
		}
	}
	calculate() {
		if (Configuration.CURRENT.columns.find(this.x, this.y) !== null && typeof Configuration.CURRENT.columns.find(this.x, this.y) !== 'undefined') {
			this.braceColumnActive = Configuration.CURRENT.columns.find(this.x, this.y).columnActive;
		} else {
			this.braceColumnActive = false;
		}
	}
}
