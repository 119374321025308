import { jsonProfile } from '../../../../configurator/hicad/jsonProfile';
import { Canvas3D } from '../../../Canvas3D';
import { ProfilesHorizontal3D } from '../../ProfilesHorizontal3D';

export class MainBeamTop3D extends ProfilesHorizontal3D {
	objectName = 'MainBeamTop3D';
	profileType = 'mainbeam';
	// eslint-disable-next-line no-useless-constructor
	constructor(x, y, length, params) {
		super(x, y, length, params);
		this.depth = 1;
	}
	draw(app) {
		super.draw(app);
	}

	// Convert MainBeamTop3D naar HiCADProfile, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_MAINBEAM, direction: jsonProfile.DIRECTION_HORIZONTAL, position: jsonProfile.POSITION_TOP });
	}
}
