import { Data } from './data';
import { Leaflet } from './leaflet';
export class Leaflets extends Data {
	objectName = 'Leaflets';
	static CURRENT = null;
	url = 'leaflets';
	loadAll(language, force, cacheId, thenCallBack) {
		this.list({ language }, force, cacheId, '', (data) => {
			let newList = [];
			for (const item of data) {
				let newItem = new Leaflet();
				newItem.id = item.id;
				newItem.description = item.description;
				newItem.thumbnail = item.thumbnail;
				newList.push(newItem);
			}
			thenCallBack(newList);
		});
	}
}
