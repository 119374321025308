import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { ColumnProtector3D } from './ColumnProtector3D';

export class ProtectorRightTop3D extends ColumnProtector3D {
  objectName = 'ProtectorRightTop3D';
  rotationY = -90;
  draw(app) {
    let modelWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
    let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

    this.x += modelWidth;
    this.x += columnWidth / 2;
    this.z -= modelWidth;

    super.draw(app);
  }

}
