import { Canvas3D } from '../Canvas3D';
import { Asset3D } from './Asset3D';

export class LocalAsset3D extends Asset3D {
	objectName = 'LocalAsset3D';
	assetName = '';
	type = '';
	oid = null;
	// eslint-disable-next-line no-useless-constructor
	constructor(assetName, oid, params = null, type = 'model', extension = 'json') {
		super(assetName, oid, params);
		this.assetName = assetName;
		this.folder = assetName;
		this.oid = oid;
		this.type = type;
		this.extension = extension;
	}
	load(thenCallBack) {
		if (this.folder !== '') {
			this.folder = this.folder + '/';
		}

		Canvas3D.CURRENT.app.assets.loadFromUrl('/playcanvas/' + this.folder + this.assetName + '.' + this.extension, this.type, (err, asset) => {
			// Locale assets hebben alleen fallbackdata
			asset.modelData = this.fallBackData;
			if (err) {
				console.log(err, this);
			}
			if (typeof thenCallBack === 'function') {
				thenCallBack();
			}
		});
	}
}
