import { Configuration } from '../configuration';
import { HicadDrawObject } from './HicadDrawObject';

export class jsonBracing extends HicadDrawObject {
	position = 0;
	width = 0;
	modelName = '';
	verticalHeight = 0;
	startColumnPosition;
	constructor(bracing) {
		super(bracing);
		this.position = this.getPosition(bracing);
		this.width = this.calculateWidth(bracing);
		this.modelName = bracing.objectName;
		this.verticalHeight = bracing.calculationData.etageHeight;
		this.startColumnPosition = bracing.calculationData.startColumnPosition;
		this.endColumnPosition = bracing.calculationData.endColumnPosition;
	}

	getPosition(bracing) {
		// horizontal
		if (bracing.objectName === 'BracingHorizontalBottom3D' || bracing.objectName === 'BracingHorizontalTop3D') {
			return 0;
		}
		// vertical
		return 1;
	}

	calculateWidth(bracing) {
		let width = bracing.calculationData.endColumnPosition - bracing.calculationData.startColumnPosition;

		if (this.position === 0) {
			width -= (Configuration.CURRENT.columns.column._h_col / 2) * 3;
		}

		return width;
	}
}
