import { Columns } from '../../configurator/columns';
import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
export class CapPlateVertical3D extends Model3D {
	objectName = 'CapPlateVertical3D';
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.rowPositions = params.info.rowPositions;
		this.placement = params.info.placement;

		this.assetName = params.info.capPlate.name;
		this.oid = params.info.capPlate.oid;
	}
	draw(app) {
		let capPlateWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.capPlate.oid, 'width');
		let capPlateDepth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.capPlate.oid, 'depth');
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		this.z -= Math.abs(capPlateDepth - columnWidth) / 2;

		super.draw(app);
	}
}
