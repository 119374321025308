import { Configuration } from '../../../configurator/configuration';
import { jsonProfile } from '../../../configurator/hicad/jsonProfile';
import { Raster } from '../../../configurator/raster';
import { Canvas3D } from '../../Canvas3D';
import { ProfilesHorizontal3D } from '../ProfilesHorizontal3D';

export class HorizontalChildBeam3D extends ProfilesHorizontal3D {
	objectName = 'HorizontalChildBeam3D';
	mainBeamDepth = 0;
	mainBeamHeight = 0;
	// eslint-disable-next-line no-useless-constructor
	constructor(x, y, length, params) {
		super(x, y, length, params);
		this.calculate(params);
		this.updateExceptions(params);
		this.parentEntityName = params.profileType + '_' + params.indexX + '_' + params.indexY + '_' + params.loopIndex;
	}

	updateExceptions(params) {
		let childBeamHeight = Canvas3D.CURRENT.getBeamData(params.oid, 'height');
		let childBeamDepth = Canvas3D.CURRENT.getBeamData(params.oid, 'depth');
		// wanneer het de laatste is, moet deze worden geroteerd in tegengestelde richting van de andere balken,
		// zodat de kpp geplaatst kan worden.
		if (params.isLast===true) {
			this.rotationX = -180;
			this.y += childBeamHeight;
		}
	}
	calculate(params) {
		this.mainBeamDepth = params.mainBeamDepth;
		this.mainBeamHeight = params.mainBeamHeight;

		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let childBeamHeight = Canvas3D.CURRENT.getBeamData(params.oid, 'height');

		this.width = this.getHorizontalChildBeamLength(this.width);

		if (this.buildingColumnBeam) {
			this.applyBuildingColumnBeamOffsets(app);
		} else if (this.multipleFloors) {
			if (this.doubleDouble) {
				this.x += this.mainBeamDepth;
			}
			if (this.rowPositions.includes(Raster.POSITION_LEFT)) {
				this.x += columnWidth;
				this.x += this.mainBeamDepth;
			} else if (this.rowPositions.includes(Raster.POSITION_RIGHT)) {
				this.x += columnWidth / 2;
				this.x += this.mainBeamDepth;
			} else {
				this.x += columnWidth / 2;
				this.x += this.mainBeamDepth;
			}
		} else {
			this.x += this.mainBeamDepth;
			if (this.doubleDouble) {
				this.x += this.mainBeamDepth;
			}
		}

		// this.y += mainBeamHeight - childBeamHeight;
		this.y -= childBeamHeight;
		this.y -= deckingFinishHeight;
		// this.y -= mainBeamHeight;
	}
	draw(app) {
		super.draw(app, 'childbeam');
	}
	getHorizontalChildBeamLength(width) {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		// Horizontal Childbeam
		if (this.multipleFloors) {
			if (this.rowPositions.includes(Raster.POSITION_LEFT) || this.rowPositions.includes(Raster.POSITION_RIGHT)) {
				width -= this.mainBeamDepth * 2;
				width -= columnWidth / 2;
				width -= columnWidth;
			} else {
				width -= this.mainBeamDepth * 2;
				width -= columnWidth / 2;
			}
		} else {
			width -= this.mainBeamDepth * 2;
		}
		if (this.doubleDouble) {
			width -= this.mainBeamDepth * 2;
		}

		return width;
	}

	applyBuildingColumnBeamOffsets() {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		if (this.multipleFloors) {
			if (this.buildingColumnBeamPosition === 'start') {
				if (this.doubleDouble) {
					this.x += this.mainBeamDepth;
				}
			}
			if (this.rowPositions.includes(Raster.POSITION_LEFT)) {
				if (this.buildingColumnBeamPosition === 'start') {
					this.x += columnWidth;
					this.x += this.mainBeamDepth;
				} else {
					this.x += this.mainBeamDepth;
				}
			} else if (this.rowPositions.includes(Raster.POSITION_RIGHT)) {
				if (this.buildingColumnBeamPosition === 'start') {
					this.x += columnWidth / 2;
					this.x += this.mainBeamDepth;
				} else {
					this.x += this.mainBeamDepth;
				}
			} else if (this.buildingColumnBeamPosition === 'start') {
				this.x += columnWidth / 2;
				this.x += this.mainBeamDepth;
			} else {
				this.x += this.mainBeamDepth;
			}
		} else {
			this.x += this.mainBeamDepth;
			if (this.buildingColumnBeamPosition === 'start') {
				if (this.doubleDouble) {
					this.x += this.mainBeamDepth;
				}
			}
		}
	}

	// Convert HorizontalChildBeam3D HiCADProfile, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_CHILDBEAM, direction: jsonProfile.DIRECTION_HORIZONTAL, position: jsonProfile.POSITION_TOP });
	}
}
