import { Functions } from '../helpers/functions';

export class CustomError {
	objectName = 'CustomError';
	text = '';
	type = '';
	errorObjectName = '';
	id = '';
	source = '';
	sourceDescription = '';
	simpleError = '';
	constructor(text, type, simpleError = '', objectName, id) {
		this.text = text;
		this.type = type;
		this.errorObjectName = objectName;
		this.source = objectName;
		this.sourceDescription = objectName;
		this.simpleError = simpleError;
		if (typeof id !== 'undefined' && id !== null) {
			this.id = id;
		} else {
			this.id = Functions.uuidv4();
		}
	}
}
