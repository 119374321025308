import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { ColumnProtector3D } from './ColumnProtector3D';

export class ProtectorRightBottom3D extends ColumnProtector3D {
	objectName = 'ProtectorRightBottom3D';
  rotationY = 180;
	draw(app) {
    let modelWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
    let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

    this.x += modelWidth;
    this.x += columnWidth / 2;
    this.z += modelWidth;
    this.z += columnWidth;

    super.draw(app);

  }
}
