import { PalletGate3D } from './PalletGate3D';
export class PalletGateTop3D extends PalletGate3D {
	objectName = 'PalletGateTop3D';
	rotationY = 180;
	draw(app) {
		// Op Y as 180 graden geroteerd dus dan moeten we de drawWidth bij de x optellen.
		this.x += this.info.drawWidth;
		// Omgekeerde strip aan de buitenkant.
		this.z -= 5;
		super.draw(app);
	}
}
