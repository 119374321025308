export class Color {
	objectName = 'Color';
	_ralColor = '7016';
	_finish = 0;
	_type = null;
	_colorPossible = true;
	_overrideDefault = false;
	_changed = false;

	set colorPossible(value) {
		this._colorPossible = value;
	}
	get colorPossible() {
		return this._colorPossible;
	}

	set overrideDefault(value) {
		this._overrideDefault = value;
	}
	get overrideDefault() {
		return this._overrideDefault;
	}

	constructor(onSave, onChange) {
		this._onSave = onSave;
		this._onChange = onChange;
	}

	set ralColor(value) {
		if (this._ralColor !== value) {
			this._ralColor = value;
			this.onChange();
		}
	}

	//* * Functie die wanneer de defaultkleur aangepast word de eigen kleur aanpast en de changed op false zet. Hier geen onChange aanroepen maar na de loep uit colors.js
	setDefaultRal(value) {
		if (this._ralColor !== value) {
			this._ralColor = value;
		}
	}

	//* * Functie die aangeroepen word vanuit component, dan triggeren we een onchange met changedbydefault op false, die dan changed in colors.js op true zet.   */
	setColorByOwn(value) {
		if (this._ralColor !== value) {
			this._ralColor = value;
			this.changed = true;
			this.onChange();
		}
	}

	//* * Functie die vanuit setDefaults word aangeroepen, hiermee triggeren we dan niet de onSave, maar zet wel zijn eigen waarden goed. (Wanneer de onsave getriggerd word dan alsnog de juiste kleuren ophalen) */
	SetOwnDefaults(item) {
		this._finish = item.finish;
		this._ralColor = item.color.toString();
		this._overrideDefault = item.overrideDefault;
		this._colorPossible = item.colorPossible;
		this._type = item.type;
	}

	get ralColor() {
		return this._ralColor;
	}

	set finish(value) {
		if (value !== this._finish) {
			this._finish = value;
			this.onChange();
		}
		if (value === 2) {
			this.changed = false;
		}
	}
	get finish() {
		return this._finish;
	}

	set type(value) {
		this._type = value;
	}
	get type() {
		return this._type;
	}

	set changed(value) {
		this._changed = value;
	}
	get changed() {
		return this._changed;
	}

	setReferences(params) {
		this._onSave = params.colorSave;
	}
	removeReferences() {
		this._onChange = null;
		this._onSave = null;
	}

	onChange() {
		if (typeof this._onChange === 'function' && typeof this.type !== 'undefined') {
			this._onChange(this.type);
		}

		if (typeof this._onSave === 'function') {
			this._onSave(this.type);
		}
	}

	getData() {
		return {
			ralColor: this.ralColor,
			finish: this.finish,
		};
	}
}
