import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { Span } from './span';
import { SpanArray } from './spanArray';
// import { SizeHandles } from './sizeHandles';
import { RemoveRaster } from './removeRaster';
import { Configuration } from './configuration';
import { Profiles } from './profiles';
import { SizeHandleTotal } from '../draw/sizeHandleTotal';
import { Canvas3D } from '../draw3d/Canvas3D';
import { LocalAsset3D } from '../draw3d/assets/LocalAsset3D';
import { DimensionHorizontalTop3D } from '../draw3d/dimensioning/horizontal/dimensionHorizontalTop3D';
import { DimensionVerticalLeft3D } from '../draw3d/dimensioning/vertical/dimensionVerticalLeft3D';
import { DimensionHorizontalBottom3D } from '../draw3d/dimensioning/horizontal/dimensionHorizontalBottom3D';
import { DimensionVerticalRight3D } from '../draw3d/dimensioning/vertical/dimensionVerticalRight3D';

export class Raster {
	objectName = 'Raster';
	static BOVEN = { x: 0, y: -1 };

	static ONDER = { x: 0, y: 1 };
	static LINKS = { x: -1, y: 0 };
	static RECHTS = { x: 1, y: 0 };
	static LINKSBOVEN = { x: -1, y: -1 };
	static LINKSONDER = { x: -1, y: 1 };
	static RECHTSBOVEN = { x: 1, y: -1 };
	static RECHTSONDER = { x: 1, y: 1 };
	static MIN_SIZE = 1000;

	static POSITION_TOP = 0;
	static POSITION_BOTTOM = 1;
	static POSITION_LEFT = 2;
	static POSITION_RIGHT = 3;
	static TYPE_MIDDLERASTER = 4;
	static TYPE_BORDERRASTER = 5;

	spansX = new SpanArray(null, this.onChange.bind(this));
	spansY = new SpanArray(null, this.onChange.bind(this));
	mouseAreaOffset = { x: 0, y: 10 };
	mousePriority = 40;
	rasterInterActive = true;
	onChangeSizeHandleXWaiting = null;
	onChangeSizeHandleYWaiting = null;
	onChangeTimeOut = 50;
	_onChange = null;
	onChange(params, force) {
		if (typeof this._onChange === 'function') {
			this._onChange(params, force);
		}
	}
	mouseMoveActive = { x: -1, y: -1 };
	constructor(onChange) {
		this._onChange = onChange;
	}
	setReferences(params) {
		this._onChange = params.onChange;
		this.spansX.setReferences(params);
		this.spansY.setReferences(params);
	}

	removeReferences() {
		this._onChange = null;
		if (typeof this.spansX.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.spansX.removeReferences();
		}
		if (typeof this.spansY.removeReferences === 'function') {
			// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
			this.spansY.removeReferences();
		}
	}

	createNewConfiguration(data) {
		this.spansX = new SpanArray(null, this.onChange.bind(this));
		this.spansY = new SpanArray(null, this.onChange.bind(this));
		data.spansX.forEach((spansX, indexX) => {
			this.spansX.push(new Span(spansX));
		});
		data.spansY.forEach((spansY, indexX) => {
			this.spansY.push(new Span(spansY));
		});
	}
	getRasterByCoordinate(xCoordinate, yCoordinate) {
		const raster = { x: -1, y: -1 };
		let length = 0;
		this.spansX.spans.forEach((span, index) => {
			// zoek de xcoordinaat
			if (xCoordinate >= length && xCoordinate < length + span.value) {
				// als xcoordinaat binnen raster valt dan onthouden
				raster.x = index;
			}
			length += span.value;
		});
		length = 0;
		this.spansY.spans.forEach((span, index) => {
			// zoek de ycoordinaate
			if (yCoordinate >= length && yCoordinate < length + span.value) {
				raster.y = index;
			}
			length += span.value;
		});
		return raster;
	}
	getSizeX(index = this.spansX.length - 1) {
		return this.spansX.getSize(index);
	}
	getSizeY(index = this.spansY.length - 1) {
		return this.spansY.getSize(index);
	}
	getSize(span, index = span.length - 1) {
		return span.getSize(index);
	}

	getRowPositions(x, y) {
		let rowPositions = [];
		let placement = -1;

		let POSITION_TOP = 0;
		let POSITION_BOTTOM = 1;
		let POSITION_LEFT = 2;
		let POSITION_RIGHT = 3;
		let TYPE_MIDDLERASTER = 4;
		let TYPE_BORDERRASTER = 5;

		if (y === 0) {
			// Eerste rij
			placement = TYPE_BORDERRASTER;
			rowPositions.push(POSITION_TOP);
		} else if (y !== 0 && y !== Configuration.CURRENT.raster.spansY.length - 1) {
			// Middelste rij
			placement = TYPE_MIDDLERASTER;
		} else if (y === Configuration.CURRENT.raster.spansY.length - 1) {
			placement = TYPE_BORDERRASTER;
			rowPositions.push(POSITION_BOTTOM);
		}
		if (x === 0) {
			// Eerste kolom op de laatste rij
			rowPositions.push(POSITION_LEFT);
		} else if (x === Configuration.CURRENT.raster.spansX.length - 1) {
			// Laatste op de laatste rij
			rowPositions.push(POSITION_RIGHT);
		}

		return { rowPositions: rowPositions, placement: placement };
	}

	setStep(step) {
		// doorzetten van step. Komt uit configuration
		this.spansX.getSpans().forEach(function (value) {
			value.step = step;
		});
		this.spansY.getSpans().forEach(function (value) {
			value.step = step;
		});
	}
	getMaxOverSpanHorizontal() {
		// Ophalen van de kolommmen
		let columns = Configuration.CURRENT.columns;

		// bijhouden langste lengte
		let maxLength = 0; //

		// doorloop alle rasterrijen
		for (let indexY = 0; indexY <= this.spansY.length; indexY++) {
			// doorgaan kleiner of gelijk aan omdat er een extra rij kolommen is
			let maxLengthRasterRow = 0; // bijhouden voor huidige rij

			// doorloop alle rasterkolommen
			this.spansX.spans.forEach((spanX, indexX) => {
				// lengte van huidige raster
				let length = spanX.value;
				// zoek kolom op
				let column = columns.find(indexX, indexY);
				if (column !== null) {
					// als kolom gevonden
					length -= column.positionOffset.x; // offset eraf halen optellen (of eraf erbij optellen als negatief)
					if (column.positionOffset.x !== 0) {
					}
				}

				// zoek volgende kolom
				let nextColumn = columns.find(indexX + 1, indexY);
				if (nextColumn !== null) {
					// als die bestaat
					length += nextColumn.positionOffset.x; // offset erbij optellen (of eraf halen als negatief)

					if (nextColumn.positionOffset.x !== 0) {
					}
				}
				if (maxLengthRasterRow < length) {
					maxLengthRasterRow = length;
				}
			});
			if (maxLength < maxLengthRasterRow) {
				maxLength = maxLengthRasterRow;
			}
		}
		return Math.round(maxLength);
	}
	getMaxOverSpanVertical() {
		// Ophalen van de kolommmen
		let columns = Configuration.CURRENT.columns;

		// bijhouden langste lengte
		let maxLength = 0; //

		// doorloop alle rasterkolommen
		for (let indexX = 0; indexX <= this.spansX.length; indexX++) {
			let maxLengthRasterColumn = 0; // bijhouden voor huidige kolom

			// doorloop alle rasterrijen
			this.spansY.spans.forEach((spanY, indexY) => {
				// lengte van huidige raster
				let length = spanY.value;
				// zoek kolom op
				let column = columns.find(indexX, indexY);
				if (column !== null) {
					// als kolom gevonden
					length -= column.positionOffset.y; // offset eraf halen optellen (of eraf erbij optellen als negatief)
				}

				// zoek volgende kolom
				let nextColumn = columns.find(indexX, indexY + 1);
				if (nextColumn !== null) {
					// als die bestaat
					length += nextColumn.positionOffset.y; // offset erbij optellen (of eraf halen als negatief)
				}
				if (maxLengthRasterColumn < length) {
					maxLengthRasterColumn = length;
				}
			});
			if (maxLength < maxLengthRasterColumn) {
				maxLength = maxLengthRasterColumn;
			}
		}

		return Math.round(maxLength);
	}
	onSizeHandleChangedHorizontal(evt, object, canvas, params) {
		// na drag en mouseup van sizehandle
		if (evt.type === 'mouseup') {
			const raster = params.raster;

			const rasterAbove = params.raster - 1;
			let newRoundValue = Math.floor(params.lengthRaster / 50) * 50;
			let newRoundValueAbove = Math.floor(params.lengthRasterAbove / 50) * 50;
			newRoundValueAbove += params.lengthRaster + params.lengthRasterAbove - (newRoundValue + newRoundValueAbove);
			Configuration.CURRENT.etages.activeEtage().raster.spansX.get(raster).saveChangedValue(raster, 'x', newRoundValue);
			Configuration.CURRENT.etages.activeEtage().raster.spansX.get(rasterAbove).saveChangedValue(rasterAbove, 'x', newRoundValueAbove);
		} else {
			const prevRaster = params.raster - 1;
			const currentRaster = params.raster;
			const nextRaster = params.raster + 1;
			let currentRasterValue = Math.floor(params.newLengthCurrentRaster / 50) * 50;
			let prevRasterValue = Math.floor(params.newLengthPreviousRaster / 50) * 50;
			let nextRasterValue = Math.floor(params.newLengthNextRaster / 50) * 50;

			if (typeof Configuration.CURRENT.etages.activeEtage().raster.spansX.get(nextRaster) !== 'undefined' && nextRasterValue > 0) {
				Configuration.CURRENT.etages.activeEtage().raster.spansX.get(nextRaster).saveChangedValue(nextRaster, 'x', nextRasterValue);
			}
			if (typeof Configuration.CURRENT.etages.activeEtage().raster.spansX.get(currentRaster) !== 'undefined') {
				Configuration.CURRENT.etages.activeEtage().raster.spansX.get(currentRaster).saveChangedValue(currentRaster, 'x', currentRasterValue);
			}
			if (typeof Configuration.CURRENT.etages.activeEtage().raster.spansX.get(prevRaster) !== 'undefined' && prevRasterValue > 0) {
				Configuration.CURRENT.etages.activeEtage().raster.spansX.get(prevRaster).saveChangedValue(prevRaster, 'x', prevRasterValue);
			}
		}
	}
	onSizeHandleChangeHorizontal(evt, object, canvas, params) {
		// tijdens drag van sizehandle
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			canvas.drawObjects.onChangeChildBeamLength({ x: params.raster, y: -1 }, { x: params.move, y: 0 }, evt, object, canvas, params, {
				raster: params.lengthRaster,
				rasterLinks: params.lengtRasterLeft,
			});
		} else {
			canvas.drawObjects.onChangeMainBeamLength({ x: params.raster, y: -1 }, { x: params.move, y: 0 }, evt, object, canvas, params, {
				raster: params.lengthRaster,
				rasterLinks: params.lengtRasterLeft,
			});
		}
	}
	onSizeHandleChangedVertical(evt, object, canvas, params) {
		// na drag en mouseup van sizehandle
		if (evt.type === 'mouseup') {
			const raster = params.raster;

			const rasterAbove = params.raster - 1;
			let newRoundValue = Math.floor(params.lengthRaster / 50) * 50;
			let newRoundValueAbove = Math.floor(params.lengthRasterAbove / 50) * 50;
			newRoundValueAbove += params.lengthRaster + params.lengthRasterAbove - (newRoundValue + newRoundValueAbove);
			Configuration.CURRENT.etages.activeEtage().raster.spansY.get(raster).saveChangedValue(raster, 'y', newRoundValue);
			Configuration.CURRENT.etages.activeEtage().raster.spansY.get(rasterAbove).saveChangedValue(rasterAbove, 'y', newRoundValueAbove);
		} else {
			const prevRaster = params.raster - 1;
			const currentRaster = params.raster;
			const nextRaster = params.raster + 1;
			let currentRasterValue = Math.floor(params.newLengthCurrentRaster / 50) * 50;
			let prevRasterValue = Math.floor(params.newLengthPreviousRaster / 50) * 50;
			let nextRasterValue = Math.floor(params.newLengthNextRaster / 50) * 50;

			if (typeof Configuration.CURRENT.etages.activeEtage().raster.spansY.get(nextRaster) !== 'undefined') {
				Configuration.CURRENT.etages.activeEtage().raster.spansY.get(nextRaster).saveChangedValue(nextRaster, 'y', nextRasterValue);
			}
			if (typeof Configuration.CURRENT.etages.activeEtage().raster.spansY.get(currentRaster) !== 'undefined') {
				Configuration.CURRENT.etages.activeEtage().raster.spansY.get(currentRaster).saveChangedValue(currentRaster, 'y', currentRasterValue);
			}
			if (typeof Configuration.CURRENT.etages.activeEtage().raster.spansY.get(prevRaster) !== 'undefined') {
				Configuration.CURRENT.etages.activeEtage().raster.spansY.get(prevRaster).saveChangedValue(prevRaster, 'y', prevRasterValue);
			}
		}
	}
	onSizeHandleChangeVertical(evt, object, canvas, params) {
		// tijdens drag van sizehandle
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			canvas.drawObjects.onChangeMainBeamLength({ x: -1, y: params.raster }, { x: 0, y: params.move }, evt, object, canvas, params, {
				raster: params.lengthRaster,
				rasterLinks: params.lengtRasterTop,
			});
		} else {
			canvas.drawObjects.onChangeChildBeamLength({ x: -1, y: params.raster }, { x: 0, y: params.move }, evt, object, canvas, params, {
				raster: params.lengthRaster,
				rasterLinks: params.lengtRasterTop,
			});
		}
	}
	isActive(raster, removeRasters) {}
	onClick(evt, object, canvas, params) {
		Configuration.CURRENT.select({ id: this.id }, canvas);
		if (this.rasterInterActive === true) {
			params.etages.activeEtage().toggleRaster(new RemoveRaster(object.objectParams.x, object.objectParams.y));
		}
	}
	onMouseMove(evt, object, canvas) {
		object.lineColor = 'green';
	}
	onMouseLeave(evt, object, canvas) {
		object.lineColor = 'transparent';
	}
	checkMovePossible(parameters) {
		return Configuration.CURRENT.profiles.moveProfilePossible(parameters);
	}
	addDrawObjects(canvas, param) {
		let events = {
			onChangeHorizontal: this.onSizeHandleChangeHorizontal.bind(this),
			onChangeVertical: this.onSizeHandleChangeVertical.bind(this),
			onChangedHorizontal: this.onSizeHandleChangedHorizontal.bind(this),
			onChangedVertical: this.onSizeHandleChangedVertical.bind(this),
			movePossible: this.checkMovePossible.bind(this),
		};

		// Wanneer sizehandle nog niet gemaakt dan aanmaken
		if (canvas.sizeHandles.get('raster') === null || typeof canvas.sizeHandles.get('raster') === 'undefined') {
			let sizeHandleRaster = new SizeHandleTotal('raster', 1, true);
			sizeHandleRaster.set(this.spansX.get(), this.spansY.get());
			sizeHandleRaster.registerEvents(events);
			canvas.sizeHandles.push(sizeHandleRaster);
		} else {
			// Anders updaten.
			canvas.sizeHandles.get('raster').registerEvents(events);
		}

		this.spansX.getSpans().forEach((spanX, indexX) => {
			this.spansY.getSpans().forEach((spanY, indexY) => {
				let raster = new Rectangle(
					new DrawValue(param.raster.getSizeX(indexX - 1)),
					new DrawValue(param.raster.getSizeY(indexY - 1)),
					new DrawValue(spanX.value),
					new DrawValue(spanY.value),
					'transparent',
					null,
					null,
					Configuration.CURRENT.accessoriesType === '',
					this,
					{ x: indexX, y: indexY, type: 'raster' },
				);
				raster.border = true;
				canvas.addDrawObject(raster);
			});
		});
	}
	create3DAssets() {
		Canvas3D.CURRENT.addAsset(new LocalAsset3D('roboto', null, null, 'font', 'json'));
	}

	addDrawObjects3d() {
		let positionMargin = 450;
		let drawFromCurrentRaster;
		let drawTop;
		let drawBottom;
		let drawRight;
		let drawLeft;

		this.spansX.getSpans().forEach((spanX, indexX) => {
			this.spansY.getSpans().forEach((spanY, indexY) => {
				if (Configuration.CURRENT.drawDimensioning3DEachRaster === true) {
					drawFromCurrentRaster = false;
					drawTop = true;
					drawBottom = true;
					drawLeft = true;
					drawRight = true;
					// Per etage en rasterIndexX en IndexY kijken of het raster boven het huidige actief is,
					// Als dat op 1 van de etagelevels is, dan hoeft het niet aan de bovenkant getekend te worden.
					// Zo ook voor de andere kanten.
					Configuration.CURRENT.etages.etages.forEach((etage, index) => {
						// Als raster op 1 van de etages actief is, dan mag er vanuit het raster getekend worden.
						// Dan afhankelijk van omliggende rasters.
						if (etage.isActiveRaster(new RemoveRaster(indexX, indexY))) {
							drawFromCurrentRaster = true;
						}
						if (etage.isActiveRaster(new RemoveRaster(indexX, indexY - 1))) {
							drawTop = false;
						}
						if (etage.isActiveRaster(new RemoveRaster(indexX, indexY + 1))) {
							drawBottom = false;
						}
						if (etage.isActiveRaster(new RemoveRaster(indexX - 1, indexY))) {
							drawLeft = false;
						}
						if (etage.isActiveRaster(new RemoveRaster(indexX + 1, indexY))) {
							drawRight = false;
						}
					});

					if (drawFromCurrentRaster === true) {
						if (drawTop === true) {
							Canvas3D.CURRENT.addDrawObject(
								new DimensionHorizontalTop3D(this.getSizeX(indexX - 1), 0, this.getSizeY(indexY - 1) - positionMargin, { text: spanX.value, width: spanX.value, indexX: indexX, indexY: indexY }),
								Canvas3D.TYPE_DIMENSIONING,
							);
						}
						if (drawBottom === true) {
							Canvas3D.CURRENT.addDrawObject(
								new DimensionHorizontalBottom3D(this.getSizeX(indexX - 1), 0, this.getSizeY(indexY) + positionMargin, {
									text: spanX.value,
									width: spanX.value,
									indexX: indexX,
									indexY: indexY,
								}),
								Canvas3D.TYPE_DIMENSIONING,
							);
						}

						if (drawLeft === true) {
							Canvas3D.CURRENT.addDrawObject(
								new DimensionVerticalLeft3D(this.getSizeX(indexX - 1) - positionMargin, 0, this.getSizeY(indexY - 1), {
									text: spanY.value,
									width: spanY.value,
									indexX: indexX,
									indexY: indexY,
								}),
								Canvas3D.TYPE_DIMENSIONING,
							);
						}
						if (drawRight === true) {
							Canvas3D.CURRENT.addDrawObject(
								new DimensionVerticalRight3D(this.getSizeX(indexX) + positionMargin, 0, this.getSizeY(indexY - 1), {
									text: spanY.value,
									width: spanY.value,
									indexX: indexX,
									indexY: indexY,
								}),
								Canvas3D.TYPE_DIMENSIONING,
							);
						}
					}
				} else {
					// Horizontale rasters aan de bovenkant van de vloer.
					if (indexY === 0) {
						Canvas3D.CURRENT.addDrawObject(
							new DimensionHorizontalTop3D(this.getSizeX(indexX - 1), 0, this.getSizeY(indexY - 1) - positionMargin, { text: spanX.value, width: spanX.value, indexX: indexX, indexY: indexY }),
							Canvas3D.TYPE_DIMENSIONING,
						);
					}
					// Verticale rasters aan de linkerkant van de vloer.
					if (indexX === 0) {
						Canvas3D.CURRENT.addDrawObject(
							new DimensionVerticalLeft3D(this.getSizeX(indexX - 1) - positionMargin, 0, this.getSizeY(indexY - 1), {
								text: spanY.value,
								width: spanY.value,
								indexX: indexX,
								indexY: indexY,
							}),
							Canvas3D.TYPE_DIMENSIONING,
						);
					}
					// Horizontale rasters aan de onderkant van de vloer.
					if (indexY === this.spansY.length - 1) {
						Canvas3D.CURRENT.addDrawObject(
							new DimensionHorizontalBottom3D(this.getSizeX(indexX - 1), 0, this.getSizeY(indexY) + positionMargin, {
								text: spanX.value,
								width: spanX.value,
								indexX: indexX,
								indexY: indexY,
							}),
							Canvas3D.TYPE_DIMENSIONING,
						);
					}
					if (indexX === this.spansX.length - 1) {
						Canvas3D.CURRENT.addDrawObject(
							new DimensionVerticalRight3D(this.getSizeX(indexX) + positionMargin, 0, this.getSizeY(indexY - 1), {
								text: spanY.value,
								width: spanY.value,
								indexX: indexX,
								indexY: indexY,
							}),
							Canvas3D.TYPE_DIMENSIONING,
						);
					}
				}
			});
		});

		// Sizehandles totaal buitenzijde toevoegen.
		if (Configuration.CURRENT.drawDimensioningAllRasters === true) {
			const totalLengthX = this.spansX.getSize() + Configuration.CURRENT.overhang.size * 2;
			const totalLengthY = this.spansY.getSize() + Configuration.CURRENT.overhang.size * 2;

			// Startpunt van deze sizeHandles is gewoon RasterXY 0 en daar dan de Marge af en één keer de overhang.
			Canvas3D.CURRENT.addDrawObject(
				new DimensionHorizontalTop3D(this.getSizeX(-1) - Configuration.CURRENT.overhang.size, 0, this.getSizeY(-1) - positionMargin * 3, {
					text: totalLengthX,
					width: totalLengthX,
					indexX: 0,
					indexY: 0,
				}),
				Canvas3D.TYPE_DIMENSIONING,
			);

			Canvas3D.CURRENT.addDrawObject(
				new DimensionHorizontalBottom3D(this.getSizeX(-1) - Configuration.CURRENT.overhang.size, 0, this.getSizeY() + positionMargin * 3, {
					text: totalLengthX,
					width: totalLengthX,
					indexX: 0,
					indexY: 0,
				}),
				Canvas3D.TYPE_DIMENSIONING,
			);

			Canvas3D.CURRENT.addDrawObject(
				new DimensionVerticalLeft3D(this.getSizeX(-1) - positionMargin * 3, 0, this.getSizeY(-1) - Configuration.CURRENT.overhang.size, {
					text: totalLengthY,
					width: totalLengthY,
					indexX: 0,
					indexY: 0,
				}),
				Canvas3D.TYPE_DIMENSIONING,
			);

			Canvas3D.CURRENT.addDrawObject(
				new DimensionVerticalRight3D(this.getSizeX() + positionMargin * 3, 0, this.getSizeY(-1) - Configuration.CURRENT.overhang.size, {
					text: totalLengthY,
					width: totalLengthY,
					indexX: 0,
					indexY: 0,
				}),
				Canvas3D.TYPE_DIMENSIONING,
			);
		}
	}

	getMaxSpanvalue(spans) {
		return Math.max.apply(
			Math,
			spans.map(function (span) {
				return span.value;
			}),
		);
	}

	getRasterSizesByCoordinates(X, Y) {
		let x = 0;
		let y = 0;

		for (var indexX = 0; indexX < X; indexX++) {
			x += this.spansX.getSpans()[indexX].value;
		}

		for (var indexY = 0; indexY < Y; indexY++) {
			y += this.spansY.getSpans()[indexY].value;
		}

		return { x: x, y: y };
	}
}
