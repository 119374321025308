import { Canvas3D } from '../../draw3d/Canvas3D';
import { Columns } from '../columns';
import { Configuration } from '../configuration';
import { HicadDrawObject } from './HicadDrawObject';

export class jsonColumn extends HicadDrawObject {
	h_col = 0;
	thickness_of_tube = 0;
	steelquality = 0;
	profile_height = 0;
	multipleFloors = false;
	footplate = {};
	placement = {};
	levels = [];
	deckingFinish = 0;

	constructor(column) {
		super(column);
		this.h_col = column.info._h_col;
		this.thickness_of_tube = column.info.thickness;
		this.steelquality = column.info.steelquality;

		this.getFootplateDetails(column);
		this.getProfileData(column);
		this.getPlacement(column);
		this.getLevels();
		this.getDeckingFinish();
	}

	getFootplateDetails(column) {
		const regex = /(?<width>[0-9]+)x/gis;
		const str = column.basePlate.assetName;

		let match = regex.exec(str);
		let footplate = {};

		do {
			footplate = {
				width: match.groups.width,
			};
		} while ((match = regex.exec(str)) !== null);

		footplate.height = column.basePlate.height;
		footplate.oid = column.basePlate.oid;
		footplate.steelquality = this.steelquality;

		this.footplate = footplate;
	}

	getProfileData(column) {
		this.profile_height = Canvas3D.CURRENT.getBeamData(column.mainBeamId, 'height');
		this.profile_width = Canvas3D.CURRENT.getBeamData(column.mainBeamId, 'width');
		this.profile_depth = Canvas3D.CURRENT.getBeamData(column.mainBeamId, 'depth');
	}

	getPlacement(column) {
		let placement = {};
		if (Configuration.CURRENT.profiles.mainBeamDirection === 'x') {
			if (
				column.placement === Columns.TYPE_MIDDLECOLUMN ||
				(column.rowPositions.length === 1 && column.rowPositions[0] === Columns.POSITION_LEFT) ||
				(column.rowPositions.length === 1 && column.rowPositions[0] === Columns.POSITION_RIGHT)
			) {
				placement = {
					position: 2,
					name: 'Centre',
				};
			} else if (column.rowPositions.includes(Columns.POSITION_TOP)) {
				placement = {
					position: 1,
					name: 'Edge',
				};
			} else {
				placement = {
					position: 3,
					name: 'Edge',
				};
			}
		} else {
			if (
				column.rowPositions.length === 0 ||
				(column.rowPositions.length === 1 && column.rowPositions[0] === Columns.POSITION_BOTTOM) ||
				(column.rowPositions.length === 1 && column.rowPositions[0] === Columns.POSITION_TOP)
			) {
				placement = {
					position: 2,
					name: 'Centre',
				};
			} else if (column.rowPositions.includes(Columns.POSITION_RIGHT)) {
				placement = {
					position: 3,
					name: 'Edge',
				};
			} else {
				placement = {
					position: 1,
					name: 'Edge',
				};
			}
		}

		this.placement = placement;
	}

	getLevels() {
		this.levels = [];
		Configuration.CURRENT.etages.etages.forEach((etage) => {
			this.levels.push(etage.height);
		});
	}

	getDeckingFinish() {
		const finish = Configuration.CURRENT.finish;

		this.deckingFinish = finish.selected.height;
	}
}
