import { Rectangle } from '../draw/rectangle';
import { Holes } from './holes';
import { HoleTrimmings } from './holeTrimmings';
import { DrawValue } from '../draw/drawValue';
import { ObjectGroup } from '../draw/objectGroup';
import { Configuration } from './configuration';
import { Functions } from '../helpers/functions';
import { Profiles } from './profiles';
import { Errors } from './errors';
import { Mathematic } from '../helpers/mathematic';
import { CustomError } from './CustomError';
export class Hole {
	objectName = 'Hole';
	width = 1000;
	depth = 1000;
	isNewHole = true;
	selected = true;
	rasters = [];
	x = 0;
	y = 0;
	position = { x: -1, y: -1 };
	mousePriority = 20;
	_onChange = null;
	id = '';
	active = true;
	boundaries = [];
	holeTrimmings = new HoleTrimmings(this.onChange.bind(this));
	isInFloor = true; // collision voor invloer tekenen van deckingfinish
	objectsYMinMaxHorizontal = ['hole']; // objecten die verplaatst worden als over de y-as wordt verplaatst
	objectsYMinMaxVertical = ['hole']; // objecten die verplaatst worden als over de y-as wordt verplaatst
	errors = new Errors();

	get hasErrors() {
		return this.errors.length > 0;
	}
	set hasErrors(v) {
		console.log(v);
	}
	getErrors() {
		return this.errors;
	}

	collisionCheck() {
		let collisionCheck = this.checkCollisions(this.boundaries, this);
		let hasNowErrors = this.hasErrors;
		if (collisionCheck.result) {
			this.errors.clear(Errors.ERRORTYPE.collision);
			collisionCheck.errors.forEach((error) => {
				this.errors.add(error);
			});
		} else {
			this.errors.clear(Errors.ERRORTYPE.collision);
		}
		if (hasNowErrors !== this.hasErrors) {
			let drawObject = Configuration.CURRENT.canvas.drawObjects.get(this.drawObject);
			if (drawObject !== null) {
				this.setCollisionDrawObject(drawObject.drawObjects, this.hasErrors);
			}
		}

		return collisionCheck;
	}
	collisions(boundaries, self) {
		if (this.id === self.id) {
			// zichzelf dan geen  fout
			return { result: false, errors: [], objectName: this.objectName };
		}
		if (this.id === self.name) {
			// Als het een kolom is die hoort bij de gebouwkolom dan lijkt er een overlapping maar is het niet zo omdat ze dicht bij elkaar staan
			return { result: false, errors: [], objectName: this.objectName };
		}
		if (this.overlap(boundaries) === true) {
			return { result: true, errors: [new CustomError(window.Vue.$translate('collision.hole'), Errors.ERRORTYPE.collision, this.objectName)] };
		}
		return { result: false, errors: [], objectName: this.objectName };
	}
	overlap(boundaries) {
		let overlap = false;
		boundaries.forEach((boundary) => {
			this.boundaries.forEach((boundaryHole) => {
				if (Mathematic.overlapRectangles(boundary.topLeft, boundary.bottomRight, boundaryHole.topLeft, boundaryHole.bottomRight) === true) {
					overlap = true;
				}
			});
		});
		return overlap;
	}

	checkCollisions(boundaries, self) {
		if (typeof this._checkCollisions === 'function') {
			return this._checkCollisions(boundaries, self);
		}
	}

	constructor(hole, canvas, params) {
		this.id = Functions.uuidv4();
		if (typeof hole !== 'undefined' && hole !== null) {
			this.isNewHole = false;
			this.update(hole, canvas, params);
		} else {
			this.isNewHole = true;
		}
	}

	findRasters() {

		let currentRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.x, this.y);
		let nextRaster = Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.width, this.y + this.depth);

		let useRasterX = currentRaster.x !== -1 ? currentRaster.x : nextRaster.x !== -1 ? nextRaster.x : 0;
		let useRasterY = currentRaster.y !== -1 ? currentRaster.y : nextRaster.y !== -1 ? nextRaster.y : 0;


		this.rasters = [];
		this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(this.x, this.y)); // raster kolom links-boven
		this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(this.x, this.y + this.width)); // raster kolom rechts-boven
		this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.width, this.y)); // raster kolom links-onder
		this.insertUniqueRaster(Configuration.CURRENT.raster.getRasterByCoordinate(this.x + this.width, this.y + this.depth)); // raster kolom rechts-onder
	}

	update(object, canvas, params) {
		this.x = object.x;
		this.y = object.y;
		this.width = parseInt(object.width);
		this.depth = parseInt(object.depth);

		if (typeof object.active === 'undefined' || object.active === null) {
			this.active = true;
		} else {
			this.active = object.active;
		}

		this.holeTrimmings.setTrimming(this.x, this.y, this.width, this.depth, this.id);
		this.onChange();
	}

	getPositionProfiles() {
		return { startX: this.x, startY: this.y, endX: this.x + this.width, endY: this.y + this.depth };
	}

	onMouseMove(evt, object, canvas) {
		canvas.canvas.style.cursor = 'move';
		return { stopPropagation: true };
	}
	onMouseLeave(evt, object, canvas) {
		canvas.canvas.style.cursor = 'default';
		return { stopPropagation: true };
	}

	calculateAmount(params) {
		this.holeTrimmings.calculateAmount(params);
	}

	onMouseUp(evt, object, canvas) {
		this.calculate();
		this.onChange();
		return { stopPropagation: true };
	}
	onMouseDown(evt, object, canvas) {
		return { stopPropagation: true };
	}
	onClick(evt, object, canvas, params) {
		Configuration.CURRENT.select({ id: this.id }, canvas);
		return { stopPropagation: true, object: this };
	}
	select(parameters, canvas) {
		if (this.id === parameters.id) {
			this.selected = !this.selected;
		} else {
			// Wanneer nieuwe object niet dit object is dan is het een deselect.
			this.selected = false;
		}
	}

	setSizeHandleObject(canvas, dimensionsHorizontal, dimensionsVertical) {
		const sizeHandle = canvas.sizeHandles.get('object');
		sizeHandle.setByArray(dimensionsHorizontal, dimensionsVertical);
		let events = { onChangedHorizontal: this.onSizeHandleChangedHorizontal.bind(this), onChangedVertical: this.onSizeHandleChangedVertical.bind(this) };
		sizeHandle.registerEvents(events);
		sizeHandle.parent = this;
	}
	onSizeHandleChangedHorizontal(evt, object, canvas, params) {
		if (params.raster === 0) {
			this.x = params.newLengthCurrentRaster;
		} else {
			this.x += object.width.value - params.newLengthCurrentRaster;
		}
		this.calculate();
		this.onChange();
	}
	onSizeHandleChangedVertical(evt, object, canvas, params) {
		if (params.raster === 0) {
			this.y = params.newLengthCurrentRaster;
		} else {
			this.y += object.width.value - params.newLengthCurrentRaster;
		}
		this.calculate();
		this.onChange();
	}

	getEdgePosition() {
		let startX = this.boundaries[0].topLeft.x;
		let startY = this.boundaries[0].topLeft.y;
		let endX = this.boundaries[0].topRight.x;
		let endY = this.boundaries[0].bottomLeft.y;

		return { startX, startY, endX, endY, height: this.height };
	}

	addDrawObjects(canvas, drawGroup) {
		// addDrawobjects komt vanuit drag en vanuit globale teken functie.
		// Drag meegeven in drag als die true is dan updaten dus of selected of drag.
		if (this.selected === true) {
			this.setSizeHandleObject(
				canvas,
				[Math.round(this.x), this.width, Math.round(Configuration.CURRENT.raster.getSizeX() - this.x - this.width)],
				[Math.round(this.y), this.depth, Math.round(Configuration.CURRENT.raster.getSizeY() - this.y - this.depth)],
			);
		}

		// gebruik de standaardkleur
		let holeColor = Holes.COLORS.notSelected;
		if (this.selected === true) {
			// als hole geselecteerd afwijkende kleur
			holeColor = Holes.COLORS.selected;
		}
		if (this.hasErrors === true) {
			// Bij errors dus collision
			holeColor = Holes.COLORS.error;
		}

		if (typeof drawGroup === 'undefined' || drawGroup === null) {
			// Drawgroep voor alleen rectangle, die van trimming zit in trimmings
			drawGroup = new ObjectGroup(holeColor, null, null, false, this, {});
			this.drawGroupId = drawGroup.id;
		}

		// Hole toevoegen
		drawGroup.push(new Rectangle(new DrawValue(this.x), new DrawValue(this.y), new DrawValue(this.width), new DrawValue(this.depth), holeColor, null, null, true, this, { type: 'hole' }));

		// Trimming toevoegen
		if (typeof this.holeTrimmings.addDrawObjects === 'function') {
			drawGroup.push(this.holeTrimmings.addDrawObjects(this)); // trimmings toevoegen
		}

		canvas.addDrawObject(drawGroup);
	}

	addDrawObjects3d(canvas3d, etage, raster, posY) {
		this.holeTrimmings.addDrawObjects3d(canvas3d, etage, raster, posY);
	}

	getContextMenu() {
		// bij bestaande objecten gaat getContextMenu fout. Daarom niet via variabele maar hier direct in return
		return [
			{ icon: 'edit', action: this.edit.bind(this), active: true },
			{ icon: 'delete', action: this.remove.bind(this), active: true },
		];
	}
	redrawTrimming() {
		this.holeTrimmings.setTrimming(this.x, this.y, this.width, this.depth, this.id, false);
	}
	onMouseDrag(evt, object, canvas) {
		// Wanneer geen rasters dan kan er ook geen max en min berekent worden voor het slepen.
		// Dan niets doen.
		if (this.rasters.length > 0) {
			// Group ID opgeslagen, alleen selecteren op rectangle maar wel hele groep verslepen.
			let groupObject = canvas.drawObjects.findByDrawId(this.drawGroupId);

			// verschuiving schalen naar juiste maten evt.delta is geschaalde maat
			let moveX = evt.delta.x / canvas.scaleFactor; // verplaatsing links-rechts (links - rechts +)
			let moveY = evt.delta.y / canvas.scaleFactor; // verplaatsing onder-boven (boven - onder +)

			let maxX = Configuration.CURRENT.raster.spansX.getSize();
			let minY = 0;
			let minX = 0;
			let maxY = Configuration.CURRENT.raster.spansY.getSize();

			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				// Wel selecteren op object wat de rectangle is.
				const minMaxCoordinatesXmove = groupObject.minMaxCoordinates(this.objectsYMinMaxVertical);
				const minMaxCoordinatesYmove = groupObject.minMaxCoordinates(this.objectsYMinMaxHorizontal);



				if (minMaxCoordinatesXmove.min.x + moveX < 0) {
					// als x kleiner dan 0 dan tot aan 0
					moveX = 0 - minMaxCoordinatesXmove.min.x;
					evt.delta.x = moveX * canvas.scaleFactor;
				}

				if (minMaxCoordinatesXmove.max.x + moveX > maxX) {
					// als x groter dan totale lengte dan tot aan totale lengte
					moveX = maxX - minMaxCoordinatesXmove.max.x;
					evt.delta.x = moveX * canvas.scaleFactor;
				}

				// y tussen boven en onderprofiel
				if (minMaxCoordinatesYmove.min.y + moveY < minY) {
					// inclusief verplaatsing voorbij bovenliggende hoofdbalk dan tot aan bovenliggende hoofdbalk
					moveY = minY - minMaxCoordinatesYmove.min.y;
					evt.delta.y = moveY * canvas.scaleFactor;
				}

				// y tussen boven en onderprofiel
				if (minMaxCoordinatesYmove.max.y + moveY > maxY) {
					// inclusief verplaatsing voorbij onderliggende hoofdbalk dan tot aan onderliggende hoofdbalk
					moveY = maxY - minMaxCoordinatesYmove.max.y;
					evt.delta.y = moveY * canvas.scaleFactor;
				}
			} else {
				const minMaxCoordinatesXmove = groupObject.minMaxCoordinates(this.objectsYMinMaxHorizontal);
				const minMaxCoordinatesYmove = groupObject.minMaxCoordinates(this.objectsYMinMaxVertical);


				if (minMaxCoordinatesXmove.min.y + moveY < 0) {
					// als y kleiner dan 0 dan tot aan 0
					moveY = 0 - minMaxCoordinatesXmove.min.y;
					evt.delta.y = moveY * canvas.scaleFactor;
				}

				if (minMaxCoordinatesXmove.max.y + moveY > maxY) {
					// als y groter dan totale lengte dan tot aan totale lengte
					moveY = maxY - minMaxCoordinatesXmove.max.y;
					evt.delta.y = moveY * canvas.scaleFactor;
				}

				// y tussen boven en onderprofiel
				if (minMaxCoordinatesYmove.min.x + moveX < minX) {
					// inclusief verplaatsing voorbij linkse hoofdbalk dan tot aan linkse hoofdbalk
					moveX = minX - minMaxCoordinatesYmove.min.x;
					evt.delta.x = moveX * canvas.scaleFactor;
				}

				// y tussen boven en onderprofiel
				if (minMaxCoordinatesYmove.max.x + moveX > maxX) {
					// inclusief verplaatsing voorbij rechtse hoofdbalk dan tot aan rechtse hoofdbalk
					moveX = maxX - minMaxCoordinatesYmove.max.x;
					evt.delta.x = moveX * canvas.scaleFactor;
				}
			}

			this.x += moveX; // neem x van trapgat over naar de trap
			this.y += moveY; // neem y van trapgat over naar de trap
			// opnieuw berekenen van trimming en rasters
			this.redrawTrimming();
			// this.calculate();

			// om redraw te voorkomen ook toepassen op het drawobject
			// omdat er veel variatie is met de trimming hier een nieuw drawobject toevoegen
			// eerst alles weggooien
			groupObject.clear();

			// dan objecten opnieuw maken
			this.addDrawObjects(canvas, groupObject);
			this.updateSizeHandles(canvas);
		}

		return { stopPropagation: true };
	}
	updateSizeHandles(canvas) {
		let objectSizeHandle = canvas.sizeHandles.get('object');
		if (this.selected && objectSizeHandle !== null && typeof objectSizeHandle !== 'undefined') {
			objectSizeHandle.updateDrawObjects(
				[Math.round(this.x), this.width, Math.round(Configuration.CURRENT.raster.getSizeX() - this.x - this.width)],
				[Math.round(this.y), this.depth, Math.round(Configuration.CURRENT.raster.getSizeY() - this.y - this.depth)],
			);
		}
	}

	edit() {
		Configuration.CURRENT.contextMenu.hide();

		// omdat contextmenu op de kolom zelf staat is hier edit nodig die komt echter vanuit buildingcolumns vandaar opgelost met meegeven
		if (typeof this._edit === 'function') {
			this._edit(this);
		}
	}

	remove() {
		Configuration.CURRENT.contextMenu.hide();
		// omdat contextMenu op de kolom zelf staat is hier remove  nodig die komt echter vanuit buildingcolumns vandaar opgelost met meegeven
		if (typeof this._remove === 'function') {
			this._remove(this);
		}

		this.onChange();
	}

	setReferences(params) {
		this._onChange = params.onChange;
		this._redraw = params.redraw;
		this._checkCollisions = params.checkCollisions;
		this._edit = params.edit;
		this._remove = params.remove;
	}

	removeReferences() {
		this._onChange = null;
		this._redraw = null;
		this._edit = null;
		this._remove = null;
	}
	onChange(status) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}

	insertUniqueRaster(raster) {
		if (raster.x > -1 && raster.y > -1) {
			// geldig raster
			const rasterFound = this.rasters.find((rst) => rst.x === raster.x && rst.y === raster.y);
			if (typeof rasterFound === 'undefined') {
				this.rasters.push(raster);
			}
		}
	}

	calculate(activeEtageIndex) {
		if (activeEtageIndex === null || typeof activeEtageIndex === 'undefined') {
			activeEtageIndex = Configuration.CURRENT.etages.activeEtageIndex;
		}

		this.findRasters();

		this.boundaries = [
			{
				topLeft: { x: this.x, y: this.y },
				topRight: { x: this.x + this.width, y: this.y },
				bottomLeft: { x: this.x, y: this.y + this.depth },
				bottomRight: { x: this.x + this.width, y: this.y + this.depth },
			},
		];

		if (typeof this.holeTrimmings.setTrimming === 'function') {
			this.holeTrimmings.setTrimming(this.x, this.y, this.width, this.depth, this.id, true);
		}
	}

	onProfilePosition(profilePosition) {
		if (this.boundaries.length === 0) {
			return;
		}

		return (
			(this.x >= profilePosition.startX &&
				this.x <= profilePosition.endX &&
				this.x + this.width >= profilePosition.startX &&
				this.x + this.width <= profilePosition.endX &&
				profilePosition.startY >= this.y &&
				profilePosition.endY <= this.y + this.depth) ||
			(this.y >= profilePosition.startY &&
				this.y <= profilePosition.endY &&
				this.y + this.depth >= profilePosition.startY &&
				this.y + this.depth <= profilePosition.endY &&
				profilePosition.startX >= this.x &&
				profilePosition.endX <= this.x + this.width) ||
			(this.x <= profilePosition.startX && this.x + this.width >= profilePosition.startX && this.y <= profilePosition.startY && this.y + this.depth >= profilePosition.startY) ||
			(this.x <= profilePosition.endX && this.x + this.width >= profilePosition.endX && this.y <= profilePosition.endY && this.y + this.width >= profilePosition.endY)
		);
	}

	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		// regels voor kolomgebouwen zijn nogal uitgebreidt daarom bij verslepen niet de trimming laten zien. Dat komt bij het loslaten wel
		let found = false;

		// kijk eerst of een deel van de gebouwkolom in het raster valt
		this.rasters.forEach((currentRaster) => {
			if ((raster.x > -1 && (raster.x === currentRaster.x || raster.x - 1 === currentRaster.x)) || (raster.y > -1 && (raster.y === currentRaster.y || raster.y - 1 === currentRaster.y))) {
				found = true;
			}
		});
		if (found === true) {
			// als een deel van de kolom in het raster valt
			drawObject.drawObjects.forEach((drawObject) => {
				if (typeof drawObject.object !== 'undefined' && drawObject.object !== null && drawObject.object.objectName === 'HoleTrimmings') {
					drawObject.hide();
				}
			});
		}
	}
}
