import { Configuration } from '../../../configurator/configuration';
import { jsonProfile } from '../../../configurator/hicad/jsonProfile';
import { Raster } from '../../../configurator/raster';
import { Canvas3D } from '../../Canvas3D';
import { ProfilesVertical3D } from '../ProfilesVertical3D';

export class VerticalMainBeamLeft3D extends ProfilesVertical3D {
	objectName = 'VerticalMainBeamLeft3D';
	constructor(x, y, length, params) {
		params.rotationX = 180;
		params.rotationY = -90;
		params.profilePosition = 'left';
		super(x, y, length, params);
		this.RecalculateProfilePositionByMultipleFloor();
		this.rotationX = 180;
		this.rotationY = -90;
		this.rotationZ = 0;

		this.parentEntityName = params.profileType + '_' + this.profilePosition + '_' + params.indexX + '_' + params.indexY;
	}
	draw(app) {
		super.draw(app, 'mainbeam');
	}
	RecalculateProfilePositionByMultipleFloor() {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		// Doorbouw kolom
		if (this.multipleFloors) {
			if (this.rowPositions.includes(Raster.POSITION_LEFT)) {
				this.x += columnWidth;
			}
			if (this.rowPositions.includes(Raster.POSITION_RIGHT)) {
				this.x += columnWidth / 2;
			}
			if (!this.rowPositions.includes(Raster.POSITION_LEFT) && !this.rowPositions.includes(Raster.POSITION_RIGHT)) {
				this.x += columnWidth / 2;
			}
		}
	}

	// Convert naar HiCADProfile van VerticalMainBeamLeft3D, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_MAINBEAM, direction: jsonProfile.DIRECTION_VERTICAL, position: jsonProfile.POSITION_LEFT });
	}
}
