import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { Mathematic } from '../helpers/mathematic';
import { BracingHorizontalTop3D } from '../draw3d/bracing/BracingHorizontalTop3D';
import { BracingHorizontalBottom3D } from '../draw3d/bracing/BracingHorizontalBottom3D';
import { BracingVerticalBottom3D } from '../draw3d/bracing/BracingVerticalBottom3D';
import { BracingVerticalTop3D } from '../draw3d/bracing/BracingVerticalTop3D';
import { Configuration } from './configuration';
import { Bracings } from './bracings';
import { Columns } from './columns';
import { CustomError } from './CustomError';
import { Errors } from './errors';
import { Profiles } from './profiles';
import { Canvas3D } from '../draw3d/Canvas3D';
export class Bracing {
	objectName = 'Bracing';
	startColumn = { x: -1, y: -1 };
	endColumn = { x: -1, y: -1 };
	_bracingActive = true;
	_onChange = null;
	set bracingActive(value) {
		this._bracingActive = value;
	}

	get bracingActive() {
		return this._bracingActive;
	}

	onColumnChange(x, y, evt, canvas, params, actualSizeBefore, actualSizeAfter, drawObject) {
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (this.startColumn.x === x && this.startColumn.y === y) {
				drawObject.x.value = drawObject.x.value - (actualSizeAfter - (drawObject.eindX.value - drawObject.x.value));
				drawObject.eindX.value = drawObject.x.value + actualSizeAfter;
			}
			if (this.endColumn.x === x && this.endColumn.y === y) {
				drawObject.eindX.value = drawObject.x.value + actualSizeBefore;
			}
		} else {
			if (this.startColumn.x === x && this.startColumn.y === y) {
				drawObject.y.value = drawObject.y.value - (actualSizeAfter - (drawObject.eindY.value - drawObject.y.value));
				drawObject.eindY.value = drawObject.y.value + actualSizeAfter;
			}
			if (this.endColumn.x === x && this.endColumn.y === y) {
				drawObject.eindY.value = drawObject.y.value + actualSizeBefore;
			}
		}
	}
	collisions(boundaries, self) {
		// voorlopig ervan uitgaand dat het een vierkant betreft
		if (!this.bracingActive) {
			return { result: false, errors: [], objectName: this.objectName };
		}

		let overlap = false;
		let positionStart = { x: Configuration.CURRENT.raster.getSizeX(this.startColumn.x - 1), y: Configuration.CURRENT.raster.getSizeY(this.startColumn.y - 1) };
		let positionEnd = { x: Configuration.CURRENT.raster.getSizeX(this.endColumn.x - 1), y: Configuration.CURRENT.raster.getSizeY(this.endColumn.y - 1) };
		boundaries.forEach((boundary) => {
			if (Mathematic.overlapRectangles(boundary.topLeft, boundary.bottomRight, positionStart, positionEnd) === true) {
				if (self.objectName !== 'PalletGate' && self.objectName !== 'StairOutSide') {
					// bij palletgate is geen overlap met windverband (staat er boven) // zou met hoogte te doen zijn maar wordt nu niet naar gekeken
					overlap = true;
				}
			}
		});
		if (overlap === true) {
			return { result: true, errors: [new CustomError(window.Vue.$translate('collision.bracing', { x: this.x, y: this.y }), Errors.ERRORTYPE.collision, this.objectName)] };
		}
		return { result: false, errors: [], objectName: this.objectName };
	}
	constructor(startColumn, endColumn) {
		if (typeof startColumn !== 'undefined' && startColumn !== null) {
			this.startColumn.x = startColumn.x;
			this.startColumn.y = startColumn.y;
		}
		if (typeof endColumn !== 'undefined' && endColumn !== null) {
			this.endColumn.x = endColumn.x;
			this.endColumn.y = endColumn.y;
		}
	}
	contains(column) {
		if (column === null) {
			return false;
		}

		return (this.startColumn.x === column.x && this.startColumn.y === column.y) || (this.endColumn.x === column.x && this.endColumn.y === column.y);
	}
	equals(startColumn, endColumn) {
		return this.startColumn.x === startColumn.x && this.startColumn.y === startColumn.y && this.endColumn.x === endColumn.x && this.endColumn.y === endColumn.y;
	}
	onChangeMainBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		this.onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params); // mainBeamLength is hetzelfde als childBeamLength voor de kolommen
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params) {
		if (raster.x > -1 && drawObject.objectParams !== null) {
			if (raster.x === drawObject.objectParams.start.x && raster.x === drawObject.objectParams.end.x) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			} else if (raster.x === drawObject.objectParams.start.x) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
			} else if (raster.x === drawObject.objectParams.end.x) {
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			}
		} else if (raster.y > -1 && drawObject.objectParams !== null) {
			if (raster.y === drawObject.objectParams.start.y && raster.y === drawObject.objectParams.end.y) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			} else if (raster.y === drawObject.objectParams.start.y) {
				drawObject.x.value += delta.x;
				drawObject.y.value += delta.y;
			} else if (raster.y === drawObject.objectParams.end.y) {
				drawObject.eindX.value += delta.x;
				drawObject.eindY.value += delta.y;
			}
		}
	}
	addDrawObjects(canvas, params) {
		if (!this.bracingActive) {
			return;
		}

		const startColumn = Configuration.CURRENT.columns.find(this.startColumn.x, this.startColumn.y);
		const endColumn = Configuration.CURRENT.columns.find(this.endColumn.x, this.endColumn.y);
		if (typeof startColumn === 'undefined' || startColumn === null || typeof endColumn === 'undefined' || endColumn === null) {
			// een van beide niet gevonden
			return;
		}
		const startColumnPosition = startColumn.getPosition();
		const endColumnPosition = endColumn.getPosition();
		if (this.startColumn.y === this.endColumn.y) {
			// horizontaal

			startColumnPosition.endX.offsetScaled += Bracings.drawOffset.x;
			startColumnPosition.endY.offsetScaled -= Columns.COLUMN_SIZE / 2;

			endColumnPosition.startX.offsetScaled -= Bracings.drawOffset.x;
			endColumnPosition.startY.offsetScaled += Columns.COLUMN_SIZE / 2;
		} else {
			// verticaal
			startColumnPosition.endX.offsetScaled -= Columns.COLUMN_SIZE / 2;
			startColumnPosition.endY.offsetScaled += Bracings.drawOffset.y;

			endColumnPosition.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
			endColumnPosition.startY.offsetScaled -= Bracings.drawOffset.y;
		}

		canvas.addDrawObject(this.drawObject(startColumnPosition.endX, startColumnPosition.endY, endColumnPosition.startX, endColumnPosition.startY, startColumn, endColumn));
	}
	drawObject(endX, endY, startX, startY, startColumn, endColumn) {
		return new Line(endX, endY, startX, startY, new DrawValue(6, 0, true), null, Bracings.COLORS.bracing, null, null, true, this, {
			start: startColumn,
			end: endColumn,
		});
	}
	addDrawObjects3d(canvas3d, etage, raster, posY, etageIndex, oid) {
		if (!this.bracingActive) {
			return;
		}
		let etageHeight = etage.getHeight(true);
		let multipleFloors = Configuration.CURRENT.etages.length > 1;
		// let plaatjehoogte = 25;
		const startColumn = Configuration.CURRENT.columns.find(this.startColumn.x, this.startColumn.y);
		const endColumn = Configuration.CURRENT.columns.find(this.endColumn.x, this.endColumn.y);

		if (typeof startColumn === 'undefined' || startColumn === null || typeof endColumn === 'undefined' || endColumn === null) {
			// een van beide niet gevonden
			return;
		}
		const startColumnPosition3D = startColumn.getPosition3D();
		const endColumnPosition3D = endColumn.getPosition3D();

		// 2D posities meesturen voor Hicad.
		const startColumnPosition2D = startColumn.getPosition();
		const endColumnPosition2D = endColumn.getPosition();

		let mainBeamHeight;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			let rastersX = raster.spansX.getSpans();
			// ALs het rechts buitenste kolom is dan gaat hij zoeken naar raster ernaast terwijl dat niet bestaat.
			if (startColumn.x >= rastersX.length) {
				mainBeamHeight = rastersX[startColumn.x - 1].beam.oid;
			} else {
				mainBeamHeight = rastersX[startColumn.x].beam.oid;
			}
		} else {
			let rastersY = raster.spansY.getSpans();
			// ALs het onder buitenste kolom is dan gaat hij zoeken naar raster eronder terwijl dat niet bestaat.
			if (startColumn.y >= rastersY.length) {
				mainBeamHeight = rastersY[startColumn.y - 1].beam.oid;
			} else {
				mainBeamHeight = rastersY[startColumn.y].beam.oid;
			}
		}

		// Horizontale bracing.
		if (this.startColumn.y === this.endColumn.y) {
			canvas3d.addDrawObject(
				new BracingHorizontalTop3D(startColumnPosition3D.x, endColumnPosition3D.z, {
					yPosition: posY + etageHeight,
					rowPositions: startColumn.rowPositions,
					placement: startColumn.placement,
					column: startColumn,
					etageIndex: etageIndex,
					multipleFloors: multipleFloors,
					mainBeamOid: mainBeamHeight,
					oid: oid,
					calculationData: {
						startColumnPosition: startColumnPosition2D.startX.value,
						endColumnPosition: endColumnPosition2D.startX.value,
						etageHeight: etageHeight,
					},
				}),
				Canvas3D.TYPE_BRACING,
			);

			canvas3d.addDrawObject(
				new BracingHorizontalBottom3D(startColumnPosition3D.x, endColumnPosition3D.z, {
					yPosition: posY,
					rowPositions: startColumn.rowPositions,
					placement: startColumn.placement,
					column: startColumn,
					etageIndex: etageIndex,
					multipleFloors: multipleFloors,
					mainBeamOid: mainBeamHeight,
					oid: oid,
					calculationData: {
						startColumnPosition: startColumnPosition2D.startX.value,
						endColumnPosition: endColumnPosition2D.startX.value,
						etageHeight: etageHeight,
					},
				}),
				Canvas3D.TYPE_BRACING,
			);
		} else {
			canvas3d.addDrawObject(
				new BracingVerticalTop3D(startColumnPosition3D.x, endColumnPosition3D.z, {
					yPosition: posY + etageHeight,
					rowPositions: startColumn.rowPositions,
					placement: startColumn.placement,
					column: startColumn,
					multipleFloors: multipleFloors,
					mainBeamOid: mainBeamHeight,
					oid: oid,
					calculationData: {
						startColumnPosition: startColumnPosition2D.startY.value,
						endColumnPosition: endColumnPosition2D.startY.value,
						etageHeight: etageHeight,
					},
				}),
				Canvas3D.TYPE_BRACING,
			);

			canvas3d.addDrawObject(
				new BracingVerticalBottom3D(startColumnPosition3D.x, endColumnPosition3D.z, {
					yPosition: posY,
					rowPositions: startColumn.rowPositions,
					placement: startColumn.placement,
					column: startColumn,
					multipleFloors: multipleFloors,
					mainBeamOid: mainBeamHeight,
					oid: oid,
					calculationData: {
						startColumnPosition: startColumnPosition2D.startY.value,
						endColumnPosition: endColumnPosition2D.startY.value,
						etageHeight: etageHeight,
					},
				}),
				Canvas3D.TYPE_BRACING,
			);
		}
	}
	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
	}
	onChange() {
		if (typeof this._onChange !== 'undefined') {
			this._onChange();
		}
	}
	calculate() {
		// Kolom ophalen, en kijken of beide actief zijn op het huidige etage niveau.
		let activeEtageIndex = Configuration.CURRENT.etages.activeEtageIndex;
		let startColumn = Configuration.CURRENT.columns.find(this.startColumn.x, this.startColumn.y);
		let endColumn = Configuration.CURRENT.columns.find(this.endColumn.x, this.endColumn.y);

		if (startColumn !== null && endColumn !== null) {
			this.bracingActive = startColumn.active[activeEtageIndex] === true && endColumn.active[activeEtageIndex] === true;
			return;
		}

		this.bracingActive = false;
	}
}

Math.radians_to_degrees = function (radians) {
	let pi = Math.PI;
	return radians * (180 / pi);
};
