export class Dimensions {
	objectName = 'Dimensions';
	dimensions = [];
	mousePriority = 10;
	mouseAreaOffset = { x: 10, y: 10 };
	_onChange = null;
	_onChanged = null;
	_movePossible = null;
	push(dimension) {
		this.dimensions.push(dimension);
	}
	onMouseUpSizeHandleStart(evt, object, canvas, params) {
		let position = object.objectParams.position;
		let newLengthRaster = 0;
		let newLengthRasterAbove = 0;
		if (position < this.dimensions.length) {
			newLengthRasterAbove = this.dimensions[position];
		}
		if (position + 1 < this.dimensions.length) {
			newLengthRaster = this.dimensions[position + 1];
		}

		params.raster = position + 1;
		params.lengthRaster = newLengthRaster;
		params.lengthRasterAbove = newLengthRasterAbove;
		this.onChanged(evt, object, canvas, params);
	}
	onMouseUp(evt, object, canvas, params) {
		if (object.objectParams.type === 'sizeHandleEnd') {
			this.onMouseUpSizeHandleStart(evt, object, canvas, params);
		}
		return { stopPropagation: true };
	}
	clear() {
		this.dimensions = [];
	}
	onChange(evt, object, canvas, params) {
		if (typeof this._onChange === 'function') {
			this._onChange(evt, object, canvas, params);
		}
	}
	onChanged(evt, object, canvas, params) {
		if (typeof this._onChanged === 'function') {
			this._onChanged(evt, object, canvas, params);
		}
	}
	movePossible(params) {
		if (typeof this._movePossible === 'function') {
			return this._movePossible(params);
		}
		return true;
	}
	getTotalLength() {
		let totalLength = 0;
		this.dimensions.forEach((dimension) => {
			totalLength += dimension;
		});
		return totalLength;
	}
	onMouseMove(evt, object, canvas) {
		if (object.objectParams.type === 'sizeHandleEnd') {
			canvas.canvas.style.cursor = this.objectName === 'DimensionsHorizontal' ? 'w-resize' : 'ns-resize';
		} else if (object.objectParams.type === 'text') {
			canvas.canvas.style.cursor = 'text';
		}

		return { stopPropagation: true };
	}
	onMouseLeave(evt, object, canvas) {
		canvas.canvas.style.cursor = 'default';
		return { stopPropagation: true };
	}
	get length() {
		return this.dimensions.length;
	}
	onMouseDown(evt, object, canvas, params) {
		// zonder mousedown werkt drag niet
		return { stopPropagation: true };
	}
	setById(id, value) {
		if (typeof id !== 'undefined' && id !== null && id < this.dimensions.length) {
			this.dimensions[id] = value;
		}
	}
	get(id) {
		if (typeof id !== 'undefined' && id !== null) {
			if (id < this.dimensions.length) {
				return this.dimensions[id];
			}
			return -1;
		}
		return this.dimensions;
	}
	onMouseDrag(evt, object, canvas, params) {
		return { stopPropagation: true };
	}
	addDrawObjects(canvas, params) {}

	isSizeHandleResizable(canvas, dimension) {
		if (canvas.sizeHandles.get('object') === null || typeof canvas.sizeHandles.get('object') === 'undefined') {
			return false;
		}

		// wanneer de parent undefined is, hoeft de onderstaande check niet uitgevoerd te worden
		if (typeof canvas.sizeHandles.get('object').parent === 'undefined' || canvas.sizeHandles.get('object').parent === null) {
			return true;
		}

		// typeof stairwell undefined, zorgt ervoor dat een object als bijv. palletgate geen error toont, omdat er geen propperty stairwell is
		// check of de value overeenkomt met width || depth van het betreffende object, als dit het geval is, return false
		return !(
			(typeof canvas.sizeHandles.get('object').parent.stairWell !== 'undefined' && dimension === Math.round(canvas.sizeHandles.get('object').parent.stairWell.depth)) ||
			(typeof canvas.sizeHandles.get('object').parent.stairWell !== 'undefined' && dimension === Math.round(canvas.sizeHandles.get('object').parent.stairWell.width)) ||
			(typeof canvas.sizeHandles.get('object').parent.stairWell === 'undefined' && dimension === canvas.sizeHandles.get('object').parent.width) ||
			(typeof canvas.sizeHandles.get('object').parent.stairWell === 'undefined' && dimension === canvas.sizeHandles.get('object').parent.depth)
		);
	}

	isSizeHandleEndResizable(canvas, dimension) {
		// TODO: Slepen fixen.

		if (canvas.sizeHandles.get('object') === null || typeof canvas.sizeHandles.get('object') === 'undefined') {
			return false;
		}

		// wanneer de parent undefined is, hoeft de onderstaande check niet uitgevoerd te worden
		if (typeof canvas.sizeHandles.get('object').parent === 'undefined' || canvas.sizeHandles.get('object').parent === null) {
			return true;
		}

		// sizeHandleEnd before object size not resizable
		return !(
			dimension === Math.round(canvas.sizeHandles.get('object').parent.startX) ||
			dimension === Math.round(canvas.sizeHandles.get('object').parent.x) ||
			dimension === Math.round(canvas.sizeHandles.get('object').parent.startY) ||
			dimension === Math.round(canvas.sizeHandles.get('object').parent.y)
		);
	}
}
