import { Data } from './data';

export class FloorDeflections extends Data {
	objectName = 'FloorDeflections';
	static CURRENT = null;
	url = 'FloorDeflections';
	getMaxCtC(currentDeflection) {
		if (typeof currentDeflection === 'undefined' || currentDeflection === null) {
			return 0;
		}
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let deflectionFind = this._list.list.filter((deflection) => deflection.deflection === currentDeflection);
			if (deflectionFind.length > 0) {
				return deflectionFind[0].maxCtC;
			}
		}
		return 0;
	}
	get floorDeflectionList() {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0) {
			// dan lijst teruggeven opgemaakt zoals ze in het menu verschijnen
			return this.getMappedList();
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen , dan checkecn of de lijst niet leeg is en dan pas returnen van gemapte lijst.
			this.list();
			let returnLoop = setInterval(() => {
				if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0) {
					clearInterval(returnLoop);
					return this.getMappedList();
				}
			}, 1000);
		}
	}

	getMappedList() {
		return this._list.list
			.map((deflection) => {
				return { value: deflection.deflection, caption: deflection.name, info: deflection.info };
			})
			.sort((a, b) => a.value - b.value);
	}
}
