import { Data } from './data';

export class Country extends Data {
	objectName = 'Country';
	id = -1;
	name = '';
	code = '';
	maxCtC = 0;
	zones = [];
	url = 'Countries';
}
