import { Configuration } from '../../../../configurator/configuration';
import { HandRails } from '../../../../configurator/handRails';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

// DIT IS DE PLAATHOUDER, DUS DE GELE HOUDER DIE DE LEUNING MET DE PLAAT VASTMAAKT.
export class HandRailPlateHolder3D extends Model3D {
	objectName = 'HandRailPlateHolder3D';
	assetName = 'handrail_plate_holder';
	fallBackName = 'handrail_plate_holder';
	fallBackData = { height: 30, depth: 50, width: 120 };
	draw(app) {
		let middlePlateHolderWidth = Canvas3D.CURRENT.getModelData('handrail_plate_holder', 'width');
		let middlePlateHolderDepth = Canvas3D.CURRENT.getModelData('handrail_plate_holder', 'depth');
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');

		this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;

		switch (this.position) {
			case HandRails.TOP:
				// Topplaatje van de midden paaltjes in het midden krijgen
				this.x -= middlePlateHolderWidth / 2;
				this.x += poleWidth / 2;
				this.z += (poleWidth - middlePlateHolderDepth) / 2;
				// this.z += (poleWidth - plateDepth) / 2;
				break;
			case HandRails.BOTTOM:
				// Topplaatje van de midden paaltjes in het midden krijgen
				this.x -= middlePlateHolderWidth / 2;
				this.x += poleWidth / 2;
				this.z += (poleWidth - middlePlateHolderDepth) / 2;
				break;
			case HandRails.LEFT:
				this.rotationY = -90;
				this.z -= middlePlateHolderWidth / 2;
				this.z += poleWidth / 2;
				this.x += (poleWidth - middlePlateHolderDepth) / 2;
				this.x += middlePlateHolderDepth;
				break;
			case HandRails.RIGHT:
				this.rotationY = -90;
				this.z -= middlePlateHolderWidth / 2;
				this.z += poleWidth / 2;
				this.x += (poleWidth - middlePlateHolderDepth) / 2;
				this.x += middlePlateHolderDepth;
				break;
		}

		this.y += this.middlePoleLength;
		this.y -= 5;

		super.draw(app);
	}
}
