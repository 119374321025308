import { Data } from './data';

export class Quotation extends Data {
	static STATUS_DESIGN = 0;
	static STATUS_QUOTATIONREQUEST = 1;
	static STATUS_QUOTATION = 2;
	static STATUS_CLOSED = 3;
	states = ['Design', 'QuotationRequest', 'Quotation'];
	objectName = 'Quotation';
	id = -1;
	team = '';
	salesPrice = 0;
	multipleCalculations = true;
	contact = {};
	language = '';
	quotationDueDate = '';
	currentRevision = {};
	company = {};
	createdAt = '';
	reference = '';
	quotationDate = '';
	quotationNumber = '';
	quotationNumberSearch = '';
	type = {};
	state = {};
	url = 'Quotations';
	calculations = [];
	contactPerson = {};
	salesTeam = {};
	readOnly = false;

	fetch(json) {
		if (typeof json === 'undefined' || json === null) {
			this.id = -1;
			this.team = '';
			this.salesPrice = 0;
			this.multipleCalculations = true;
			this.contact = {};
			this.language = '';
			this.quotationDate = '';
			this.currentRevision = {};
			this.company = {};
			this.createdAt = '';
			this.reference = '';
			this.quotationDate = '';
			this.quotationNumber = '';
			this.type = {};
			this.state = {};
			this.salesTeam = {};
			this.readOnly = false;
		} else {
			super.fetch(json);
		}
		this.quotationNumberSearch = this.quotationNumber;
	}
	searchQuotationByNumber() {
		// samenstellen url
		let url = this.baseUrl + this.url + '/Item?quotationNumber=' + this.quotationNumberSearch;

		this.get(url, true);
	}
	getCalculations() {
		super.getById(this.id, true, (r) => {
			this.calculations = r[0].calculations;
		});
	}
}
