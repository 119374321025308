import * as pc from 'playcanvas';
export class DrawObjects3D {
	objectName = 'DrawObjects3D';
	y = 0;
	x = 0;
	z = 0;

	width = 1;
	depth = 1;
	height = 1;

	rotationX = 0;
	rotationY = 0;
	rotationZ = 0;

	assetName = '';
	fallBackName = '';
	oid = null;
	layers = [pc.LAYERID_WORLD];

	constructor(x, y, z, params) {
		this.x = x;
		this.y = y;
		this.z = z;

		if (typeof params !== null && typeof params !== 'undefined') {
			Object.keys(params).forEach((param) => {
				this[param] = params[param];
			});
		}

		this.assetName = this.assetName.replaceAll('/', '_');
	}
	// Zoeken naar de asset, waar de modelData in staan en deze proberen op te vragen.
	// Lukt dit niet dan roepen we getFallBackData aan
	getModelData(app, model, prop, fallbackName) {
		if (typeof fallbackName === 'undefined' || fallbackName === null) {
			fallbackName = model;
		}
		let findAsset = app.assets._assets.filter((asset) => asset.fallBackName === fallbackName);
		let findIndex = 0;

		// Als er een model vanuit ERP is en een model wat dezelfde fallback zal de findasset groter dan 1 zijn, dit moeten we nog afvangen welke we dan moeten hebben,
		// We pakken dan degene die overeenkomt met de opgegeven naam, dit zal de naam vanuit de configurator zijn en niet de fallbackname.
		if (findAsset.length > 1) {
			findAsset.forEach((asset, index) => {
				if (asset.name !== model + '.json') {
					findIndex = index;
				}
			});
		}

		if (findAsset.length > 0) {
			let obj = findAsset[findIndex].modelData;
			if (obj !== null && typeof obj !== 'undefined') {
				if (obj.hasOwnProperty(prop)) {
					if (obj[prop] !== null && typeof obj[prop] !== 'undefined' && obj[prop] !== 0) {
						return obj[prop];
					}
				}
			}
		}
		return this.getFallBackData(findAsset[0], prop);
	}
	// Van het opgevraagde model proberen de fallbackdata op te halen
	// Als deze niet bestaat 0 returnen zodat we in iedergeval het getekende zien.
	getFallBackData(asset, prop) {
		if (typeof asset !== 'undefined' && asset !== null) {
			if (typeof asset.fallBackData !== 'undefined' && asset.fallBackData !== null) {
				if (asset.fallBackData.hasOwnProperty(prop)) {
					let foundProp = asset.fallBackData[prop];
					if (foundProp !== null && typeof foundProp !== 'undefined') {
						return foundProp;
					}
				}
			}
		}
		return 0;
	}
}
