export class MiscellaneousArticleTool {
	objectName = 'MiscellaneousArticleTool';
	id = -1;
	oid = -1;
	_name = '';
	_count = 0;
	disabled = false;
	_rounding = 0.0;
	_optional = false;
	order = 0;
	optionalPossible = false;

	get optional() {
		return this._optional;
	}
	set optional(value) {
		if (this._optional !== value) {
			this._optional = value;
			// Wanneer item count naar 0 gaat en de optional naar de default waarde gaat voorkomen dat hij de save nogmaals uitvoert.
			if (this.count > 0) {
				this.onChange();
			}
		}
	}

	set name(value) {
		if (this._name !== value) {
			this._name = value;
		}
	}
	get name() {
		return this._name;
	}

	set rounding(value) {
		if (this._rounding !== value) {
			this._rounding = value;
			this.onChange();
		}
	}
	get rounding() {
		return this._rounding;
	}

	set count(value) {
		if (this._count !== value) {
			this._count = value;
			this.onChange();
		}
	}
	get count() {
		return this._count;
	}
	round() {
		let useRounding = 1;
		if (this.rounding > 0) {
			useRounding = this.rounding;
		}

		this.count = Math.ceil(this.count / useRounding) * useRounding;
		this.onChange();
	}

	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}

	constructor(id, oid, name, count, disabled, rounding, onChange, optional, order, optionalPossible) {
		this.id = id;
		this.oid = oid;
		this._name = name;
		this._count = count;
		this.disabled = disabled;
		this._rounding = rounding;
		this._onChange = onChange;
		this._optional = optional;
		this.order = order;
		this.optionalPossible = optionalPossible;
	}

	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
	}
	getAmount() {
		return {
			id: this.id,
			disabled: this.disabled,
			article: { oid: this.oid, name: this.name, amount: this.count, rounding: this.rounding, optional: this.optional, optionalPossible: this.optionalPossible },
		};
	}
}
