/* eslint-disable no-new */
import { Configuration } from '../../../configurator/configuration';
import { Profiles } from '../../../configurator/profiles';
import { Mathematic } from '../../../helpers/mathematic';
import { Canvas3D } from '../../Canvas3D';
import { MainBeamBottom3D } from '../../profiles/mainbeams/standard/MainBeamBottom3D';
import { MainBeamLeft3D } from '../../profiles/mainbeams/standard/MainBeamLeft3D';
import { MainBeamRight3D } from '../../profiles/mainbeams/standard/MainBeamRight3D';
import { MainBeamTop3D } from '../../profiles/mainbeams/standard/MainBeamTop3D';

export class StairInFloorProfiles3D {
	objectName = 'StairInFloorProfiles3D';
	constructor(stairData, raster, etage, posY = 0) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let deltaX = Mathematic.soscastoa({ a: stairData.etageHeight, angle: 90 - stairData.angle }).o;
		let shared = { deltaX: deltaX };

		let beamDataX = raster.spansX.getSpans()[stairData.rasters[0].x].beam.oid;
		let beamDataY = raster.spansY.getSpans()[stairData.rasters[0].y].beam.oid;
		let mainBeamHeight;
		let mainBeamDepth;
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
			mainBeamHeight = Canvas3D.CURRENT.getBeamData(beamDataY, 'height');
			mainBeamDepth = Canvas3D.CURRENT.getBeamData(beamDataY, 'depth');
		} else {
			mainBeamHeight = Canvas3D.CURRENT.getBeamData(beamDataX, 'height');
			mainBeamDepth = Canvas3D.CURRENT.getBeamData(beamDataX, 'depth');
		}

		let paramsObject = {
			raster: raster,
			etage: etage,
			drawHeight: posY + stairData.etageHeight - mainBeamHeight - deckingFinishHeight,
			profileType: 'mainbeam',
			doubleDouble: false,
			ralColor: Configuration.CURRENT.colors.profiles.ralColor,
			isStairWellProfile: true,
			mainBeamHeight: mainBeamHeight,
		};

		let currentRaster = raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY);
		let nextRaster = raster.getRasterByCoordinate(stairData.stairWell.startX + stairData.stairWell.width, stairData.stairWell.startY + stairData.stairWell.depth);
		let rasterStartX = raster.getSizeX(currentRaster.x - 1);

		let rasterStartY = raster.getSizeY(currentRaster.y - 1);
		let nextRasterStartY = raster.getSizeY(currentRaster.y);
		let nextRasterStartX = raster.getSizeX(currentRaster.x);
		let nextSecondRasterStartY = raster.getSizeY(currentRaster.y + 1);

		let stairWellWidth = stairData.stairWell.width;
		let stairWellDepth = stairData.stairWell.depth;

		if (stairData.intermediateLandings.intermediateLandings.length === 0) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
				let horizontalLength = nextRasterStartX - rasterStartX;
				paramsObject.oid = beamDataY;

				if (this.horizontalStairInNextFloor(stairData.stairWell.startX, stairData.stairWell.startY, stairData, raster, shared)) {
					horizontalLength +=
						raster.getSizeX(raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY).x + 1) -
						raster.getSizeX(raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY).x);
				}
				new MainBeamTop3D(rasterStartX, stairData.stairWell.startY, horizontalLength, paramsObject);
				new MainBeamBottom3D(rasterStartX, stairData.stairWell.startY + stairWellDepth, horizontalLength, paramsObject);

				new MainBeamLeft3D(stairData.stairWell.startX, stairData.stairWell.startY, stairWellDepth, paramsObject);
				new MainBeamRight3D(stairData.stairWell.startX + stairWellWidth, stairData.stairWell.startY, stairWellDepth, paramsObject);
			} else {
				paramsObject.oid = beamDataX;
				let verticalLength = nextRasterStartY - rasterStartY;

				new MainBeamTop3D(stairData.stairWell.startX, stairData.stairWell.startY, stairWellWidth, paramsObject);
				new MainBeamBottom3D(stairData.stairWell.startX, stairData.stairWell.startY + stairWellDepth, stairWellWidth, paramsObject);

				if (this.verticalStairInNextFloor(stairData.stairWell.startX, stairData.stairWell.startY, stairData, raster, shared)) {
					verticalLength +=
						raster.getSizeY(raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY).y + 1) -
						raster.getSizeY(raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY).y);
				}

				new MainBeamLeft3D(stairData.stairWell.startX, rasterStartY, verticalLength, paramsObject);
				new MainBeamRight3D(stairData.stairWell.startX + stairWellWidth, rasterStartY, verticalLength, paramsObject);
			}
		} else if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			paramsObject.oid = beamDataX;
			new MainBeamTop3D(stairData.stairWell.startX, stairData.stairWell.startY, stairWellWidth, paramsObject);
			new MainBeamBottom3D(stairData.stairWell.startX, stairData.stairWell.startY + stairWellDepth, stairWellWidth, paramsObject);

			// Checken of de stair in 2 rasters zit
			if (currentRaster.x === nextRaster.x && currentRaster.y === nextRaster.y) {
				new MainBeamLeft3D(stairData.stairWell.startX, rasterStartY, nextRasterStartY - rasterStartY, paramsObject);
				new MainBeamRight3D(stairData.stairWell.startX + stairWellWidth, rasterStartY, nextRasterStartY - rasterStartY, paramsObject);
			} else {
				// ALs dat nte zo is dan moeten we een andere lengte pakken.
				new MainBeamLeft3D(stairData.stairWell.startX, rasterStartY, nextSecondRasterStartY - rasterStartY, paramsObject);
				new MainBeamRight3D(stairData.stairWell.startX + stairWellWidth, rasterStartY, nextSecondRasterStartY - rasterStartY, paramsObject);
			}
		} else {
			paramsObject.oid = beamDataY;
			let horizontalLength = nextRasterStartX - rasterStartX;

			if (this.horizontalStairInNextFloor(stairData.stairWell.startX, stairData.stairWell.startY, stairData, raster, shared)) {
				horizontalLength +=
					raster.getSizeX(raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY).x + 1) -
					raster.getSizeX(raster.getRasterByCoordinate(stairData.stairWell.startX, stairData.stairWell.startY).x);
			}
			new MainBeamTop3D(rasterStartX, stairData.stairWell.startY, horizontalLength, paramsObject);
			new MainBeamBottom3D(rasterStartX, stairData.stairWell.startY + stairWellDepth, horizontalLength, paramsObject);

			new MainBeamLeft3D(stairData.stairWell.startX, stairData.stairWell.startY, stairWellDepth, paramsObject);
			new MainBeamRight3D(stairData.stairWell.startX + stairWellWidth, stairData.stairWell.startY, stairWellDepth, paramsObject);
		}
	}

	// Function to check if the stair X + width is in the next raster, if so, than we need to make the wooden plate longer so we cover the next raster up aswell.
	horizontalStairInNextFloor(x, y, stairData, raster, shared) {
		if (x + stairData.stairWell.width > raster.getSizeX(raster.getRasterByCoordinate(x, y).x)) {
			return true;
		}
	}
	verticalStairInNextFloor(x, y, stairData, raster, shared) {
		if (y + stairData.stairWell.depth > raster.getSizeY(raster.getRasterByCoordinate(x, y).y)) {
			return true;
		}
	}
}
