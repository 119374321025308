import * as pc from 'playcanvas';
import { Configuration } from '../../../../configurator/configuration';
import { Stair } from '../../../../configurator/stair';
import { StairOutSide } from '../../../../configurator/stairOutSide';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class StairStep3D extends Model3D {
	objectName = 'StairStep3D';
	hoverTags = ['stairs'];
	constructor(x, y, params) {
		super(x, params.shared.yPos, y, params);
		this.yellowNose = params.yellowNose;
		this.riser = params.riser;
		this.stepsAmount = params.stepsAmount;
		this.lastStep = params.lastStep;
		this.firstStep = params.firstStep;

		if (params.stairData.step.groupName === 'Grating') {
			this.oid = 191;
		} else {
			this.oid = params.stairData.step.articleId;
		}
	}
	draw(app) {
		let stepWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
		let stepHeight = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'height');

		let trapboomDepth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.stinger, 'depth');
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.handRailPost, 'width');

		this.stepDeltaHeight = (this.shared.yPosEnd - this.shared.yPos) / this.stepsAmount;
		this.stepDeltaWidth = this.shared.deltaX / this.stepsAmount;
		this.ralColor = Configuration.CURRENT.colors.stairs.ralColor;

		if (typeof this.y === 'undefined') {
			this.y = 0;
		}

		this.width = this.stairData.stepWidth;
		let obj;
		switch (this.shared.upComing) {
			case Stair.UPCOMING_TOP:
				obj = this.shared.objupComingTop.step;

				Object.keys(obj).forEach((param) => {
					this[param] = obj[param];
				});

				this.rotationY = 180;
				this.z -= this.shared.deltaX;

				this.z += this.stepDeltaWidth * this.index;
				this.z += stepWidth;
				this.y += stepHeight;
				this.y += this.stepDeltaHeight * this.index;

				this.width -= trapboomDepth * 2;
				this.x -= trapboomDepth;

				break;
			case Stair.UPCOMING_BOTTOM:
				obj = this.shared.objupComingBottom.step;
				Object.keys(obj).forEach((param) => {
					this[param] = obj[param];
				});

				this.z += this.shared.deltaX;
				this.z -= stepWidth;
				this.y += stepHeight;
				this.z -= this.stepDeltaWidth * this.index;

				this.y += this.stepDeltaHeight * this.index;
				this.x += trapboomDepth;
				this.width -= trapboomDepth * 2;
				break;
			case Stair.UPCOMING_LEFT:
				obj = this.shared.objupComingLeft.step;
				Object.keys(obj).forEach((param) => {
					this[param] = obj[param];
				});

				this.x -= this.shared.deltaX;
				this.x += stepWidth;
				this.x += this.stepDeltaWidth * this.index;
				this.y += this.stepDeltaHeight * this.index;
				this.y += stepHeight;

				this.width -= poleWidth;
				this.width -= trapboomDepth;

				this.z += poleWidth;

				break;
			case Stair.UPCOMING_RIGHT:
				obj = this.shared.objupComingRight.step;

				Object.keys(obj).forEach((param) => {
					this[param] = obj[param];
				});
				this.x += this.shared.deltaX;
				this.x -= this.stepDeltaWidth * this.index;
				this.x -= stepWidth;
				this.y += stepHeight;

				this.y += this.stepDeltaHeight * this.index;
				this.z += this.stairData.step.width;

				this.z -= trapboomDepth;
				this.width -= trapboomDepth;
				this.width -= trapboomDepth;

				break;
		}

		if (this.stairData.step.groupName === 'Grating') {
			// TODO: VOOR NU DAN OOK GEWOON HOUT TENENEN.
			this.oid = 191;
			// this.createGratingStep(this.x, this.y, this.z, this.width * 1000, this.stairData.step.depth, stepHeight, app);
			super.draw(app);
		} else {
			if (this.stairData.step.groupName === 'Checker plate') {
				// texture = 'checkerplate.jpg';
			}
			super.draw(app);
		}

		if (this.yellowNose !== null) {
			this.yellowNose.x = this.x;
			this.yellowNose.y = this.y;
			this.yellowNose.z = this.z;
			this.yellowNose.rotationY = this.rotationY;
			this.yellowNose.rotationZ = this.rotationZ;
			this.yellowNose.rotationX = this.rotationX;
			this.yellowNose.width = this.width;
			this.yellowNose.draw(app);
		}

		if (this.riser !== null && this.lastStep === false) {
			this.riser.x = this.x;
			this.riser.y = this.y;
			this.riser.z = this.z;
			this.riser.rotationY = this.rotationY;
			this.riser.rotationZ = this.rotationZ;
			this.riser.rotationX = this.rotationX;
			this.riser.width = this.width;
			this.riser.draw(app);
		}
	}

	createGratingStep(x, y, z, stepWidth, stepDepth, stepHeight, app) {
		let depth = stepDepth;
		let width = stepWidth;

		// if(this.stairData.position === Stair)
		// Omdat we geen rotation kunnen gebruiken op losse stukjes rooster zetten we de positie op deze manier.
		switch (this.stairData.position) {
			case StairOutSide.POSITION_LEFT:
				depth = stepWidth;
				width = stepDepth;
				x -= stepDepth;
				y += stepHeight;
				break;

			case StairOutSide.POSITION_RIGHT:
				depth = stepWidth;
				width = stepDepth;
				z -= stepWidth;
				y += stepHeight;
				// x += stepDepth / 2;
				break;
			case StairOutSide.POSITION_TOP:
				x -= stepWidth;
				z -= stepDepth;
				y += stepHeight;
				// z -= stepDepth / 1000;
				// y += 240 / 1000;
				break;
			case StairOutSide.POSITION_BOTTOM:
				y += stepHeight;
				break;
		}

		// let depth = 100;
		x = x / 1000;
		y = y / 1000;
		z = z / 1000;
		let startY = 0;
		while (depth > 0) {
			// per horizontale regel over de hele diepte.

			// bpeaal aantal stukken dat in de breedte en (overgebleven diepte) past
			let roosterHorizontal = this.getRoosterAmount(width, depth);

			// kijk wat de grootste lengte is zodat kleinere stukken straks bijgevuld kunnen worden tot deze lengte (vierkanten)
			let maxLength = 0;
			roosterHorizontal.forEach((amount) => {
				if (maxLength < amount.length) {
					maxLength = amount.length;
				}
			});

			let startX = 0;
			// loop over de horizontale amount heen om toe te voegen
			// let marge = 1;
			roosterHorizontal.forEach((deckingFinish, index) => {
				for (let i = 0; i < deckingFinish.count; i++) {
					// teken aantal keer
					this.createGrating(app, { x: x + startX / 1000, y: y, z: z + startY / 1000, length: deckingFinish.length });
					// als createGrating dan langste lengte dan bijvullen tot langste lengte
					let newY = startY;
					// bepaal aantal exrta
					let countExtra = maxLength / deckingFinish.length;
					for (let j = 0; j < countExtra; j++) {
						this.createGrating(app, { x: x + startX / 1000, y: y, z: z + newY / 1000, length: deckingFinish.length });
						newY += maxLength / Math.floor(maxLength / (deckingFinish.length - 1.5));
					}

					startX += maxLength / Math.floor(maxLength / (deckingFinish.length - 1.5));
				}
			});
			// om een infinity loop te voorkomen. Als maxLength = 0 en er is diepte over dan is er geen passend stuk gevonden en verlaten we de loop door depth op 0 te zetten
			if (maxLength === 0) {
				depth = 0;
			}
			depth -= maxLength;
			startY += maxLength;
		}
	}
	createGrating(app, data) {
		let finishEntity = new pc.Entity('Grating' + this.objectName);
		finishEntity.addComponent('model');
		let assetName = 'rooster-' + data.length;
		let jsonModel = super.findModel(app, assetName);

		finishEntity.model.model = jsonModel;
		finishEntity.setLocalScale(1, 1, 1);
		finishEntity.setPosition(data.x, data.y, data.z);
		app.root.addChild(finishEntity);
	}
	getLengths() {
		return [34];
	}

	getRoosterAmount(rasterLength, rasterDepth) {
		let lengths = this.getLengths();

		// lengtes zijn gesorteerd van groot naar klein
		let amount = [];
		// start lengte is rastrelengte
		let length = rasterLength;
		// loopen over lengte
		lengths.forEach((l) => {
			// is er nog restlengte over, past de lengte ook in de diepte (vierkante stukjes) en is dit deelbaar door de lengte dan de hele aantallen opslaan en lengte de restlengte
			if (length > 0 && l <= rasterDepth && Math.floor(length / l) > 0) {
				amount.push({ length: l, count: Math.floor(length / l) });
				length = length - Math.floor(length / l) * l;
			}
		});

		return amount;
	}
}
