import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { Bracing } from './bracing';
import { PortalBracings } from './portalBracings';

export class PortalBracing extends Bracing {
	objectName = 'PortalBracing';
	objectName3d = 'PortalBracing3D';
	drawObject(endX, endY, startX, startY, startColumn, endColumn) {
		return new Line(endX, endY, startX, startY, new DrawValue(6, 0, true), [5, 15], PortalBracings.COLORS.bracing, null, null, true, this, { start: startColumn, end: endColumn });
	}
	addDrawObjects3d(canvas3d, etage, raster, posY, etageIndex) {}
}
