import { HicadDrawObject } from '../../../configurator/hicad/HicadDrawObject';
import { Stair } from '../../../configurator/stair';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';

export class StingerStair3D extends Model3D {
	objectName = 'StingerStair3D';
	hoverTags = ['stairs'];
	rotationY = 180;
	constructor(x, y, params) {
		super(x, 0, y, params);
		this.kickEdge = params.kickEdge;
		this.oid = params.stairData.material.stinger;
	}
	draw(app) {
		let trapboomdiepte = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'depth');
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.handRailPost, 'width');
		switch (this.shared.upComing) {
			case Stair.UPCOMING_TOP:
				Object.keys(this.shared.objupComingTop.boom).forEach((param) => {
					this[param] = this.shared.objupComingTop.boom[param];
				});

				if (this.trapboomPosition === 'right') {
					this.x -= this.stairData.step.width;
					this.x += trapboomdiepte;
				}
				this.z -= this.shared.deltaX;
				this.y += this.shared.yPos;
				break;
			case Stair.UPCOMING_BOTTOM:
				Object.keys(this.shared.objupComingBottom.boom).forEach((param) => {
					this[param] = this.shared.objupComingBottom.boom[param];
				});

				if (this.trapboomPosition === 'right') {
					this.x += this.stairData.step.width;
					this.x -= trapboomdiepte;
				}

				this.z += this.shared.deltaX;
				this.y += this.shared.yPos;
				break;

			case Stair.UPCOMING_LEFT:
				Object.keys(this.shared.objupComingLeft.boom).forEach((param) => {
					this[param] = this.shared.objupComingLeft.boom[param];
				});

				if (this.trapboomPosition === 'right') {
					this.z += this.stairData.step.width;
				} else {
					this.z += poleWidth;
				}

				this.y += this.shared.yPosEnd;
				break;
			case Stair.UPCOMING_RIGHT:
				this.y += this.shared.yPos;
				Object.keys(this.shared.objupComingRight.boom).forEach((param) => {
					this[param] = this.shared.objupComingRight.boom[param];
				});

				this.x += this.shared.deltaX;

				this.z += trapboomdiepte;
				if (this.trapboomPosition === 'right') {
					this.z += this.stairData.step.width;
					this.z -= trapboomdiepte;
				}
				break;
		}

		this.width = this.shared.diagonalLength;

		if (this.kickEdge !== null) {
			this.kickEdge.x = this.x;
			this.kickEdge.y = this.y;
			this.kickEdge.z = this.z;
			this.kickEdge.rotationY = this.rotationY;
			this.kickEdge.rotationZ = this.rotationZ;
			this.kickEdge.rotationX = this.rotationX;
			this.kickEdge.width = this.width;
			this.kickEdge.draw(app);
		}

		super.draw(app);
	}
	convert() {
		return new HicadDrawObject(this);
	}
}
