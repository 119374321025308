import { Data } from '../data';

export class ColumnProtectorsModels extends Data {
	objectName = 'ColumnProtectorsModels';
	url = 'columnprotectorModels';
	result = null;
	firstList(id, force = false) {
		super.list(
			null,
			force,
			null,
			'?id=' + id,
			(res) => {
				// Omdat result geen array is word het in de super niet opgeslagen in de list.
				this.result = res;
			},
			(err) => {
				console.log(err);
			},
		);
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			thenCallBack(this._list.list);
		}
		if (this.store.companies.selectedCompany.id === -1) {
			return null;
		}
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		data.companyId = this.store.companies.selectedCompany.id;
		data.language = Languages.CURRENT.currentLanguage;
		super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}

	getByParameters(id, force, parameters, thenCallBack, catchCallBack, cacheId) {
		// samenstellen url
		let url = this.baseUrl + this.url + '?id=' + parameters.id + '&type=' + parameters.type;
		this.get(url, true, thenCallBack, catchCallBack, cacheId + '_' + id, false);
	}
	getByColumnProtectorOidByPosition(type) {
		if (this.result !== null && typeof this.result !== 'undefined') {
			if (type.length === 2) {
				return this.result.details.columnProtectors.columnProtectorCorner.id;
			} else {
				return this.result.details.columnProtectors.columnProtectorU.id;
			}
		}
	}
}
