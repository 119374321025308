import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';
import { JsonBraceColumn } from '@/configurator/hicad/jsonBraceColumn';

export class BraceColumnLeft3D extends Model3D {
	objectName = 'BraceColumnLeft3D';
	hoverTags = ['bracecolumns'];
	rotationX = 180;
	rotationZ = -20;
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		// Door manier van tekenen en rotatie eigen diepte erbij
		this.z += columnWidth;

		// Hoogte die we standaard meegeven is etage hoogte.
		// Daar dan finishHoogte en beamHoogte vanaf zodat hij precies daaronder komt.
		this.y -= deckingFinishHeight;
		this.y -= this.mainBeamHeight;

		this.height -= deckingFinishHeight;
		this.height -= this.mainBeamHeight;

		// Links naast de kolom, dus min de kolom breedte , deze nog ophalen!!!
		this.x -= columnWidth;

		// Min 135 omdat de schoorkolom altijd 135mm onder de profielen moet komen.
		this.y -= 135;

		this.basePlate.x = this.x - this.info.depth;
		this.basePlate.z = this.z - columnWidth;

		this.basePlate.draw(app);
		super.draw(app);
	}

	convert() {
		return new JsonBraceColumn(this);
	}
}
