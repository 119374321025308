export class ContextMenu {
	objectName = 'ContextMenu';
	visible = false;
	items = [];
	x = 500;
	y = 100;
	// Om bij te houden of hij open was bij een drag of mousedown etc.
	// Als hij dan op true staat en mouseup gebeurd in canvas.js dan zet hij hem alleen weer actief als hij dat eerder ook al was.
	wasOpen = false;
	setItems(items = []) {
		this.items = [];
		items.forEach((item) => {
			if (!this.items.includes(item) && item.active === true) {
				this.items.push(item);
			}
		});
	}
	reconstruct() {
		this.visible = false;
	}
	show() {
		this.visible = true;
	}
	toggle() {
		this.visible = !this.visible;
	}
	hide(wasOpen = false) {
		this.wasOpen = wasOpen;
		this.visible = false;
	}
	updatePosition(x = null, y = null) {
		this.x = x;
		this.y = y;
	}
}
