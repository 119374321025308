import { Configuration } from '../../configurator/configuration';
import { HandRails } from '../../configurator/handRails';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';

export class HandRailChildPoleHolder3D extends Model3D {
	objectName = 'HandRailChildPoleHolder3D';
	assetName = 'childfriendly_pole_holder';
	fallBackName = 'childfriendly_pole_holder';
	fallBackData = { height: 60, depth: 30, width: 1000 };
	depth = 1;
	constructor(x, y, z, params) {
		super(x, y, z, params);
	}
	draw(app) {
		this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'depth');
		let childfriendly_pole_holderWidth = Canvas3D.CURRENT.getModelData('childfriendly_pole_holder', 'depth');

		// Handrail rechts, dan is de X positie start van normale paaltje.
		// Dan aan de rechterkant van normale paaltje uitlijnen. (Op de rand)
		if (this.position === HandRails.RIGHT) {
			this.rotationY = -90;
			this.x += poleWidth;
		}

		// Links, dan roteren en door rotatie goed zetten op de X positie normale paaltje.
		if (this.position === HandRails.LEFT) {
			this.rotationY = -90;
			this.x += childfriendly_pole_holderWidth;
		}

		// Z positie is begin van normale paaltje, we willen ze zo ver mogelijk naar buiten uitlijnen.
		if (this.position === HandRails.BOTTOM) {
			this.z += poleWidth - childfriendly_pole_holderWidth;
		}

		super.draw(app);
	}
}
