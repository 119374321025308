import { Data } from './data';
import { Store } from './store';

export class StairSettings extends Data {
	objectName = 'StairSettings';
	static CURRENT = null;
	url = 'Settings/stairSettings';
	settings = [];
	landingFinish = null; // Id van artikel (Dus niet van groep)
	landingFinishHeight = 0;
	landingFinishName = ''; // Naam van het gehele artikel
	landingFinishCtc = 0;
	landingFinishGroupName = ''; // Naam van de groep waar hij inzit
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}

		super.list(
			data,
			force,
			cacheId,
			urlExtension,
			(res) => {
				this.settings = res;
				this.fetch(res);
				if (typeof thenCallBack === 'function') {
					thenCallBack(res);
				}
			},
			(err) => {
				if (typeof catchCallBack === 'function') {
					catchCallBack(err);
				}
			},
		);
	}

	getList() {
		if (this.settings !== null && typeof this.settings !== 'undefined') {
			return this.settings;
		}
	}

	getDefaultIntermediateLandingFinish() {
		if (typeof this.landingFinish !== 'undefined' || this.landingFinish !== null) {
			return {
				landingFinish: this.landingFinish,
				landingFinishHeight: this.landingFinishHeight,
				landingFinishName: this.landingFinishName,
				landingFinishCtc: this.landingFinishCtc,
				landingFinishGroupName: this.landingFinishGroupName,
			};
		}
	}

	getDefaultIntermediateLandingCtc(thenCallBack) {
		if (typeof this.landingFinish !== 'undefined' || this.landingFinish !== null) {
			Store.CURRENT.deckingFinishes.getById(this.landingFinish, (findFinish) => {
				if (findFinish !== null && typeof findFinish !== 'undefined') {
					if (typeof thenCallBack === 'function') {
						thenCallBack(findFinish.centerToCenter);
					} else {
						return findFinish.centerToCenter;
					}
				} else {
					Store.CURRENT.deckingFinishes.getFinish(this.landingFinishGroupName, this.landingFinishHeight, (res) => {
						if (res !== null && typeof res !== 'undefined' && typeof thenCallBack === 'function') {
							thenCallBack(res.centerToCenter);
						}
					});
				}
			});
		} else {
			return 0;
		}
	}
}
