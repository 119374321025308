import { Data } from './data';
import { Store } from './store';

export class Translations extends Data {
	objectName = 'Translations';

	url = 'Translations';

	translate(text) {
		// lijst nog niet opgehaald dan ophalen
		if (typeof this._list.list === 'undefined' || this._list.list === null) {
			return '';
		}
		// zoek de tekst op
		let found = this._list.list.filter((l) => l.name === text);
		// niet gevonden of geen vertaling dan lege string

		if (found.length === 0) {
			return '';
		}
		if (found[0].value === null) {
			return '';
		}
		// gevonden waarde teruggeven
		return found[0].value;
	}
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.language === 'undefined' || data.language === null) {
			data.language = Store.CURRENT.languages.currentLanguage;
		}

		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
	executePreLoad() {
		// zodra store wordt geladen en user gevalideerd is de vertalingen laden
		this.list();
	}
}
