import { Store } from '../data/store';

export default ({ app, store }, inject) => {
	const translate = (string, args) => {
		let store = new Store();
		const translations = require('~/locales/' + store.languages.currentLanguage.toLowerCase() + '.json');
		// let op er is ook nog een plugins/i18n.js die dezelfde functionaliteit heeft
		// vertaling ophalen uit bestand

		let translation = translations;
		// geen tekst meegegeven dan oorspronkelijke waarde teruggeven
		if (typeof string === 'undefined') {
			return '';
		}
		if (typeof string !== 'string') {
			return string;
		}

		// opsplitsen en zoeken in json
		string.split('.').forEach(function (part) {
			if (typeof translation[part] !== 'undefined') {
				translation = translation[part];
			}
		});
		// als niet gevonden dan komt object terug van het laatste gevonden part en staat dus niet in json
		// ophalen uit database
		if (typeof translation !== 'string') {
			// niet gevonden
			let translationFromDb = Store.CURRENT.translations.translate(string);

			// gevonden in database dan terug
			if (translationFromDb !== '') {
				translation = translationFromDb;
			}
		}

		// gevonden dan tekst terug anders oorspronkelijke waarde
		let result = typeof translation === 'string' ? translation : string;
		// vervang eventuele parameters in de tekst door de waarden die meegegeven zijn
		if (typeof args === 'object') {
			for (const key in args) {
				result = result.replace(new RegExp('{' + key + '}', 'g'), args[key]);
			}
		}
		return result;
	};
	inject('translate', translate);
};
