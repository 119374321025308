import { Columns } from '../../configurator/columns';
import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { BasePlate3D } from './basePlate3D';
export class BasePlateHorizontal3D extends BasePlate3D {
	objectName = 'BasePlateHorizontal3D';
	draw(app) {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		// Goed zetten van de baseplate op basis van de rowPosition van de kolom.
		if (typeof this.rowPositions !== 'undefined' && this.rowPositions !== null) {
			// Alle linker baseplates (verticaal) 1/3e naar rechts
			if (this.rowPositions.includes(Columns.POSITION_LEFT)) {
				this.x += columnWidth / 3;
			}
			// Alle rechter baseplates (verticaal) 1/3e naar links
			if (this.rowPositions.includes(Columns.POSITION_RIGHT)) {
				this.x -= columnWidth / 3;
			}
		}
		super.draw(app);
	}
}
