export class Span {
	objectName = 'Span';
	_value = 5000;
	beam = {};
	salesProductId = 0;

	get value() {
		return this._value;
	}

	set value(val) {
		this._value = parseInt(val);
		this.roundValue = val;
	}
	_onChange = null;
	saveChangedValue(rasterIndex, beamDirection, newValue = null, custom = false) {
		this.value = newValue;
		this.onChange({ rasterIndex: rasterIndex, beamDirection: beamDirection, custom: custom });
	}
	onChange(params) {
		if (typeof this._onChange === 'function') {
			this._onChange(params);
		}
	}
	roundValue = 5000;
	_step = 50; // initiele waarde. Echte waarde komt uit configuration.

	get step() {
		return this._step;
	}
	set step(step) {
		this._step = step;
		this.value = this._value; // aftrappen van afronden op basis van nieuwe step
	}

	constructor(value) {
		this.value = value;
	}
}
