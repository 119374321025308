import { Canvas3D } from '../Canvas3D';
import { Asset3D } from './Asset3D';

export class MaterialAsset3D extends Asset3D {
	objectName = 'MaterialAsset3D';
	type = 'material';
	// eslint-disable-next-line no-useless-constructor
	constructor(assetName, oid, params = null) {
		super(assetName, oid, params);
		this.assetName = assetName;
		this.folder = params.folder;
		this.oid = oid;
	}
	load(thenCallBack) {
		if (this.folder !== '') {
			this.folder = this.folder + '/';
		}
		Canvas3D.CURRENT.app.assets.loadFromUrl('/playcanvas/' + this.folder + this.assetName, 'texture', (err, asset) => {
			if (err) {
				console.log(err, this);
			}
			if (typeof thenCallBack === 'function') {
				thenCallBack();
			}
		});
	}
}
