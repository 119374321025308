import { HicadDrawObject } from './HicadDrawObject';

export class JsonBraceColumn extends HicadDrawObject {
	h_col = 0;
	thickness = 0;
	direction = 0;
	constructor(bc) {
		super(bc);
		this.h_col = bc.columnWidth;
		this.thickness = bc.columnThickness;
		this.direction = bc.info.direction;
	}
}
