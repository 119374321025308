import { DimensionVertical3D } from './dimensionVertical3D';

export class DimensionVerticalLeft3D extends DimensionVertical3D {
	objectName = 'DimensionVerticalLeft3D';
	rotationX = -90;
	rotationY = -90;
	draw() {
		super.draw();
		super.addEntities();
	}
}
