import { HicadDrawObject } from './HicadDrawObject';

export class jsonPalletGate extends HicadDrawObject {
	height = 0;
	depth = 0;
	width = 0;
	type = '';
	position = 1;
	palletgateType = 0;
	constructor(palletgate) {
		super(palletgate);
		this.height = palletgate.info.height;
		this.width = palletgate.info.width;
		this.depth = palletgate.info.depth;
		this.type = palletgate.info.type;
		this.position = palletgate.info.position;
		this.determinePalletGateType(palletgate);
	}

	determinePalletGateType(palletgate) {
		if (palletgate.info.type === 'Self closing gate') {
			this.palletgateType = 0;
		} else if (palletgate.info.type === 'Chain gate') {
			this.palletgateType = 1;
		} else {
			this.palletgateType = 2;
		}
	}
}
