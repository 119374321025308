import { Store } from '../data/store';
import { MiscellaneousArticleTool } from './MiscellaneousArticleTool';

export class MiscellaneousArticles {
	objectName = 'MiscellaneousArticles';
	articles = [];
	allArticles = [];

	constructor(onChange) {
		this._onChange = onChange;
	}
	onChange(status) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}

	setArticles() {
		if (typeof this.allArticles !== 'undefined' && this.allArticles !== null && this.allArticles.length > 0) {
			this.allArticles.forEach((article, index) => {
				article.article.count = 0;
				article.article.disabled = false;
				let findArticle = this.articles.find((a) => a.id === article.id);
				if (typeof findArticle === 'undefined' || findArticle === null) {
					this.articles.push(
						new MiscellaneousArticleTool(
							article.id,
							article.article.oid,
							article.article.name,
							article.article.count,
							article.article.disabled,
							article.article.rounding,
							this.onChange.bind(this),
							false,
							null,
							article.article.optional,
						),
					);
				} else if (article.article.name !== findArticle.name) {
					findArticle.name = article.article.name; // door vertaling kan dit afwijken
				}
			});
		}
	}

	setReferences(params) {
		this._onChange = params.save;
		this.articles.forEach((article, index) => {
			article.setReferences({ onChange: this.onChange.bind(this) });
		});
	}

	removeReferences() {
		this._onChange = null;
		this.articles.forEach((article, index) => {
			if (typeof article.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				article.removeReferences();
			}
		});
	}
	createNewConfiguration(data) {
		this.getAllArticles();
	}
	getAllArticles() {
		Store.CURRENT.miscellaneousArticles.list(
			null,
			true,
			-1,
			null,
			(response) => {
				this.allArticles = response;
				this.setArticles();
			},
			(exception) => {
				console.log(exception);
			},
		);
	}
	afterReconstruct() {
		this.getAllArticles();
	}

	getAmount() {
		let amount = {};

		if (typeof this.allArticles !== 'undefined' && this.allArticles !== null && this.allArticles.length > 0) {
			amount = { Miscellaneous: [] };
			this.articles.forEach((article, index) => {
				if (article.count > 0) {
					let findArticle = this.allArticles.filter((a) => a.id === article.id);
					if (findArticle.length > 0) {
						amount.Miscellaneous.push(article.getAmount());
					}
				}
			});
		}

		return amount;
	}
}
