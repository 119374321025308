<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<div class="page">
					<div class="view">
						<h1>Loginpage</h1>
						<nuxt />
					</div>
				</div>
			</div>
		</div>
		<div class="footer-copyright text-center py-3">
			a
			<a href="https://proautnorm.com">ProAutNorm</a> product
		</div>
	</div>
</template>

<script>
export default {
	transition: {},
	methods: {
		isActive(path) {
			return this.$route.path === path;
		},
	},
};
</script>
<style lang="less" scoped>
h1 {
	color: #fcc43e;
}
.page {
	min-height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	.user {
		background: #eee;
		h1 {
			padding: 20px;
			margin: 0;
		}
	}
	ul.tabs {
		position: relative;
		float: left;
		width: 100%;
		padding: 50px 0 0 0;
		margin: 0;
		list-style: none;
		background: #eee;
		li {
			position: relative;
			float: left;
			padding: 10px 20px;
			cursor: pointer;
			&.active {
				background: #fff;
			}
		}
	}
}
</style>
