import { jsonProfile } from '../../../configurator/hicad/jsonProfile';
import { Canvas3D } from '../../Canvas3D';
import { ProfilesHorizontal3D } from '../ProfilesHorizontal3D';

export class HorizontalMainBeamBottom3D extends ProfilesHorizontal3D {
	objectName = 'HorizontalMainBeamBottom3D';
	rotationX = -180;
	constructor(x, y, length, params) {
		params.rotationX = -180;
		params.profilePosition = 'bottom';

		super(x, y, length, params);
		this.RecalculateProfilePositionByMultipleFloor();
		this.rotationX = -180;
		this.rotationY = 0;
		this.rotationZ = 0;

		this.parentEntityName = params.profileType + '_' + this.profilePosition + '_' + params.indexX + '_' + params.indexY;
	}
	draw(app) {
		super.draw(app, 'mainbeam');
	}
	RecalculateProfilePositionByMultipleFloor() {
		// Doorbouw kolom
		if (this.multipleFloors) {
			// if (this.rowPositions.includes(Raster.POSITION_TOP)) {
			// 	this.z -= columnWidth / 2;
			// }
			// if (this.placement === Raster.TYPE_MIDDLERASTER) {
			// 	this.z -= columnWidth / 2;
			// }
			// if (this.rowPositions.includes(Raster.POSITION_BOTTOM)) {
			// 	this.z -= columnWidth;
			// }
		}
	}

	// Convert HorizontalMainBeamBottom3D naar HiCADProfile, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_MAINBEAM, direction: jsonProfile.DIRECTION_HORIZONTAL, position: jsonProfile.POSITION_BOTTOM });
	}
}
