import { DrawObject } from './drawObject';

export class DrawText extends DrawObject {
	objectName = 'DrawText';
	text = '';
	font = 'bold 12px Arial';
	fontSize = 12;
	constructor(x, y, text = '', width = null, color = null, fillColor = null, lineColor = null, font = null, align = null, rotate = null, mouseDetect = null, object = null, objectParams = null) {
		super(x, y, color, fillColor, lineColor, mouseDetect, object, objectParams);
		if (font === null) {
			font = 'bold 12px Arial';
		}
		if (align === null) {
			align = 'start';
		}
		if (rotate === null) {
			rotate = false;
		}
		this.text = text;
		this.font = font;
		this.width = width;
		this.align = align;
		this.rotate = rotate;
	}
	draw(canvas) {
		if (this.visible === false) {
			return { x: -1, y: -1, width: -1, height: -1 };
		}

		let calculatedX = this.x.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallLeft : canvas.offsetLeft);
		let calculatedY = this.y.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallTop : canvas.offsetTop);

		const measureText = canvas.ctx.measureText(this.text);

		let calculatedWidth = measureText.width;

		canvas.ctx.fillStyle = this.fillColor;
		canvas.ctx.lineColor = this.lineColor;
		canvas.ctx.font = this.font;
		canvas.ctx.textAlign = this.align;

		if (this.rotate === true) {
			canvas.ctx.save();

			canvas.ctx.translate(calculatedX, calculatedY + this.width.scaledValue(canvas.scaleFactor) / 2);
			canvas.ctx.rotate(-Math.PI / 2);
			canvas.ctx.textAlign = 'center';
			canvas.ctx.fillText(this.text, 0, 0);
			canvas.ctx.restore();
			if (this.mouseDetect === true) {
				switch (this.align) {
					case 'center':
						this.mouseArea.minX = calculatedX - this.fontSize;
						this.mouseArea.minY = calculatedY + this.width.scaledValue(canvas.scaleFactor) / 2 - measureText.width / 2;
						this.mouseArea.maxX = this.mouseArea.minX + this.fontSize;
						this.mouseArea.maxY = this.mouseArea.minY + measureText.width;
						break;
					case 'left':
						this.mouseArea.minX = calculatedX - this.fontSize;
						this.mouseArea.minY = this.width.scaledValue(canvas.scaleFactor) / 2 - measureText.width;
						this.mouseArea.maxX = this.mouseArea.minX + this.fontSize;
						this.mouseArea.maxY = this.mouseArea.minY + measureText.width;
						break;
					case 'right':
						this.mouseArea.minX = calculatedX - measureText.width;
						this.mouseArea.minY = this.mouseArea.y = this.width.scaledValue(canvas.scaleFactor) / 2 + measureText.width;
						this.mouseArea.maxX = this.mouseArea.minX + this.fontSize;
						this.mouseArea.maxY = this.mouseArea.minY + measureText.width;
						break;
				}
			}
		} else {
			if (this.width !== null) {
				calculatedWidth = this.width.scaledValue(canvas.scaleFactor);
				calculatedX = calculatedX + calculatedWidth / 2;
			}
			if (this.mouseDetect === true) {
				switch (this.align) {
					case 'center':
						this.mouseArea.minX = calculatedX - measureText.width / 2;
						this.mouseArea.minY = calculatedY - this.fontSize;
						this.mouseArea.maxX = this.mouseArea.minX + measureText.width;
						this.mouseArea.maxY = this.mouseArea.minY + this.fontSize;
						break;
					case 'left':
						this.mouseArea.minX = calculatedX;
						this.mouseArea.minY = calculatedY - this.fontSize;
						this.mouseArea.maxX = this.mouseArea.minX + measureText.width;
						this.mouseArea.maxY = this.mouseArea.minY + this.fontSize;
						break;
					case 'right':
						this.mouseArea.minX = calculatedX - measureText.width;
						this.mouseArea.minY = calculatedY - this.fontSize;
						this.mouseArea.maxX = this.mouseArea.minX + measureText.width;
						this.mouseArea.maxY = this.mouseArea.minY + this.fontSize;

						break;
				}
			}
			canvas.ctx.fillText(this.text, calculatedX, calculatedY);
		}

		return { x: this.mouseArea.minX, y: this.mouseArea.minY, width: this.mouseArea.maxX - this.mouseArea.minX, height: this.mouseArea.maxY - this.mouseArea.minY };
	}
}
