import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { Model3D } from '../Model3D';

export class TorsionalSupportHorizontal3D extends Model3D {
	objectName = 'TorsionalSupportHorizontal3D';
	rotationX = 90;
	assetName = 'torsiesteun';
	fallBackName = 'torsiesteun';

	constructor(x, y, params) {
		super(x, params.yPosition, y, params);
	}
	draw(app) {
    this.ralColor = 1003;
		// Door de rotatie moeten we de hoogte van de torsisteun bij de Y optellen.
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		this.y -= deckingFinishHeight;

		this.y -= this.childBeamHeight / 2;
		this.y += 150 / 2;

		super.draw(app);
	}
}
