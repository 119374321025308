import { Data } from '../data';

export class DeckingFinishesModels extends Data {
	objectName = 'DeckingFinishesModels';
	url = 'DeckingFinishesModel';
	getByParameters(id, force, parameters, thenCallBack, catchCallBack, cacheId) {
		// samenstellen url
		let url = this.baseUrl + this.url + '?groupname=' + parameters.groupname + '&height=' + parameters.height + '&centerToCenter=' + parameters.centerToCenter;

		this.get(url, true, thenCallBack, catchCallBack, cacheId + '_' + id, false);
	}
}
