import { Data } from './data';
import { Languages } from './languages';
import { Store } from './store';

export class CrossBracings extends Data {
	objectName = 'CrossBracings';
	url = 'CrossBracings';
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		data.language = Languages.CURRENT.currentLanguage;

		if (data.companyId !== -1) {
			super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
		}
	}

	getItemBasedOnWidth(width) {
		if (this._list.list !== null && typeof this._list.list !== 'undefined') {
			let items = this._list.list.find((item) => width >= item.columnWidthFrom && width <= item.columnWidthTo);
			if (typeof items !== 'undefined' && items !== null) {
				return items;
			} else {
				// Er van uit gaande dat we een lijst krijgen met From 0 tot aan waarde.
				// En er vanuit gaande dat de laatste tot aan null is (grootste type bracing)
				// Omdat we niet op null kunnen checken halen we hier dan het grootste item op die in de lijst zit en geven die terug.
				items = this._list.list.find((item) => width >= item.columnWidthFrom);
				if (typeof items !== 'undefined' && items !== null) {
					return items;
				}
			}
		}
	}
}
