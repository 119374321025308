/* eslint-disable no-lonely-if */
/*
  Dit is de plaat die op de paaltjes komt, dus waar degene zich aan vast houd.
*/

import { Stair } from '../../../../configurator/stair';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class StairHandRailPlate3D extends Model3D {
	objectName = 'StairHandRailPlate3D';
	hoverTags = ['stairs'];
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.oid = params.stairData.material.handRail;
	}
	draw(app) {
		let handrailPoleHeight = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.handRailPost, 'height');
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.handRailPost, 'width');
		let plateDepth = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.handRail, 'depth');
		let plateHeight = Canvas3D.CURRENT.getModelDataByOid(this.stairData.material.handRail, 'height');

		let obj;
		switch (this.shared.upComing) {
			case Stair.UPCOMING_TOP:
				this.y += this.shared.yPos;
				obj = this.shared.objupComingTop.boom;
				Object.keys(obj).forEach((param) => {
					this[param] = obj[param];
				});
				this.y += handrailPoleHeight / (this.knee ? 2 : 1);
				this.z -= this.shared.deltaX;

				if (this.trapboomPosition === 'right') {
					this.x -= this.stairData.step.width;
					this.x += plateDepth;
				}
				break;

			case Stair.UPCOMING_LEFT:
				obj = this.shared.objupComingLeft.boom;
				Object.keys(obj).forEach((param) => {
					this[param] = obj[param];
				});

				this.y += handrailPoleHeight / (this.knee ? 2 : 1) + this.shared.yPosEnd;

				if (this.trapboomPosition === 'right') {
					this.z += this.stairData.step.width;
					// this.z += plateDepth;
					this.z -= poleWidth - plateDepth;
				} else {
					this.z += poleWidth;
				}
				break;

			case Stair.UPCOMING_RIGHT:
				obj = this.shared.objupComingRight.boom;
				Object.keys(obj).forEach((param) => {
					this[param] = obj[param];
				});
				this.y += handrailPoleHeight / (this.knee ? 2 : 1) + this.shared.yPosEnd;
				// this.z += plateDepth;
				this.y += plateHeight;
				if (this.trapboomPosition === 'right') {
					this.z += this.stairData.step.width;
					this.z -= plateDepth;
				}

				break;

			case Stair.UPCOMING_BOTTOM:
				obj = this.shared.objupComingBottom.boom;
				Object.keys(obj).forEach((param) => {
					this[param] = obj[param];
				});
				this.z += this.shared.deltaX;
				this.y += handrailPoleHeight / (this.knee ? 2 : 1);
				// this.y += plateHeight;
				this.y += this.shared.yPos;
				if (this.trapboomPosition === 'right') {
					this.x += this.stairData.step.width;
					this.x -= poleWidth;
				}
				break;
		}

		this.width = this.shared.diagonalLength;

		super.draw(app);
	}
}
