import { Configuration } from '../configurator/configuration';
import { Functions } from '../helpers/functions';
import { DimensionsHorizontal } from './dimensionsHorizontal';
import { DimensionsVertical } from './dimensionsVertical';
import { DrawText } from './drawText';
import { DrawValue } from './drawValue';

export class SizeHandle {
	objectName = 'SizeHandle';
	static MANUAL = 1;
	static TOTAL = 2;
	static COLOR = '#999999';
	startPosition = 0;
	id = '';
	_space = 50;

	_visible = true;
	horizontal = new DimensionsHorizontal();
	vertical = new DimensionsVertical();
	order = 0;
	resizable = true;
	drawGroupIdHorizontal = null;
	drawGroupIdVertical = null;
	get visible() {
		return this._visible;
	}
	set visible(value) {
		this._visible = value;
		this.redraw();
	}
	redraw() {
		if (typeof this._redraw === 'function') {
			this._redraw();
		}
	}
	registerEvents(events) {
		this.horizontal._onChange = events.onChangeHorizontal;
		this.vertical._onChange = events.onChangeVertical;
		this.horizontal._onChanged = events.onChangedHorizontal;
		this.vertical._onChanged = events.onChangedVertical;
		this.horizontal._movePossible = events.movePossible;
		this.vertical._movePossible = events.movePossible;
	}
	set(spansX, spansY) {
		this.horizontal.clear();
		this.vertical.clear();
		spansX.forEach((span) => {
			this.horizontal.push(span.value);
		});
		spansY.forEach((span) => {
			this.vertical.push(span.value);
		});
	}
	clear() {
		this.horizontal.clear();
		this.vertical.clear();
	}
	setByArray(dimensionsHorizontal, dimensionsVertical) {
		this.horizontal.clear();
		this.vertical.clear();
		dimensionsHorizontal.forEach((dimension) => {
			this.horizontal.push(dimension);
		});
		dimensionsVertical.forEach((dimension) => {
			this.vertical.push(dimension);
		});
	}

	// Aanroepen vanuit objecten met nieuwe versleep dimensions.
	// Update dan automatisch de drawobjecten in de correcte dimension.
	updateDrawObjects(dimensionsHorizontal, dimensionsVertical) {
		let groupObjectHorizontal = Configuration.CURRENT.canvas.drawObjects.findByDrawId(this.drawGroupIdHorizontal);
		let groupObjectVertical = Configuration.CURRENT.canvas.drawObjects.findByDrawId(this.drawGroupIdVertical);

		if (groupObjectHorizontal !== null && typeof groupObjectHorizontal !== 'undefined' && dimensionsHorizontal.length > 0) {
			this.horizontal.updateDrawObjects(groupObjectHorizontal, dimensionsHorizontal);
		}
		if (groupObjectVertical !== null && typeof groupObjectVertical !== 'undefined' && dimensionsVertical.length > 0) {
			this.vertical.updateDrawObjects(groupObjectVertical, dimensionsVertical);
		}
	}
	setHorizontalById(id, value) {
		this.horizontal.setById(id, value);
	}
	setVerticalById(id, value) {
		this.vertical.setById(id, value);
	}
	setReferences(parameters) {
		this._redraw = parameters.redraw;
	}
	removeReferences() {
		this._redraw = null;
	}
	get space() {
		if (this.visible === true) {
			return this._space;
		}
		return 0;
	}
	set space(value) {
		this._space = value;
	}

	constructor(id, order, resizable) {
		if (typeof id === 'undefined' || id === null) {
			this.id = Functions.uuidv4();
		} else {
			this.id = id;
		}
		if (typeof order !== 'undefined' || order !== null) {
			this.order = order;
		}
		if (typeof resizable !== 'undefined' && resizable !== null) {
			this.resizable = resizable;
		}
	}
	addDrawObjects(canvas, params = {}) {
		if (this.visible === true) {
			params.resizable = this.resizable;
			params.startPosition = this.startPosition;
			let horizontalGroup = this.horizontal.addDrawObjects(canvas, params);
			let verticalGroup = this.vertical.addDrawObjects(canvas, params);

			// Opslaan van group id zodat we bij drag de groep makkelijk kunnen aanpassen.
			this.drawGroupIdHorizontal = horizontalGroup.id;
			this.drawGroupIdVertical = verticalGroup.id;
		}
	}
	afterReconstruct() {
		// in oude configuraties stond de object-sizehandler op niet resizable. Tegenwoordig hebben we dat wel geimplementeerd.
		if (this.id === 'object') {
			this.resizable = true;
		}
	}
}
