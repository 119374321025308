export class ConstructionTool {
	objectName = 'ConstructionTool';
	id = -1;
	_name = '';
	_count = 0;
	_disabled = false;
	order = null;

	set name(value) {
		this._name = value;
		this.onChange();
	}
	get name() {
		return this._name;
	}

	set count(value) {
		this._count = value;
		this.onChange();
	}

	get count() {
		return this._count;
	}

	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}

	constructor(id, name, disabled, count, onChange) {
		this._onChange = onChange;
		this.id = id;
		this._name = name;
		this._count = count;
		this._disabled = disabled;
	}

	setReferences(params) {
		this._onChange = params.save;
	}
	removeReferences() {
		this._onChange = null;
	}
	getAmount() {
		return { id: this.id, disabled: this.disabled, count: this.count, name: this.name };
	}
}
