import { Configuration } from '../../../configurator/configuration';
import { Stair } from '../../../configurator/stair';
import { StairOutSide } from '../../../configurator/stairOutSide';
import { Canvas3D } from '../../Canvas3D';
import { IntermediateLandings } from '../../../configurator/intermediateLandings';
import { Store } from '../../../data/store';
import { HandRails } from '../../../configurator/handRails';
import { HandRail3D } from '../../handrail/handRail3D';

export class EndLandingHandRail3D {
	objectName = 'EndLandingHandRail3D';
	static SIDE_TOP = 1;
	static SIDE_RIGHT = 2;
	static SIDE_BOTTOM = 3;
	static SIDE_LEFT = 4;
	constructor(x, y, z, params) {
		const stairMaterial = params.stairData.material;
		this.poleWidth = Canvas3D.CURRENT.getModelDataByOid(stairMaterial.handRailPost, 'width');

		// Wanneer geen landing gevonden, dan doen we net of er wel 1 is die recht vooruit is, de logica is in dat geval hetzelfde.
		let firstLanding = params.stairData.intermediateLandings.intermediateLandings[0];
		if (firstLanding === null || typeof firstLanding === 'undefined') {
			firstLanding = {
				landingType: IntermediateLandings.straightAhead,
			};
		}
		// params.ralColor = Configuration.CURRENT.colors.handRail.ralColor;

		let paramPreset = {
			childFriendly: Store.CURRENT.handRails.isChildFriendly(Configuration.CURRENT.handRailType),
			handRailType: Configuration.CURRENT.handRailType,
		};

		switch (params.stairData.upComing) {
			case Stair.UPCOMING_TOP:
				// Wanneer links of rechts dan aan de onderkant horizontaal stuk handrail.
				if (params.stairData.position === StairOutSide.POSITION_LEFT || params.stairData.position === StairOutSide.POSITION_RIGHT) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + params.depth, paramPreset, HandRails.BOTTOM, params.width), Canvas3D.TYPE_HANDRAIL);
				}

				// Wanneer links of boven dan altijd aan de linkerkant een stuk handrail verticaal.
				if (params.stairData.position === StairOutSide.POSITION_LEFT || params.stairData.position === StairOutSide.POSITION_TOP) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, params.depth), Canvas3D.TYPE_HANDRAIL);
				}

				// Wanneer rechts of onder dan altijd een de rechterkant een stuk handrail verticaal.
				if (params.stairData.position === StairOutSide.POSITION_RIGHT || params.stairData.position === StairOutSide.POSITION_TOP) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width, y, z, paramPreset, HandRails.RIGHT, params.depth), Canvas3D.TYPE_HANDRAIL);
				}

				// Als geen 180 graden dan 2 kleine horizontale stukjes tekenen.
				if (firstLanding.landingType !== IntermediateLandings.oneeightyDegrees) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, (params.width - params.stairData.step.width) / 2 + this.poleWidth), Canvas3D.TYPE_HANDRAIL);
					Canvas3D.CURRENT.addDrawObject(
						new HandRail3D(
							x + (params.width - params.stairData.step.width) / 2 + params.stairData.step.width - this.poleWidth,
							y,
							z,
							paramPreset,
							HandRails.TOP,
							(params.width - params.stairData.step.width) / 2 + this.poleWidth,
						),
						Canvas3D.TYPE_HANDRAIL,
					);
				}
				// Als de volgende landing gedraaid is dan lijnt de trap altijd aan de onderkant uit.
				else if (firstLanding.landingType === IntermediateLandings.oneeightyDegrees) {
					Canvas3D.CURRENT.addDrawObject(
						new HandRail3D(x + params.stairData.step.width - this.poleWidth, y, z, paramPreset, HandRails.TOP, params.width - params.stairData.step.width + this.poleWidth),
						Canvas3D.TYPE_HANDRAIL,
					);
				}

				break;

			case Stair.UPCOMING_LEFT:
				// Wanneer boven of onder dan aan de rechterkant altijd een verticaal stuk handrail.
				if (params.stairData.position === StairOutSide.POSITION_TOP || params.stairData.position === StairOutSide.POSITION_BOTTOM) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width, y, z, paramPreset, HandRails.RIGHT, params.depth), Canvas3D.TYPE_HANDRAIL);
				}

				// Als de trap dan aan de bovenkant van de vloer staat of aan de linkerkant dan aan de bovenkant een horizontaal stuk.
				if (params.stairData.position === StairOutSide.POSITION_TOP || params.stairData.position === StairOutSide.POSITION_LEFT) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, params.width), Canvas3D.TYPE_HANDRAIL);
				}

				// Als de trap dan aan de onderkant van de vloer staat of aan de linkerkant dan aan de onderkant een horizontaal stuk.
				if (params.stairData.position === StairOutSide.POSITION_BOTTOM || params.stairData.position === StairOutSide.POSITION_LEFT) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + params.depth, paramPreset, HandRails.BOTTOM, params.width), Canvas3D.TYPE_HANDRAIL);
				}

				// Wanneer de landing naar links wijst dan altijd 2 kleine stukjes verticaal om de trap aan te sluiten.
				if (firstLanding.landingType !== IntermediateLandings.oneeightyDegrees) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, (params.depth - params.stairData.step.width) / 2 + this.poleWidth), Canvas3D.TYPE_HANDRAIL);
					Canvas3D.CURRENT.addDrawObject(
						new HandRail3D(
							x,
							y,
							z + params.depth - (params.depth - params.stairData.step.width) / 2 - this.poleWidth,
							paramPreset,
							HandRails.LEFT,
							(params.depth - params.stairData.step.width) / 2 + this.poleWidth,
						),
						Canvas3D.TYPE_HANDRAIL,
					);
				}
				// Als de volgende landing gedraaid is dan lijnt de trap altijd aan de onderkant uit.
				else if (firstLanding.landingType === IntermediateLandings.oneeightyDegrees) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, params.depth - params.stairData.step.width + this.poleWidth), Canvas3D.TYPE_HANDRAIL);
				}

				break;

			case Stair.UPCOMING_RIGHT:
				// Wanneer boven of onder dan aan de linkerkant altijd een verticaal stuk handrail.
				if (params.stairData.position === StairOutSide.POSITION_TOP || params.stairData.position === StairOutSide.POSITION_BOTTOM) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, params.depth), Canvas3D.TYPE_HANDRAIL);
				}

				// Als de trap dan aan de bovenkant van de vloer staat of aan de rechterkant dan aan de bovenkant een horizontaal stuk.
				if (params.stairData.position === StairOutSide.POSITION_TOP || params.stairData.position === StairOutSide.POSITION_RIGHT) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, params.width), Canvas3D.TYPE_HANDRAIL);
				}

				// Als de trap dan aan de onderkant van de vloer staat of aan de rechterkant dan aan de onderkant een horizontaal stuk.
				if (params.stairData.position === StairOutSide.POSITION_BOTTOM || params.stairData.position === StairOutSide.POSITION_RIGHT) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + params.depth, paramPreset, HandRails.BOTTOM, params.width), Canvas3D.TYPE_HANDRAIL);
				}

				if (firstLanding.landingType !== IntermediateLandings.oneeightyDegrees) {
					// BIj het naar rechts wijzen altijd aan de rechterkant 2 kleine verticale stukjes.
					Canvas3D.CURRENT.addDrawObject(
						new HandRail3D(x + params.width, y, z, paramPreset, HandRails.RIGHT, (params.depth - params.stairData.step.width) / 2 + this.poleWidth),
						Canvas3D.TYPE_HANDRAIL,
					);
					Canvas3D.CURRENT.addDrawObject(
						new HandRail3D(
							x + params.width,
							y,
							z + params.depth - (params.depth - params.stairData.step.width) / 2 - this.poleWidth,
							paramPreset,
							HandRails.RIGHT,
							(params.depth - params.stairData.step.width) / 2 + this.poleWidth,
						),
						Canvas3D.TYPE_HANDRAIL,
					);
				} else if (firstLanding.landingType === IntermediateLandings.oneeightyDegrees) {
					// Berekenen vanaf links tot aan trap positie.
					// Als de volgende landing gedraaid is dan lijnt de trap altijd rechts uit.
					Canvas3D.CURRENT.addDrawObject(
						new HandRail3D(x + params.width, y, z + params.stairData.step.width - this.poleWidth, paramPreset, HandRails.RIGHT, params.depth - params.stairData.step.width + this.poleWidth),
						Canvas3D.TYPE_HANDRAIL,
					);
				}

				break;

			case Stair.UPCOMING_BOTTOM:
				// Wanneer links of rechts dan aan de bovenkant horizontaal stuk handrail.
				if (params.stairData.position === StairOutSide.POSITION_LEFT || params.stairData.position === StairOutSide.POSITION_RIGHT) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, params.width), Canvas3D.TYPE_HANDRAIL);
				}

				// Wanneer links of onder dan altijd aan de linkerkant een stuk handrail verticaal.
				if (params.stairData.position === StairOutSide.POSITION_LEFT || params.stairData.position === StairOutSide.POSITION_BOTTOM) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, params.depth), Canvas3D.TYPE_HANDRAIL);
				}

				// Wanneer rechts of onder dan altijd een de rechterkant een stuk handrail verticaal.
				if (params.stairData.position === StairOutSide.POSITION_RIGHT || params.stairData.position === StairOutSide.POSITION_BOTTOM) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + params.width, y, z, paramPreset, HandRails.RIGHT, params.depth), Canvas3D.TYPE_HANDRAIL);
				}

				// Als geen 180 graden dan 2 kleine horizontale stukjes tekenen.
				if (firstLanding.landingType !== IntermediateLandings.oneeightyDegrees) {
					Canvas3D.CURRENT.addDrawObject(
						new HandRail3D(x, y, z + params.depth, paramPreset, HandRails.BOTTOM, (params.width - params.stairData.step.width) / 2 + this.poleWidth),
						Canvas3D.TYPE_HANDRAIL,
					);
					Canvas3D.CURRENT.addDrawObject(
						new HandRail3D(
							x + (params.width - params.stairData.step.width) / 2 + params.stairData.step.width - this.poleWidth,
							y,
							z + params.depth,
							paramPreset,
							HandRails.BOTTOM,
							(params.width - params.stairData.step.width) / 2 + this.poleWidth,
						),
						Canvas3D.TYPE_HANDRAIL,
					);
				}
				// Als de volgende landing gedraaid is dan lijnt de trap altijd aan de onderkant uit.
				else if (firstLanding.landingType === IntermediateLandings.oneeightyDegrees) {
					Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + params.depth, paramPreset, HandRails.BOTTOM, params.width - params.stairData.step.width + this.poleWidth), Canvas3D.TYPE_HANDRAIL);
				}

				break;
		}
	}
}
