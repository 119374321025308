import { Canvas } from '../draw/canvas';
import { Canvas3D } from '../draw3d/Canvas3D';
import { DrawObject } from '../draw/drawObject';
import { DrawObjects } from '../draw/drawObjects';
import { DrawText } from '../draw/drawText';
import { DrawValue } from '../draw/drawValue';
import { ObjectGroup } from '../draw/objectGroup';
import { Rectangle } from '../draw/rectangle';
import { Line } from '../draw/line';
import { SizeHandles } from '../draw/sizeHandles';
import { SizeHandle } from '../draw/sizeHandle';
import { SizeHandleManual } from '../draw/sizeHandleManual';
import { SizeHandleTotal } from '../draw/sizeHandleTotal';

import { DimensionsHorizontal } from '../draw/dimensionsHorizontal';
import { Dimensions } from '../draw/dimensions';
import { DimensionsVertical } from '../draw/dimensionsVertical';
// import { Store } from '../data/store';
import { SizeHandleTotalOutside } from '../draw/SizeHandleTotalOutside';
import { AssetManifest3D } from '../draw3d/assets/AssetManifest3D';
import { MiscellaneousArticles } from './miscellaneousArticles';
import { Services } from './services';
import { Etage } from './etage';
import { RemoveRaster } from './removeRaster';
import { RemoveRasters } from './removeRasters';
import { Stairs } from './stairs';
import { Stair } from './stair';
import { Raster } from './raster';
import { BraceColumns } from './braceColumns';
import { Bracings } from './bracings';
import { Bracing } from './bracing';
import { Columns } from './columns';
import { Column } from './column';
import { Etages } from './etages';
import { Overhang } from './overhang';
import { Profiles } from './profiles';
import { SpanArray } from './spanArray';
import { Span } from './span';
import { Configuration } from './configuration';
import { BraceColumn } from './braceColumn';
import { ColumnProtector } from './columnProtector';
import { Filters } from './filters';
import { DeckingFinish } from './deckingFinish';
import { HandRail } from './handRail';
import { HandRails } from './handRails';
import { SelectionListOption } from './selectionListOption';
import { TourStep } from './tourStep';
import { Tour } from './tour';
import { ColumnProtectors } from './columnProtectors';
import { Notification } from './notification';
import { StairTrimmings } from './stairTrimmings';
import { StairTrimming } from './stairTrimming';
import { ContextMenu } from './contextmenu';
import { IntermediateLanding } from './intermediateLanding';
import { IntermediateLandings } from './intermediateLandings';
import { StairWell } from './stairWell';
import { Standard } from './standards/standard';
import { NenEnIso14122 } from './standards/NenEnIso14122';
import { UkGeneralAccessStair } from './standards/UkGeneralAccessStair';
import { UkUtilityStair } from './standards/UkUtilityStair';
import { DynamicStandard } from './standards/dynamicStandard';
import { LogisStair } from './standards/LogisStair';
import { SpecialStair } from './standards/SpecialStair';
import { Errors } from './errors';
import { StairInFloor } from './stairInFloor';
import { StairOutSide } from './stairOutSide';
import { EndLanding } from './endLanding';
import { PalletGate } from './palletGate';
import { PalletGates } from './palletGates';
import { BuildingColumn } from './buildingColumn';
import { Hole } from './hole';
import { BuildingColumns } from './buildingColumns';
import { Holes } from './holes';

import { BuildingColumnTrimming } from './buildingColumnTrimming';
import { BuildingColumnTrimmings } from './buildingColumnTrimmings';

import { Construction } from './construction';
import { Colors } from './colors';
import { Color } from './color';
import { ConstructionTool } from './constructionTool';
import { MiscellaneousArticleTool } from './MiscellaneousArticleTool';
import { Transport } from './transport';
import { Address } from './address';
import { SiteSurvey } from './siteSurvey';
import { Plates } from './plates';
import { PortalBracings } from './portalBracings';
import { PortalBracing } from './portalBracing';
import { BracingRules } from './bracingRules';
import { Service } from './service';
import { Building } from './building';
import { BuildingObject } from './buildingObject';
import { Logging3D } from './logging3D';
import { HoleTrimming } from './holeTrimming';
import { HoleTrimmings } from './holeTrimmings';
import { OverSpan } from './overspan';

export class Serializer {
	types = [
		{ name: 'BraceColumn', object: BraceColumn },
		{ name: 'BraceColumns', object: BraceColumns },
		{ name: 'Bracing', object: Bracing },
		{ name: 'Bracings', object: Bracings },
		{ name: 'Column', object: Column },
		{ name: 'ColumnProtector', object: ColumnProtector },
		{ name: 'ColumnProtectors', object: ColumnProtectors },
		{ name: 'Columns', object: Columns },
		{ name: 'Configuration', object: Configuration },
		{ name: 'Etage', object: Etage },
		{ name: 'Etages', object: Etages },
		{ name: 'Filters', object: Filters },
		{ name: 'DeckingFinish', object: DeckingFinish },
		{ name: 'HandRail', object: HandRail },
		{ name: 'HandRails', object: HandRails },
		{ name: 'Overhang', object: Overhang },
		{ name: 'Profiles', object: Profiles },
		{ name: 'Raster', object: Raster },
		{ name: 'RemoveRaster', object: RemoveRaster },
		{ name: 'RemoveRasters', object: RemoveRasters },
		{ name: 'SelectionListOption', object: SelectionListOption },
		{ name: 'SizeHandles', object: SizeHandles },
		{ name: 'SizeHandle', object: SizeHandle },
		{ name: 'SizeHandleManual', object: SizeHandleManual },
		{ name: 'SizeHandleTotal', object: SizeHandleTotal },
		{ name: 'Dimensions', object: Dimensions },
		{ name: 'DimensionsHorizontal', object: DimensionsHorizontal },
		{ name: 'DimensionsVertical', object: DimensionsVertical },
		{ name: 'Span', object: Span },
		{ name: 'SpanArray', object: SpanArray },
		{ name: 'Stair', object: Stair },
		{ name: 'Stairs', object: Stairs },
		{ name: 'Canvas', object: Canvas },
		{ name: 'Canvas3D', object: Canvas3D },
		{ name: 'DrawObject', object: DrawObject },
		{ name: 'DrawObjects', object: DrawObjects },
		{ name: 'DrawText', object: DrawText },
		{ name: 'DrawValue', object: DrawValue },
		{ name: 'Line', object: Line },
		{ name: 'ObjectGroup', object: ObjectGroup },
		{ name: 'Rectangle', object: Rectangle },
		{ name: 'Tour', object: Tour },
		{ name: 'TourStep', object: TourStep },
		{ name: '', object: Array },
		{ name: 'Notification', object: Notification },
		{ name: 'StairTrimmings', object: StairTrimmings },
		{ name: 'StairTrimming', object: StairTrimming },
		{ name: 'ContextMenu', object: ContextMenu },
		{ name: 'IntermediateLanding', object: IntermediateLanding },
		{ name: 'IntermediateLandings', object: IntermediateLandings },
		{ name: 'StairWell', object: StairWell },
		{ name: 'Standard', object: Standard },
		{ name: 'NenEnIso14122', object: NenEnIso14122 },
		{ name: 'UkGeneralAccessStair', object: UkGeneralAccessStair },
		{ name: 'UkUtilityStair', object: UkUtilityStair },
		{ name: 'DynamicStandard', object: DynamicStandard },
		{ name: 'LogisStair', object: LogisStair },
		{ name: 'SpecialStair', object: SpecialStair },
		{ name: 'Errors', object: Errors },
		{ name: 'StairInFloor', object: StairInFloor },
		{ name: 'StairOutSide', object: StairOutSide },
		{ name: 'EndLanding', object: EndLanding },
		{ name: 'PalletGate', object: PalletGate },
		{ name: 'PalletGates', object: PalletGates },
		{ name: 'BuildingColumn', object: BuildingColumn },
		{ name: 'Hole', object: Hole },
		{ name: 'BuildingColumns', object: BuildingColumns },
		{ name: 'Holes', object: Holes },
		{ name: 'BuildingColumnTrimming', object: BuildingColumnTrimming },
		{ name: 'HoleTrimming', object: HoleTrimming },
		{ name: 'BuildingColumnTrimmings', object: BuildingColumnTrimmings },
		{ name: 'HoleTrimmings', object: HoleTrimmings },
		{ name: 'Construction', object: Construction },
		{ name: 'ConstructionTool', object: ConstructionTool },
		{ name: 'Colors', object: Colors },
		{ name: 'Color', object: Color },
		{ name: 'Transport', object: Transport },
		{ name: 'Services', object: Services },
		{ name: 'Address', object: Address },
		{ name: 'SizeHandleTotalOutside', object: SizeHandleTotalOutside },
		{ name: 'SiteSurvey', object: SiteSurvey },
		{ name: 'AssetManifest3D', object: AssetManifest3D },
		{ name: 'MiscellaneousArticles', object: MiscellaneousArticles },
		{ name: 'MiscellaneousArticleTool', object: MiscellaneousArticleTool },
		{ name: 'Plates', object: Plates },
		{ name: 'PortalBracings', object: PortalBracings },
		{ name: 'PortalBracing', object: PortalBracing },
		{ name: 'BracingRules', object: BracingRules },
		{ name: 'Service', object: Service },
		{ name: 'Building', object: Building },
		{ name: 'BuildingObject', object: BuildingObject },
		{ name: 'Logging3D', object: Logging3D },
		{ name: 'OverSpan', object: OverSpan },
	];

	markRecursive(object) {
		// anoint each object with a type index

		let idx = this.types.findIndex((t) => {
			return t.name === object.objectName || (t.name === '' && t.object.name === object.constructor.name);
		});
		if (idx !== -1) {
			object.typeIndex = this.types[idx].name !== '' ? this.types[idx].name : object.constructor.name;
			for (let key in object) {
				if (typeof object[key] !== 'undefined' && object[key] !== null && object.hasOwnProperty(key)) this.markRecursive(object[key]);
			}
		}
	}

	reconstructRecursive(object) {
		// kijk of er een typeindex aanwezig is
		if (object.hasOwnProperty('typeIndex')) {
			// zoek typeindex op en haal de index op
			let idx = this.types.findIndex((t) => {
				return t.name === object.typeIndex || (t.name === '' && t.object.name === object.constructor.name);
			});

			if (typeof this.types[idx] === 'undefined' || this.types[idx] === null) {
				console.log(object.typeIndex + ' not serialized');
				return;
			}

			// zoek bijbehorend type op
			let type = this.types[idx].object;
			// eslint-disable-next-line new-cap
			let obj = new type();

			if (typeof obj.reconstruct === 'function') {
				obj.reconstruct();
			}
			for (let key in object) {
				if (object.hasOwnProperty(key) && object[key] !== null) {
					obj[key] = this.reconstructRecursive(object[key]);
					if (typeof obj[key] === 'undefined' || obj[key] === null) {
						continue;
					}
					if (obj[key].constructor.name === 'Array') {
						obj[key].forEach((element, index) => {
							if (element !== null) {
								obj[key][index] = this.reconstructRecursive(element);

								if (typeof element.reconstruct === 'function') {
									element.reconstruct();
								}
							}
						});
					}
				}
			}
			if (typeof obj.afterReconstruct === 'function') {
				obj.afterReconstruct();
			}
			delete obj.typeIndex;
			return obj;
		}

		return object;
	}

	stringify(object) {
		let result = '';
		this.markRecursive(object);
		try {
			let copyObject = JSON.parse(JSON.stringify(object)); // copy maken om debuginfo te kunnen verwijderen zonder dat het invloed heeft op de weer te geven info
			this.cleanDebugInfo(copyObject);

			result = JSON.stringify(copyObject);
		} catch (exception) {
			console.log('Stringify error', exception);
		}
		return result;
	}
	cleanDebugInfo(object) {
		// verwijdert debugInfo
		let idx = this.types.findIndex((t) => {
			return t.name === object.objectName || (t.name === '' && t.object.name === object.constructor.name);
		});
		if (idx !== -1) {
			// alleen voor bekende objecten om loops te voorkomen

			for (let key in object) {
				if (key === 'debugInfo') {
					object[key] = {};
				}
				if (key === 'drawObjects') {
					object[key].objects = [];
				}

				if (typeof object[key] !== 'undefined' && object[key] !== null && object.hasOwnProperty(key)) this.cleanDebugInfo(object[key]);
			}
		}
	}
	parse(object) {
		let result = {};
		try {
			result = this.reconstructRecursive(JSON.parse(object));
		} catch (exception) {
			console.log('Parse error', exception);
		}

		return result;
	}
	clone(object) {
		this.markRecursive(object);

		let copy = JSON.parse(JSON.stringify(object));

		return this.reconstructRecursive(copy);
	}
}
