import { Store } from '../data/store';
import { DrawValue } from '../draw/drawValue';
import { Rectangle } from '../draw/rectangle';
import { MaterialAsset3D } from '../draw3d/assets/MaterialAsset3D';
import { RalColorAsset3D } from '../draw3d/assets/RalColorAsset3D';
import { Box3D } from '../draw3d/box3D';
import { Canvas3D } from '../draw3d/Canvas3D';
import { BuildingObject } from './buildingObject';
export class Building {
	objectName3d = 'Building';
	objectName = 'Building';
	buildingObjects = [];
	_showBuilding = false;
	_buildingAvailable = false;
	_onChange = null;
	constructor(onChange) {
		this._onChange = onChange;
		this.getJson();
	}

	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange();
		}
	}

	set showBuilding(value) {
		this._showBuilding = value;
		this.onChange();
	}

	get showBuilding() {
		return this._showBuilding;
	}

	set buildingAvailable(value) {
		if (value !== this._buildingAvailable) {
			this._buildingAvailable = value;
			this.onChange();
		}
	}

	get buildingAvailable() {
		return this._buildingAvailable;
	}

	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
	}

	getJson(callBack) {
		this.buildingObjects = [];
		Store.CURRENT.building.getBuilding(
			(res) => {
				if (res === '') {
					//geen building available
					this.buildingAvailable = false;
					return;
				}
				res.forEach((item, index) => {
					this.buildingObjects.push(new BuildingObject(item));
				});
				if (typeof callBack === 'function') {
					callBack();
				}
				this.buildingAvailable = true;
			},
			(err) => {
				// Hier komt hij in als er geen building is.
				// Alleen setter aanroepen als hij al op true staat, anders triggert de onChange wanneer dit niet nodig is.
				if (this.buildingAvailable !== null && typeof this.buildingAvailable !== 'undefined') {
					if (this.buildingAvailable === true) {
						this.buildingAvailable = false;
					}
				}
			},
		);
	}
	addDrawObjects3d(canvas3d) {
		if (this.showBuilding) {
			this.getJson((res) => {
				this.buildingObjects.forEach((object, index) => {
					object.drawY = object.location.Z;
					canvas3d.addDrawObject(new Box3D(object.location.X, object.location.Y, object), Canvas3D.TYPE_BUILDING);
				});
			});
		}
	}

	addDrawObjects(canvas) {
		if (this.showBuilding) {
			this.buildingObjects.forEach((object, index) => {
				canvas.addDrawObject(
					new Rectangle(new DrawValue(object.x), new DrawValue(object.z), new DrawValue(object.width), new DrawValue(object.depth), 'grey', 'transparent', 'grey', false, {}, {}, false, true, true),
				);
			});
		}
	}

	create3DAssets(canvas3d) {
		// Eventueel om de muur van andere onderdelen te kunnen zien deze een kleur of texture geven.
		canvas3d.addAsset(new MaterialAsset3D('concrete-wall.jpg', null, { folder: 'concrete-wall' }));
		canvas3d.addAsset(new RalColorAsset3D('7036'));
	}
}
