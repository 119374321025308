import { PalletGate3D } from './PalletGate3D';

export class PalletGateRight3D extends PalletGate3D {
	objectName = 'PalletGateRight3D';
	rotationY = 90;
	draw(app) {
		// Door rotatie op Y as de drawWidth bij de Z optellen.
		this.z += this.info.drawWidth;
		this.x += this.info.depth;
		// Dikte omgekeerde strip aan de buitenkant.
		this.x += 5;
		super.draw(app);
	}
}
