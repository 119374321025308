import { Store } from '../../data/store';
import { Canvas3D } from '../Canvas3D';
import { Asset3D } from './Asset3D';

export class ProfileAsset3D extends Asset3D {
	objectName = 'ProfileAsset3D';
	type = 'model';
	constructor(assetName, oid, params = null) {
		super(assetName, oid, null);
		this.oid = oid;
	}
	load(thenCallBack) {
		Store.CURRENT.profileModels.getById(
			this.oid,
			false,
			(response) => {
				this.handleResponse(response, thenCallBack);
			},
			(error) => {
				console.log(error);
				// If article not found
				if (error.response.status === 404) {
					console.log('MODEL NIET IN ERP GEVONDEN');
					// this.checkFallBackModel(this.fallBackName, thenCallBack);
				}
			},
			this.assetName,
		);
	}

	handleResponse(response, thenCallBack) {
		try {
			if (typeof Canvas3D.CURRENT.app.assets !== 'undefined') {
				if ((typeof response !== 'object' && Object.keys(response.model).length === 0) || response.model === null) {
					// Als het model niet geladen kan worden dan een default hier voor inladen
					this.checkFallBackModel(this.fallBackName, thenCallBack);
					// Canvas3D.CURRENT.stopLoading3dAsset();
				} else if (response.model.length > 0) {
					response.model.forEach((model, index) => {
						let fallBackName;
						// lengte ingevoerd maar geen model erbij
						if (model.model !== null) {
							// Als er een string teruggegeven word tussenstap maken.
							if (typeof model.model === 'string') {
								let parsedModel = JSON.parse(model.model);
								model.model = parsedModel;
							}

							let convertedDetails = { width: response.details.depth, height: response.details.height, depth: response.details.width, thickness : response.details.thickness };
							this.modelData = convertedDetails;

							let blob = new Blob([JSON.stringify(model.model)]);
							let url = URL.createObjectURL(blob);

							let filename = this.assetName + '_' + model.length + '.json';
							this.loadModel(filename, fallBackName, url, thenCallBack, index + 1 === response.model.length);
						}
					});
				}
			}
		} catch (exception) {
			if (typeof thenCallBack === 'function') {
				thenCallBack();
			}
		}
	}

	loadModel(assetName, fallBackName, url, thenCallBack, useCallBack) {
		Canvas3D.CURRENT.app.assets.loadFromUrlAndFilename(url, assetName, 'model', (err, asset) => {
			asset.modelData = this.modelData; // Modeldata vanuit ERP
			asset.oid = this.oid;
			if (err || !asset) {
				console.log('fout met laden model of texture:', err);
			}
			if (useCallBack) {
				if (typeof thenCallBack === 'function') {
					thenCallBack();
				}
			}
		});
	}
}
