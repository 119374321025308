import { DimensionHorizontal3D } from './dimensionHorizontal3D';

export class DimensionHorizontalTop3D extends DimensionHorizontal3D {
	objectName = 'DimensionHorizontal3D';
	rotationX = -90;
	rotationY = 180;
	draw() {
		// Functie in Dimension3D die de globale entity van dimension tekent.
		super.draw();

		// Top is de tekst altijd naar boven wijzend / 180 graden naar boven geroteerd.
		this.dimensionEntity.setLocalPosition(this.x + this.width / 1000, this.y, this.z);

		super.addEntities();
	}
}
