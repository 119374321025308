import { Mathematic } from '../helpers/mathematic';
import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { Stairs } from './stairs';
import { Stair } from './stair';
import { StairTrimmings } from './stairTrimmings';

import { Configuration } from './configuration';
import { Columns } from './columns';
import { Profiles } from './profiles';
import { IntermediateLandings } from './intermediateLandings';

export class StairWell {
	objectName = 'StairWell';
	depth = 0;
	width = 0;
	startX = 0;
	startY = 0;
	stairShift = { x: 0, y: 0 };
	active = true;
	trimmings = new StairTrimmings();
	onRasterChanged(params) {}
	setTrimming(startX, startY, stairDrawWidth, stairDrawHeight, stairId, addColumns = true) {
		this.trimmings.setTrimming(startX, startY, stairDrawWidth, stairDrawHeight, stairId, addColumns);
	}
	setMinMax(min, max, rectangle) {
		if (min.x > rectangle.leftTop.x) {
			min.x = rectangle.leftTop.x;
		}
		if (min.y > rectangle.leftTop.y) {
			min.y = rectangle.leftTop.y;
		}
		if (max.x < rectangle.rightBottom.x) {
			max.x = rectangle.rightBottom.x;
		}
		if (max.y < rectangle.rightBottom.y) {
			max.y = rectangle.rightBottom.y;
		}
	}
	calculateAmount(params) {
		this.trimmings.calculateAmount(params);
	}

	calculate(startX, startY, etageHeight, packetHeight, stepWidth, angle, upComing, intermediateLandings, place = 'inFloor') {
		let etageUnder = etageHeight - packetHeight;
		this.startX = startX;
		this.startY = startY;

		if (intermediateLandings.length === 0) {
			// geen tussenbordes

			let depth = Mathematic.calculateDepth(Math.min(etageUnder, Stairs.PASSAGE_HEIGHT + packetHeight), angle);
			let width = stepWidth + (place === 'inFloor' ? Stairs.PASSAGE_WIDTH : 0);

			switch (upComing) {
				case Stair.UPCOMING_TOP:
					this.depth = depth;
					this.width = width;

					this.stairShift = { x: Stairs.PASSAGE_WIDTH / 2, y: 0 };
					break;
				case Stair.UPCOMING_RIGHT:
					this.depth = width;
					this.width = depth;
					this.stairShift = { x: 0, y: Stairs.PASSAGE_WIDTH / 2 };
					break;
				case Stair.UPCOMING_BOTTOM:
					this.depth = depth;
					this.width = width;
					this.stairShift = { x: Stairs.PASSAGE_WIDTH / 2, y: 0 };
					break;
				case Stair.UPCOMING_LEFT:
					this.depth = width;
					this.width = depth;
					this.stairShift = { x: 0, y: Stairs.PASSAGE_WIDTH / 2 };
					break;
			}

			return;
		}
		let stairWidth = stepWidth + Stairs.PASSAGE_WIDTH;
		this.depth = 0;
		this.width = 0;
		let lastUpComing = Stair.toOppositeUpComing(upComing);

		let min = { x: this.startX, y: this.startY };
		let max = { x: this.startX, y: this.startY };

		let point = { x: this.startX, y: this.startY };

		let firstLanding = intermediateLandings.get(0);
		let stairDepth = Mathematic.calculateDepth(Math.min(etageHeight - firstLanding.height, Stairs.PASSAGE_HEIGHT), angle);

		switch (upComing) {
			case Stair.UPCOMING_TOP:
				if (min.x > point.x) {
					min.x = point.x;
				}
				if (min.y > point.y) {
					min.y = point.y;
				}
				if (max.x < point.x + stairWidth) {
					max.x = point.x + stairWidth;
				}
				if (max.y < point.y + stairDepth) {
					max.y = point.y + stairDepth;
				}
				point.x = this.startX;
				point.y = this.startY + stairDepth;
				break;
			case Stair.UPCOMING_RIGHT:
				if (min.x > point.x - stairDepth) {
					min.x = point.x - stairDepth;
				}
				if (min.y > point.y) {
					min.y = point.y;
				}
				if (max.x < point.x) {
					max.x = point.x;
				}
				if (max.y < point.y + stairWidth) {
					max.y = point.y + stairWidth;
				}
				point.x = this.startX - stairDepth;
				point.y = this.startY;
				break;
			case Stair.UPCOMING_BOTTOM:
				if (min.x > point.x) {
					min.x = point.x;
				}
				if (min.y > point.y - stairDepth) {
					min.y = point.y - stairDepth;
				}
				if (max.x < point.x + stairWidth) {
					max.x = point.x + stairWidth;
				}
				if (max.y < point.y) {
					max.y = point.y;
				}
				point.x = this.startX;
				point.y = this.startY - stairDepth;
				break;
			case Stair.UPCOMING_LEFT:
				if (min.x > point.x) {
					min.x = point.x;
				}
				if (min.y > point.y) {
					min.y = point.y;
				}
				if (max.x < point.x + stairDepth) {
					max.x = point.x + stairDepth;
				}
				if (max.y < point.y + stairWidth) {
					max.y = point.y + stairWidth;
				}

				point.x = this.startX + stairDepth;
				point.y = this.startY;

				break;
		}

		let sameDirection = true;
		intermediateLandings.get().forEach((landing, index) => {
			if (etageHeight - landing.height <= Stairs.PASSAGE_HEIGHT) {
				let shiftWidth = (landing.width - stepWidth) / 2;
				let shiftDepth = (landing.depth - stepWidth) / 2; // afhankelijk van landing.upcoming een van beide gebruiken

				//startpositie van de landing is daar waar de vorige trap eindigde
				let landingBoundaries = { leftTop: { x: point.x, y: point.y }, rightTop: { x: point.x, y: point.y }, leftBottom: { x: point.x, y: point.y }, rightBottom: { x: point.x, y: point.y } };

				// landinghoogte is vanaf de grond tot aan de landing

				//kijk of er een volgende landing is
				let nextLanding = intermediateLandings.get(index + 1);
				let prevLanding = intermediateLandings.get(index - 1);

				if (prevLanding === null || typeof prevLanding === 'undefined') {
					prevLanding = {
						width: 0,
						depth: 0,
					};
				}

				let useHeight = landing.height;
				//als er een volgende landing is dan is de hoogte het verschil tussen deze landing en de volgende.
				if (typeof nextLanding !== 'undefined' && nextLanding !== null) {
					useHeight -= nextLanding.height;
				}

				let stairDepth = 0;

				// bereken of deze landing + de trap ernaar toe volledig een trapgat nodig heeft dan die in stairDepth opslaan
				// als niet volledig alleen het deel dat hoger ligt dan PASSAGE_HEIGHT meerekenen
				if (etageHeight - landing.height + useHeight < Stairs.PASSAGE_HEIGHT) {
					//alles meerekenen
					stairDepth = Mathematic.calculateDepth(useHeight, angle);
				} else {
					//meerekenen deel dat hoger ligt dan PASSAGE_HEIGHT
					stairDepth = Mathematic.calculateDepth(Stairs.PASSAGE_HEIGHT - (etageHeight - landing.height), angle);
				}

				let stairWidth = stepWidth;
				if (lastUpComing !== landing.upComing && landing.landingType !== IntermediateLandings.oneeightyDegrees) {
					sameDirection = false;
				}
				switch (
					lastUpComing // inverse van inFloor
				) {
					case Stair.UPCOMING_TOP:
						if (landing.upComing === Stair.UPCOMING_LEFT || landing.upComing === Stair.UPCOMING_RIGHT) {
							// breedte en diepte is afhankelijk van upComing landing. Dus als die dwars op last upcoming staat breedte en diepte omdraaien
							landingBoundaries.leftTop.x -= shiftDepth;
							landingBoundaries.leftTop.y -= landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.rightTop.x = landingBoundaries.leftTop.x + landing.depth;
							landingBoundaries.rightTop.y -= landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.leftBottom.x -= shiftDepth;
							landingBoundaries.rightBottom.x = landingBoundaries.leftBottom.x + landing.depth;
						} else if (landing.upComing === lastUpComing) {
							// Rechtuit
							landingBoundaries.leftTop.x -= shiftWidth;
							landingBoundaries.leftTop.y -= landing.depth;
							landingBoundaries.rightTop.x = landingBoundaries.leftTop.x + landing.width;
							landingBoundaries.rightTop.y -= landing.depth;
							landingBoundaries.leftBottom.x -= shiftWidth;
							landingBoundaries.rightBottom.x = landingBoundaries.leftBottom.x + landing.width;
						} else {
							// 180 graden
							// Naar boven = naar rechts tekenen, dan staat leftTop X en leftBottom X al goed.
							landingBoundaries.rightBottom.x = landingBoundaries.leftBottom.x + landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.rightTop.x = landingBoundaries.leftTop.x + landing.width + Stairs.PASSAGE_WIDTH;

							// Naar boven dus de left en Right Top Y word aangepast, de bottom is al juist van zichzelf
							landingBoundaries.leftTop.y -= landing.depth;
							landingBoundaries.rightTop.y -= landing.depth;
						}
						break;
					case Stair.UPCOMING_RIGHT:
						if (landing.upComing === Stair.UPCOMING_TOP || landing.upComing === Stair.UPCOMING_BOTTOM) {
							// breedte en diepte is afhankelijk van upComing landing. Dus als die dwars op last upcoming staat breedte en diepte omdraaien
							landingBoundaries.leftTop.y -= shiftDepth;
							landingBoundaries.rightTop.x += landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.rightTop.y -= shiftDepth;
							landingBoundaries.leftBottom.y = landingBoundaries.leftTop.y + landing.depth;
							landingBoundaries.rightBottom.x += landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.rightBottom.y = landingBoundaries.rightTop.y + landing.depth;
						} else if (landing.upComing === lastUpComing) {
							// Rechtuit
							landingBoundaries.leftTop.y -= shiftWidth;
							landingBoundaries.rightTop.x += landing.depth;
							landingBoundaries.rightTop.y -= shiftWidth;
							landingBoundaries.leftBottom.y = landingBoundaries.leftTop.y + landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.rightBottom.x += landing.depth;
							landingBoundaries.rightBottom.y = landingBoundaries.rightTop.y + landing.width + Stairs.PASSAGE_WIDTH;
						} else {
							// 180 Graden
							// Naar onderen tekenen
							landingBoundaries.rightTop.x += landing.depth;
							landingBoundaries.rightBottom.x += landing.depth;

							landingBoundaries.leftBottom.y = landingBoundaries.leftTop.y + landing.width;
							landingBoundaries.rightBottom.y = landingBoundaries.rightTop.y + landing.width;
						}
						break;
					case Stair.UPCOMING_BOTTOM:
						if (landing.upComing === Stair.UPCOMING_LEFT || landing.upComing === Stair.UPCOMING_RIGHT) {
							// breedte en diepte is afhankelijk van upComing landing. Dus als die dwars op last upcoming staat breedte en diepte omdraaien
							landingBoundaries.leftTop.x -= shiftDepth;
							landingBoundaries.rightTop.x = landingBoundaries.leftTop.x + landing.depth;
							landingBoundaries.leftBottom.x -= shiftDepth;
							landingBoundaries.leftBottom.y += landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.rightBottom.x = landingBoundaries.leftBottom.x + landing.depth;
							landingBoundaries.rightBottom.y += landing.width + Stairs.PASSAGE_WIDTH;
						} else if (landing.upComing === lastUpComing) {
							// Rechtuit
							// Wanneer rechtuit dan in het midden plaatsen.
							landingBoundaries.leftTop.x -= shiftWidth;
							landingBoundaries.leftBottom.x -= shiftWidth;

							// Rechts is dan gewoon left.x + breedte landing.
							landingBoundaries.rightTop.x = landingBoundaries.leftTop.x + landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.rightBottom.x = landingBoundaries.leftBottom.x + landing.width + Stairs.PASSAGE_WIDTH;

							// Nieuwe Y is dan plus de landing diepte.
							landingBoundaries.leftBottom.y += landing.depth;
							landingBoundaries.rightBottom.y += landing.depth;
						} else {
							// 180 graden
							// Naar onder = naar links tekenen,
							// Landingboundaries zijn vorige stair, landing width eraf en stepWidth erbij om landing naar links te verplaatsen.
							landingBoundaries.leftBottom.x = landingBoundaries.leftBottom.x - landing.width + prevLanding.width;
							landingBoundaries.leftTop.x = landingBoundaries.leftTop.x - landing.width + prevLanding.width;

							landingBoundaries.rightBottom.x = landingBoundaries.leftBottom.x + landing.width;
							landingBoundaries.rightTop.x = landingBoundaries.leftTop.x + landing.width;

							// Nog steeds naar onder dus Left en Rightbottom Y moet de depth van de landing bij, de top is al juist van zichzelf.
							landingBoundaries.leftBottom.y += landing.depth;
							landingBoundaries.rightBottom.y += landing.depth;
						}
						break;
					case Stair.UPCOMING_LEFT:
						if (landing.upComing === Stair.UPCOMING_TOP || landing.upComing === Stair.UPCOMING_BOTTOM) {
							// breedte en diepte is afhankelijk van upComing landing. Dus als die dwars op last upcoming staat breedte en diepte omdraaien
							landingBoundaries.leftTop.x -= landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.leftTop.y -= shiftDepth;
							landingBoundaries.rightTop.y -= shiftDepth;
							landingBoundaries.leftBottom.x -= landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.leftBottom.y = landingBoundaries.leftTop.y + landing.depth;
							landingBoundaries.rightBottom.y = landingBoundaries.rightTop.y + landing.depth;
						} else if (landing.upComing === lastUpComing) {
							// Rechtuit
							// Nieuwe X richting links bepalen met landing depth
							landingBoundaries.leftTop.x -= landing.depth;
							landingBoundaries.leftBottom.x -= landing.depth;

							// LeftTop met shiftWidth bepalen
							landingBoundaries.leftTop.y -= shiftWidth;
							landingBoundaries.rightTop.y -= shiftWidth;

							// Bottom positie bepalen, daar zit gelijk shiftWidth al in omdat we die hierboven al toepassen
							landingBoundaries.leftBottom.y = landingBoundaries.leftTop.y + landing.width + Stairs.PASSAGE_WIDTH;
							landingBoundaries.rightBottom.y = landingBoundaries.rightTop.y + landing.width + Stairs.PASSAGE_WIDTH;
						} else {
							// 180 Graden
							// Diepte van landing naar links
							landingBoundaries.leftTop.x -= landing.depth;
							landingBoundaries.leftBottom.x -= landing.depth;

							// Naar links is naar boven tekenen
							landingBoundaries.leftTop.y = landingBoundaries.leftBottom.y - landing.width + prevLanding.width;
							landingBoundaries.rightTop.y = landingBoundaries.rightBottom.y - landing.width + prevLanding.width;
						}
						break;
				}
				this.setMinMax(min, max, landingBoundaries);

				// Kijk of landing tussen min en max past. Anders min en max aanpassen. Min en max is dan de stairwell
				switch (landing.upComing) {
					// bij een landing is upcoming anders dan bij inFloor
					case Stair.UPCOMING_TOP:
						if (min.x > landingBoundaries.leftTop.x) {
							min.x = landingBoundaries.leftTop.x;
						}
						if (min.y > landingBoundaries.leftTop.y - stairDepth) {
							min.y = landingBoundaries.leftTop.y - stairDepth;
						}
						if (max.x < landingBoundaries.leftTop.x + stairWidth) {
							max.x = landingBoundaries.leftTop.x + stairWidth;
						}
						if (max.y < landingBoundaries.leftTop.y) {
							max.y = landingBoundaries.leftTop.y;
						}
						point.x = landingBoundaries.leftTop.x;
						point.y = landingBoundaries.leftTop.y - stairDepth;
						break;
					case Stair.UPCOMING_RIGHT:
						if (min.x > landingBoundaries.rightTop.x) {
							min.x = landingBoundaries.rightTop.x;
						}
						if (min.y > landingBoundaries.rightTop.y) {
							min.y = landingBoundaries.rightTop.y;
						}
						if (max.x < landingBoundaries.rightTop.x + stairDepth) {
							max.x = landingBoundaries.rightTop.x + stairDepth;
						}
						if (max.y < landingBoundaries.rightTop.y + stairWidth) {
							max.y = landingBoundaries.rightTop.y + stairWidth;
						}
						point.x = landingBoundaries.rightTop.x + stairDepth;
						point.y = landingBoundaries.rightTop.y;

						break;
					case Stair.UPCOMING_BOTTOM:
						if (min.x > landingBoundaries.leftBottom.x) {
							min.x = landingBoundaries.leftBottom.x;
						}
						if (min.y > landingBoundaries.leftBottom.y) {
							min.y = landingBoundaries.leftBottom.y;
						}
						if (max.x < landingBoundaries.leftBottom.x + stairWidth) {
							max.x = landingBoundaries.leftBottom.x + stairWidth;
						}

						if (max.y < landingBoundaries.leftBottom.y + stairDepth) {
							max.y = landingBoundaries.leftBottom.y + stairDepth;
						}

						point.x = landingBoundaries.leftBottom.x;
						point.y = landingBoundaries.leftBottom.y + stairDepth;
						break;
					case Stair.UPCOMING_LEFT:
						if (min.x > landingBoundaries.leftTop.x - stairDepth) {
							min.x = landingBoundaries.leftTop.x - stairDepth;
						}
						if (min.y > landingBoundaries.leftTop.y) {
							min.y = landingBoundaries.leftTop.y;
						}
						if (max.x < landingBoundaries.leftTop.x) {
							max.x = landingBoundaries.leftTop.x;
						}
						if (max.y < landingBoundaries.leftTop.y + stairWidth) {
							max.y = landingBoundaries.leftTop.y + stairWidth;
						}

						point.x = landingBoundaries.leftTop.x - stairDepth;
						point.y = landingBoundaries.leftTop.y;

						break;
				}
			}
			lastUpComing = landing.upComing;
		});
		this.stairShift = { x: this.startX - min.x, y: this.startY - min.y };
		this.width = max.x - min.x;
		this.depth = max.y - min.y;
		if (upComing === Stair.UPCOMING_TOP || upComing === Stair.UPCOMING_BOTTOM) {
			// Als de bordessen in dezelfde richting lopen als de trao komt er vanuit de bordessen al extra ruimte
			// als dat niet het geval is moet er extra ruimte komen in de breedte om ruimte tussen trap en trapgat te creeeren.
			// stairShift klopt hier wel.

			if (sameDirection === false) {
				this.width += Stairs.PASSAGE_WIDTH;
			}

			this.stairShift.x += Stairs.PASSAGE_WIDTH / 2;
		} else {
			if (sameDirection === false) {
				this.depth += Stairs.PASSAGE_WIDTH;
			}

			this.stairShift.y += Stairs.PASSAGE_WIDTH / 2;
		}
	}

	startStair() {
		return { x: this.startX + this.stairShift.x, y: this.startY + this.stairShift.y };
	}
	addDrawObjects(canvas, params) {
		if (this.active === false) {
			return [];
		}
		let stairWellGroup = [];
		let stairWell;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			stairWell = new Rectangle(
				new DrawValue(this.startX),
				new DrawValue(this.startY, Columns.COLUMN_SIZE / 2 + 1), // +1 voor de kolomlijn
				new DrawValue(this.width),
				new DrawValue(this.depth, -Columns.COLUMN_SIZE - 2), // +2 voor de kolomlijn boven en onder
				this.hasErrors === true ? Stairs.COLORS.stairCollisions : Stairs.COLORS.stairWell,
				Stairs.COLORS.stairWell,
				null,
				true,
				params.stairInFloorObject,
				{ type: 'stairWell', color: Stairs.COLORS.stairWell },
			);
		} else {
			stairWell = new Rectangle(
				new DrawValue(this.startX, Columns.COLUMN_SIZE / 2 + 1), // +1 voor de kolomlijn
				new DrawValue(this.startY),
				new DrawValue(this.width, -Columns.COLUMN_SIZE - 1), // +2 voor de kolomlijn boven en onder
				new DrawValue(this.depth),
				this.hasErrors === true ? Stairs.COLORS.stairCollisions : Stairs.COLORS.stairWell,
				Stairs.COLORS.stairWell,
				null,
				true,
				params.stairInFloorObject,
				{ type: 'stairWell', color: Stairs.COLORS.stairWell },
			);
		}
		stairWell.colorFromParent = false;
		stairWellGroup.push(stairWell);
		if (params.rasterY !== null) {
			params.rasterStart = Configuration.CURRENT.raster.spansY.getSize(params.rasterY - 1); // zolang niet hoofdbalk doorsnijdt
			params.rasterLength = Configuration.CURRENT.raster.spansY.get(params.rasterY);
		} else {
			params.rasterStart = Configuration.CURRENT.raster.spansX.getSize(params.rasterX - 1); // zolang niet hoofdbalk doorsnijdt
			params.rasterLength = Configuration.CURRENT.raster.spansX.get(params.rasterX);
		}
		params.stairDrawWidth = this.width;
		params.startX = this.startX;
		params.startY = this.startY;
		params.stairDrawHeight = this.depth;

		if (params.rasterLength !== null) {
			this.trimmings.addDrawObjects(canvas, params).forEach((drawObject) => {
				stairWellGroup.push(drawObject);
			});
		}

		return stairWellGroup;
	}
}
