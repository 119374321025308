import { TourStep } from './tourStep';

export class Tour {
	objectName = 'Tour';
	steps = [
		new TourStep('location'),
		new TourStep('properties'),
		new TourStep('new'),
		new TourStep('grid'),
		new TourStep('etages'),
		new TourStep('etageProperties'),
		new TourStep('accessoiries'),
		new TourStep('color'),
		new TourStep('view'),
		new TourStep('preferences'),
		new TourStep('language'),
		new TourStep('info'),
		new TourStep('price'),
		new TourStep('dashboard'),
	];
}
