import { StairTrimming } from './stairTrimming';
import { Configuration } from './configuration';
import { Profiles } from './profiles';
import { Columns } from './columns';

export class StairTrimmings {
	static LEFT = 0;
	static TOP = 1;
	static RIGHT = 2;
	static BOTTOM = 3;
	objectName = 'StairTrimmings';
	trimmings = [];
	addDrawObjects(canvas, params) {
		let group = [];
		this.trimmings.forEach((trimming) => {
			const drawObject = trimming.addDrawObjects(canvas, params);
			if (drawObject !== null) {
				group.push(drawObject);
			}
		});
		return group;
	}
	get() {
		return this.trimmings;
	}
	setTrimming(x, y, width, depth, stairId, addColumns) {
		// kijk startX + width of startY+height zoek dan begin en eindraster op en voorzie die + tussenliggende rasters van trimming boven/onder of links/rechts
		// afhankelijk van horizontaal of verticaal
		// kijken naar verschillende rasters x of y horizontaal dan naar y kijken verticaal dan naar x
		// lange toevoegen op basis van die verschillende rasters boven en onder
		// andere korte rasters
		// een van beide lange raster binnen 200 van hoofdbalk dan niet

		this.trimmings = [];

		let columns = Configuration.CURRENT.columns;
		columns.removeByName(stairId);
		let columnSize = Columns.COLUMN_SIZE / 2 / Configuration.CURRENT.canvas.scaleFactor;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y);
			let bottomLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y + depth);
			let endRaster = bottomLeftRaster.y;
			if (bottomLeftRaster.y === -1) {
				endRaster = Configuration.CURRENT.raster.spansY.length;
			}

			let trimmingTopNeeded = y > Configuration.CURRENT.raster.spansY.getSize(topLeftRaster.y - 1) + 200 && y < Configuration.CURRENT.raster.spansY.getSize(topLeftRaster.y) - 200;

			let trimmingBottomNeeded =
				y + depth < Configuration.CURRENT.raster.spansY.getSize(bottomLeftRaster.y) - 200 && y + depth > Configuration.CURRENT.raster.spansY.getSize(bottomLeftRaster.y - 1) + 200;

			// links en rechts maar 1x
			if (trimmingTopNeeded === true) {
				// boven alleen een volledige trimming als hij niet op de rand staat
				this.trimmings.push(new StairTrimming({ startX: x, startY: y, endX: x + width, endY: y, position: StairTrimmings.TOP })); // trimming top
			}
			if (trimmingBottomNeeded === true) {
				// boven alleen een volledige trimming als hij niet op de rand staat
				this.trimmings.push(new StairTrimming({ startX: x, startY: y + depth, endX: x + width, endY: y + depth, position: StairTrimmings.BOTTOM })); // trimming bottom
			}
			for (let raster = topLeftRaster.y; raster <= endRaster; raster++) {
				// boven en onder voor alle rasters
				let startY = Configuration.CURRENT.raster.getSizeY(raster - 1);
				let endY = Configuration.CURRENT.raster.getSizeY(raster);
				this.trimmings.push(new StairTrimming({ startX: x, startY: startY, endX: x, endY: endY, position: StairTrimmings.LEFT })); // trimming left
				this.trimmings.push(new StairTrimming({ startX: x + width, startY: startY, endX: x + width, endY: endY, position: StairTrimmings.RIGHT })); // trimming rigt
				// eventueel toevoegen van kolommen bij doorsnijden hoofdbalk, kijken naar prop addColumns, deze is false als we slepen maar zodra we loslaten doet hij het wel.
				if (addColumns === true) {
					if (y <= Configuration.CURRENT.raster.getSizeY(raster - 1) + Columns.COLUMN_SIZE && y + depth >= Configuration.CURRENT.raster.getSizeY(raster - 1) && raster > -1) {
						// (x, y, name, type, position)
						columns.push(-1, raster, stairId, Columns.STAIR_TRIMMING_COLUMN, { x: x - columnSize, y: Configuration.CURRENT.raster.getSizeY(raster - 1) });
						columns.push(-1, raster, stairId, Columns.STAIR_TRIMMING_COLUMN, { x: x + width + columnSize, y: Configuration.CURRENT.raster.getSizeY(raster - 1) });
					}
				}
			}
		} else {
			let topLeftRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x, y);
			let topRightRaster = Configuration.CURRENT.raster.getRasterByCoordinate(x + width, y);
			let endRaster = topRightRaster.x;
			if (topRightRaster.x === -1) {
				endRaster = Configuration.CURRENT.raster.spansX.length;
			}

			let trimmingLeftNeeded = x > Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x - 1) + 200 && x < Configuration.CURRENT.raster.spansX.getSize(topLeftRaster.x) - 200;

			let trimmingRightNeeded = x + width < Configuration.CURRENT.raster.spansX.getSize(topRightRaster.x) - 200 && x + width > Configuration.CURRENT.raster.spansX.getSize(topRightRaster.x - 1) + 200;

			// links en rechts maar 1x
			if (trimmingLeftNeeded === true) {
				// boven alleen een volledige trimming als hij niet op de rand staat
				this.trimmings.push(new StairTrimming({ startX: x, startY: y, endX: x, endY: y + depth, position: StairTrimmings.LEFT })); // trimming left
			}
			if (trimmingRightNeeded === true) {
				// boven alleen een volledige trimming als hij niet op de rand staat
				this.trimmings.push(new StairTrimming({ startX: x + width, startY: y, endX: x + width, endY: y + depth, position: StairTrimmings.RIGHT })); // trimming right
			}
			for (let raster = topLeftRaster.x; raster <= endRaster; raster++) {
				// boven en onder voor alle rasters
				let startX = Configuration.CURRENT.raster.getSizeX(raster - 1);
				let endX = Configuration.CURRENT.raster.getSizeX(raster);
				this.trimmings.push(new StairTrimming({ startX: startX, startY: y, endX: endX, endY: y, position: StairTrimmings.TOP })); // trimming top
				this.trimmings.push(new StairTrimming({ startX: startX, startY: y + depth, endX: endX, endY: y + depth, position: StairTrimmings.BOTTOM })); // trimming top
				// eventueel toevoegen van kolommen bij doorsnijden hoofdbalk
				if (addColumns === true) {
					if (x <= Configuration.CURRENT.raster.getSizeX(raster - 1) + Columns.COLUMN_SIZE && x + width >= Configuration.CURRENT.raster.getSizeX(raster - 1) && raster > -1) {
						// (x, y, name, type, position)
						columns.push(raster, -1, stairId, Columns.STAIR_TRIMMING_COLUMN, { x: Configuration.CURRENT.raster.getSizeX(raster - 1), y: y - columnSize });
						columns.push(-1, raster, stairId, Columns.STAIR_TRIMMING_COLUMN, { x: Configuration.CURRENT.raster.getSizeX(raster - 1), y: y + depth + columnSize });
					}
				}
			}
		}
	}
	setEnabled(type) {
		this.trimmings.forEach((trimming) => {
			if (trimming.type === type) {
				trimming.enabled = true;
			}
		});
	}
	calculateAmount(params) {
		// kolommenm worden direct toegevoegd. Die hoeven dus niet gedaan te worden
		this.trimmings.forEach((trimming) => {
			trimming.calculateAmount(params);
		});
	}

	setDisabled(type) {
		this.trimmings.forEach((trimming) => {
			if (trimming.type === type) {
				trimming.enabled = false;
			}
		});
	}
}
