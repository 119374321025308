import { Store } from '../data/store';
import { LocalAsset3D } from '../draw3d/assets/LocalAsset3D';
import { MaterialAsset3D } from '../draw3d/assets/MaterialAsset3D';
import { ModelAsset3D } from '../draw3d/assets/ModelAsset3D';
import { RalColorAsset3D } from '../draw3d/assets/RalColorAsset3D';
import { Box3D } from '../draw3d/box3D';
import { Canvas3D } from '../draw3d/Canvas3D';
import { DeckingFinishDynamic3D } from '../draw3d/deckingFinish/DeckingFinishDynamic3D';
import { Mathematic } from '../helpers/mathematic';
import { Columns } from './columns';
import { Configuration } from './configuration';
import { RemoveRaster } from './removeRaster';

export class DeckingFinish {
	objectName = 'DeckingFinish';
	objectName3d = 'DeckingFinish3D';
	_selected = { id: 278, caption: 'Chipboard P6', text: '', height: 38, centerToCenter: [], customization: false, customizationPercentage: 0, edgeTrim: false, edgeTrimPossible: false };
	centerToCenter = [];
	maxOverhang = 300;
	defaultOverhang = 170; // initieel wordt dit nog niet toegepast bij overhang omdat vanuit constructor Configuration.CURRENT nog niet beschikbaar is
	caption = 'Chipboard P6';
	// caption = this.$translate('dashboard.priceInfo.chipboard') + 'P6';
	info = '';
	height = 38;
	wastePercentage = 0;
	surface = [];
	articleGroup = '';
	id = 0;
	oldVal = 0;
	static TYPE_GRATING = 1;
	static TYPE_WOODPLATE = 2;
	list = [];

	get parameters() {
		return {
			centerToCenter: this.centerToCenter,
			maxOverhang: this.maxOverhang,
			defaultOverhang: this.defaultOverhang,
			caption: this.caption,
			info: this.info,
			height: this.height,
			wastePercentage: this.wastePercentage,
			id: this.id,
		};
	}
	set parameters(value) {
		this.centerToCenter = value.centerToCenter;
		this.maxOverhang = value.maxOverhang;
		this.defaultOverhang = value.defaultOverhang;
		this.caption = value.caption;
		this.info = value.info;
		this.height = value.height;
		this.wastePercentage = value.wastePercentage;
		this.articleGroup = value.articleGroup;
		this.id = value.id;
	}

	_onChanged = null;
	constructor(onChanged) {
		this._onChanged = onChanged;
	}
	setReferences(params) {
		this._onChanged = params.onChange;
		this._onChange = params.save;
	}
	removeReferences() {
		this._onChange = null;
		this._onChanged = null;
	}
	onChanged() {
		if (typeof this._onChanged === 'function') {
			this._onChanged();
		}
	}
	calculateAmount() {
		this.surface = [];
		Configuration.CURRENT.etages.getEtages().forEach((etage, index) => {
			let surface = etage.getSurface(true).toFixed(2);
			this.surface.push(surface);
		});
	}
	getClosest(array, value) {
		let diff = -1;
		let closest = 0;
		array.forEach((item) => {
			const newDif = Math.max(value, item) - Math.min(value, item);
			if (newDif < diff || diff === -1) {
				diff = newDif;
				closest = item;
			}
		});
		return closest;
	}
	getAmount() {
		let amount = { Finishes: [] };

		if (this.selected.customizationPercentage === null || this.selected.customizationPercentage === '') {
			this.selected.customizationPercentage = 0;
		}

		this.surface.forEach((surface, index) => {
			const originalParameters = Store.CURRENT.deckingFinishes.getItem(this._selected.id);

			amount.Finishes.push({
				id: this.id,
				etage: index,
				surface: Math.round(surface),
				centerToCenter: Configuration.CURRENT.profiles.centerToCenter,
				name: originalParameters.caption,
				height: this.selected.height,
				customizationPercentage: this.selected.customizationPercentage,
			});
		});
		return amount;
	}
	get customizationPercentage() {
		return this._selected.customizationPercentage;
	}
	set customizationPercentage(percentage) {
		if (this._selected.customizationPercentage !== percentage) {
			this._selected.customizationPercentage = percentage;
			this.onChanged();
		}
	}

	set edgeTrim(value) {
		if (this._selected.edgeTrim !== value) {
			this._selected.edgeTrim = value;
			this.onChanged();
		}
	}
	get edgeTrim() {
		return this._selected.edgeTrimPossible;
	}

	get selected() {
		// zou zonder _Selected moeten kunnen maar dan ontstaat er een infinity loop
		return this._selected;
	}
	set selected(value) {
		if (typeof value === 'object') {
			value = value.id;
		}
		const newParameters = Store.CURRENT.deckingFinishes.getItem(value);

		if (newParameters === null) {
			return;
		}

		const overhangValid = Configuration.CURRENT.overhang.size <= newParameters.maxOverhang;
		const centerToCenterValid = newParameters.centerToCenter.includes(Configuration.CURRENT.profiles.centerToCenter);

		if (!centerToCenterValid || !overhangValid) {
			const newCenterToCenter = this.getClosest(newParameters.centerToCenter, Configuration.CURRENT.profiles.centerToCenter);
			let content = window.Vue.$translate('confirm.content.finishNotValide');
			if (!centerToCenterValid) {
				content += window.Vue.$translate('confirm.content.finishCenterToCenter', {
					currentCenterToCenter: Configuration.CURRENT.profiles.centerToCenter,
					newCenterToCenter: newCenterToCenter,
				});
			}
			if (!overhangValid) {
				content += window.Vue.$translate('confirm.content.finishOverhang', { currentOverhang: Configuration.CURRENT.overhang.size, newOverhang: newParameters.maxOverhang });
			}
			content += window.Vue.$translate('confirm.content.finishContinue');
			window.Vue.$confirm({
				isHtml: true,
				title: window.Vue.$translate('confirm.title.selectedValueNotValid'),
				content: content,
				okText: window.Vue.$translate('confirm.button.yes'),
				cancelText: window.Vue.$translate('confirm.button.no'),
			})
				.then((success) => {
					// Ok button pressed
					this.oldVal = value;
					this._selected.id = newParameters.id;
					this._selected.text = newParameters.caption + ' ' + newParameters.info;
					this._selected.caption = newParameters.caption + newParameters.height;
					this._selected.height = newParameters.height;
					this._selected.centerToCenter = newParameters.centerToCenter;
					this._selected.customization = newParameters.customization;
					this._selected.edgeTrimPossible = newParameters.edgeTrimPossible;

					this.parameters = newParameters;
					Configuration.CURRENT.overhang.max = newParameters.maxOverhang;
					if (!centerToCenterValid) {
						Configuration.CURRENT.profiles.centerToCenter = newCenterToCenter;
					}
					if (!overhangValid) {
						Configuration.CURRENT.overhang.size = newParameters.maxOverhang;
					}
					this.onChanged();
				})
				.catch((cancel) => {
					// Cancel button pressed
					let oldFinish = Store.CURRENT.deckingFinishes.getItem(this.oldVal);
					this._selected = {
						id: oldFinish.id,
						text: oldFinish.caption + ' ' + oldFinish.info,
						caption: oldFinish.caption + oldFinish.height,
						height: oldFinish.height,
						centerToCenter: oldFinish.centerToCenter,
						customization: oldFinish.customization,
						customizationPercentage: oldFinish.customizationPercentage,
						edgeTrimPossible: oldFinish.edgeTrimPossible,
						edgeTrim: oldFinish.edgeTrim,
					};
				});
		} else {
			this.oldVal = value;
			this._selected.id = newParameters.id;
			this._selected.text = newParameters.caption + ' ' + newParameters.info;
			this._selected.caption = newParameters.caption + newParameters.height;
			this._selected.height = newParameters.height;
			this._selected.centerToCenter = newParameters.centerToCenter;
			this._selected.customization = newParameters.customization;
			this._selected.edgeTrimPossible = newParameters.edgeTrimPossible;

			this.parameters = newParameters;
			Configuration.CURRENT.overhang.max = newParameters.maxOverhang;
			this.onChanged();
		}
	}
	addDrawObjects3d(canvas3d, raster, etage) {
		// Over rasters akkeren
		let startDrawHeight = 0;

		// let multipleFloors = Configuration.CURRENT.etages.getEtages().length > 1;
		Configuration.CURRENT.etages.getEtages().forEach((etage, index) => {
			let yPosition = 0;
			let drawHeight = startDrawHeight + etage.getHeight(true);

			raster.spansY.getSpans().forEach((spanY, indexY) => {
				let xPosition = 0;
				raster.spansX.getSpans().forEach((spanX, indexX) => {
					let rowPositions = [];
					let rasterHoleCollisions = this.getCollisions(xPosition, yPosition, spanX.value, spanY.value, etage);

					if (etage.isActiveRaster(new RemoveRaster(indexX - 1, indexY)) === false) {
						rowPositions.push(Columns.POSITION_LEFT);
					}
					if (etage.isActiveRaster(new RemoveRaster(indexX + 1, indexY)) === false) {
						rowPositions.push(Columns.POSITION_RIGHT);
					}
					if (etage.isActiveRaster(new RemoveRaster(indexX, indexY - 1)) === false) {
						rowPositions.push(Columns.POSITION_TOP);
					}
					if (etage.isActiveRaster(new RemoveRaster(indexX, indexY + 1)) === false) {
						rowPositions.push(Columns.POSITION_BOTTOM);
					}
					if (etage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true) {
						// Bij geen collision dan tekenen we de gehele finish aan de hand van afmetingen span.

						// Gehele raster bedekken met finish.
						new DeckingFinishDynamic3D(xPosition, yPosition, {
							drawHeight: drawHeight,
							y: drawHeight,
							width: spanX.value,
							depth: spanY.value,
							rowPositions: rowPositions,
							overhang: Configuration.CURRENT.overhang.size,
							finishType: Configuration.CURRENT.getDeckingFinishType(),
							mainBeamHeight: Configuration.CURRENT.profiles.mainBeam.height,
							indexX: indexX,
							indexY: indexY,
							calculate: true,
							layers: [Canvas3D.CURRENT.deckingFinishLayer.id],
							oid: this.selected.id,
							assetName: this.selected.caption,
						});

						// Wanneer in dit raster een collision zit dan die opvullen met invisible boxes.
						if (rasterHoleCollisions.length > 0) {
							rasterHoleCollisions.forEach((item, index) => {
								Canvas3D.CURRENT.addDrawObject(
									new Box3D(item.startX, item.startY, {
										width: item.endX - item.startX,
										depth: item.endY - item.startY,
										height: Configuration.CURRENT.finish.height + 4,
										drawY: drawHeight - Configuration.CURRENT.finish.height,
										ralColor: 9999,
										layers: [Canvas3D.CURRENT.transparentObjectsLayer.id],
										invisible: true,
									}),
									Canvas3D.TYPE_INVISIBLE_BOX,
								);
							});
						}
					}
					xPosition += spanX.value;
				});
				yPosition += spanY.value;
			});
			startDrawHeight += etage.getHeight(true);
		});
	}

	getCollisions(x, y, width, depth, etage) {
		let holes = [];
		Object.keys(etage).forEach((object, index) => {
			if (etage[object] !== null && typeof etage[object] === 'object') {
				if (typeof etage[object].get === 'function') {
					etage[object].get().forEach((item) => {
						if (typeof item.isInFloor !== 'undefined') {
							if (item.active === true) {
								let boundaries = item.boundaries;
								let lt = boundaries[0].topLeft;
								let rb = boundaries[0].bottomRight;
								let lt2 = { x: x, y: y };
								let rb2 = { x: x + width, y: y + depth };
								if (Mathematic.overlapRectangles(lt, rb, lt2, rb2)) {
									holes.push(item.getPositionProfiles());
								}
							}
						}
					});
				}
			}
		});
		return holes;
	}

	create3DAssets(canvas3d) {
		// Bij deckingfinish opvullen van gaten met stukken finish.
		// Daarom losse stukken toevoegen als assets.
		// Later moet dit hetzelfde als bij profielen in ERP
		// zodat we stukjes kunnen ophalen van het juiste Deckingfinish.

		// Voor na deckingfinish wijziging van Adryan.
		canvas3d.addAsset(new ModelAsset3D(this.selected.caption, this.selected.id));

		// HOUTPLAAT
		// Eerste is voor deckingfinish voor landing en andere dingen die we kunnen resizen.
		canvas3d.addAsset(new LocalAsset3D('deckingfinish', null));
		canvas3d.addAsset(new LocalAsset3D('woodplate-2000', null));
		canvas3d.addAsset(new LocalAsset3D('woodplate-1000', null));
		canvas3d.addAsset(new LocalAsset3D('woodplate-500', null));
		canvas3d.addAsset(new LocalAsset3D('woodplate-250', null));
		canvas3d.addAsset(new LocalAsset3D('woodplate-100', null));
		canvas3d.addAsset(new LocalAsset3D('woodplate-50', null));

		// Deckingfinish foto voor op het model en 9999 is invisible box voor collisions in de deckingfinish.
		canvas3d.addAsset(new MaterialAsset3D('deckingfinish.jpg', null, { folder: 'deckingfinish' }));
		canvas3d.addAsset(new MaterialAsset3D('woodplate-1000.jpg', null, { folder: 'woodplate-1000' }));
		canvas3d.addAsset(new RalColorAsset3D('9999'));

		// ROOSTER
		canvas3d.addAsset(new LocalAsset3D('rooster-1000', null));
		canvas3d.addAsset(new LocalAsset3D('rooster-500', null));
		canvas3d.addAsset(new LocalAsset3D('rooster-234', null));
		canvas3d.addAsset(new LocalAsset3D('rooster-34', null));

		// GROND TEXTUREN
		canvas3d.addAsset(new MaterialAsset3D('ground.jpg', null, { type: 'texture', app: Canvas3D.CURRENT.app, folder: '' }));
		canvas3d.addAsset(new MaterialAsset3D('checkerplate.jpg', null, { type: 'texture', app: Canvas3D.CURRENT.app, folder: '' }));

		canvas3d.addAsset(
			new ModelAsset3D('torsiesteun', null, {
				fallBackName: 'torsiesteun',
				fallBackData: { width: 1, depth: 1, height: Configuration.CURRENT.finish.height },
				type: 'model',
				app: Canvas3D.CURRENT.app,
			}),
		);
	}
}
