import { Data } from './data';
import { Store } from './store';
import { Languages } from './languages';

export class LedOptions extends Data {
	objectName = 'LedOptions';
	static CURRENT = null;
	url = 'LedOptions';
	// Material ook hier toevoegen omdat elk item in de map functie gelijk moet zijn.
	defaultItem = {
		oid: 0,
		material: null,
		description: 'no-led',
	};
	getListWithDefault() {
		let options = [...this._list.list];

		options.unshift(this.defaultItem);
		let newArray = options.map((option) => ({
			key: option.oid,
			value: option.oid,
			caption: option.description,
		}));

		return newArray;
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		data.language = Languages.CURRENT.currentLanguage;
		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
}
