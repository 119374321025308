import { DrawObject } from './drawObject';
import { DrawValue } from './drawValue';

export class Line extends DrawObject {
	objectName = 'Line';
	width = new DrawValue();
	eindX = new DrawValue();
	eindY = new DrawValue();
	lineDash = null;
	width = new DrawValue(0, 1);
	border = true;
	opacity = 1; // 1 is ondoorzichtig. Kleiner dan 1 is doorzichtiger
	constructor(startX, startY, eindX, eindY, width = null, lineDash = null, color = null, fillColor = null, lineColor = null, mouseDetect = null, object = null, objectParams = null) {
		super(startX, startY, color, fillColor, lineColor, mouseDetect, object, objectParams, false, false, false);
		this.eindX = eindX;
		this.eindY = eindY;
		if (width === null) {
			width = new DrawValue(0, 1);
		}
		this.width = width;
		this.lineDash = lineDash;
	}
	draw(canvas) {
		if (this.visible === false) {
			return {
				x: -1,
				y: -1,
				width: -1,
				height: -1,
			};
		}

		canvas.ctx.globalAlpha = this.opacity;
		canvas.ctx.fillStyle = this.fillColor;
		canvas.ctx.strokeStyle = this.lineColor;

		canvas.ctx.beginPath();
		const calculatedX = this.x.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallLeft : canvas.offsetLeft);
		const calculatedY = this.y.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallTop : canvas.offsetTop);

		const calculatedEindX = this.eindX.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallLeft : canvas.offsetLeft);
		const calculatedEindY = this.eindY.scaledValue(canvas.scaleFactor) + (this.wallOffset ? canvas.offsetWallTop : canvas.offsetTop);

		if (this.lineDash !== null) {
			canvas.ctx.setLineDash(this.lineDash);
		} else {
			canvas.ctx.setLineDash([]);
		}
		if (this.width !== null) {
			canvas.ctx.lineWidth = this.width.scaledValue(canvas.scaleFactor);
		}

		canvas.ctx.moveTo(calculatedX, calculatedY);
		canvas.ctx.lineTo(calculatedEindX, calculatedEindY);

		if (this.border === true) {
			canvas.ctx.stroke();
		}

		let offset = { x: 0, y: 0 };
		if (typeof this.objectParams !== 'undefined' && this.objectParams !== null && typeof this.objectParams.mouseAreaOffset !== 'undefined') {
			offset.x = this.objectParams.mouseAreaOffset.x;
			offset.y = this.objectParams.mouseAreaOffset.y;
		} else if (typeof this.object !== 'undefined' && this.object !== null && typeof this.object.mouseAreaOffset !== 'undefined') {
			offset.x = this.object.mouseAreaOffset.x;
			offset.y = this.object.mouseAreaOffset.y;
		}

		if (this.mouseDetect === true) {
			this.mouseArea.minX = calculatedX - offset.x - this.width.scaledValue(canvas.scaleFactor);
			this.mouseArea.minY = calculatedY - offset.y - this.width.scaledValue(canvas.scaleFactor);
			this.mouseArea.maxX = calculatedEindX + offset.x + this.width.scaledValue(canvas.scaleFactor);
			this.mouseArea.maxY = calculatedEindY + offset.y + this.width.scaledValue(canvas.scaleFactor);
		}

		return {
			x: calculatedX,
			y: calculatedY,
			width: calculatedEindX - calculatedX,
			height: calculatedEindY - calculatedY,
		};
	}
	minMax() {
		return { min: { x: this.x.value, y: this.y.value }, max: { x: this.eindX.value, y: this.eindY.value } };
	}
}
