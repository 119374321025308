import { Data } from './data';

export class MockupData extends Data {
	objectName = 'MockupData';
	url = 'MockupData';
	useMockupData = false;

	save(data, urlExtension, thenCallBack, catchCallBack, objectName) {
		// voorlopig nog data meegeven. Later samenstellen
		if (typeof window === 'undefined') {
			return; // voorkom infinity loop op de server
		}

		let element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.json));
		element.setAttribute('download', data.objectName + '.json');

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}
}
