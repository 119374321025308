import { Canvas3D } from '../Canvas3D';
import { Grating3D } from './Grating3D';
import { WoodPlate3D } from './WoodPlate3D';

export class DeckingFinishDynamic3D {
	static TYPE_GRATING = 0;
	static TYPE_WOODPLATE = 1;
	static classes = { TYPE_WOODPLATE: WoodPlate3D, TYPE_GRATING: Grating3D };
	constructor(x, y, params) {
		if (typeof params.finishType !== 'undefined' && params.finishType !== null) {
			if (params.finishType === DeckingFinishDynamic3D.TYPE_GRATING) {
				// eslint-disable-next-line no-new
				Canvas3D.CURRENT.addDrawObject(new Grating3D(x, y, params), Canvas3D.TYPE_FINISH);
			} else if (params.finishType === DeckingFinishDynamic3D.TYPE_WOODPLATE) {
				Canvas3D.CURRENT.addDrawObject(new WoodPlate3D(x, y, params), Canvas3D.TYPE_FINISH);
			}
		}
	}
}
