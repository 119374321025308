import { Serializer } from './serializer';
import { Span } from './span';

export class SpanArray {
	objectName = 'SpanArray';
	spans = [];
	_onChange = null;
	get length() {
		return this.spans.length;
	}
	constructor(value, onChange) {
		this._onChange = onChange;

		if (typeof value !== 'undefined' && value !== null) {
			value.forEach(
				function (value) {
					this.push(value);
				}.bind(this),
			);
		}
	}
	setReferences(params) {
		this._onChange = params.onChange;

		this.spans.forEach((span, index) => {
			span._onChange = params.onChange;
		});
	}

	removeReferences() {
		this._onChange = null;
		this.spans.forEach((span, index) => {
			if (typeof span.removeReferences === 'function') {
				// om historische redenen controleren. Hier nog over nadenken. Als een object niet goed geserialized is dan maakt hij er geen object van en dus geen functies
				span.removeReferences();
			}
		});
	}
	onChange(status = null) {
		if (typeof this._onChange === 'function') {
			this._onChange(status);
		}
	}
	getMax() {
		let max = 0;
		this.spans.forEach((span, index) => {
			if (max < span.value) {
				max = span.value;
			}
		});

		return max;
	}
	setMax(max, params) {
		let custom = false;

		if (params !== null && typeof params !== 'undefined' && typeof params.custom !== 'undefined' && params.custom !== null) {
			custom = params.custom;
		}

		let totalDif = 0;
		this.spans.forEach((span, index) => {
			// afronden op 50
			const round = Math.round(span.value / 50) * 50;

			// kijk of afronding overeenkomt
			let diffRound = span.value - round;

			// als afronding niet overeenkomt dan afronding gebruiken
			// eerst werd dit ook aan een ander span toegevoegd. Maar dat zorgt voor loop.
			// als bijv 580 dan voegt hij aan ander raster 30 toe wat weer geen 50 is
			if (diffRound > 0) {
				span.value = round;
			}
			// Als de afronding niet overeenkomt en dus de span.value naar boven
			// wordt aferond
			if (diffRound < 0) {
				span.value = round;
			}

			let diff = 0;
			// Overtreedt span niet max?
			if (span.value > max) {
				// groter dan max
				diff = span.value - max;

				span.value = max;
			}

			// nog over doordat langer dan max dan kijken of het aan ander raster kan worden toegevoegd
			if (diff > 0) {
				if (index + 1 < this.spans.length) {
					this.spans[index + 1].value += diff;
				} else if (index - 1 > 0 && this.spans[index - 1].value + diff < max) {
					this.spans[index - 1].value += diff;
				} else {
					// geen ander raster om toe tevoegen dan bewaren
					totalDif += diff;
				}
			}
		});
		// nog over dan raster toevoegen
		if (totalDif > 0 && custom) {
			if (totalDif > max) {
				let toAdd = Math.floor(totalDif / max);
				for (let i = 0; i < toAdd; i++) {
					this.spans.push(new Span(max));
				}
				// Wanneer dan nog rest over is.
				if (totalDif - toAdd * max > 50) {
					this.spans.push(new Span(totalDif - toAdd * max));
				}
			} else {
				this.spans.push(new Span(totalDif));
			}
		}

		if (custom) {
			this.onChange();
		}
	}
	push(value) {
		value._onChange = this._onChange;

		const res = this.spans.push(value);

		this.onChange();

		return res;
	}
	cloneLast() {
		// this.push(this.spans.slice(this.spans.length - 1, 1));

		const lastSpan = this.spans[this.spans.length - 1];
		const serializer = new Serializer();
		const clone = serializer.clone(lastSpan);

		this.push(clone);
	}
	splice(index, count = this.spans.length - index) {
		const result = this.spans.splice(index, count);
		this.onChange();
		return result;
	}

	getRaster(position) {
		let found = false;
		let rasterPos = -1;
		this.spans.forEach(
			function (span, index) {
				if (found === false && position <= this.getSize(index)) {
					// eerste raster waar hij mogelijk kleiner is stoppen
					rasterPos = index;
					found = true;
				}
				return true;
			}.bind(this),
		);

		return rasterPos;
	}
	get(item) {
		if (typeof item !== 'undefined' && item !== null) {
			return this.spans[item];
		}
		return this.spans;
	}
	getSpans() {
		return this.spans;
	}

	getSize(index = this.length - 1) {
		let size = 0;

		this.spans.forEach(function (value, i) {
			if (i <= index) {
				size += value.value;
			}
		});

		return size;
	}
}
