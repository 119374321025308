import { ObjectGroup } from '../draw/objectGroup';
import { Line } from '../draw/line';
import { DrawValue } from '../draw/drawValue';
import { Configuration } from './configuration';
import { Profiles } from './profiles';
import { HoleTrimmings } from './holeTrimmings';
import { Columns } from './columns';
import { TrimmingTop3D } from '../draw3d/buildingcolumn/trimming/TrimmingTop3D';
import { TrimmingBottom3D } from '../draw3d/buildingcolumn/trimming/TrimmingBottom3D';
import { TrimmingLeft3D } from '../draw3d/buildingcolumn/trimming/TrimmingLeft3D';
import { TrimmingRight3D } from '../draw3d/buildingcolumn/trimming/TrimmingRight3D';
import { Canvas3D } from '../draw3d/Canvas3D';

export class HoleTrimming {
	objectName = 'HoleTrimming';
	startX = 0;
	startY = 0;
	endX = 0;
	endY = 0;
	position = -1;

	get length() {
		if (this.startX === this.endX) {
			return this.endY - this.startY;
		} else {
			return this.endX - this.startX;
		}
	}
	constructor(trimming) {
		if (typeof trimming !== 'undefined' && trimming !== null) {
			this.update(trimming);
		}
	}

	update(trimming) {
		this.startX = trimming.startX;
		this.startY = trimming.startY;
		this.endX = trimming.endX;
		this.endY = trimming.endY;
		this.position = trimming.position;

		if (typeof trimming.active === 'undefined' || trimming.active === null) {
			// niet meegegeven dan default true
			this.active = true;
		} else {
			// anders overnemen
			this.active = trimming.active;
		}
	}

	addDrawObjects() {
		let profiles = Configuration.CURRENT.profiles;

		// mainbeams worden tegen kolommen aangetekend en kolommen staan precies op het midden. Dus helft van kolom gaat eraf. Waarvan is afhankelijk van richting hoofdbalken
		let columnShiftX = 0;
		let columnShiftY = 0;
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (this.position === HoleTrimmings.LEFT || this.position === HoleTrimmings.RIGHT) {
				columnShiftX = 0;
				columnShiftY = Columns.COLUMN_SIZE / 2;
			}
		} else if (this.position === HoleTrimmings.TOP || this.position === HoleTrimmings.BOTTOM) {
			columnShiftX = Columns.COLUMN_SIZE / 2;
			columnShiftY = 0;
		}

		let trimming = new Line(
			new DrawValue(this.startX, columnShiftX),
			new DrawValue(this.startY, columnShiftY),
			new DrawValue(this.endX, -columnShiftX),
			new DrawValue(this.endY, -columnShiftY),
			null,
			null,
			profiles.colors.mainBeam,
			null,
			null,
			false,
			null,
			{ type: 'holetrimming', color: null },
		);
		trimming.colorFromParent = false;
		this.drawObjectId = trimming.id;

		return trimming;
	}

	addDrawObjects3d(canvas3d, etage, raster, posY) {
		let drawHeight = posY + etage.getHeight(true);
		let multipleFloors = Configuration.CURRENT.etages.length > 1;
		let rasterCoords = etage.raster.getRasterByCoordinate(this.startX, this.startY);
		let rowData = Configuration.CURRENT.raster.getRowPositions(rasterCoords.x, rasterCoords.y);
		let span;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (rasterCoords.x === -1) {
				rasterCoords.x = 0;
			}
			span = etage.raster.spansX.get(rasterCoords.x);
		} else {
			if (rasterCoords.y === -1) {
				rasterCoords.y = 0;
			}
			span = etage.raster.spansY.get(rasterCoords.y);
		}

		let paramsObject = {
			drawHeight: drawHeight,
			rowPositions: rowData.rowPositions,
			placement: rowData.placement,
			profileType: 'mainbeam',
			oid: span.beam.oid,
			mainBeamDepth: Canvas3D.CURRENT.getBeamData(span.beam.oid, 'depth'),
			mainBeamHeight: Canvas3D.CURRENT.getBeamData(span.beam.oid, 'height'),
		};

		switch (this.position) {
			case HoleTrimmings.TOP:
				canvas3d.addDrawObject(new TrimmingTop3D(this.startX, this.startY, this.endX - this.startX, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case HoleTrimmings.BOTTOM:
				canvas3d.addDrawObject(new TrimmingBottom3D(this.startX, this.startY, this.endX - this.startX, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case HoleTrimmings.LEFT:
				canvas3d.addDrawObject(new TrimmingLeft3D(this.startX, this.startY, this.endY - this.startY, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
			case HoleTrimmings.RIGHT:
				canvas3d.addDrawObject(new TrimmingRight3D(this.startX, this.startY, this.endY - this.startY, paramsObject), Canvas3D.TYPE_PROFILE);
				break;
		}
	}

	calculateAmount(params) {
		let rasterCoords = params.raster.getRasterByCoordinate(this.startX, this.startY);
		let span;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (rasterCoords.x === -1) {
				rasterCoords.x = 0;
			}
			span = params.raster.spansX.get(rasterCoords.x);
		} else {
			if (rasterCoords.y === -1) {
				rasterCoords.y = 0;
			}
			span = params.raster.spansY.get(rasterCoords.y);
		}

		if (this.startX === this.endX) {
			Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endY - this.startY), 1, span, null);
		} else {
			Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endX - this.startX), 1, span, null);
		}
	}
}
