import { DimensionVertical3D } from './dimensionVertical3D';

export class DimensionVerticalRight3D extends DimensionVertical3D {
	objectName = 'DimensionVerticalRight3D';
	rotationX = -90;
	rotationY = 90;
	draw() {
		super.draw();

		// Rechts is de tekst altijd naar beneden wijzend. Dus dan opschuiven door rotatie.
		this.dimensionEntity.setLocalPosition(this.x, this.y, this.z + this.width / 1000);

		super.addEntities();
	}
}
