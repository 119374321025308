/* eslint-disable no-useless-constructor */
import { Configuration } from '../../../configurator/configuration';
import { Profiles } from '../../../configurator/profiles';
import { Raster } from '../../../configurator/raster';
import { Canvas3D } from '../../Canvas3D';
import { ProfilesHorizontal3D } from '../../profiles/ProfilesHorizontal3D';

export class TrimmingBottom3D extends ProfilesHorizontal3D {
	objectName = 'TrimmingBottom3D';
	type = 'profile';
	constructor(x, y, length, params) {
		super(x, y, length, params);
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		this.ralColor = Configuration.CURRENT.colors.profiles.ralColor;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_VERTICAL) {
			this.applyVerticalOffset();
		}
		this.y -= this.mainBeamHeight;
		this.y -= deckingFinishHeight;

		super.draw(app);
	}
	applyVerticalOffset() {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		if (this.multipleFloors) {
			if (this.rowPositions.includes(Raster.POSITION_LEFT)) {
				this.x += columnWidth;
				this.x += this.mainBeamDepth;
				this.width -= this.mainBeamDepth;
				this.width -= columnWidth / 2;
			} else if (this.rowPositions.includes(Raster.POSITION_RIGHT)) {
				this.x += columnWidth / 2;
				this.x += this.mainBeamDepth;
				this.width -= this.mainBeamDepth;
				this.width -= columnWidth / 2;
			} else {
				this.x += columnWidth / 2;
				this.x += this.mainBeamDepth;
				this.width -= this.mainBeamDepth;
			}
		} else {
			this.x += this.mainBeamDepth;
		}
		this.width -= this.mainBeamDepth;
		this.width -= columnWidth;
	}
}
