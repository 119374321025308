export class SelectionListOption {
	objectName = 'SelectionListOption';
	id = -1;
	text = '';
	image = '';
	selected = false;
	imageDisabled = '';
	disabled = false;
	constructor(id, text, image = '', selected = false, onToggle = null) {
		this.id = id;
		this.text = text;
		this.image = image;

		this.selected = selected;
		this._onToggle = onToggle;
	}
	setReferences(params) {
		this._onToggle = params.onToggle;
	}
	removeReferences() {
		this._onToggle = null;
	}

	toggle(value) {
		if (typeof this._onToggle === 'function') {
			this._onToggle(value);
		}
	}
}
