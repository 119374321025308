import { Configuration } from '../configurator/configuration';
import { SizeHandle } from './sizeHandle';

export class SizeHandleTotalOutside extends SizeHandle {
	objectName = 'SizeHandleTotalOutside';
	referenceHandle = '';
	typeSizeHandle = SizeHandleTotalOutside.TOTAL;
	overhang = 0;
	constructor(id, order, resizable) {
		if (typeof resizable === 'undefined' || resizable === null) {
			resizable = false;
		}
		super(id, order, resizable);
	}
	addDrawObjects(canvas, params) {
		this.overhang = Configuration.CURRENT.overhang.size;
		this.referenceHandle = canvas.sizeHandles.get('raster');

		this.horizontal.clear();
		this.vertical.clear();

		if (typeof this.referenceHandle !== 'undefined' && this.referenceHandle !== null) {
			this.horizontal.push(this.referenceHandle.horizontal.getTotalLength() + this.overhang * 2);
			this.vertical.push(this.referenceHandle.vertical.getTotalLength() + this.overhang * 2);
		}
		this.startPosition = -this.overhang;
		super.addDrawObjects(canvas, params);
	}
}
