import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { ColumnProtector3D } from './ColumnProtector3D';

export class ProtectorLeftTop3D extends ColumnProtector3D {
  objectName = 'ProtectorLeftTop3D';
  draw(app) {
    let modelWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
    let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

    // Kolombeschermer komt precies op de kolompositie dus breedte van de beschermer plus de kolombreedte naar links
    this.x -= modelWidth;
    this.x += columnWidth / 2;
    this.z -= modelWidth;

    super.draw(app);
  }

}
