import { Configuration } from '../../../configurator/configuration';
import { HandRails } from '../../../configurator/handRails';
import { Canvas3D } from '../../Canvas3D';
import { Model3D } from '../../Model3D';

// DIT IS DE RAILING OP DE PAALTJES
export class HandRailPlateTop3D extends Model3D {
	objectName = 'HandRailPlateTop3D';
	hoverTags = ['handrail'];
	constructor(x, y, z, params) {
		super(x, y, z, params);
		this.width = params.length;
		this.oid = params.handRailMaterials.handRail;
	}
	draw(app) {
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');
		let plateDepth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.handRail, 'depth');

		if (Configuration.CURRENT.colors.handRail.finish === 1 || Configuration.CURRENT.colors.handRail.finish === 2) {
			this.ralColor = 7036;
		} else {
			this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;
		}
		this.ralColor = 7036;

		// De topplaat
		this.y += this.middlePoleLength;
		this.y += 5;

		if (this.position === HandRails.TOP || this.position === HandRails.BOTTOM) {
			this.x += poleWidth;
			this.z += (poleWidth - plateDepth) / 2;
		} else {
			this.rotationY = -90;
			this.z += poleWidth;
			this.x += plateDepth;
			this.x += (poleWidth - plateDepth) / 2;
		}

		this.width -= poleWidth;
		super.draw(app);
	}
}
