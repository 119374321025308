import { Asset3D } from './Asset3D';

export class ModelAsset3D extends Asset3D {
	objectName = 'ModelAsset3D';
	type = 'model';
	// eslint-disable-next-line no-useless-constructor
	constructor(assetName, oid, params = null) {
		super(assetName, oid, params);
		this.assetName = assetName;
		this.oid = oid;
	}
}
