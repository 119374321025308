<template>
	<modal :visible.sync="dVisible" :close-visible="false" :can-close="canClose" @cancel="close">
		<template v-if="!!this.$slots.tabs" v-slot:header>
			<div class="tabs">
				<slot name="tabs"></slot>
			</div>
		</template>
		<div v-if="!!this.$slots.info" name="info" class="info">
			<slot name="info"></slot>
		</div>
		<div v-if="!!this.$slots.fields" class="content form">
			<div class="container">
				<div class="row">
					<slot name="fields"></slot>
				</div>
			</div>
		</div>
		<template v-if="!!this.$slots.footer" v-slot:footer>
			<slot name="footer"></slot>
		</template>
		<slot />
	</modal>
</template>

<script>
import modal from '~/components/modal.vue';
export default {
	components: {
		modal,
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		tabs: {
			type: Object,
			default: null,
		},
		canClose: {
			type: Boolean,
			default: false,
		},
	},
	data: () => {
		return {
			closing: false,
			dVisible: true,
			oTabs: {},
		};
	},
	provide() {
		return {
			tabs: this.oTabs,
		};
	},
	watch: {
		visible: {
			handler() {
				this.dVisible = this.visible;
			},
			immediate: true,
		},
		dVisible: {
			handler() {
				this.$emit('update:visible', this.dVisible);
			},
		},

		tabs: {
			handler() {
				this.oTabs = this.tabs;
			},
			immediate: true,
		},
		oTabs: {
			handler() {
				this.$emit('update:tabs', this.oTabs);
			},
		},
	},
	mounted() {},
	destroyed() {},
	methods: {
		close() {
			this.dVisible = false;
			this.$emit('cancel');
		},
	},
};
</script>

<style lang="less" scoped>
.tabs {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: space-between;
	align-items: center;
}
.info {
	position: relative;
	margin: 20px;
	display: block;
	float: left;
}
</style>
