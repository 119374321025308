import { Data } from './data';
import { Languages } from './languages';
import { Store } from './store';

export class FloorApplications extends Data {
	objectName = 'FloorApplications';
	static CURRENT = null;
	url = 'FloorApplications';
	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		data.language = Languages.CURRENT.currentLanguage;
		data.companyId = Store.CURRENT.companies.selectedCompany.id;
		return super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
	}
	getByConsequenceClass(consequenceClass, thenCallBack) {
		// Kijk of lijst al opgehaald is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null && this._list.list.length > 0) {
			let find = this._list.list.filter((fa) => typeof fa.defaultConsequenceClass !== 'undefined' && fa.defaultConsequenceClass !== null && fa.defaultConsequenceClass.value === consequenceClass);
			if (find.length > 0) {
				if (typeof thenCallBack === 'function') {
					thenCallBack(find[0]);
				} else {
					return find[0];
				}
			}
			return null;
		} else if (this.listRequest === null) {
			// lijst niet opgehaald en geen lopend request dan ophalen
			this.list(null, false, null, '', () => {
				let find = this._list.list.filter((fa) => typeof fa.defaultConsequenceClass !== 'undefined' && fa.defaultConsequenceClass !== null && fa.defaultConsequenceClass.value === consequenceClass);
				if (find.length > 0) {
					if (typeof thenCallBack === 'function') {
						thenCallBack(find[0]);
					} else {
						return find[0];
					}
				}
			});
		} else {
			// Wanneer request nooit is opgehaald, deze ophalen en terugsturen
			this.list(null, false, null, '', () => {
				let find = this._list.list.filter((fa) => typeof fa.defaultConsequenceClass !== 'undefined' && fa.defaultConsequenceClass !== null && fa.defaultConsequenceClass.value === consequenceClass);
				if (find.length > 0) {
					if (typeof thenCallBack === 'function') {
						thenCallBack(find[0]);
					} else {
						return find[0];
					}
				}
			});
		}
	}
}
