<template>
	<nuxt />
</template>

<script>
export default {
	transition: {
		name: 'page',
	},
};
</script>

<style lang="less">
body {
	font-family: 'Lato', Helvetica, Arial, sans-serif;
}
</style>
