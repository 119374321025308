export class DrawValue {
	objectName = 'DrawValue';
	value = 0;
	offsetScaled = 0;
	isScaled = false;
	constructor(value = 0, offsetScaled = 0, isScaled = false) {
		this.value = value;
		this.offsetScaled = offsetScaled;
		this.isScaled = isScaled;
	}
	scaledValue(scaleFactor) {
		if (this.isScaled === true) {
			return this.value + this.offsetScaled;
		} else {
			return this.value * scaleFactor + this.offsetScaled;
		}
	}
	sum(valueToAdd) {
		if (this.isScaled === true) {
			if (valueToAdd.isScaled === true) {
				// allebei scaled dan eindresultaat ook scaled
				return new DrawValue(this.value + valueToAdd.value, this.offsetScaled + valueToAdd.offsetScaled, true);
			} else {
				return new DrawValue(valueToAdd.value, this.value + this.offsetScaled + valueToAdd.offsetScaled);
			}
		} else if (valueToAdd.isScaled === true) {
			return new DrawValue(this.value, valueToAdd.value + this.offsetScaled + valueToAdd.offsetScaled);
		} else {
			return new DrawValue(this.value + valueToAdd.value, this.offsetScaled + valueToAdd.offsetScaled);
		}
	}
}
