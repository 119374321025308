/*
	Dit object tekent de paaltjes van het leuning werk bij een trap, vanuit Stair3D word de handrail aangeroepen.
*/

import { Configuration } from '../../../../configurator/configuration';
import { Stair } from '../../../../configurator/stair';
import { StairOutSide } from '../../../../configurator/stairOutSide';
import { Store } from '../../../../data/store';
import { Canvas3D } from '../../../Canvas3D';
import { StairHandRailChildPole3D } from './StairHandRailChildPole3D';
import { StairHandRailPlate3D } from './StairHandRailPlate3D';
import { StairHandRailPole3D } from './StairHandRailPole3D';

export class StairHandRail3D {
	objectName = 'StairHandRail3D';
	constructor(x, y, params) {
		let isChildFriendly = Store.CURRENT.stairs.isStairChildFriendly(params.stairData.type);
		let amountofPoles = Math.ceil(params.shared.diagonalLength / 1000 / 1.5) + 1;
		let distance = params.shared.deltaX / (amountofPoles - 1);
		let childPoleDistance = distance - 70;
		let amountofChildPoles = Math.floor(childPoleDistance / 100);
		let heightDif = (params.shared.yPosEnd - params.shared.yPos) / (amountofPoles - 1);

		let globalX = this.getX(x, params);
		let globalY = this.getY(y, params);
		let poleWidth = 70;

		// Railing
		Canvas3D.CURRENT.addDrawObject(
			new StairHandRailPlate3D(x, 0, y, { stairData: params.stairData, shared: params.shared, trapboomPosition: 'left', ralColor: Configuration.CURRENT.colors.stairs.ralColor }),
			Canvas3D.TYPE_HANDRULE,
		);
		Canvas3D.CURRENT.addDrawObject(
			new StairHandRailPlate3D(x, 0, y, { stairData: params.stairData, shared: params.shared, trapboomPosition: 'right', ralColor: Configuration.CURRENT.colors.stairs.ralColor }),
			Canvas3D.TYPE_HANDRULE,
		);

		// Knie railing
		if (params.stairData.type !== 224) {
			Canvas3D.CURRENT.addDrawObject(
				new StairHandRailPlate3D(x, 0, y, { stairData: params.stairData, shared: params.shared, trapboomPosition: 'left', knee: true, ralColor: Configuration.CURRENT.colors.stairs.ralColor }),
				Canvas3D.TYPE_KNEERULE,
			);
			Canvas3D.CURRENT.addDrawObject(
				new StairHandRailPlate3D(x, 0, y, { stairData: params.stairData, shared: params.shared, trapboomPosition: 'right', knee: true, ralColor: Configuration.CURRENT.colors.stairs.ralColor }),
				Canvas3D.TYPE_KNEERULE,
			);
		}

		switch (params.shared.upComing) {
			case Stair.UPCOMING_LEFT:
				for (let i = 0; i < amountofPoles; i++) {
					let newY = heightDif * i;
					let newX = distance * i - poleWidth;

					// Tussenpaaltjes, niet bij laatste paaltje
					if (isChildFriendly) {
						if (i !== amountofPoles - 1) {
							for (let childI = 0; childI < amountofChildPoles; childI++) {
								let childPoleX = newX + (childPoleDistance / amountofChildPoles) * (childI + 1);
								let nextPoleY = heightDif * (i + 1);
								let heightDiffChildPoles = (nextPoleY - newY) / amountofChildPoles;
								let childPoleposY = newY + heightDiffChildPoles * (childI + 1);

								Canvas3D.CURRENT.addDrawObject(
									new StairHandRailChildPole3D(globalX + childPoleX, globalY, childPoleposY, {
										stairData: params.stairData,
										index: childI,
										trapboomPosition: 'left',
										shared: params.shared,
										oid: params.stairData.material.childFriendlyHandRailPost,
									}),
									Canvas3D.TYPE_POLE,
								);

								Canvas3D.CURRENT.addDrawObject(
									new StairHandRailChildPole3D(globalX + childPoleX, globalY, childPoleposY, {
										stairData: params.stairData,
										index: childI,
										trapboomPosition: 'right',
										shared: params.shared,
										oid: params.stairData.material.childFriendlyHandRailPost,
									}),
									Canvas3D.TYPE_POLE,
								);
							}
						}
					}

					Canvas3D.CURRENT.addDrawObject(
						new StairHandRailPole3D(globalX + newX, globalY, newY, {
							stairData: params.stairData,
							index: i,
							trapboomPosition: params.trapBoomPosition,
							shared: params.shared,
							ralColor: Configuration.CURRENT.colors.stairs.ralColor,
						}),
						Canvas3D.TYPE_POLE,
					);
					Canvas3D.CURRENT.addDrawObject(
						new StairHandRailPole3D(globalX + newX, globalY, newY, {
							stairData: params.stairData,
							index: i,
							trapboomPosition: params.trapboomPosition,
							shared: params.shared,
							ralColor: Configuration.CURRENT.colors.stairs.ralColor,
						}),
						Canvas3D.TYPE_POLE,
					);
				}

				break;
			case Stair.UPCOMING_RIGHT:
				for (let i = 0; i < amountofPoles; i++) {
					let newY = heightDif * i;
					let newX = distance * i;

					// Tussenpaaltjes, niet bij laatste paaltje
					if (isChildFriendly) {
						if (i !== amountofPoles - 1) {
							for (let childI = 0; childI < amountofChildPoles - 1; childI++) {
								let childPoleX = newX + (distance / amountofChildPoles) * (childI + 1);

								let nextPoleY = heightDif * (i + 1);
								let heightDiffChildPoles = (nextPoleY - newY) / amountofChildPoles;
								let childPoleposY = newY + heightDiffChildPoles * (childI + 1);

								Canvas3D.CURRENT.addDrawObject(
									new StairHandRailChildPole3D(globalX - childPoleX, globalY, childPoleposY, {
										stairData: params.stairData,
										index: childI,
										trapboomPosition: 'left',
										shared: params.shared,
										oid: params.stairData.material.childFriendlyHandRailPost,
									}),
									Canvas3D.TYPE_POLE,
								);

								Canvas3D.CURRENT.addDrawObject(
									new StairHandRailChildPole3D(globalX - childPoleX, globalY, childPoleposY, {
										stairData: params.stairData,
										index: childI,
										trapboomPosition: 'right',
										shared: params.shared,
										oid: params.stairData.material.childFriendlyHandRailPost,
									}),
									Canvas3D.TYPE_POLE,
								);
							}
						}
					}

					Canvas3D.CURRENT.addDrawObject(
						new StairHandRailPole3D(globalX - newX, y, newY, {
							stairData: params.stairData,
							index: i,
							trapboomPosition: params.trapBoomPosition,
							shared: params.shared,
							ralColor: Configuration.CURRENT.colors.stairs.ralColor,
						}),
						Canvas3D.TYPE_POLE,
					);
					Canvas3D.CURRENT.addDrawObject(
						new StairHandRailPole3D(globalX - newX, y, newY, {
							stairData: params.stairData,
							index: i,
							trapboomPosition: params.trapboomPosition,
							shared: params.shared,
							ralColor: Configuration.CURRENT.colors.stairs.ralColor,
						}),
						Canvas3D.TYPE_POLE,
					);
				}
				break;

			case Stair.UPCOMING_TOP:
			case Stair.UPCOMING_BOTTOM:
				for (let i = 0; i < amountofPoles; i++) {
					let newY = distance * i - (params.shared.upComing === Stair.UPCOMING_TOP ? poleWidth : 0);
					let Height = heightDif * i;

					// Tussenpaaltjes, niet bij laatste paaltje
					// if (i === 0) {
					if (isChildFriendly) {
						if (i !== amountofPoles - 1) {
							for (let childI = 0; childI < amountofChildPoles - 1; childI++) {
								let nextPoleY = heightDif * (i + 1);
								let heightDifChildPoles = (nextPoleY - Height) / amountofChildPoles;
								let childPoleposY = Height + heightDifChildPoles * (childI + 1);
								let childPoleY = newY + (childPoleDistance / amountofChildPoles) * (childI + 1);

								if (params.shared.upComing === Stair.UPCOMING_TOP) {
									Canvas3D.CURRENT.addDrawObject(
										new StairHandRailChildPole3D(globalX, globalY + childPoleY, childPoleposY, {
											stairData: params.stairData,
											index: childI,
											trapboomPosition: 'left',
											shared: params.shared,
											oid: params.stairData.material.childFriendlyHandRailPost,
										}),
										Canvas3D.TYPE_POLE,
									);

									Canvas3D.CURRENT.addDrawObject(
										new StairHandRailChildPole3D(globalX, globalY + childPoleY, childPoleposY, {
											stairData: params.stairData,
											index: childI,
											trapboomPosition: 'right',
											shared: params.shared,
											oid: params.stairData.material.childFriendlyHandRailPost,
										}),
										Canvas3D.TYPE_POLE,
									);
								} else {
									Canvas3D.CURRENT.addDrawObject(
										new StairHandRailChildPole3D(globalX, globalY - childPoleY, childPoleposY, {
											stairData: params.stairData,
											index: childI,
											trapboomPosition: 'left',
											shared: params.shared,
											oid: params.stairData.material.childFriendlyHandRailPost,
										}),
										Canvas3D.TYPE_POLE,
									);

									Canvas3D.CURRENT.addDrawObject(
										new StairHandRailChildPole3D(globalX, globalY - childPoleY, childPoleposY, {
											stairData: params.stairData,
											index: childI,
											trapboomPosition: 'right',
											shared: params.shared,
											oid: params.stairData.material.childFriendlyHandRailPost,
										}),
										Canvas3D.TYPE_POLE,
									);
								}
							}
						}
					}
					// }

					if (params.shared.upComing === Stair.UPCOMING_TOP) {
						Canvas3D.CURRENT.addDrawObject(
							new StairHandRailPole3D(globalX, globalY + newY, Height, {
								stairData: params.stairData,
								index: i,
								trapboomPosition: params.trapBoomPosition,
								shared: params.shared,
								ralColor: Configuration.CURRENT.colors.stairs.ralColor,
							}),
							Canvas3D.TYPE_POLE,
						);
						Canvas3D.CURRENT.addDrawObject(
							new StairHandRailPole3D(globalX, globalY + newY, Height, {
								stairData: params.stairData,
								index: i,
								trapboomPosition: params.trapboomPosition,
								shared: params.shared,
								ralColor: Configuration.CURRENT.colors.stairs.ralColor,
							}),
							Canvas3D.TYPE_POLE,
						);
					} else {
						Canvas3D.CURRENT.addDrawObject(
							new StairHandRailPole3D(globalX, globalY - newY, Height, {
								stairData: params.stairData,
								index: i,
								trapboomPosition: params.trapBoomPosition,
								shared: params.shared,
								ralColor: Configuration.CURRENT.colors.stairs.ralColor,
							}),
							Canvas3D.TYPE_POLE,
						);
						Canvas3D.CURRENT.addDrawObject(
							new StairHandRailPole3D(globalX, globalY - newY, Height, {
								stairData: params.stairData,
								index: i,
								trapboomPosition: params.trapboomPosition,
								shared: params.shared,
								ralColor: Configuration.CURRENT.colors.stairs.ralColor,
							}),
							Canvas3D.TYPE_POLE,
						);
					}
				}
				break;
		}
	}

	getX(x, params) {
		if (params.stairData.place !== 'inFloor') {
			switch (params.shared.upComing) {
				case Stair.UPCOMING_LEFT:
					x -= params.shared.deltaX;
					break;
				case Stair.UPCOMING_RIGHT:
					x += params.shared.deltaX;
					break;
			}
		}

		return x;
	}

	getY(y, params) {
		if (params.stairData.place !== 'inFloor') {
			let poleWidth = 70;
			switch (params.shared.upComing) {
				case StairOutSide.POSITION_BOTTOM:
					y += params.shared.deltaX;
					y -= poleWidth;
					break;
				case Stair.UPCOMING_TOP:
					y -= params.shared.deltaX;
					break;
			}
		}
		return y;
	}
}
