export class BuildingObject {
	objectName = '';
	width = 0;
	height = 0;
	depth = 0;
	x = 0;
	y = 0;
	z = 0;
	doorOpening = null;
	doorType = null;
	texture = {
		name: null,
	};
	ralColor = null;

	constructor(params) {
		Object.keys(params).forEach((param) => {
			this[param] = params[param];
		});

		if (params.objectName === 'wall') {
			this.ralColor = 7036;
		}

		this.x = params.location.X;
		this.y = params.location.Z;
		this.z = params.location.Y;
	}
}
