import { DrawValue } from '../draw/drawValue';

import { Line } from '../draw/line';
import { Profiles } from './profiles';
import { Stairs } from './stairs';
import { StairTrimmings } from './stairTrimmings';
import { Columns } from './columns';
import { Configuration } from './configuration';

export class StairTrimming {
	objectName = 'StairTrimming';
	startX = 0;
	startY = 0;
	endX = 0;
	startY = 0;
	position = -1;
	enabled = true;
	raster = [];
	constructor(trimming) {
		if (typeof trimming !== 'undefined' && trimming !== null) {
			this.update(trimming);
		}
	}
	update(trimming) {
		this.startX = trimming.startX;
		this.startY = trimming.startY;
		this.endX = trimming.endX;
		this.endY = trimming.endY;
		this.position = trimming.position;

		if (typeof trimming.active === 'undefined' || trimming.active === null) {
			// niet meegegeven dan default true
			this.active = true;
		} else {
			// anders overnemen
			this.active = trimming.active;
		}
	}
	addDrawObjects(canvas, params) {
		let startX = new DrawValue(this.startX);
		let startY = new DrawValue(this.startY);
		let endX = new DrawValue(this.endX);
		let endY = new DrawValue(this.endY);
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			if (this.position === StairTrimmings.LEFT || this.position === StairTrimmings.RIGHT) {
				startY = new DrawValue(this.startY, Columns.COLUMN_SIZE / 2);
				endY = new DrawValue(this.endY, -Columns.COLUMN_SIZE / 2);
			}
		} else if (this.position === StairTrimmings.TOP || this.position === StairTrimmings.BOTTOM) {
			startX = new DrawValue(this.startX, Columns.COLUMN_SIZE / 2);
			endX = new DrawValue(this.endX, -Columns.COLUMN_SIZE / 2);
		}

		const stairTrimming = new Line(startX, startY, endX, endY, null, null, params.hasErrors === true ? Stairs.COLORS.stairCollisions : Profiles.COLOR.mainBeam, null, null, null, this, {
			type: this.type,
			color: Profiles.COLOR.mainBeam,
		});
		stairTrimming.opacity = this.enabled === true ? 1 : 0;
		stairTrimming.colorFromParent = false;

		this.drawObjectId = stairTrimming.id;

		return stairTrimming;
	}

	calculateAmount(params) {
		let raster = params.raster.getRasterByCoordinate(this.startX, this.startY);
		let span;

		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			span = params.raster.spansX.get(raster.x);
		} else {
			span = params.raster.spansY.get(raster.y);
		}

		// Check of undefined of null span,
		// Nodig omdat wanneer trap in vloer staat en dat raster word weggehaald dan moet de trap nog wel getekend worden.
		// Hij word wel rood, maar de berekeningen van beams word nog steeds gedaan.
		// Wanneer zijn eigen raster er dus niet (meer) is dan kan hij ook geen beams vinden.
		if (span !== null && typeof span !== 'undefined') {
			if (this.startX === this.endX) {
				// Verticale balken bij top / bottom geroteerde trap.
				// Horizontale balken bij links / rechts geroteerde trap.
				Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endY - this.startY), 1, span, params.stairId);
			} else {
				// Horizontale balken bij top / bottom geroteerde trap.
				// Verticale balken bij links / rechts geroteerde trap.
				Configuration.CURRENT.profiles.addAmountMainBeam(Math.round(this.endX - this.startX), 1, span, params.stairId);
			}
		}
	}
	getAmountData() {
		let mainBeam = Configuration.CURRENT.profiles.mainBeam;
		let length = this.endX - this.startX;
		if (length === 0) {
			length = this.endY - this.startY;
		}
		return { id: mainBeam.salesProductId, name: mainBeam.name, length: length, amount: 1, color: Configuration.CURRENT.colors.profiles.ralColor, finish: Configuration.CURRENT.colors.profiles.finish };
	}
}
