import * as pc from 'playcanvas';
import { Store } from '../../data/store';
import { Canvas3D } from '../Canvas3D';
export class Asset3D {
	objectName = 'Asset3D';
	assetName = '';
	type = '';
	oid = null;
	constructor(assetName, oid, params = null) {
		this.assetName = assetName.replaceAll('/', '_');
		this.oid = oid;

		// Voor modellen die nog niet uit ERP komen.
		if (params !== null) {
			// Set assetname based on if OID is null or not
			if (oid === null || typeof oid === 'undefined') {
				this.assetName = params.fallBackName;
			}

			// Fallback
			this.fallBackName = params.fallBackName;
			this.fallBackData = params.fallBackData;
		}
	}
	load(thenCallBack) {
		if (typeof this.oid === 'undefined' || this.oid === null || this.oid === -1) {
			// geen oid automatisch fallback en geen load to server
			// this.checkFallBackModel(this.fallBackName, thenCallBack);
			thenCallBack();
			return;
		}

		Store.CURRENT.models.getById(
			this.oid,
			false,
			(response) => {
				this.handleResponse(response, thenCallBack);
			},
			(error) => {
				console.log(error, new Error(), error.response.status);
				// If article not found
				if (error.response.status === 404) {
					this.checkFallBackModel(this.fallBackName, thenCallBack);
				}
			},
			this.assetName,
		);
	}

	handleResponse(response, thenCallBack) {
		try {
			if (typeof Canvas3D.CURRENT.app.assets !== 'undefined') {
				if ((typeof response !== 'object' && Object.keys(response.model).length === 0) || response.model === null) {
					// Als het model niet geladen kan worden dan een default hier voor inladen.
					thenCallBack();
				} else {
					// Model vanuit ERP
					let blob = new Blob([JSON.stringify(response.model)]);
					let url = URL.createObjectURL(blob);

					// In ERP staat de diepte als width opgeslagen, wij gebruiken dit in 3d als depth.
					// Daarom draaien we hier de waarden om.
					let convertedDetails = {
						width: response.details.depth,
						height: response.details.height,
						depth: response.details.width,
						thickness: response.details.thickness
					};

					if (response.mapping !== null && typeof response.mapping !== 'undefined') {
						this.loadMappingFile(response.mapping);
					}

					if (response.colors !== null && typeof response.colors !== 'undefined') {
						response.colors.forEach((color) => {
							this.createColorAsset(color);
						});
					}

					this.modelData = convertedDetails;

					let filename = this.assetName + '.json';
					this.loadModel(filename, url, thenCallBack);
				}
			}
		} catch (exception) {
			if (typeof thenCallBack === 'function') {
				thenCallBack();
			}
		}
	}

	loadModel(assetName, url, thenCallBack) {
		Canvas3D.CURRENT.app.assets.loadFromUrlAndFilename(url, assetName, 'model', (err, asset) => {
			// Oid toewijzen zodat we hem kunnen zoeken op basis van dit oid.
			asset.oid = this.oid;

			// Modeldata vanuit ERP
			asset.modelData = this.modelData;

			// Fallback details
			if (typeof asset.fallBackData !== null && typeof asset.fallBackData !== 'undefined') {
				asset.fallBackData = this.fallBackData;
			}

			// Naam om assets op te kunnen zoeken
			if (typeof asset.fallBackName !== null && typeof asset.fallBackName !== 'undefined') {
				asset.fallBackName = this.fallBackName;
			}

			if (err || !asset) {
				console.log('fout met laden model of texture:', err, url, assetName, new Error());
			}
			if (typeof thenCallBack === 'function') {
				thenCallBack();
			}
		});
	}

	checkFallBackModel(fallBackName, thenCallBack) {
		// Checken of dit fallback model al in het registery zit.
		let assetExists = Canvas3D.CURRENT.app.assets.filter((asset) => asset.name === fallBackName + '.json');
		// Zo niet deze inladen.
		if (assetExists.length === 0) {
			// Locale file dus URL even samenstellen.
			let url = '/playcanvas/' + fallBackName + '/' + fallBackName + '.json';
			this.loadModel(fallBackName + '.json', url, thenCallBack);
		} else if (typeof thenCallBack === 'function') {
			// Als de fallback al in de assetsManifest zit dan hoeven we deze niet te laden dus roepen we de callback aan.
			thenCallBack();
		}
	}

	loadMappingFile(mapping) {
		let mappingName = this.oid + '.mapping.json';

		let asset = new pc.Asset(mappingName, 'json');
		asset.mapping = mapping;

		Canvas3D.CURRENT.app.assets.add(asset);
		Canvas3D.CURRENT.app.assets.load(asset);
	}

	createColorAsset(color) {
		let name = color.name.toLowerCase().replaceAll(' ', '_') + '.json';

		if (!this.assetExsists(name)) {
			let asset = new pc.Asset(name, 'material', null, color.color);

			Canvas3D.CURRENT.app.assets.add(asset);
			Canvas3D.CURRENT.app.assets.load(asset);
		}
	}

	assetExsists(name) {
		let search = Canvas3D.CURRENT.app.assets.filter((asset) => asset.name === name && asset.type === 'material');

		if (search.length === 0) {
			return false;
		}

		return true;
	}
}
