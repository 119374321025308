/* eslint-disable no-useless-constructor */
import * as pc from 'playcanvas';
import { Canvas3D } from '../Canvas3D';
import { Profiles3D } from './Profiles3D';
export class ProfilesHorizontal3D extends Profiles3D {
	constructor(x, y, length, params) {
		super(x, y, length, params);
	}
	draw(app, entityName = null) {
		let beams = this.getBeamAmount(this.width, this.beamLengths);
		this.assetName = this.beamName + '_';

		this.x = this.x / 1000;
		this.y = this.y / 1000;
		this.z = this.z / 1000;

		beams.sort((a, b) => a.length - b.length); // oplopend sorteren we starten met de kleinste
		let startX = 0;

		beams.forEach((b, index) => {
			// als deelbaar door 2 dan aantal (afgerond naar beneden) keer tekenen
			if (Math.floor(b.count / 2) > 0) {
				for (let i = 0; i < Math.floor(b.count / 2); i++) {
					this.assetName = this.getAssetName() + b.length;

					let newX = this.x + startX / 1000;
					this.createProfile(app, { x: newX, y: this.y, z: this.z }, entityName + '_' + b.length);
					startX += b.length;
				}
			}
			// sla lengte op zonder aantal (afgerond naar beneden)
			b.count = b.count - Math.floor(b.count / 2);
		});

		beams.sort((a, b) => b.length - a.length); // aflopend sorteren we starten met de grootste
		beams.forEach((b) => {
			// als deelbaar door 2 dan aantal (afgerond naar beneden) keer tekenen
			if (b.count > 0) {
				for (let i = 0; i < b.count; i++) {
					this.assetName = this.getAssetName() + b.length;

					let newX = this.x + startX / 1000;
					this.createProfile(app, { x: newX, y: this.y, z: this.z }, entityName + '_' + b.length);
					startX += b.length;
				}
			}
		});
	}
}
