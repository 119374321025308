import { Model3D } from '../Model3D';
// Plaatje op de paal, alleen op het eerste en laatste paaltje
export class HandRailPoleCapPlate3D extends Model3D {
	objectName = 'HandRailPoleCapPlate3D';
	hoverTags = ['handrail'];
	draw(app) {
		this.oid = this.handRailMaterials.capPlate;
		this.y += this.cornerPoleLength;
		this.ralColor = 7016;
		super.draw(app);
	}
}
