import { Data } from './data';

export class Project extends Data {
	objectName = 'Project';
	id = -1;
	projectNumber = '';
	url = 'Projects';
	reference = '';
	company = {};
	calculations = [];
	contact = {};
	type = {};
	creationDate = '';
	projectDate = '';

	getCalculations() {
		super.getById(this.id, true, (r) => {
			this.calculations = r[0].calculations;
		});
	}
}
