import { Data } from './data';

export class FloorVariableLoads extends Data {
	objectName = 'FloorVariableLoads';
	static CURRENT = null;
	url = 'FloorVariableLoads';
	getItem(variableLoad) {
		let vlFind = this._list.list.filter((vl) => vl.value === variableLoad);
		if (vlFind.length > 0) {
			return vlFind[0];
		}
		return null;
	}
	getMaxCtC(variableLoad) {
		if (typeof variableLoad === 'undefined' || variableLoad === null) {
			return 0;
		}
		let vlFind = this._list.list.filter((vl) => vl.value === variableLoad);
		if (vlFind.length > 0) {
			return vlFind[0].maxCtC;
		}
		return 0;
	}

	// list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
	// 	return [];
	// }
}
