/* eslint-disable no-new */

import { Configuration } from '../../../configurator/configuration';
import { Stair } from '../../../configurator/stair';
import { Canvas3D } from '../../Canvas3D';
import { ChildBeamBottom3D } from '../../profiles/childbeams/standard/ChildBeamBottom3D';
import { ChildBeamLeft3D } from '../../profiles/childbeams/standard/ChildBeamLeft3D';
import { ChildBeamRight3D } from '../../profiles/childbeams/standard/ChildBeamRight3D';
import { ChildBeamTop3D } from '../../profiles/childbeams/standard/ChildBeamTop3D';
import { MainBeamBottom3D } from '../../profiles/mainbeams/standard/MainBeamBottom3D';
import { MainBeamLeft3D } from '../../profiles/mainbeams/standard/MainBeamLeft3D';
import { MainBeamRight3D } from '../../profiles/mainbeams/standard/MainBeamRight3D';
import { MainBeamTop3D } from '../../profiles/mainbeams/standard/MainBeamTop3D';

export class LandingBeams3D {
	objectName = 'LandingBeams3D';
	// eslint-disable-next-line no-useless-constructor
	constructor(x, y, params) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		let CTC = Configuration.CURRENT.profiles._centerToCenter;
		let childBeamCount;
		let startDrawHeight = params.drawHeight;

		//* * MAINBEAMS
		if (params.upComing === Stair.UPCOMING_TOP || params.upComing === Stair.UPCOMING_BOTTOM) {
			childBeamCount = Math.ceil(params.depth / CTC);
			params.drawHeight = startDrawHeight - params.mainBeamHeight - deckingFinishHeight;
			params.oid = params.mainBeamId;
			new MainBeamLeft3D(x, y, params.depth, params);
			new MainBeamRight3D(x + params.width, y, params.depth, params);

			for (let i = 0; i < childBeamCount; i++) {
				params.drawHeight = startDrawHeight - params.childBeamHeight - deckingFinishHeight;
				let yPosition = this.getYPosition(y, CTC, i, params.upComing);
				let xPosition = this.getXPosition(x, CTC, i, params.upComing);
				params.oid = params.childBeamId;
				new ChildBeamTop3D(xPosition + params.mainBeamDepth, yPosition, params.width - params.mainBeamDepth * 2, params);
			}

			// Laatste is omgedraaid
			// Alleen tekenen als eindpunt precies profiel kan bevatten.
			if (params.width % CTC > params.childBeamDepth) {
				params.drawHeight = startDrawHeight - deckingFinishHeight;
				params.oid = params.childBeamId;
				new ChildBeamBottom3D(x + params.mainBeamDepth, y + params.depth, params.width - params.mainBeamDepth * 2, params);
			}
		} else {
			childBeamCount = Math.ceil(params.width / CTC);
			params.drawHeight = startDrawHeight - params.mainBeamHeight - deckingFinishHeight;
			params.oid = params.mainBeamId;
			new MainBeamTop3D(x, y, params.width, params);
			new MainBeamBottom3D(x, y + params.depth, params.width, params);

			for (let i = 0; i < childBeamCount; i++) {
				params.drawHeight = startDrawHeight - params.childBeamHeight - deckingFinishHeight;
				let yPosition = this.getYPosition(y, CTC, i, params.upComing);
				let xPosition = this.getXPosition(x, CTC, i, params.upComing);
				params.oid = params.childBeamId;
				new ChildBeamLeft3D(xPosition, yPosition + params.mainBeamDepth, params.depth - params.mainBeamDepth * 2, params);
			}

			// Laatste is omgedraaid
			// Alleen tekenen als eindpunt precies profiel kan bevatten.
			if (params.width % CTC > params.childBeamDepth) {
				params.drawHeight = startDrawHeight - deckingFinishHeight;
				params.oid = params.childBeamId;
				new ChildBeamRight3D(x + params.width, y + params.mainBeamDepth, params.depth - params.mainBeamDepth * 2, params);
			}
		}
	}
	getYPosition(y, CTC, i, position) {
		if (position === Stair.UPCOMING_BOTTOM || position === Stair.UPCOMING_TOP) {
			y += i * CTC;
		}
		return y;
	}
	getXPosition(x, CTC, i, position) {
		if (position === Stair.UPCOMING_LEFT || position === Stair.UPCOMING_RIGHT) {
			x += i * CTC;
		}
		return x;
	}
}
