/* eslint-disable no-useless-constructor */
import { Configuration } from '../../../configurator/configuration';
import { jsonProfile } from '../../../configurator/hicad/jsonProfile';
import { Raster } from '../../../configurator/raster';
import { Canvas3D } from '../../Canvas3D';
import { ProfilesVertical3D } from '../ProfilesVertical3D';

export class VerticalChildBeam3D extends ProfilesVertical3D {
	objectName = 'VerticalChildBeam3D';
	rotationY = -90;
	rotationX = -180;
	mainBeamDepth = 0;
	mainBeamHeight = 0;
	constructor(x, y, length, params) {
		
		// params.rotationY = -90;
		super(x, y, length, params);
		this.calculate(params);
		// this.rotationY = -90;
		this.parentEntityName = params.profileType + '_' + params.indexX + '_' + params.indexY + '_' + params.loopIndex;

		this.updateExceptions(params);
	}
	draw(app) {
		super.draw(app, 'childbeam');
	}

	updateExceptions(params) {
		let childBeamHeight = Canvas3D.CURRENT.getBeamData(params.oid, 'height');
		// wanneer het de laatste is, moet deze worden geroteerd in tegengestelde richting van de andere balken,
		// zodat de kpp geplaatst kan worden.
		if (params.isLast===true) {
			this.rotationX = 0;
			this.y -= childBeamHeight;
		}
	}

	calculate(params) {
		this.mainBeamDepth = params.mainBeamDepth;
		this.mainBeamHeight = params.mainBeamHeight;

		let childBeamDepth = Canvas3D.CURRENT.getBeamData(params.oid, 'depth');
		let childBeamHeight = Canvas3D.CURRENT.getBeamData(params.oid, 'height');

		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		let deckingFinishHeight = Configuration.CURRENT.finish.height;

		this.width = this.getVerticalChildBeamLength(this.width);

		if (this.buildingColumnBeam) {
			this.applyBuildingColumnBeamOffsets(app);
		} else if (this.multipleFloors) {
			if (this.doubleDouble) {
				this.z += this.mainBeamDepth;
			}
			if (this.rowPositions.includes(Raster.POSITION_TOP)) {
				this.z += columnWidth;
				this.z += this.mainBeamDepth;
			}
			if (this.placement === Raster.TYPE_MIDDLERASTER) {
				this.z += columnWidth / 2;
				this.z += this.mainBeamDepth;
			}
			if (this.rowPositions.includes(Raster.POSITION_BOTTOM)) {
				this.z += columnWidth / 2;
				this.z += this.mainBeamDepth;
			}
		} else {
			if (this.doubleDouble) {
				this.z += this.mainBeamDepth;
			}
			this.z += this.mainBeamDepth;
		}
		this.x += childBeamDepth;

		this.y += this.mainBeamHeight - childBeamHeight;
		// this.y -= childBeamHeight;
		this.y -= deckingFinishHeight;
		this.y -= this.mainBeamHeight;
		this.y += childBeamHeight;
	}

	applyBuildingColumnBeamOffsets() {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');
		let mainBeamDepth = this.mainBeamDepth;

		if (this.multipleFloors) {
			if (this.rowPositions.includes(Raster.POSITION_TOP)) {
				if (this.buildingColumnBeamPosition === 'start') {
					if (this.doubleDouble) {
						this.z += mainBeamDepth;
					}
					this.z += columnWidth;
					this.z += mainBeamDepth;
				} else {
					this.z += mainBeamDepth;
				}
			} else if (this.rowPositions.includes(Raster.POSITION_BOTTOM)) {
				if (this.buildingColumnBeamPosition === 'start') {
					if (this.doubleDouble) {
						this.z += mainBeamDepth;
					}
					this.z += columnWidth / 2;
					this.z += mainBeamDepth;
				} else {
					this.z += mainBeamDepth;
				}
			} else if (this.buildingColumnBeamPosition === 'start') {
				this.z += columnWidth / 2;
				this.z += mainBeamDepth;
			} else {
				this.z += mainBeamDepth;
			}
		} else {
			if (this.buildingColumnBeamPosition === 'start') {
				if (this.doubleDouble) {
					this.z += mainBeamDepth;
				}
			}
			this.z += mainBeamDepth;
		}
	}

	getVerticalChildBeamLength(width) {
		let mainBeamDepth = this.mainBeamDepth;
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		if (this.multipleFloors) {
			// Meedere verdiepingen hele kolom eraf
			width -= columnWidth;
			if (this.doubleDouble) {
				// Bij dubbel dubbel komen er aan 2 kanten van het raster extra hoofdbalken dus halen we die hier van de lengte af.
				width -= mainBeamDepth * 2;
			}
			if (this.rowPositions.includes(Raster.POSITION_TOP) || this.rowPositions.includes(Raster.POSITION_BOTTOM)) {
				// Bij top en bottom rasters rekekenen we het raster tot een halve kolom, die helft moet dan ook van de lengte af.
				width -= mainBeamDepth * 2;
				width -= columnWidth / 2;
			}
			if (this.placement === Raster.TYPE_MIDDLERASTER) {
				width -= mainBeamDepth * 2;
			}
		} else {
			if (this.doubleDouble) {
				// Enkele verdieping dubbel dubbel halen we altijd halve kolom eraf, 1x mainbeamdepth omdat eerste profiel op de kolom staat en het 2e er dan naast.
				width -= mainBeamDepth;
				width -= columnWidth / 2;
			}
			width -= mainBeamDepth;
			width -= columnWidth;
		}

		return width;
	}

	// Convert VerticalChildBeam3D naar HiCADProfile, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_CHILDBEAM, direction: jsonProfile.DIRECTION_VERTICAL, position: jsonProfile.POSITION_LEFT });
	}
}
