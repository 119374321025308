import { Data } from './data';
import { Languages } from './languages';
import { Project } from './project';
import { Store } from './store';

export class Projects extends Data {
	objectName = 'Projects';
	static CURRENT = null;
	url = 'Projects';
	_activeProjectId = 0;
	_calculations = [];
	_activeProject = new Project();
	searchProjects(text, amount, thenCallBack) {
		let lastRequest = this.getLastRequest('list'); // ophalen laatste request
		let request = JSON.parse(JSON.stringify(lastRequest)); // ombouwen naar een zelfstandig object
		if (typeof request.data === 'undefined' || request.data === null) {
			request = { data: {} };
		}
		if (text === '') {
			// Als zoektekst leeg is gewoon 20 ophalen
			request.data.top = amount;
			request.data.company = this.store.companies.selectedCompany.id;
		} else {
			// Anders laatste request parameters gebruiken en text als zoekveld toevoegen.
			request.data.searchText = text; // zoektekst toevoegen
		}

		super.list(
			request.data,
			true,
			-1,
			null,
			(response) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack(response);
				}
			},
			(exception) => {
				if (typeof thenCallBack === 'function') {
					thenCallBack();
				}
			},
		);
	}

	get activeProjectId() {
		return this._activeProjectId;
	}

	set activeProjectId(value) {
		this._activeProjectId = value;
		this.activeProject = value;
		this.saveToLocalStorage();
	}

	get activeCalculationId() {
		return this._activeProjectId;
	}

	set activeCalculationId(value) {
		this._activeCalculationId = value;
		this.saveToLocalStorage();
	}

	get calculations() {
		return this._calculations;
	}

	set calculations(calculations) {
		this._calculations = calculations;
	}

	get activeProject() {
		return this._activeProject;
	}

	set activeProject(value) {
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			let found = null;
			// doorloop de lijst om te kijken of er een configuratie is met betreffend id
			this._list.list.forEach((project) => {
				if (project.id === value) {
					found = project; // bewaar deze
				}
			});
			if (found !== null) {
				// als config gevonden dan teruggeven
				this._activeProject = found;
			}
		}
		// anders default
		return { id: -1 };
	}
	setStore(store) {
		super.setStore(store);
		// registreren dat als de company gewijzigd is ook de offertes opgehaald worden die erbij horen
		store.companies.addOnChangeSelectedCompany(() => {
			for (let key in this.lastRequest) {
				let lastRequest = JSON.parse(JSON.stringify(this.lastRequest[key]));
				lastRequest.data.company = this.store.companies.selectedCompany.id;

				this.queue.push({
					data: lastRequest.data,
					force: false,
					cacheId: key,
					urlExtension: lastRequest.urlExtension,
					thenCallBack: this.lastRequest[key].thenCallBack, // functies gaan niet mee met parse en stringify
					catchCallBack: this.lastRequest[key].catchCallBack, // functies gaan niet mee met parse en stringify
				});
			}
		});
	}

	list(data, force, cacheId) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		// als nog geen company gekozen dan ook geen offertes laten zien
		if (this.store.companies.selectedCompany.id === -1) {
			return;
		}

		// company meegeven met request
		data.company = this.store.companies.selectedCompany.id;
		data.language = Languages.CURRENT.currentLanguage;
		let list = super.list(data, force, cacheId);
		return list;
	}

	fetchListItem(item) {
		// standaard teruggeven. In andere objecten kan hier een subobject gemaakt worden
		let returnItem = new Project();
		returnItem.setStore(this.store);
		returnItem.fetch(item);

		return returnItem;
	}

	get projectsList() {
		// aparte getter gebruikt omdat list opgehaald wordt met livesearch hierdoor hoeft de lijst zelf niet te weten wat de livesearch-text is
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			return this._list.list;
		} else if (this.listRequest === null) {
			this.list();
		}
		return [];
	}

	setSelectedValue(id, thenCallBack) {
		// gegevens van company ophalen
		this.activeProject.setStore(this.store);

		this.activeProject.getById(id, true, (response) => {
			this._activeProject.id = response[0].id;
			this._activeProject.projectNumber = response[0].projectNumber;
			this._activeProject.company = response[0].company;
			this._activeProject.calculations = response[0].calculations;
			this._activeProject.readOnly = response[0].readOnly;
			Store.CURRENT.companies.setSelectedValue(response[0].company, () => {
				if (typeof thenCallBack === 'function') {
					// TODO: readonly meegeven aan thenCallBack
					thenCallBack(response[0].readOnly);
				}
			});
		});

		// objecten informeren dat company is gewijzigd
		this.saveToLocalStorage();
	}
}
