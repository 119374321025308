import { Dimension3D } from '../dimension3D';

export class DimensionVertical3D extends Dimension3D {
	objectName = 'DimensionVertical3D';
	draw() {
		super.draw();
		// Omdat barEntity een box is dan deze goed positioneren op X as.
		this.barEntity.setLocalPosition(this.x, this.y, this.z + this.width / 1000 / 2);
		this.barEntity.setLocalScale(0.05, 0.05, this.width / 1000);

		// StartHandle altijd begin van raster.
		this.startHandle.setLocalPosition(this.x, this.y, this.z);
		this.startHandle.setLocalScale(this.handleDepth, 0.05, this.handleWidth);

		// Endhandle altijd aan het einde van het huidige raster.
		this.endHandle.setLocalPosition(this.x, this.y, this.z + this.width / 1000);
		this.endHandle.setLocalScale(this.handleDepth, 0.05, this.handleWidth);
	}
}
