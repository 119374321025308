import { jsonProfile } from '../../../../configurator/hicad/jsonProfile';
import { Canvas3D } from '../../../Canvas3D';
import { ProfilesVertical3D } from '../../ProfilesVertical3D';

export class ChildBeamLeft3D extends ProfilesVertical3D {
	objectName = 'ChildBeamLeft3D';
	profileType = 'childbeam';
	rotationX = 180;
	rotationY = -90;
	constructor(x, y, length, params) {
		super(x, y, length, params);
		this.depth = 1;
	}
	draw(app) {
		let childBeamHeight = Canvas3D.CURRENT.getBeamData(this.oid, 'height');
		this.y += childBeamHeight;
		super.draw(app);
	}
	// Convert ChildBeamLeft3D HiCADProfile, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_CHILDBEAM, direction: jsonProfile.DIRECTION_VERTICAL, position: jsonProfile.POSITION_LEFT });
	}
}
