import { Configuration } from '../../../../configurator/configuration';
import { HandRails } from '../../../../configurator/handRails';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class HandRailKickEdgeHolderBottom3D extends Model3D {
	objectName = 'HandRailKickEdgeHolderBottom3D';
	fallBackData = { width: 80, height: 140, depth: 40 };
	ralColor = 7016;
	constructor(x, y, z, params) {
		if (params.position === HandRails.RIGHT) {
			params.assetName = 'handrail_kickedge_holder_right';
			params.fallBackName = 'handrail_kickedge_holder_right';
		} else {
			params.assetName = 'handrail_kickedge_holder_left';
			params.fallBackName = 'handrail_kickedge_holder_left';
		}
		super(x, y, z, params);
	}
	draw(app) {
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');
		let modelDepth = Canvas3D.CURRENT.getModelData(this.fallBackName, 'depth');
		this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;

		if (this.position === HandRails.LEFT) {
			this.rotationY = 90;
			this.x += poleWidth - modelDepth;
		} else {
			this.rotationY = -90;
			this.x += modelDepth;
			this.z -= poleWidth;
			this.z -= 10;
		}
		super.draw(app);
	}
}
