import { NenEnIso14122 } from './NenEnIso14122.js';
import { UkGeneralAccessStair } from './UkGeneralAccessStair.js';
import { UkUtilityStair } from './UkUtilityStair.js';
import { LogisStair } from './LogisStair.js';
import { SpecialStair } from './SpecialStair.js';
import { Standard } from './standard.js';
export class DynamicStandard {
	static classes = { NenEnIso14122, UkGeneralAccessStair, UkUtilityStair, LogisStair, SpecialStair };
	objectName = 'DynamicStandard';
	// class bewaren voor oude configuraties die kennen deze standaard nog ook.
	constructor(standardName) {
		if (typeof DynamicStandard.classes[standardName] !== 'undefined') {
			return new DynamicStandard.classes[standardName](standardName);
		}
		return new Standard(standardName);
	}
}
