import { Configuration } from '../../configurator/configuration';
import { Stair } from '../../configurator/stair';
import { Canvas3D } from '../Canvas3D';
import { Store } from '../../data/store';
import { HandRail3D } from '../handrail/handRail3D';
import { HandRails } from '../../configurator/handRails';

export class StairInFloorHandRail3D {
	constructor(stairObject, posY = 0) {
		let params = {};
		// Bij infloor handrail gebruiken we dezelfde handrailitems als van de handrail normaal (Dus niet die van de stair)
		// Daarom gewoon handrailtype gebruiken (oid van de saleslocatie).
		params.handRailMaterials = Store.CURRENT.handRails.getMaterials(Configuration.CURRENT.handRailType);
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(params.handRailMaterials.post, 'width');

		let x = stairObject.stairWell.startX;
		let y = posY + stairObject.etageHeight;
		let z = stairObject.stairWell.startY;

		let stairWellWidth = stairObject.stairWell.width;
		let stairWellDepth = stairObject.stairWell.depth;

		let stairX = stairObject.stairBoundries.topLeft.x;
		let stairY = stairObject.stairBoundries.topLeft.y;
		let stairWidth = stairObject.width;

		this.poleColor = Configuration.CURRENT.colors.handRail.ralColor;
		this.plateColor = 7036;

		let paramPreset = {
			childFriendly: Store.CURRENT.handRails.isChildFriendly(Configuration.CURRENT.handRailType),
			handRailType: Configuration.CURRENT.handRailType,
		};

		// this.plateColor = Configuration.CURRENT.colors.handRail.ralColor;

		if (stairObject.upComing === Stair.UPCOMING_LEFT || stairObject.upComing === Stair.UPCOMING_RIGHT) {
			// Bij links of rechts upcoming dan altijd boven en onder een lang horizontaal stuk.
			Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, stairWellWidth, true), Canvas3D.TYPE_HANDRAIL);
			Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + stairWellDepth, paramPreset, HandRails.BOTTOM, stairWellWidth, true), Canvas3D.TYPE_HANDRAIL);

			if (stairObject.upComing === Stair.UPCOMING_RIGHT) {
				// Bij upcoming right aan de linkerkant het lange verticale stuk.
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, stairWellDepth, true), Canvas3D.TYPE_HANDRAIL);

				// Dan aan de rechterkant klein stukje verticaal voor de trap.
				let firstLength = stairY - z + poleWidth;
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWellWidth, y, z, paramPreset, HandRails.RIGHT, firstLength, true), Canvas3D.TYPE_HANDRAIL);

				// En een klein stukje na de trap verticaal.
				let secondLength = z + stairWellDepth - (stairY + stairWidth) + poleWidth;
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWellWidth, y, z + stairWidth + firstLength - 2 * poleWidth, paramPreset, HandRails.RIGHT, secondLength, true), Canvas3D.TYPE_HANDRAIL);
			} else {
				// Bij upcoming left dan aan de rechterkant een lang verticaal stuk.
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWellWidth, y, z, paramPreset, HandRails.RIGHT, stairWellDepth, true), Canvas3D.TYPE_HANDRAIL);

				// Dan aan de linkerkant klein stukje verticaal voor de trap.
				let firstLength = stairY - z + poleWidth;
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, firstLength, true), Canvas3D.TYPE_HANDRAIL);

				// En aan de linkerkant klein stukje verticaal na de trap.
				let secondLength = z + stairWellDepth - (stairY + stairWidth) + poleWidth;
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + stairWidth + firstLength - 2 * poleWidth, paramPreset, HandRails.LEFT, secondLength, true), Canvas3D.TYPE_HANDRAIL);
			}
		} else if (stairObject.upComing === Stair.UPCOMING_BOTTOM || stairObject.upComing === Stair.UPCOMING_TOP) {
			// Bij top of bottom upcoming dan altijd links en rechts een lang verticaal stuk.
			Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.LEFT, stairWellDepth, true), Canvas3D.TYPE_HANDRAIL);
			Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWellWidth, y, z, paramPreset, HandRails.RIGHT, stairWellDepth, true), Canvas3D.TYPE_HANDRAIL);

			if (stairObject.upComing === Stair.UPCOMING_BOTTOM) {
				// Bij bottom upcoming hebben we dus aan de bovenkant een lang stuk horizonaal.
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, stairWellWidth, true), Canvas3D.TYPE_HANDRAIL);

				// Dan aan de onderkant klein stukje horizontaal voor de trap.
				let firstLength = stairX - x + poleWidth;
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + stairWellDepth, paramPreset, HandRails.BOTTOM, firstLength, true), Canvas3D.TYPE_HANDRAIL);

				// En een klein stukje na de trap horizontaal.
				let secondLength = x + stairWellWidth - (stairX + stairWidth) + 1 * poleWidth;
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWidth + firstLength - 2 * poleWidth, y, z + stairWellDepth, paramPreset, HandRails.BOTTOM, secondLength, true), Canvas3D.TYPE_HANDRAIL);
			} else {
				// Wanneer upcoming boven is dan aan de onderkant lang horizontaal stuk.
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z + stairWellDepth, paramPreset, HandRails.BOTTOM, stairWellWidth, true), Canvas3D.TYPE_HANDRAIL);

				// Dan aan de bovenkant 2 kleine stukjes horizontaal, de eerste voor de trap.
				let firstLength = stairX - x + poleWidth;
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x, y, z, paramPreset, HandRails.TOP, firstLength, true), Canvas3D.TYPE_HANDRAIL);

				// 2e stukje horizonaal precies na de trap.
				let secondLength = x + stairWellWidth - (stairX + stairWidth) + 1 * poleWidth;
				Canvas3D.CURRENT.addDrawObject(new HandRail3D(x + stairWidth + firstLength - 2 * poleWidth, y, z, paramPreset, HandRails.TOP, secondLength, true), Canvas3D.TYPE_HANDRAIL);
			}
		}
	}
}
