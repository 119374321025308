import { Configuration } from '../../configurator/configuration';
import { Canvas3D } from '../Canvas3D';
import { ColumnProtector3D } from './ColumnProtector3D';

export class ProtectorRight3D extends ColumnProtector3D {
  objectName = 'protectorRight3D';
  rotationY = -90;
  draw(app) {
    let modelWidth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'width');
    let modelDepth = Canvas3D.CURRENT.getModelDataByOid(this.oid, 'depth');
    let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

    this.z -= modelWidth / 2;
    this.z += columnWidth / 2;
    this.x += modelDepth;
    this.x += columnWidth;

    super.draw(app);
  }
}
