import { Store } from '../data/store';

export class Logging3D {
	objectName = 'Logging3D';
	errors = [];
	assetsCreatedDone = false;
	drawObjectsCreatedDone = false;
	drawDone = false;
	getErrors() {
		return {
			user: { ...Store.CURRENT.user },
			calculationId: Store.CURRENT.configurations.activeConfigId,
			errors: this.errors,
			assetsCreatedDone: this.assetsCreatedDone,
			drawObjectsCreatedDone: this.drawObjectsCreatedDone,
			drawDone: this.drawDone,
		};
	}
	newError(error) {
		error.time = new Date();
		this.errors.push(error);
	}

	setReferences() {
		// Bij opnieuw openen dan deze waarden terug op false zetten
		this.assetsCreatedDone = false;
		this.drawObjectsCreatedDone = false;
		this.drawDone = false;
	}
}
