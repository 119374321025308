import { Functions } from '../helpers/functions';
import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { Stair } from './stair';
import { Stairs } from './stairs';
import { IntermediateLandings } from './intermediateLandings';
import { Store } from '../data/store';
import { Configuration } from './configuration';

export class IntermediateLanding {
	objectName = 'IntermediateLanding';
	height = 0;
	id = '';
	width = 0;
	depth = 0;
	upComing = 3;
	_landingType = null;
	_finish = null;
	finishHeight = null;
	finishName = '';
	ctc = null;
	amountSteps = 0;
	rise = 0;
	_active = true;
	_disabledUpcomings = [];
	fallingProtectionStandardAdded = false; // bijhouden of deze toegevoegd is vanwege de norm. De default landings hebben dit op false staan

	get disabledUpcomings() {
		return this._disabledUpcomings;
	}
	set disabledUpcomings(value) {
		this._disabledUpcomings = value;

		if (typeof this._setDisabledUpcomings === 'function') {
			this._setDisabledUpcomings(value);
		}
	}

	get active() {
		return this._active;
	}
	set active(value) {
		this._active = value;
	}

	set landingType(value) {
		this._landingType = value;
	}
	get landingType() {
		return this._landingType;
	}

	set finish(value) {
		this._finish = value;
	}
	get finish() {
		return this._finish;
	}

	get upComingPositions() {
		let upComings = [];

		Stair.UPCOMING_POSITIONS.forEach((upComing) => {
			upComings.push(JSON.parse(JSON.stringify(upComing)));
			if (this.disabledUpcomings.includes(upComing.index)) {
				upComings[upComings.length - 1].enabled = false;
			} else {
				upComings[upComings.length - 1].enabled = true;
			}
			upComings[upComings.length - 1].id = this.id;
		});

		return upComings;
	}

	constructor(height, width, depth, upComing, disabledUpcomings, setUpComing, setDisabledUpcomings, fallingProtectionStandardAdded, etageHeightLanding, etageId, landingType = 0, finish, ctc) {
		let finishHeight = 0;
		let finishName = '';

		// Wanneer het de endlanding betreft dan halen we de defaults nogmaals op.
		if (finish === null || typeof finish === 'undefined' || ctc === null || typeof ctc === 'undefined') {
			let defaultFinishData = Store.CURRENT.stairSettings.getDefaultIntermediateLandingFinish();
			Store.CURRENT.deckingFinishes.getById(defaultFinishData.landingFinish, (findFinish) => {
				if (findFinish === null || typeof findFinish === 'undefined') {
					// Wanneer op basis van ID niet gevonden kan worden dan kijken naar de naam van de finish.
					Store.CURRENT.deckingFinishes.getFinish(defaultFinishData.landingFinishGroupName, defaultFinishData.landingFinishHeight, (foundFinish) => {
						finish = foundFinish.id;
						finishHeight = foundFinish.height;
						finishName = foundFinish.name;
						ctc = foundFinish.centerToCenter[foundFinish.centerToCenter.length - 1];
					});
				} else {
					// Wanneer finish wel gevonden is dan de waardes direct overnemen.
					finish = findFinish.id;
					finishHeight = findFinish.height;
					finishName = findFinish.name;
					ctc = findFinish.centerToCenter[findFinish.centerToCenter.length - 1];
				}
			});
		} else {
			Store.CURRENT.deckingFinishes.getById(finish, (findFinish) => {
				if (findFinish !== null && typeof findFinish !== 'undefined') {
					// Wanneer op plus word gedrukt dan de ctc overnemen van de erbovenstaande landing.
					// als dat niet het geval is doordat deze automatisch word toegevoegd door bijvoorbeeld etagehoogte aanpassing, dan pakken we de meest voordelige CTC.
					if (ctc === null || typeof ctc === 'undefined') {
						ctc = findFinish.centerToCenter[findFinish.centerToCenter.length - 1];
					}
					finishHeight = findFinish.height;
					finishName = findFinish.name;
				}
			});
		}

		this.id = Functions.uuidv4();
		this.height = height;
		this.width = width;
		this.depth = depth;

		this.upComing = upComing;
		this.etageHeightLanding = etageHeightLanding;
		this.etageId = etageId;
		this.finish = finish;
		this.finishHeight = finishHeight;
		this.finishName = finishName;
		this.ctc = ctc;
		if (typeof disabledUpcomings !== 'undefined' && disabledUpcomings !== null) {
			this.disabledUpcomings = disabledUpcomings;
		}
		if (typeof setUpComing !== 'undefined' && setUpComing !== null) {
			this._setUpcoming = setUpComing;
		}
		if (typeof setDisabledUpcomings !== 'undefined' && setDisabledUpcomings !== null) {
			this._setDisabledUpcomings = setDisabledUpcomings;
		}

		this.fallingProtectionStandardAdded = fallingProtectionStandardAdded;
		this.landingType = landingType;
	}
	recalculate() {
		if (Configuration.CURRENT.finish.list.length < 1) {
			let list = Store.CURRENT.deckingFinishes.deckingFinishList();
			Configuration.CURRENT.finish.list = list;
		}

		Configuration.CURRENT.finish.list.forEach((item) => {
			if (item.id === this.finish) {
				this.finishHeight = item.height;
				this.finishName = item.caption;
			}
		});
	}
	setUpComing(value) {
		this.upComing = value;
		if (typeof this._setUpcoming === 'function') {
			this._setUpcoming(value);
		}
	}

	setLandingType(upComing, prevUpcoming, stairPlace, prevLanding, stepWidth) {
		// Kan soms null of undefined zijn, beter om hier daar 1 keer op te checken dan op andere plekken meerdere keren.
		if (prevLanding === null || typeof prevLanding === 'undefined') {
			prevLanding = null;
		}
		// Wanneer de previouslanding null is en het inFloor is gebruiken we andere logica, bij stairoutside is upcoming alijd de kant van het raster en bij inFloor kan dat elke kant zijn die je kiest.
		if (stairPlace === 'inFloor' && prevLanding === null) {
			if (upComing === prevUpcoming) {
				this.landingType = IntermediateLandings.oneeightyDegrees;
				this.oneeightyDegreesLanding(stepWidth);
			} else if (upComing === Stair.toOppositeUpComing(prevUpcoming)) {
				this.landingType = IntermediateLandings.straightAhead;
			} else {
				this.landingType = IntermediateLandings.ninetyDegrees;
			}
		} else {
			this.setLandingByLogic(upComing, prevUpcoming, stepWidth);
		}
	}
	setLandingByLogic(upComing, prevUpcoming, stepWidth) {
		if (upComing === prevUpcoming) {
			this.landingType = IntermediateLandings.straightAhead;
		} else if (Stair.toOppositeUpComing(upComing) === prevUpcoming) {
			this.landingType = IntermediateLandings.oneeightyDegrees;
			this.oneeightyDegreesLanding(stepWidth);
		} else {
			this.landingType = IntermediateLandings.ninetyDegrees;
		}
	}

	oneeightyDegreesLanding(stepWidth) {
		this.landingType = IntermediateLandings.oneeightyDegrees;
		if (this.width < stepWidth * 2 + 100) {
			this.width = stepWidth * 2 + 100;
		}
	}

	rotateLeft() {
		this.upComing -= 1;
		if (this.upComing <= 0) {
			this.upComing = 4;
		}
	}
	rotateRight() {
		this.upComing += 1;
		if (this.upComing > 4) {
			this.upComing -= 4;
		}
	}
	addDrawObjects(canvas, params) {
		if (this.active === false) {
			return [];
		}
		let drawObjects = [];
		let landing = null;
		let boundary = { topLeft: { x: 0, y: 0 }, topRight: { x: 0, y: 0 }, bottomLeft: { x: 0, y: 0 }, bottomRight: { x: 0, y: 0 } };
		let shiftLanding = 0;
		let width = 0;
		let depth = 0;

		switch (
			params.upComing // upcoming is hier uiteraard come down. Maar komt overeen als het in de vloer is. Dan is de upcoming_top gelijk aan de comeDown Top enz.
		) {
			case Stair.UPCOMING_TOP:
				shiftLanding = (this.width - params.stepWidth) / 2;
				width = this.width;
				depth = this.depth;
				if (this.upComing === Stair.UPCOMING_RIGHT || this.upComing === Stair.UPCOMING_LEFT) {
					// width en depth gaan uit van bovenkomst. Tekenen kijkt vanuit neerkomst. Daarom omdraaien als upcoming dwars op params.upComing
					shiftLanding = (this.depth - params.stepWidth) / 2;
					width = this.depth;
					depth = this.width;
				} else if (this.upComing === params.upComing) {
					shiftLanding = this.width - params.stepWidth;
				}

				landing = new Rectangle(
					new DrawValue(params.boundary.bottomLeft.x - shiftLanding),
					new DrawValue(params.boundary.bottomLeft.y),
					new DrawValue(width),
					new DrawValue(depth),
					params.hasErrors === true ? Stairs.COLORS.stairCollisions : params.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.landing,
					null,
					null,
					false,
					this,
					{
						type: 'stairLanding',
						color: Stairs.COLORS.landing,
						selected: Stairs.COLORS.selected,
					},
				);
				boundary = {
					topLeft: { x: params.boundary.bottomLeft.x - shiftLanding, y: params.boundary.bottomLeft.y },
					topRight: { x: params.boundary.bottomLeft.x - shiftLanding + width, y: params.boundary.bottomLeft.y },
					bottomLeft: { x: params.boundary.bottomLeft.x - shiftLanding, y: params.boundary.bottomLeft.y + depth },
					bottomRight: { x: params.boundary.bottomLeft.x - shiftLanding + width, y: params.boundary.bottomLeft.y + depth },
				};
				break;
			case Stair.UPCOMING_RIGHT:
				shiftLanding = (this.width - params.stepWidth) / 2;
				width = this.depth;
				depth = this.width;
				if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
					// width en depth gaan uit van bovenkomst. Tekenen kijkt vanuit neerkomst. Daarom omdraaien als upcoming dwars op params.upComing
					shiftLanding = (this.depth - params.stepWidth) / 2;
					width = this.width;
					depth = this.depth;
				} else if (this.upComing === params.upComing) {
					shiftLanding = this.width - params.stepWidth;
				}
				landing = new Rectangle(
					new DrawValue(params.boundary.topLeft.x - width),
					new DrawValue(params.boundary.topLeft.y - shiftLanding),
					new DrawValue(width),
					new DrawValue(depth),
					params.hasErrors === true ? Stairs.COLORS.stairCollisions : params.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.landing,
					null,
					null,
					false,
					this,
					{
						type: 'stairLanding',
						color: Stairs.COLORS.landing,
						selected: Stairs.COLORS.selected,
					},
				);

				boundary = {
					topLeft: { x: params.boundary.topLeft.x - width, y: params.boundary.topLeft.y - shiftLanding },
					topRight: { x: params.boundary.topLeft.x, y: params.boundary.topLeft.y - shiftLanding },
					bottomLeft: { x: params.boundary.topLeft.x - width, y: params.boundary.topLeft.y - shiftLanding + depth },
					bottomRight: { x: params.boundary.topLeft.x + width, y: params.boundary.topLeft.y - shiftLanding + depth },
				};
				break;
			case Stair.UPCOMING_BOTTOM:
				shiftLanding = (this.width - params.stepWidth) / 2;
				width = this.width;
				depth = this.depth;
				if (this.upComing === Stair.UPCOMING_RIGHT || this.upComing === Stair.UPCOMING_LEFT) {
					// width en depth gaan uit van bovenkomst. Tekenen kijkt vanuit neerkomst. Daarom omdraaien als upcoming dwars op params.upComing
					shiftLanding = (this.depth - params.stepWidth) / 2;
					width = this.depth;
					depth = this.width;
				} else if (this.upComing === params.upComing) {
					shiftLanding = 0;
				}
				landing = new Rectangle(
					new DrawValue(params.boundary.topLeft.x - shiftLanding),
					new DrawValue(params.boundary.topLeft.y - depth),
					new DrawValue(width),
					new DrawValue(depth),
					params.hasErrors === true ? Stairs.COLORS.stairCollisions : params.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.landing,
					null,
					null,
					false,
					this,
					{
						type: 'stairLanding',
						color: Stairs.COLORS.landing,
						selected: Stairs.COLORS.selected,
					},
				);
				boundary = {
					topLeft: { x: params.boundary.topLeft.x - shiftLanding, y: params.boundary.topLeft.y - depth },
					topRight: { x: params.boundary.topLeft.x - shiftLanding + width, y: params.boundary.topLeft.y - depth },
					bottomLeft: { x: params.boundary.topLeft.x - shiftLanding, y: params.boundary.topLeft.y },
					bottomRight: { x: params.boundary.topLeft.x - shiftLanding + width, y: params.boundary.topLeft.y },
				};

				break;
			case Stair.UPCOMING_LEFT:
				shiftLanding = (this.width - params.stepWidth) / 2;
				width = this.depth;
				depth = this.width;
				if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
					// width en depth gaan uit van bovenkomst. Tekenen kijkt vanuit neerkomst. Daarom omdraaien als upcoming dwars op params.upComing
					shiftLanding = (this.depth - params.stepWidth) / 2;
					width = this.width;
					depth = this.depth;
				} else if (params.upComing === this.upComing) {
					// Wanneer het een 180 graden is dan tekenen we altijd naar rechts toe, daarom bij een 180 degree de shiftlanding niet in het midden maar naar de linkerkant toe (als het een richting landing top is)
					shiftLanding = 0;
				}

				landing = new Rectangle(
					new DrawValue(params.boundary.topRight.x),
					new DrawValue(params.boundary.topRight.y - shiftLanding),
					new DrawValue(width),
					new DrawValue(depth),
					params.hasErrors === true ? Stairs.COLORS.stairCollisions : params.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.landing,
					null,
					null,
					false,
					this,
					{
						type: 'stairLanding',
						color: Stairs.COLORS.landing,
						selected: Stairs.COLORS.selected,
					},
				);

				boundary = {
					topLeft: { x: params.boundary.topRight.x, y: params.boundary.topRight.y - shiftLanding },
					topRight: { x: params.boundary.topRight.x + width, y: params.boundary.topRight.y - shiftLanding },
					bottomLeft: { x: params.boundary.topRight.x, y: params.boundary.topRight.y - shiftLanding + depth },
					bottomRight: { x: params.boundary.topRight.x + width, y: params.boundary.topRight.y - shiftLanding + depth },
				};

				break;
		}

		if (landing !== null) {
			landing.opacity = 0.5;
			landing.colorFromParent = false;
			landing.border = true;
			drawObjects.push(landing);
		}
		this.boundary = boundary;
		params.boundary = boundary;
		return drawObjects;
	}
	getAmountData(compare = true) {
		this.recalculate();
		if (!compare) {
			return {
				width: this.width,
				depth: this.depth,
				height: this.height,
				landingType: this.landingType,
				centerToCenter: this.ctc,
				finish: this.finish,
				finishHeight: this.finishHeight,
				id: this.id,
			};
		}

		return {
			width: this.width,
			depth: this.depth,
			height: this.height,
			upcoming: this.upComing,
			landingType: this.landingType,
			centerToCenter: this.ctc,
			finish: this.finish,
			finishHeight: this.finishHeight,
			finishName: this.finishName,
			id: this.id,
		};
	}
}
