import { Data } from './data';

export class Invoices extends Data {
	objectName = 'Invoices';
	static CURRENT = null;
	url = 'Invoices';
	setStore(store) {
		super.setStore(store);
		// registreren dat als de company gewijzigd is ook de offertes opgehaald worden die erbij horen
		store.companies.addOnChangeSelectedCompany(() => {
			for (let key in this.lastRequest) {
				let lastRequest = JSON.parse(JSON.stringify(this.lastRequest[key]));
				lastRequest.data.company = this.store.companies.selectedCompany.id;

				this.queue.push({
					data: lastRequest.data,
					force: false,
					cacheId: key,
					urlExtension: lastRequest.urlExtension,
					thenCallBack: this.lastRequest[key].thenCallBack, // functies gaan niet mee met parse en stringify
					catchCallBack: this.lastRequest[key].catchCallBack, // functies gaan niet mee met parse en stringify
				});
			}
		});
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		let list = super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
		return list;
	}
}
