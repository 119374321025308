/* eslint-disable no-new */
import { Rectangle } from '../draw/rectangle';
import { DrawValue } from '../draw/drawValue';
import { ObjectGroup } from '../draw/objectGroup';
import { Line } from '../draw/line';
import { Mathematic } from '../helpers/mathematic';

import { Canvas3D } from '../draw3d/Canvas3D';
import { Stair3D } from '../draw3d/stairs/Stair3D';
import { StairInFloorProfiles3D } from '../draw3d/stairs/profiles/StairInFloorProfiles3D';
import { Landing3D } from '../draw3d/stairs/landing/Landing3D';
import { StairInFloorHandRail3D } from '../draw3d/stairs/StairInFloorHandRail3D';
import { Stair } from './stair';
import { Configuration } from './configuration';
import { RemoveRaster } from './removeRaster';
import { Profiles } from './profiles';
import { Stairs } from './stairs';
import { Columns } from './columns';
import { IntermediateLandings } from './intermediateLandings';

export class StairInFloor extends Stair {
	objectName = 'StairInFloor';
	place = 'inFloor';
	isInFloor = true; // collision voor invloer tekenen van deckingfinish
	constructor(newStairConfiguration, raster) {
		super(newStairConfiguration, raster);

		if (typeof this.stairWell !== 'undefined' && this.stairWell !== null) {
			this.stairWell.active = true;
		}
		if (typeof raster !== 'undefined' && raster !== null) {
			this.startRaster = raster;
			const startX = Configuration.CURRENT.raster.getSizeX(raster.x - 1);
			const startY = Configuration.CURRENT.raster.getSizeY(raster.y - 1);
			const lengthRasterX = Configuration.CURRENT.raster.spansX.get(raster.x).value;
			const lengthRasterY = Configuration.CURRENT.raster.spansY.get(raster.y).value;
			const totalLengthRasterX = Configuration.CURRENT.raster.getSizeX();

			this.startY = startY + Math.floor((lengthRasterY - this.stairDrawHeight) / 2);
			this.stairWell.startY = this.startY;

			if (lengthRasterX <= this.stairDrawWidth) {
				if (totalLengthRasterX < this.startX + this.stairDrawWidth) {
					// als vanaf begin raster tot eind tekening smaller is dan plaatsen aan eind van raster
					this.startX = totalLengthRasterX - this.stairDrawWidth;
				} else {
					this.startX = startX;
				}
			} else {
				this.startX = startX + Math.floor((lengthRasterX - this.stairDrawWidth) / 2);
			}

			this.stairWell.startX = this.startX;
			this.findRaster();
		}
	}

	addUsedSurface() {
		this.removeUsedSurface();
		Configuration.CURRENT.etages.activeEtage().usedSurface.push({
			width: this.stairWell.width,
			depth: this.stairWell.depth,
			id: this.id,
		});
	}
	removeUsedSurface() {
		Configuration.CURRENT.etages.activeEtage().usedSurface.forEach((surf, index) => {
			if (surf.id === this.id) {
				Configuration.CURRENT.etages.activeEtage().usedSurface.splice(index, 1);
			}
		});
	}
	addPossiblePositions(canvas, params, stairs) {
		let possiblePositionFound = false;
		params.raster.spansX.getSpans().forEach((spanX, indexX) => {
			params.raster.spansY.getSpans().forEach((spanY, indexY) => {
				if (params.actieveEtage.isActiveRaster(new RemoveRaster(indexX, indexY)) === true && stairs.containsStair({ x: indexX, y: indexY }) === false) {
					let raster = new Rectangle(
						new DrawValue(params.raster.getSizeX(indexX - 1), 1),
						new DrawValue(params.raster.getSizeY(indexY - 1), 1),
						new DrawValue(spanX.value, -2),
						new DrawValue(spanY.value, -2),
						Stairs.COLORS.possiblePosition,
						null,
						null,
						true,
						stairs,
						{ x: indexX, y: indexY },
					);
					raster.border = true;
					raster.opacity = 0.2;
					possiblePositionFound = true;

					canvas.addDrawObject(raster);
				}
			});
		});

		// Wanneer geen raster gevonden om in te plaatsen.
		if (possiblePositionFound === false) {
			Configuration.CURRENT.notification.hide();
			Configuration.CURRENT.notification.show(window.Vue.$translate('stair.noPosition'), 1000);
			this.possiblePossitionsVisible = '';
			Configuration.CURRENT.setAccessoriesType('');
		}
	}
	select(parameters) {
		// Updaten van kleur hoeft hier niet omdat in addDrawobjects al de kleur word bepaald op basis van selected.
		if (this.id === parameters.id) {
			this.selected = !this.selected;
		} else {
			// Wanneer nieuwe object niet dit object is dan is het een deselect.
			this.selected = false;
		}
	}
	redrawTrimming() {
		if (this.rasters.length > 0) {
			this.stairWell.setTrimming(this.startX, this.startY, this.stairDrawWidth, this.stairDrawHeight, this.id, false);
		}
	}
	onMouseDrag(evt, object, canvas, params) {
		// Group ID opgeslagen, alleen selecteren op rectangle maar wel hele groep verslepen.
		let groupObject = canvas.drawObjects.findByDrawId(this.drawGroupId);

		let moveX = evt.delta.x / canvas.scaleFactor; // verplaatsing links-rechts (links - rechts +)
		let moveY = evt.delta.y / canvas.scaleFactor; // verplaatsing onder-boven (boven - onder +)

		// minimale marge van trapbreedte aanhouden
		let maxX = Configuration.CURRENT.raster.spansX.getSize();
		let minY = 0;
		let minX = 0;
		let maxY = Configuration.CURRENT.raster.spansY.getSize();

		// x onbeperkt als > 0 en < totale lengte. Als hoofdbalken verticaal dan y onbeperkt
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			const minMaxCoordinatesXmove = groupObject.minMaxCoordinates(this.objectsYMinMaxHorizontal);
			const minMaxCoordinatesYmove = groupObject.minMaxCoordinates(this.objectsYMinMaxHorizontal);

			if (minMaxCoordinatesXmove.min.x + moveX < 0) {
				// als x kleiner dan 0 dan tot aan 0
				moveX = 0 - minMaxCoordinatesXmove.min.x;
				evt.delta.x = moveX * canvas.scaleFactor;
			}

			if (minMaxCoordinatesXmove.max.x + moveX > maxX) {
				// als x groter dan totale lengte dan tot aan totale lengte
				moveX = maxX - minMaxCoordinatesXmove.max.x;
				evt.delta.x = moveX * canvas.scaleFactor;
			}

			// y tussen boven en onderprofiel
			if (minMaxCoordinatesYmove.min.y + moveY < minY) {
				// inclusief verplaatsing voorbij bovenliggende hoofdbalk dan tot aan bovenliggende hoofdbalk
				moveY = minY - minMaxCoordinatesYmove.min.y;
				evt.delta.y = moveY * canvas.scaleFactor;
			}

			if (minMaxCoordinatesYmove.max.y + moveY > maxY) {
				// inclusief verplaatsing voorbij onderliggende hoofdbalk dan tot aan onderliggende hoofdbalk
				moveY = maxY - minMaxCoordinatesYmove.max.y;
				evt.delta.y = moveY * canvas.scaleFactor;
			}
		} else {
			const minMaxCoordinatesXmove = groupObject.minMaxCoordinates(this.objectsYMinMaxVertical);
			const minMaxCoordinatesYmove = groupObject.minMaxCoordinates(this.objectsYMinMaxVertical);

			if (minMaxCoordinatesXmove.min.y + moveY < 0) {
				// als y kleiner dan 0 dan tot aan 0
				moveY = 0 - minMaxCoordinatesXmove.min.y;
				evt.delta.y = moveY * canvas.scaleFactor;
			}

			if (minMaxCoordinatesXmove.max.y + moveY > maxY) {
				// als y groter dan totale lengte dan tot aan totale lengte
				moveY = maxY - minMaxCoordinatesXmove.max.y;
				evt.delta.y = moveY * canvas.scaleFactor;
			}

			// y tussen boven en onderprofiel
			if (minMaxCoordinatesYmove.min.x + moveX < minX) {
				// inclusief verplaatsing voorbij linkse hoofdbalk dan tot aan linkse hoofdbalk
				moveX = minX - minMaxCoordinatesYmove.min.x;
				evt.delta.x = moveX * canvas.scaleFactor;
			}

			// y tussen boven en onderprofiel
			if (minMaxCoordinatesYmove.max.x + moveX > maxX) {
				// inclusief verplaatsing voorbij rechtse hoofdbalk dan tot aan rechtse hoofdbalk
				moveX = maxX - minMaxCoordinatesYmove.max.x;
				evt.delta.x = moveX * canvas.scaleFactor;
			}
		}

		this.startX += moveX; // neem x van trapgat over naar de trap
		this.startY += moveY; // neem y van trapgat over naar de trap

		this.stairWell.startX += moveX; // neem x van trapgat over naar de trap
		this.stairWell.startY += moveY; // neem y van trapgat over naar de trap

		// Trimming updaten, en de andere die ook andere dingen updatë
		this.redrawTrimming();

		// om redraw te voorkomen ook toepassen op het drawobject
		// omdat er veel variatie is met de trimming hier een nieuw drawobject toevoegen
		// eerst alles weggooien
		groupObject.clear();

		// Dan objecten opnieuw maken
		this.addDrawObjects(canvas, groupObject, true);
		return { stopPropagation: true };
	}

	onMouseUp(evt, object, canvas, params) {
		this.calculate();
		// Updaten van rasters waar trap in valt na loslaten.
		this.findRaster();

		this.addUsedSurface();
		this.onChange();
		return { stopPropagation: true };
	}
	setObjectSizeHandle(canvas) {
		const lengthX = Configuration.CURRENT.raster.spansX.getSize();
		const lengthY = Configuration.CURRENT.raster.spansY.getSize();
		canvas.sizeHandles
			.get('object')
			.setByArray(
				[Math.round(this.startX), Math.round(this.stairDrawWidth), Math.round(lengthX - this.startX - this.stairDrawWidth)],
				[Math.round(this.startY), Math.round(this.stairDrawHeight), Math.round(lengthY - this.startY - this.stairDrawHeight)],
			);
	}
	onMouseMovePossiblePosition(evt, object, canvas) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(object.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(object.objectParams.y);

		if (lengthX !== null && lengthY !== null && this.isPossible(lengthX.value, lengthY.value)) {
			object.opacity = 0.5;
		}

		return { stopPropagation: true };
	}
	onMouseLeavePossiblePosition(evt, object, canvas) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(object.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(object.objectParams.y);
		if (lengthX !== null && lengthY !== null && this.isPossible(lengthX.value, lengthY.value)) {
			object.opacity = 0.2;
		}
		return { stopPropagation: true };
	}
	onChangeChildBeamLength(raster, delta, evt, drawObject, mainBeam, canvas, params, actualSize) {
		const rasterX = raster.x;
		const rasterY = raster.y;
		const stairPositionX = drawObject.objectParams.x;
		const stairPositionY = drawObject.objectParams.y;

		if ((rasterY > -1 && rasterY === stairPositionY) || (rasterX > -1 && rasterX === stairPositionX)) {
			if (this.isPossible(actualSize.raster, actualSize.raster)) {
				drawObject.opacity = 0.2;
			} else {
				drawObject.opacity = 0;
			}
			drawObject.x.value += delta.x;
			drawObject.y.value += delta.y;
			drawObject.width.value -= delta.x;
			drawObject.height.value -= delta.y;
		}
		if ((rasterY > -1 && rasterY - 1 === stairPositionY) || (rasterX > -1 && rasterX - 1 === stairPositionX)) {
			if (this.isPossible(actualSize.rasterLinks, actualSize.rasterLinks)) {
				drawObject.opacity = 0.2;
			} else {
				drawObject.opacity = 0;
			}
			drawObject.width.value += delta.x;
			drawObject.height.value += delta.y;
		}
	}
	showPossiblePostion(object) {
		object.opacity = 0.5;
	}
	hidePossiblePostion(object) {
		object.opacity = 0;
	}
	moveProfilePossible(params) {
		if (params.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			let lengthToAbove = Configuration.CURRENT.raster.spansY.getSize(params.y - 2); // nieuwe lengte van raster en raster erboven komen mee. Daar rastersDaarboven weer bij optellen om respectievelijk startY en startY + drawheight te controleren

			if (this.rasters.length > 0) {
				let y = this.rasters[0].y;

				if (params.y === y) {
					if (lengthToAbove + params.newLengthRasterAbove > this.startY) {
						return false;
					}
				}
				if (params.y - 1 === y) {
					if (lengthToAbove + params.newLengthRasterAbove < this.startY + this.stairDrawHeight) {
						return false;
					}
				}
			}
		} else {
			let lengthToLeft = Configuration.CURRENT.raster.spansX.getSize(params.x - 2); // nieuwe lengte van raster en raster links komen mee. Daar rastersDaarboven weer bij optellen om respectievelijk startY en startY + drawheight te controleren

			if (this.rasters.length > 0) {
				let x = this.rasters[0].x;

				if (params.x === x) {
					if (lengthToLeft + params.newLengthRasterLeft > this.startX) {
						return false;
					}
				}
				if (params.x - 1 === x) {
					if (lengthToLeft + params.newLengthRasterLeft < this.startX + this.stairDrawWidth) {
						return false;
					}
				}
			}
		}
		return true;
	}
	onClickPossiblePosition(evt, object, canvas, params, stairSettings, stairs) {
		const lengthX = Configuration.CURRENT.raster.spansX.get(object.objectParams.x);
		const lengthY = Configuration.CURRENT.raster.spansY.get(object.objectParams.y);

		if (lengthX !== null && lengthY !== null) {
			stairSettings.hidePossiblePostion(object);
			stairs.possiblePossitionsVisible = '';
			Configuration.CURRENT.notification.hide();
			stairSettings.newStair = false;

			stairs.push(stairSettings, object.objectParams);
			Configuration.CURRENT.setAccessoriesType('');

			Configuration.CURRENT.etages.activeEtage().stairs.clearStairSettings();
			stairs.onChange(true);
		}
	}
	isPossible(spanX, spanY) {
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			return spanY > this.stairDrawHeight; // nog controle op richting hoofdbalken dan ipv spanX spanY pakken. Voorlopig niet naar breedte kijken. Kan meerdere rasters beslaan
		} else {
			return spanX > this.stairDrawWidth;
		}
	}
	inRaster(raster) {
		let contains = false;
		this.rasters.forEach((rst) => {
			if (rst.x === raster.x && rst.y === raster.y) {
				contains = true;
			}
		});
		return contains;
	}
	setSizeHandleObject(canvas, dimensionsHorizontal, dimensionsVertical) {
		canvas.sizeHandles.get('object').setByArray(dimensionsHorizontal, dimensionsVertical);
		let events = { onChangedHorizontal: this.onSizeHandleChangedHorizontal.bind(this), onChangedVertical: this.onSizeHandleChangedVertical.bind(this) };
		canvas.sizeHandles.get('object').registerEvents(events);
		// op deze manier heeft de sizeHandle zijn parent en kan de functie sizeHandleIsResizable(dimension) aangeroepen worden
		canvas.sizeHandles.get('object').parent = this;
	}

	getAbsoluteDepth() {
		if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
			return this.stairWell.width;
		} else {
			return this.stairWell.depth;
		}
	}

	onSizeHandleChangedHorizontal(evt, object, canvas, params) {
		let etageWidth = params.etages.get(params.etages.activeIndex).floor.width;
		if (params.raster === 0) {
			if (params.newLengthCurrentRaster < 0) {
				this.startX = 0;
				this.onChange();
				return;
			}

			if (params.newLengthCurrentRaster > etageWidth || params.newLengthCurrentRaster + this.getAbsoluteDepth() > etageWidth) {
				this.startX = etageWidth - this.getAbsoluteDepth();
				this.onChange();
				return;
			}

			this.startX = params.newLengthCurrentRaster;
		} else if (params.raster === 2) {
			if (params.newLengthCurrentRaster + this.getAbsoluteDepth() > etageWidth) {
				this.startX = 0;
				this.onChange();
				return;
			}
			this.startX += object.width.value - params.newLengthCurrentRaster;
		} else {
			this.startX += object.width.value - params.newLengthCurrentRaster;
		}

		this.onChange();
	}
	onSizeHandleChangedVertical(evt, object, canvas, params) {
		let etageDepth = params.etages.get(params.etages.activeIndex).floor.length;
		if (params.raster === 0) {
			if (params.newLengthCurrentRaster < 0) {
				this.startY = 0;
				this.onChange();
				return;
			}
			if (params.newLengthCurrentRaster > etageDepth || params.newLengthCurrentRaster + this.getAbsoluteDepth() > etageDepth) {
				this.startY = etageDepth - this.getAbsoluteDepth();
				this.onChange();
				return;
			}
			this.startY = params.newLengthCurrentRaster;
		} else if (params.raster === 2) {
			if (params.newLengthCurrentRaster + this.getAbsoluteDepth() > etageDepth) {
				this.startY = 0;
				this.onChange();
				return;
			}
			this.startY += object.width.value - params.newLengthCurrentRaster;
		} else {
			this.startY += object.width.value - params.newLengthCurrentRaster;
		}
		this.onChange();
	}
	updateSizeHandles(canvas) {
		let objectSizeHandle = canvas.sizeHandles.get('object');
		if (objectSizeHandle !== null && typeof objectSizeHandle !== 'undefined') {
			const lengthX = Configuration.CURRENT.raster.spansX.getSize();
			const lengthY = Configuration.CURRENT.raster.spansY.getSize();
			objectSizeHandle.updateDrawObjects(
				[Math.round(this.startX), Math.round(this.stairDrawWidth), Math.round(lengthX - this.startX - this.stairDrawWidth)],
				[Math.round(this.startY), Math.round(this.stairDrawHeight), Math.round(lengthY - this.startY - this.stairDrawHeight)],
			);
		}
	}
	addDrawObjects(canvas, stairGroup, drag = false) {
		if (canvas !== null) {
			if (this.selected === true) {
				const lengthX = Configuration.CURRENT.raster.spansX.getSize();
				const lengthY = Configuration.CURRENT.raster.spansY.getSize();
				if (!drag) {
					this.setSizeHandleObject(
						canvas,
						[Math.round(this.startX), Math.round(this.stairDrawWidth), Math.round(lengthX - this.startX - this.stairDrawWidth)],
						[Math.round(this.startY), Math.round(this.stairDrawHeight), Math.round(lengthY - this.startY - this.stairDrawHeight)],
					);
				} else {
					this.updateSizeHandles(canvas);
				}
			}
		}

		this.boundaries = [];
		if (typeof stairGroup === 'undefined' || stairGroup === null) {
			stairGroup = new ObjectGroup(Profiles.COLOR.mainBeam, null, null, false, this, {});
			this.drawGroupId = stairGroup.id;
		}

		let params = {};
		params.rasterY = null;
		params.rasterX = null;
		params.hasErrors = this.hasErrors;

		if (this.rasters.length > 0) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				params.rasterY = this.rasters[0].y;
			} else {
				params.rasterX = this.rasters[0].x;
			}
		}

		params.stairInFloorObject = this;
		stairGroup.push(this.stairWell.addDrawObjects(canvas, params));

		let stairDepth = Mathematic.calculateDepth(this.etageHeight, this.angle); // stairdepth gebruiken voor de linkse en rechtse lijn. Niet voor de tail. Die blijft altijd over de hele diepte van detrap
		// hier nog sorteren
		if (this.intermediateLandings.get().length > 0) {
			stairDepth = this.intermediateLandings.getDepth(0, this.etageHeight, this.angle);
		}
		let startStair = this.stairWell.startStair(true, this.upComing);
		let boundary = {
			topLeft: { x: startStair.x, y: startStair.y },
			topRight: { x: startStair.x + this.stairDrawWidth, y: startStair.y },
			bottomLeft: { x: startStair.x, y: startStair.y + this.stairDrawHeight },
			bottomRight: { x: startStair.x + this.stairDrawWidth, y: startStair.y + this.stairDrawHeight },
		};
		let nextLanding = this.intermediateLandings.get(0);
		let prevLanding = null;
		// Stukje trap tot de eerste landing. (vanaf bovenkant stair gezien)
		boundary = this.drawObjectsStair(stairGroup, this.upComing, boundary, stairDepth, true, prevLanding, null, nextLanding, false);

		let lastUpcoming = this.upComing;
		this.intermediateLandings.intermediateLandings.forEach((landing, index) => {
			params.boundary = boundary;
			params.upComing = lastUpcoming;
			params.stepWidth = this.width;
			params.hasErrors = this.hasErrors;
			params.selected = this.selected;
			nextLanding = this.intermediateLandings.get(index + 1);
			prevLanding = this.intermediateLandings.get(index - 1);

			let drawObjects = landing.addDrawObjects(canvas, params);
			boundary = params.boundary;
			this.boundaries.push(boundary);
			drawObjects.forEach((drawObject) => {
				stairGroup.push(drawObject);
			});
			stairDepth = this.intermediateLandings.getDepth(index + 1, this.etageHeight, this.angle);

			boundary = this.drawObjectsStair(
				stairGroup,
				Stair.toOppositeUpComing(landing.upComing),
				boundary,
				stairDepth,
				false,
				prevLanding,
				landing,
				nextLanding,
				landing.landingType === IntermediateLandings.oneeightyDegrees,
			);

			lastUpcoming = Stair.toOppositeUpComing(landing.upComing);
		});

		this.drawObject = stairGroup.id;
		return { regenerate: false, stair: stairGroup };
	}
	isOwnRasterActive() {
		let raster = Configuration.CURRENT.raster.getRasterByCoordinate(this.startX, this.startY);
		return Configuration.CURRENT.etages.etages[this.etageIndex].isActiveRaster(new RemoveRaster(raster.x, raster.y));
	}
	addDrawObjects3d(canvas3d, etage, raster, posY) {
		if (this.isOwnRasterActive()) {
			//! Stairstart word al gekeken vanuit de stairwell, daar word ook de stair al in het midden gezet, dat hoeft dan in 3D niet meer.
			let startStair = this.stairWell.startStair();
			let boundary = {
				topLeft: { x: startStair.x, y: startStair.y },
				topRight: { x: startStair.x + this.stairDrawWidth, y: startStair.y },
				bottomLeft: { x: startStair.x, y: startStair.y + this.stairDrawHeight },
				bottomRight: { x: startStair.x + this.stairDrawWidth, y: startStair.y + this.stairDrawHeight },
			};
			this.stairBoundries = boundary;

			// Eerste trap tot eerste landing of onderkant vloer.

			let yPos = 0; // Standaard tot op de grond.
			let yPosEnd = this.etageHeight; // Standaard vanaf de hoogte waar de etage is.

			// Wanneer de trap in Floor niet op de eerste verdieping staat moeten we tekenen tot de verdieping eronder en worden de waardes anders.
			if (this.etageIndex > 0) {
				yPos = posY; // Als er meerdere etages zijn, dan tot de vorige etagehoogte.
				yPosEnd = posY + this.etageHeight; // Vanaf = dan this.etageHeight = hoogte totaal vanaf grond tot huidige etage waar de trap dus op eindigt.
			}

			// Of wanneer er een landing is dan de eerste trap tot de eerste landing tekenen.
			let firstLanding = this.intermediateLandings.get(0);
			if (firstLanding !== null) {
				yPos = posY + firstLanding.height;
			}

			let startX = this.stairBoundries.topLeft.x;
			let startY = this.stairBoundries.topLeft.y;

			canvas3d.addDrawObject(
				new Stair3D(startX, startY, {
					stairData: this,
					yPos: yPos,
					yPosEnd: yPosEnd, // Eindpunt, dus eigen landing hoogte oftewel vanaf boven naarbeneden begin punt in hoogte.
					landingData: null, // Geven hier null mee omdat we de stair niet hoeven te centereren rondom een endlanding. (Stairinfloor kan geen endlanding hebben)
					overhang: Configuration.CURRENT.overhang.size,
					upComing: Stair.toOppositeUpComing(this.upComing),
					width: this.stairWell.width,
					depth: this.stairWell.depth,
					firstStair: true,
					stepsAmount:
						this.intermediateLandings.length !== 0 ? Math.ceil((this.etageHeight - firstLanding.height) / this._fallingProtectionStandardRules.getMaxRise(this.angle)) + 1 : this.amountSteps,
				}),
				Canvas3D.TYPE_STAIR,
			);

			this.intermediateLandings.intermediateLandings.forEach((landing, index) => {
				let prevLanding = this.intermediateLandings.get(index - 1);
				let upComingPrev;
				if (prevLanding !== null && typeof prevLanding !== 'undefined') {
					upComingPrev = Stairs.getOppositeUpComing(prevLanding.upComing);
				} else {
					upComingPrev = this.upComing;
				}

				// Toevoegen van de landing
				new Landing3D(landing.boundary.topLeft.x, landing.boundary.topLeft.y, {
					drawHeight: posY + landing.height,
					upComing: landing.upComing,
					upComingPrev: upComingPrev,
					width: this.getLandingWidth3D(landing, false),
					depth: this.getLandingDepth3D(landing, false),
					landingData: landing,
					raster: raster,
					stairData: this,
					endLanding: false,
				});

				// Toevoegen van de stair na de huidige landing
				let nextLanding = this.intermediateLandings.get(index + 1);
				if (nextLanding === null || typeof nextLanding === 'undefined') {
					nextLanding = {};
					nextLanding.height = 0;
				}

				// Na elke landing een stuk trap naar de volgende landing.
				canvas3d.addDrawObject(
					new Stair3D(landing.boundary.topLeft.x, landing.boundary.topLeft.y, {
						stairData: this,
						yPos: posY + nextLanding.height, // Plek tot aan de volgende landing of tot aan de vloer.
						yPosEnd: posY + landing.height, // Plek ter hoogte van huidige landing.
						landingData: landing,
						overhang: Configuration.CURRENT.overhang.size,
						upComing: landing.upComing,
						width: this.getLandingWidth3D(landing, false),
						depth: this.getLandingDepth3D(landing, false),
						stairId: this.id,
						firstStair: false,
						endLandingActive: this.endLanding.active,
						stepsAmount: landing.amountSteps,
					}),
					Canvas3D.TYPE_STAIR,
				);
			});

			// Niet meer nodig met inivisible holes.
			// new StairDeckingFinish3D(this.startX, this.startY, this, raster, etage, posY);

			new StairInFloorHandRail3D(this, posY);

			// eslint-disable-next-line no-new
			new StairInFloorProfiles3D(this, raster, etage, posY);
		}
	}
	// Doorverwijzen naar intermediatelandings
	recalculateLandingHeights() {
		if (this.crossStairWell === true) {
			this.intermediateLandings.recalculateLandingHeights(this);
		}
	}
	setEtageHeight(value, etageIndex) {
		this.etageHeight = value;
		this.validate();
		this.createMinimalIntermediateLandings();
	}
	createMinimalIntermediateLandings() {
		let minLanding = Math.ceil(this.etageHeight / this._fallingProtectionStandardRules.getMaxHeightLanding(this.etageHeight, this.angle)) - 1;
		let landingCount = this.intermediateLandings.getAll().length;
		if (landingCount > 0) {
			// check te laag
			let maxHeight = this._fallingProtectionStandardRules.getMaxHeightLanding(this.etageHeight, this.angle);
			let height = this.etageHeight;

			// check te veel
			this.intermediateLandings.removeUnnecessaryLandings(minLanding);
			this.intermediateLandings.getAll().forEach((il) => {
				if (height - il.height > maxHeight) {
					il.height = height - maxHeight;
				}
				if (il.height > this.etageHeight) {
					il.height = this.etageHeight / 2;
				}
				height = il.height;
			});
			this.intermediateLandings.sort(); // zorgen dat ze op de juiste volgorde komen te staan
		}
		if (landingCount < minLanding) {
			for (let i = landingCount; i < minLanding; i++) {
				this.addIntermediateLanding(true);
			}
		}

		this.intermediateLandings.updateAllLandings(this);
	}
	getEdgePosition(handRailPosition) {
		let startX = this.stairWell.startX;
		let startY = this.stairWell.startY;
		let endX = this.stairWell.startX + this.stairWell.width;
		let endY = this.stairWell.startY + this.stairWell.depth;

		if (startX - Configuration.CURRENT.overhang.size === handRailPosition.startX.value) {
			startX -= Configuration.CURRENT.overhang.size; // links tegen de kant
		}
		if (endX + Configuration.CURRENT.overhang.size === handRailPosition.startX.value) {
			endX += Configuration.CURRENT.overhang.size; // rechts tegen de kant
		}
		if (startY - Configuration.CURRENT.overhang.size === handRailPosition.startY.value) {
			startY -= Configuration.CURRENT.overhang.size; // boven tegen de kant
		}
		if (endY + Configuration.CURRENT.overhang.size === handRailPosition.startY.value) {
			endY += Configuration.CURRENT.overhang.size; // onder tegen de kant
		}

		return { startX: startX, startY: startY, endX: endX, endY: endY };
	}
	getPositionProfiles() {
		let startX = this.stairWell.startX;
		let startY = this.stairWell.startY;

		let endX = this.stairWell.startX + this.stairWell.width;
		let endY = this.stairWell.startY + this.stairWell.depth;

		return { startX: startX, startY: startY, endX: endX, endY: endY };
	}

	onHandrailPosition(handRailPosition) {
		if (handRailPosition === null || typeof handRailPosition === 'undefined') {
			return;
		}

		let edgePosition = this.getEdgePosition(handRailPosition);

		if (edgePosition.startX === handRailPosition.startX.value || edgePosition.endX === handRailPosition.startX.value) {
			return (
				(edgePosition.startY >= handRailPosition.startY.value && edgePosition.startY <= handRailPosition.endY.value) ||
				(edgePosition.endY >= handRailPosition.startY.value && edgePosition.endY <= handRailPosition.endY.value)
			);
		} else if (edgePosition.startY === handRailPosition.startY.value || edgePosition.endY === handRailPosition.startY.value) {
			return (
				(edgePosition.startX >= handRailPosition.startX.value && edgePosition.startX <= handRailPosition.endX.value) ||
				(edgePosition.endX >= handRailPosition.startX.value && edgePosition.endX <= handRailPosition.endX.value)
			);
		}
	}

	onProfilePosition(profilePosition) {
		let stairWidth = this.stairWell.width;
		let stairDepth = this.stairWell.depth;

		return (
			(this.stairWell.startX >= profilePosition.startX &&
				this.stairWell.startX <= profilePosition.endX &&
				this.stairWell.startX + stairWidth >= profilePosition.startX &&
				this.stairWell.startX + stairWidth <= profilePosition.endX &&
				profilePosition.startY >= this.stairWell.startY &&
				profilePosition.endY <= this.startY + stairDepth) ||
			(this.stairWell.startY >= profilePosition.startY &&
				this.stairWell.startY <= profilePosition.endY &&
				this.stairWell.startY + stairDepth >= profilePosition.startY &&
				this.stairWell.startY + stairDepth <= profilePosition.endY &&
				profilePosition.startX >= this.stairWell.startX &&
				profilePosition.endX <= this.stairWell.startX + stairWidth) ||
			(this.stairWell.startX <= profilePosition.startX &&
				this.stairWell.startX + stairWidth >= profilePosition.startX &&
				this.stairWell.startY <= profilePosition.startY &&
				this.stairWell.startY + stairDepth >= profilePosition.startY) ||
			(this.stairWell.startX <= profilePosition.endX &&
				this.stairWell.startX + stairWidth >= profilePosition.endX &&
				this.stairWell.startY <= profilePosition.endY &&
				this.stairWell.startY + stairDepth >= profilePosition.endY)
		);
	}

	calculate() {
		if (Configuration.CURRENT.raster.spansX.getSize() === 0 || Configuration.CURRENT.raster.spansY.getSize() === 0) {
			return;
		}

		super.calculate();
		this.addUsedSurface();

		if (this.rasters.length > 0) {
			this.stairWell.setTrimming(this.startX, this.startY, this.stairDrawWidth, this.stairDrawHeight, this.id, true);
		}
	}
	calculateAmount(params) {
		params.stairId = this.id;
		this.stairWell.calculateAmount(params);
	}

	drawObjectsStair(stairGroup, upComingInFloor, boundary, stairDepth, inFloor, prevLanding, landing, nextLanding, oneeightyDegreesLanding) {
		if (typeof inFloor === 'undefined' || inFloor === null) {
			inFloor = false;
		}
		let stairRight = null;
		let stairLeft = null;
		let stairTail = null;
		let startX = 0;
		let startY = 0;
		let totalHeight = this.etageHeight;
		let stairHeight = this.etageHeight;
		if (landing !== null) {
			stairHeight = landing.height;
		}
		if (typeof nextLanding !== 'undefined' && nextLanding !== null) {
			totalHeight -= nextLanding.height;
			stairHeight -= nextLanding.height;
		}

		switch (upComingInFloor) {
			case Stair.UPCOMING_TOP:
				if (inFloor === true) {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
				} else {
					startX = boundary.bottomLeft.x;
					startY = boundary.bottomLeft.y;
				}
				// Als de huidige 180 graden is lijnen we het stukje trap LINKS uit
				if (oneeightyDegreesLanding) {
					//als 180 graden dan aan begin van bordes
					startX = boundary.topRight.x - this.width;
				} else if (prevLanding !== null) {
					// In het midden uitlijnen van het bordes
					startX = boundary.topRight.x + (boundary.topLeft.x - boundary.topRight.x - this.width) / 2;
					if (nextLanding !== null && typeof nextLanding !== 'undefined') {
						if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
							// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
							startX = boundary.topRight.x - this.width;
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0),
					new DrawValue(startX),
					new DrawValue(startY + stairDepth), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);

				stairLeft = new Line(
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0),
					new DrawValue(startX + this.width),
					new DrawValue(startY + stairDepth), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairLeft',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);
				let correctionBeam = false;
				if (totalHeight >= Stairs.PASSAGE_HEIGHT + this.packetHeight) {
					let wellDepth = Mathematic.calculateDepth(Math.min(totalHeight - (Stairs.PASSAGE_HEIGHT + this.packetHeight), stairHeight), this.angle);
					let well = { startX: startX, startY: startY, width: this.width, depth: stairDepth };

					// als trap onder trapgat uit komt
					if (well.depth > wellDepth) {
						well.startY = well.startY + well.depth - wellDepth;
						well.depth = wellDepth;
						correctionBeam = true;
					}
					stairTail = new Rectangle(
						new DrawValue(well.startX),
						new DrawValue(well.startY, correctionBeam === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
						new DrawValue(well.width),
						new DrawValue(well.depth, correctionBeam === true ? Columns.COLUMN_SIZE / 2 : 0),
						this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
						null,
						null,
						false,
						this,
						{
							type: 'stairTail',
							color: Stairs.COLORS.stairTail,
							selected: Stairs.COLORS.selected,
						},
					);
				}

				boundary = {
					topLeft: { x: startX, y: startY },
					topRight: { x: startX + this.width, y: startY },
					bottomLeft: { x: startX, y: startY + stairDepth },
					bottomRight: { x: startX + this.width, y: startY + stairDepth },
				};

				break;
			case Stair.UPCOMING_BOTTOM:
				if (this.intermediateLandings.length === 0) {
					startX = boundary.bottomLeft.x;
					startY = boundary.bottomLeft.y;
				} else {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
					// Als de huidige 180 graden is lijnen we het stukje trap LINKS uit
					if (oneeightyDegreesLanding) {
						//als 180 graden dan aan begin van bordes
						startX = boundary.topLeft.x;
					} else if (prevLanding !== null) {
						// In het midden uitlijnen van het bordes
						startX = boundary.topLeft.x + (boundary.topRight.x - boundary.topLeft.x - this.width) / 2;
						if (nextLanding !== null && typeof nextLanding !== 'undefined') {
							if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
								// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
								startX = boundary.topLeft.x;
							}
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX),
					new DrawValue(startY - stairDepth),
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairLeft = new Line(
					new DrawValue(startX + this.width),
					new DrawValue(startY - stairDepth),
					new DrawValue(startX + this.width / 2),
					new DrawValue(startY), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairLeft',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);

				correctionBeam = false;
				boundary = {
					topLeft: { x: startX, y: startY - stairDepth },
					topRight: { x: startX + this.width, y: startY - stairDepth },
					bottomLeft: { x: startX, y: startY },
					bottomRight: { x: startX + this.width, y: startY },
				};
				if (totalHeight >= Stairs.PASSAGE_HEIGHT + this.packetHeight) {
					let wellDepth = Mathematic.calculateDepth(Math.min(totalHeight - (Stairs.PASSAGE_HEIGHT + this.packetHeight), stairHeight), this.angle);
					let well = { startX: startX, startY: startY - stairDepth, width: this.width, depth: stairDepth };

					// als trap onder trapgat uit komt
					if (well.depth > wellDepth) {
						well.depth = wellDepth;
						correctionBeam = true;
					}
					stairTail = new Rectangle(
						new DrawValue(well.startX),
						new DrawValue(well.startY, correctionBeam === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
						new DrawValue(well.width),
						new DrawValue(well.depth, correctionBeam === true ? Columns.COLUMN_SIZE / 2 : 0),
						this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
						null,
						null,
						false,
						this,
						{
							type: 'stairTail',
							color: Stairs.COLORS.stairTail,
							selected: Stairs.COLORS.selected,
						},
					);
				}

				break;
			case Stair.UPCOMING_RIGHT:
				if (this.intermediateLandings.length === 0) {
					startX = boundary.topRight.x;
					startY = boundary.topRight.y;
				} else {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
					if (oneeightyDegreesLanding) {
						//als 180 graden dan aan begin van bordes
						startY = boundary.bottomLeft.y - this.width;
					} else if (prevLanding !== null) {
						startY = boundary.topLeft.y + (boundary.bottomLeft.y - boundary.topLeft.y - this.width) / 2;
						if (nextLanding !== null && typeof nextLanding !== 'undefined') {
							if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
								// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
								startY = boundary.bottomLeft.y - this.width;
							}
						}
					}
				}

				stairLeft = new Line(
					new DrawValue(startX - stairDepth),
					new DrawValue(startY, Columns.COLUMN_SIZE / 2),
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairLeft', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairRight = new Line(
					new DrawValue(startX - stairDepth),
					new DrawValue(startY + this.width, -Columns.COLUMN_SIZE / 2),
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairRight',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);

				correctionBeam = false;
				if (totalHeight >= Stairs.PASSAGE_HEIGHT + this.packetHeight) {
					let wellDepth = Mathematic.calculateDepth(Math.min(totalHeight - (Stairs.PASSAGE_HEIGHT + this.packetHeight), stairHeight), this.angle);

					let well = { startX: startX - stairDepth, startY: startY, width: stairDepth, depth: this.width };

					// als trap onder trapgat uit komt
					if (well.width > wellDepth) {
						well.width = wellDepth;
						correctionBeam = true;
					}
					stairTail = new Rectangle(
						new DrawValue(well.startX),
						new DrawValue(well.startY, correctionBeam === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
						new DrawValue(well.width),
						new DrawValue(well.depth, correctionBeam === true ? Columns.COLUMN_SIZE / 2 : 0),
						this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
						null,
						null,
						false,
						this,
						{
							type: 'stairTail',
							color: Stairs.COLORS.stairTail,
							selected: Stairs.COLORS.selected,
						},
					);
				}

				boundary = {
					topLeft: { x: startX - stairDepth, y: startY },
					topRight: { x: startX, y: startY },
					bottomLeft: { x: startX - stairDepth, y: startY + this.width },
					bottomRight: { x: startX, y: startY + this.width },
				};

				break;
			case Stair.UPCOMING_LEFT:
				if (inFloor === true) {
					startX = boundary.topLeft.x;
					startY = boundary.topLeft.y;
				} else {
					startX = boundary.topRight.x;

					if (oneeightyDegreesLanding) {
						//als 180 graden dan aan begin van bordes
						startY = boundary.topLeft.y;
					} else if (prevLanding !== null) {
						startY = boundary.topLeft.y + (boundary.bottomLeft.y - boundary.topLeft.y - this.width) / 2;
						if (nextLanding !== null && typeof nextLanding !== 'undefined') {
							if (nextLanding.landingType === IntermediateLandings.oneeightyDegrees) {
								// Als de volgende 180 graden is dan rechts uitlijnen van het bordes.
								startY = boundary.topLeft.y;
							}
						}
					}
				}

				stairRight = new Line(
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2),
					new DrawValue(startX + stairDepth),
					new DrawValue(startY, inFloor === true ? Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					false,
					this,
					{ type: 'stairRight', color: Stairs.COLORS.stair, selected: Stairs.COLORS.selected },
				);
				stairLeft = new Line(
					new DrawValue(startX),
					new DrawValue(startY + this.width / 2),
					new DrawValue(startX + stairDepth),
					new DrawValue(startY + this.width, inFloor === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
					null,
					null,
					this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stair,
					null,
					null,
					false,
					this,
					{
						type: 'stairLeft',
						color: Stairs.COLORS.stair,
						selected: Stairs.COLORS.selected,
					},
				);
				correctionBeam = false;
				if (totalHeight >= Stairs.PASSAGE_HEIGHT + this.packetHeight) {
					let wellDepth = Mathematic.calculateDepth(Math.min(totalHeight - (Stairs.PASSAGE_HEIGHT + this.packetHeight), stairHeight), this.angle);
					let well = { startX: startX - stairDepth, startY: startY, width: stairDepth, depth: this.width };

					// als trap onder trapgat uit komt
					if (well.width > wellDepth) {
						well.startX = startX + stairDepth - wellDepth;
						well.width = wellDepth;
						correctionBeam = true;
					}

					stairTail = new Rectangle(
						new DrawValue(well.startX),
						new DrawValue(well.startY, correctionBeam === true ? -Columns.COLUMN_SIZE / 2 : 0), // laatste zou niet nodig zijn. Maar lijkt met onderRaveling niet helemaal goed te gaan
						new DrawValue(well.width),
						new DrawValue(well.depth, correctionBeam === true ? Columns.COLUMN_SIZE / 2 : 0),
						this.hasErrors === true ? Stairs.COLORS.stairCollisions : this.selected === true ? Stairs.COLORS.selected : Stairs.COLORS.stairTail,
						null,
						null,
						false,
						this,
						{
							type: 'stairTail',
							color: Stairs.COLORS.stairTail,
							selected: Stairs.COLORS.selected,
						},
					);
				}

				boundary = {
					topLeft: { x: startX, y: startY },
					topRight: { x: startX + stairDepth, y: startY },
					bottomLeft: { x: startX, y: startY + this.width },
					bottomRight: { x: startX + stairDepth, y: startY + this.width },
				};
				break;
		}
		if (stairRight !== null) {
			stairRight.opacity = 0.5;
			stairRight.colorFromParent = false;
			stairGroup.push(stairRight);
		}
		if (stairLeft !== null) {
			stairLeft.opacity = 0.5;
			stairLeft.colorFromParent = false;
			stairGroup.push(stairLeft);
		}
		if (stairTail !== null) {
			stairTail.opacity = 0.2;
			stairTail.colorFromParent = false;
			stairGroup.push(stairTail);
		}
		this.boundaries.push(boundary);
		return boundary;
	}

	updateStairPositionOnRasterChanged(params) {
		let activeEtageId = params.etages.activeEtageIndex;
		let floor = params.etages.etages[activeEtageId].floor;
		// Alleen als huidige raster of rasters ervoor veranders is van grootte
		if (params.changedParams.beamDirection === 'x' && params.changedParams.rasterIndex <= this.rasters[0].x) {
			if (this.startX + this.stairDrawWidth > floor.width) {
				this.startX = this.startX - this.stairDrawWidth;
			}
		}
		if (params.changedParams.beamDirection === 'y' && params.changedParams.rasterIndex <= this.rasters[0].y) {
			if (this.startY + this.stairDrawHeight > floor.length) {
				this.startY = this.startY - this.stairDrawHeight;
			}
		}
	}
	// TODO: Deze functie geheel weghalen als 180 graden is gefixt is.
	checkUpComing() {
		this.disabledUpcomings = [];
		if (this.rasters.length > 0) {
			if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
				if (this.stairDrawWidth > Configuration.CURRENT.raster.spansY.get(this.rasters[0].y).value) {
					if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
						this.disabledUpcomings = [Stair.UPCOMING_LEFT, Stair.UPCOMING_RIGHT];
					} else {
						this.disabledUpcomings = [Stair.UPCOMING_TOP, Stair.UPCOMING_BOTTOM];
					}
				}
				if (this.stairDrawHeight > Configuration.CURRENT.raster.spansY.get(this.rasters[0].y).value) {
					if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
						this.disabledUpcomings = [Stair.UPCOMING_TOP, Stair.UPCOMING_BOTTOM];
					} else {
						this.disabledUpcomings = [Stair.UPCOMING_LEFT, Stair.UPCOMING_RIGHT];
					}
				}
			} else {
				if (this.stairDrawWidth > Configuration.CURRENT.raster.spansX.get(this.rasters[0].x).value) {
					if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
						this.disabledUpcomings = [Stair.UPCOMING_TOP, Stair.UPCOMING_BOTTOM];
					} else {
						this.disabledUpcomings = [Stair.UPCOMING_LEFT, Stair.UPCOMING_RIGHT];
					}
				}
				if (this.stairDrawHeight > Configuration.CURRENT.raster.spansX.get(this.rasters[0].x).value) {
					if (this.upComing === Stair.UPCOMING_TOP || this.upComing === Stair.UPCOMING_BOTTOM) {
						this.disabledUpcomings = [Stair.UPCOMING_LEFT, Stair.UPCOMING_RIGHT];
					} else {
						this.disabledUpcomings = [Stair.UPCOMING_TOP, Stair.UPCOMING_BOTTOM];
					}
				}
			}
		}
		if (this.disabledUpcomings.includes(this.upComing)) {
			this.upComing++;
			if (this.upComing > 4) {
				this.upComing -= 4;
			}
		}
	}
}
