import { Data } from '../data';

export class ProfileModels extends Data {
	objectName = 'ProfileModels';
	url = 'ProfileModels';
	getById(id, force = false, thenCallBack, catchCallBack, cacheId) {
		if (typeof id === 'undefined' || id === null) {
			return;
		}
		// samenstellen url
		let url = this.baseUrl + this.url + '?id=' + id;
		this.get(url, force, thenCallBack, catchCallBack, cacheId + '_' + id, false);
	}

	getLengths(id, force = false, thenCallBack, catchCallBack, cacheId) {
		if (typeof id === 'undefined' || id === null) {
			return;
		}
		let url = this.baseUrl + this.url + '?id=' + id;
		this.get(url, force, thenCallBack, catchCallBack, cacheId + '_' + id, false);
	}
}
