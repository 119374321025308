import { Stair } from './stair';
import { StairInFloor } from './stairInFloor';
import { StairOutSide } from './stairOutSide';

export class StairDynamic {
	objectName = 'StairDynamic';
	static classes = {
		inFloor: StairInFloor,
		outside: StairOutSide,
	};
	constructor(typeStair, newStairConfiguration, raster) {
		if (typeof StairDynamic.classes[typeStair] !== 'undefined') {
			return new StairDynamic.classes[typeStair](newStairConfiguration, raster);
		}

		return new Stair(newStairConfiguration, raster);
	}
}
