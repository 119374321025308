import { Line } from '../draw/line';
import { Canvas3D } from '../draw3d/Canvas3D';
import { ProtectorBottom3D } from '../draw3d/columnprotectors/protectorBottom3D';
import { ProtectorLeft3D } from '../draw3d/columnprotectors/protectorLeft3D';
import { ProtectorLeftBottom3D } from '../draw3d/columnprotectors/ProtectorLeftBottom3D';
import { ProtectorLeftTop3D } from '../draw3d/columnprotectors/ProtectorLeftTop3D';
import { ProtectorRight3D } from '../draw3d/columnprotectors/ProtectorRight3D';
import { ProtectorRightBottom3D } from '../draw3d/columnprotectors/ProtectorRightBottom3D';
import { ProtectorRightTop3D } from '../draw3d/columnprotectors/ProtectorRightTop3D';
import { ProtectorTop3D } from '../draw3d/columnprotectors/ProtectorTop3D';
import { Columns } from './columns';

export class ColumnProtector {
	objectName = 'ColumnProtector';
	position = null;
	samePositions = [];
	isCorner = true;

	get selected() {
		return this._selected;
	}
	set selected(value) {
		this._selected = value;
	}
	offset = { x: 3, y: 3 };
	_onChange = null;

	constructor(position, samePositions, isCorner) {
		this.position = position;
		this.samePositions = samePositions;
		this.isCorner = isCorner;
	}
	setReferences(params) {
		this._onChange = params.onChange;
	}
	removeReferences() {
		this._onChange = null;
	}
	onChange() {
		if (typeof this._onChange === 'function') {
			this._onChange(this, true);
		}
	}
	afterReconstruct() {
		// Na reconstruct kijken of type undefined is,
		// Dan dit veranderen naar id en id weggooien.
		// Id moet nummer zijn, type is dan welke positie de protector staat.
		if (typeof this.position === 'undefined' || this.position === null) {
			this.position = this.id;
			delete this.id;
		}
	}
	addDrawObjects(canvas, column) {
		switch (this.position) {
			case 'lt':
				const ltPositionStart = column.getPosition();
				const ltPositionEnd = column.getPosition();
				const ltPositionStart1 = column.getPosition();
				const ltPositionEnd1 = column.getPosition();

				ltPositionStart.startX.offsetScaled -= this.offset.x;
				ltPositionStart.startY.offsetScaled -= this.offset.y;
				ltPositionEnd.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
				ltPositionEnd.startY.offsetScaled -= this.offset.y;
				ltPositionStart1.startX.offsetScaled -= this.offset.x;
				ltPositionStart1.startY.offsetScaled -= this.offset.y;
				ltPositionEnd1.startX.offsetScaled -= this.offset.x;
				ltPositionEnd1.startY.offsetScaled += Columns.COLUMN_SIZE / 2;

				return [
					new Line(ltPositionStart.startX, ltPositionStart.startY, ltPositionEnd.startX, ltPositionEnd.startY),
					new Line(ltPositionStart1.startX, ltPositionStart1.startY, ltPositionEnd1.startX, ltPositionEnd1.startY),
				];
			case 'rt':
				const rtPositionStart = column.getPosition();
				const rtPositionEnd = column.getPosition();
				const rtPositionStart1 = column.getPosition();
				const rtPositionEnd1 = column.getPosition();

				rtPositionStart.endX.offsetScaled -= Columns.COLUMN_SIZE / 2;
				rtPositionStart.startY.offsetScaled -= this.offset.y;
				rtPositionEnd.endX.offsetScaled += this.offset.x;
				rtPositionEnd.startY.offsetScaled -= this.offset.y;
				rtPositionStart1.endX.offsetScaled += this.offset.x;
				rtPositionStart1.startY.offsetScaled -= this.offset.y;
				rtPositionEnd1.endX.offsetScaled += this.offset.x;
				rtPositionEnd1.startY.offsetScaled += Columns.COLUMN_SIZE / 2;

				return [
					new Line(rtPositionStart.endX, rtPositionStart.startY, rtPositionEnd.endX, rtPositionEnd.startY),
					new Line(rtPositionStart1.endX, rtPositionStart1.startY, rtPositionEnd1.endX, rtPositionEnd1.startY),
				];

			case 't':
				const tPositionStart = column.getPosition();
				const tPositionEnd = column.getPosition();
				const tPositionStart1 = column.getPosition();
				const tPositionEnd1 = column.getPosition();
				const tPositionStart2 = column.getPosition();
				const tPositionEnd2 = column.getPosition();

				tPositionStart.startX.offsetScaled -= this.offset.x;
				tPositionStart.startY.offsetScaled -= this.offset.y;
				tPositionEnd.startX.offsetScaled -= this.offset.x;
				tPositionEnd.startY.offsetScaled += Columns.COLUMN_SIZE / 2;
				tPositionStart1.startX.offsetScaled -= this.offset.x;
				tPositionStart1.startY.offsetScaled -= this.offset.y;
				tPositionEnd1.endX.offsetScaled += this.offset.x;
				tPositionEnd1.startY.offsetScaled -= this.offset.y;
				tPositionStart2.endX.offsetScaled += this.offset.x;
				tPositionStart2.startY.offsetScaled -= this.offset.y;
				tPositionEnd2.endX.offsetScaled += this.offset.x;
				tPositionEnd2.startY.offsetScaled += Columns.COLUMN_SIZE / 2;
				return [
					new Line(tPositionStart.startX, tPositionStart.startY, tPositionEnd.startX, tPositionEnd.startY),
					new Line(tPositionStart1.startX, tPositionStart1.startY, tPositionEnd1.endX, tPositionEnd1.startY),
					new Line(tPositionStart2.endX, tPositionStart2.startY, tPositionEnd2.endX, tPositionEnd2.startY),
				];

			case 'b':
				const bPositionStart = column.getPosition();
				const bPositionEnd = column.getPosition();
				const bPositionStart1 = column.getPosition();
				const bPositionEnd1 = column.getPosition();
				const bPositionStart2 = column.getPosition();
				const bPositionEnd2 = column.getPosition();

				bPositionStart.startX.offsetScaled -= this.offset.x;
				bPositionStart.endY.offsetScaled -= Columns.COLUMN_SIZE / 2;
				bPositionEnd.startX.offsetScaled -= this.offset.x;
				bPositionEnd.endY.offsetScaled += this.offset.y;
				bPositionStart1.startX.offsetScaled -= this.offset.x;
				bPositionStart1.endY.offsetScaled += this.offset.y;
				bPositionEnd1.endX.offsetScaled += this.offset.x;
				bPositionEnd1.endY.offsetScaled += this.offset.y;
				bPositionStart2.endX.offsetScaled += this.offset.x;
				bPositionStart2.endY.offsetScaled -= Columns.COLUMN_SIZE / 2;
				bPositionEnd2.endX.offsetScaled += this.offset.x;
				bPositionEnd2.endY.offsetScaled += this.offset.y;
				return [
					new Line(bPositionStart.startX, bPositionStart.endY, bPositionEnd.startX, bPositionEnd.endY),
					new Line(bPositionStart1.startX, bPositionStart1.endY, bPositionEnd1.endX, bPositionEnd1.endY),
					new Line(bPositionStart2.endX, bPositionStart2.endY, bPositionEnd2.endX, bPositionEnd2.endY),
				];

			case 'rb':
				const rbPositionStart = column.getPosition();
				const rbPositionEnd = column.getPosition();
				const rbPositionStart1 = column.getPosition();
				const rbPositionEnd1 = column.getPosition();

				rbPositionStart.endX.offsetScaled -= Columns.COLUMN_SIZE / 2;
				rbPositionStart.endY.offsetScaled += this.offset.y;
				rbPositionEnd.endX.offsetScaled += this.offset.x;
				rbPositionEnd.endY.offsetScaled += this.offset.y;
				rbPositionStart1.endX.offsetScaled += this.offset.x;
				rbPositionStart1.endY.offsetScaled -= Columns.COLUMN_SIZE / 2;
				rbPositionEnd1.endX.offsetScaled += this.offset.x;
				rbPositionEnd1.endY.offsetScaled += this.offset.y;
				return [
					new Line(rbPositionStart.endX, rbPositionStart.endY, rbPositionEnd.endX, rbPositionEnd.endY),
					new Line(rbPositionStart1.endX, rbPositionStart1.endY, rbPositionEnd1.endX, rbPositionEnd1.endY),
				];

			case 'lb':
				const lbPositionStart = column.getPosition();
				const lbPositionEnd = column.getPosition();
				const lbPositionStart1 = column.getPosition();
				const lbPositionEnd1 = column.getPosition();

				lbPositionStart.startX.offsetScaled -= this.offset.x;
				lbPositionStart.endY.offsetScaled -= Columns.COLUMN_SIZE / 2;
				lbPositionEnd.startX.offsetScaled -= this.offset.x;
				lbPositionEnd.endY.offsetScaled += this.offset.y;
				lbPositionStart1.startX.offsetScaled -= this.offset.x;
				lbPositionStart1.endY.offsetScaled += this.offset.y;
				lbPositionEnd1.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
				lbPositionEnd1.endY.offsetScaled += this.offset.y;
				return [
					new Line(lbPositionStart.startX, lbPositionStart.endY, lbPositionEnd.startX, lbPositionEnd.endY),
					new Line(lbPositionStart1.startX, lbPositionStart1.endY, lbPositionEnd1.startX, lbPositionEnd1.endY),
				];

			case 'r':
				const rPositionStart = column.getPosition();
				const rPositionEnd = column.getPosition();
				const rPositionStart1 = column.getPosition();
				const rPositionEnd1 = column.getPosition();
				const rPositionStart2 = column.getPosition();
				const rPositionEnd2 = column.getPosition();

				rPositionStart.endX.offsetScaled -= Columns.COLUMN_SIZE / 2;
				rPositionStart.startY.offsetScaled -= this.offset.y;
				rPositionEnd.endX.offsetScaled += this.offset.x;
				rPositionEnd.startY.offsetScaled -= this.offset.y;
				rPositionStart1.endX.offsetScaled += this.offset.x;
				rPositionStart1.startY.offsetScaled -= this.offset.y;
				rPositionEnd1.endX.offsetScaled += this.offset.x;
				rPositionEnd1.endY.offsetScaled += this.offset.y;
				rPositionStart2.endX.offsetScaled -= Columns.COLUMN_SIZE / 2;
				rPositionStart2.endY.offsetScaled += this.offset.y;
				rPositionEnd2.endX.offsetScaled += this.offset.x;
				rPositionEnd2.endY.offsetScaled += this.offset.y;
				return [
					new Line(rPositionStart.endX, rPositionStart.startY, rPositionEnd.endX, rPositionEnd.startY),
					new Line(rPositionStart1.endX, rPositionStart1.startY, rPositionEnd1.endX, rPositionEnd1.endY),
					new Line(rPositionStart2.endX, rPositionStart2.endY, rPositionEnd2.endX, rPositionEnd2.endY),
				];
			case 'l':
				const lPositionStart = column.getPosition();
				const lPositionEnd = column.getPosition();
				const lPositionStart1 = column.getPosition();
				const lPositionEnd1 = column.getPosition();
				const lPositionStart2 = column.getPosition();
				const lPositionEnd2 = column.getPosition();

				lPositionStart.startX.offsetScaled -= this.offset.x;
				lPositionStart.startY.offsetScaled -= this.offset.y;
				lPositionEnd.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
				lPositionEnd.startY.offsetScaled -= this.offset.y;
				lPositionStart1.startX.offsetScaled -= this.offset.x;
				lPositionStart1.startY.offsetScaled -= this.offset.y;
				lPositionEnd1.startX.offsetScaled -= this.offset.x;
				lPositionEnd1.endY.offsetScaled += this.offset.y;
				lPositionStart2.startX.offsetScaled -= this.offset.x;
				lPositionStart2.endY.offsetScaled += this.offset.y;
				lPositionEnd2.startX.offsetScaled += Columns.COLUMN_SIZE / 2;
				lPositionEnd2.endY.offsetScaled += this.offset.y;
				return [
					new Line(lPositionStart.startX, lPositionStart.startY, lPositionEnd.startX, lPositionEnd.startY),
					new Line(lPositionStart1.startX, lPositionStart1.startY, lPositionEnd1.startX, lPositionEnd1.endY),
					new Line(lPositionStart2.startX, lPositionStart2.endY, lPositionEnd2.startX, lPositionEnd2.endY),
				];
		}
	}
	addDrawObjects3d(canvas3d, column, columnInfo, columnPosition) {
		let protectorData = { ...this };
		protectorData.column = column;

		switch (this.position) {
			case 'lt':
				canvas3d.addDrawObject(new ProtectorLeftTop3D(columnPosition.x, 0, columnPosition.z, { info: protectorData, columnOid: columnInfo.oid }), Canvas3D.TYPE_COLUMNPROTECTOR);
				break;
			case 'rt':
				canvas3d.addDrawObject(new ProtectorRightTop3D(columnPosition.x, 0, columnPosition.z, { info: protectorData, columnOid: columnInfo.oid }), Canvas3D.TYPE_COLUMNPROTECTOR);
				break;
			case 'rb':
				canvas3d.addDrawObject(new ProtectorRightBottom3D(columnPosition.x, 0, columnPosition.z, { info: protectorData, columnOid: columnInfo.oid }), Canvas3D.TYPE_COLUMNPROTECTOR);
				break;
			case 'lb':
				canvas3d.addDrawObject(new ProtectorLeftBottom3D(columnPosition.x, 0, columnPosition.z, { info: protectorData, columnOid: columnInfo.oid }), Canvas3D.TYPE_COLUMNPROTECTOR);
				break;
			case 't':
				canvas3d.addDrawObject(new ProtectorTop3D(columnPosition.x, 0, columnPosition.z, { info: protectorData, columnOid: columnInfo.oid }), Canvas3D.TYPE_COLUMNPROTECTOR);
				break;
			case 'b':
				canvas3d.addDrawObject(new ProtectorBottom3D(columnPosition.x, 0, columnPosition.z, { info: protectorData, columnOid: columnInfo.oid }), Canvas3D.TYPE_COLUMNPROTECTOR);
				break;
			case 'r':
				canvas3d.addDrawObject(new ProtectorRight3D(columnPosition.x, 0, columnPosition.z, { info: protectorData, columnOid: columnInfo.oid }), Canvas3D.TYPE_COLUMNPROTECTOR);
				break;
			case 'l':
				canvas3d.addDrawObject(new ProtectorLeft3D(columnPosition.x, 0, columnPosition.z, { info: protectorData, columnOid: columnInfo.oid }), Canvas3D.TYPE_COLUMNPROTECTOR);
				break;
		}
	}
}
