import { Data } from './data';
import { Store } from './store';

export class MiscellaneousArticles extends Data {
	objectName = 'MiscellaneousArticles';
	static CURRENT = null;
	url = 'MiscellaneousArticles';

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}
		if (typeof data.companyId === 'undefined' || data.companyId === null || data.companyId === -1) {
			data.companyId = Store.CURRENT.companies.selectedCompany.id;
		}
		if (typeof data.language === 'undefined' || data.language === null) {
			data.language = Store.CURRENT.languages.currentLanguage;
		}

		let list = super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
		return list;
	}
}
