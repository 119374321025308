<template>
	<div class="button" :class="primary === true ? 'primary' : ''" @click="click()">
		<slot />
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},

		primary: {
			type: Boolean,
			default: false,
		},
	},
	data: () => {
		return {};
	},

	watch: {},

	mounted() {
		this.id = 'button_' + this._uid;
	},
	beforeDestroy() {},
	destroyed() {},
	methods: {
		click: function () {
			this.$emit('click');
		},
	},
};
</script>

<style lang="less" scoped></style>
