/* eslint-disable no-useless-constructor */
import { jsonProfile } from '../../../../configurator/hicad/jsonProfile';
import { ProfilesHorizontal3D } from '../../ProfilesHorizontal3D';

export class ChildBeamBottom3D extends ProfilesHorizontal3D {
	objectName = 'ChildBeamBottom3D';
	profileType = 'childbeam';
	rotationX = -180;
	constructor(x, y, length, params) {
		super(x, y, length, params);
		this.depth = 1;
	}
	draw(app) {
		super.draw(app);
	}
	// Convert ChildBeamLeft3D HiCADProfile, zodat deze in hicad gebruikt kan worden.
	convert() {
		return new jsonProfile(this, { type: jsonProfile.TYPE_CHILDBEAM, direction: jsonProfile.DIRECTION_HORIZONTAL, position: jsonProfile.POSITION_TOP });
	}
}
