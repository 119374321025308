import { Bracings } from './bracings';
import { PortalBracing } from './portalBracing';

export class PortalBracings extends Bracings {
	objectName = 'PortalBracings';
	objectName3d = 'PortalBracings3D';
	amountData = { PortalBracings: [] };
	static COLORS = { bracing: '#999', possible: '#6ba3ff', added: '#10821a', mouseMove: '#00286d', addedMouseMove: '#0f6d01' };
	push(startColumn, endColumn) {
		this.bracings.push(new PortalBracing(startColumn, endColumn));
	}
	resetAmountData() {
		this.amountData = { PortalBracings: [] };
	}
	pushToAmountData(object) {
		this.amountData.PortalBracings.push(object);
	}
	possiblePosition(column, columnRight, activeIndex, activeEtage, braceColumnEnumRight, braceColumnEnumLeft) {
		return (
			columnRight !== null &&
			columnRight.positionOffset.y === column.positionOffset.y &&
			columnRight.isActive(activeIndex) &&
			activeEtage.braceColumns.findIndex(column, braceColumnEnumRight) === -1 &&
			activeEtage.braceColumns.findIndex(columnRight, braceColumnEnumLeft) === -1 &&
			activeEtage.bracings.findIndex(column, columnRight) === -1
		); // alleen kolommen die op dezelfde lijn liggen voor bracing
		// kolom rechts aanwezig en actief en geen schoorkolom rechts van de kolom of links van de rechterkolom
	}
}
