import { Data } from './data';

export class IntroImage extends Data {
	id = -1;
	name = '';
	salesLocation = null;
	get imageUrl() {
		return this.baseUrl + 'introImages/image?id=' + this.id;
	}
}
