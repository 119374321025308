import { Configuration } from '../../../../configurator/configuration';
import { HandRails } from '../../../../configurator/handRails';
import { Canvas3D } from '../../../Canvas3D';
import { Model3D } from '../../../Model3D';

export class HandrailMiddlePlateHolderRight3D extends Model3D {
	objectName = 'HandrailMiddlePlateHolderRight3D';
	assetName = 'handrail_middleplate_holder';
	fallBackName = 'handrail_middleplate_holder';
	fallBackData = { height: 60, depth: 25, width: 40 };
	// De haakjes aan de paaltjes die de plaat op de helft van het paaltjes hoogte vastmaakt
	draw(app) {
		let poleWidth = Canvas3D.CURRENT.getModelDataByOid(this.handRailMaterials.post, 'width');
		let modelDepth = Canvas3D.CURRENT.getModelData('handrail_middleplate_holder', 'depth');

		this.ralColor = Configuration.CURRENT.colors.handRail.ralColor;

		if (this.position === HandRails.TOP || this.position === HandRails.BOTTOM) {
			// this.z += (poleWidth - modelDepth) / 2;

			if (this.position === HandRails.TOP) {
				// Terugzetten in de plaat
				this.z += 5;
			} else {
				this.z += poleWidth;
				this.z -= modelDepth;
				this.z -= 5;
			}
			// Hoogte instellen
			this.x += poleWidth;
		} else {
			this.rotationY = -90;
			this.z += poleWidth;
			if (this.position === HandRails.LEFT) {
				this.x += 5;
				this.x += modelDepth;
			} else {
				this.x += poleWidth;
				this.x -= 5;
			}
		}

		this.y += this.cornerPoleLength / 2;
		super.draw(app);
	}
}
