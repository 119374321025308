import { DrawObject } from './drawObject';

export class ObjectGroup extends DrawObject {
	objectName = 'ObjectGroup';
	drawObjects = [];

	constructor(color = null, fillColor = null, lineColor = null, mouseDetect = null, object = null, objectParams = null) {
		super(0, 0, color, fillColor, lineColor, mouseDetect, object, objectParams);
	}
	clear() {
		this.drawObjects = [];
	}
	minMaxCoordinates(types) {
		let min = { x: null, y: null };
		let max = { x: null, y: null };

		this.drawObjects.forEach((drawObject) => {
			if (typeof types === 'undefined' || types === null || types.includes(drawObject.objectParams.type) === true) {
				const minMax = drawObject.minMax();

				if (min.x === null || min.x > minMax.min.x) {
					min.x = minMax.min.x;
				}
				if (min.y === null || min.y > minMax.min.y) {
					min.y = minMax.min.y;
				}
				if (max.x === null || max.x < minMax.max.x) {
					max.x = minMax.max.x;
				}
				if (max.y === null || max.y < minMax.max.y) {
					max.y = minMax.max.y;
				}
			}
		});

		return { min: min, max: max };
	}
	minMaxCoordinatesScaled(types) {
		return { min: this.minScaled, max: this.maxScaled };
	}
	// vanwege historie setLineColor gemaakt ipv setter voor linecolor enz.
	setLineColor(color) {
		if (typeof this.drawObjects !== 'undefined') {
			this.drawObjects.forEach((drawObject) => {
				drawObject.setLineColor(color);
			});
		}
	}
	setFillColor(color) {
		if (typeof this.drawObjects !== 'undefined') {
			this.drawObjects.forEach((drawObject) => {
				drawObject.setFillColor(color);
			});
		}
	}
	setColor(color) {
		if (typeof this.drawObjects !== 'undefined') {
			this.drawObjects.forEach((drawObject) => {
				drawObject.setColor(color);
			});
		}
	}
	draw(canvas) {
		if (this.visible === false) {
			return { x: -1, y: -1, width: -1, height: -1 };
		}
		this.mouseArea = { minX: 0, minY: 0, maxX: 0, maxY: 0 };
		this.minScaled = { x: null, y: null };
		this.maxScaled = { x: null, y: null };
		this.drawObjects.forEach((drawObject, index) => {
			if (typeof drawObject !== 'undefined') {
				if (drawObject.colorFromParent === true) {
					drawObject.color = this.color;
					drawObject.lineColor = this.lineColor;
					drawObject.fillColor = this.fillColor;
				}
				const result = drawObject.draw(canvas);

				if (result.x < this.minScaled.x || this.minScaled.x === null) {
					this.minScaled.x = result.x;
				}
				if (result.y < this.minScaled.y || this.minScaled.y === null) {
					this.minScaled.y = result.y;
				}
				if (result.x + result.width > this.maxScaled.x || this.maxScaled.x === null) {
					this.maxScaled.x = result.x + result.width;
				}
				if (result.y + result.height > this.maxScaled.y || this.maxScaled.y === null) {
					this.maxScaled.y = result.y + result.height;
				}
				let offset = { x: 0, y: 0 };
				if (typeof this.objectParams !== 'undefined' && this.objectParams !== null && typeof this.objectParams.mouseAreaOffset !== 'undefined') {
					offset.x = this.objectParams.mouseAreaOffset.x;
					offset.y = this.objectParams.mouseAreaOffset.y;
				} else if (typeof this.object !== 'undefined' && this.object !== null && typeof this.object.mouseAreaOffset !== 'undefined') {
					offset.x = this.object.mouseAreaOffset.x;
					offset.y = this.object.mouseAreaOffset.y;
				}
				if (this.mouseDetect === true) {
					if (this.mouseArea.minX === 0 || result.x - offset.x < this.mouseArea.minX) {
						this.mouseArea.minX = result.x - offset.x;
					}
					if (this.mouseArea.minY === 0 || result.y - offset.y < this.mouseArea.minY) {
						this.mouseArea.minY = result.y - offset.y;
					}

					if (this.mouseArea.maxX === 0 || result.x + result.width + offset.x > this.mouseArea.maxX) {
						this.mouseArea.maxX = result.x + result.width + offset.x;
					}

					if (this.mouseArea.maxY === 0 || result.y + result.height + offset.y > this.mouseArea.maxY) {
						this.mouseArea.maxY = result.y + result.height + offset.y;
					}
				}
			} else {
			}
		});
		return {
			// nog niet getest dus weet niet of deze de juiste waarden teruggeeft
			x: this.minScaled.x,
			y: this.minScaled.y,
			width: this.maxScaled.x - this.minScaled.x,
			height: this.maxScaled.y - this.minScaled.y,
		};
	}
	push(value) {
		if (Array.isArray(value)) {
			value.forEach((drawObject, index) => {
				this.drawObjects.push(drawObject);
			});
		} else {
			this.drawObjects.push(value);
		}
	}
	findByDrawId(id) {
		// zoekt op id van het drawobject
		let result = null;
		this.drawObjects.forEach((drawObject, index) => {
			if (drawObject.id === id) {
				result = drawObject;
			}
			if (drawObject.objectName === 'ObjectGroup') {
				let objectGroupResult = drawObject.findByDrawId(id);
				if (objectGroupResult !== null) {
					result = objectGroupResult;
				}
			}
		});

		return result;
	}
	find(x, y) {
		const foundPositions = [];
		this.drawObjects.forEach((drawObject, index) => {
			if (drawObject.equals(x, y)) {
				foundPositions.push(drawObject);
			}
			if (typeof drawObject.find === 'function') {
				const groupPositions = drawObject.find(x, y);
				if (groupPositions.length > 0) {
					groupPositions.forEach((position) => {
						foundPositions.push(position);
					});
				}
			}
		});

		return foundPositions;
	}
	containsItems() {
		return this.drawObjects.length > 0;
	}
	minMax() {
		let result = { min: { x: -1, y: -1 }, max: { x: -1, y: -1 } };
		this.drawObjects.forEach((drawObject) => {
			let minMax = drawObject.minMax();
			if (result.min.x === -1 || result.min.x > minMax.min.x) {
				result.min.x = minMax.min.x;
			}
			if (result.min.y === -1 || result.min.y > minMax.min.y) {
				result.min.y = minMax.min.y;
			}
			if (result.max.x === -1 || result.max.x < minMax.max.x) {
				result.max.x = minMax.max.x;
			}
			if (result.max.y === -1 || result.max.y < minMax.max.y) {
				result.max.y = minMax.max.y;
			}
		});
		return result;
	}
}
