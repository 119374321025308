import { Data } from './data';
import { Company } from './company';
import { User } from './user';
import { Store } from './store';
import { Languages } from './languages';
// import { Configuration } from './configuration';
import * as Sentry from '@sentry/browser';
export class Companies extends Data {
	objectName = 'Companies';
	static CURRENT = null;
	url = 'Companies';
	_selectedCompany = new Company();
	onChangeSelectedCompanyEvents = [];
	user = new User();
	mutableLoading = true;
	amountOfCompanies = 0;
	listOfSearchedCompanies = [];

	restoreFromLocalStorage() {
		if (Store.CURRENT.checkLogin === true) {
			setTimeout(() => {
				super.restoreFromLocalStorage();
			}, 10);
		}
	}
	fetchListItem(item) {
		let returnItem = new Company();
		returnItem.setStore(this.store);
		returnItem.fetch(item);

		return returnItem;
	}
	getDefaultCountryCode() {
		let countryList = this.store.countries.list();
		if (
			typeof countryList !== 'undefined' &&
			countryList !== null &&
			typeof this.selectedCompany !== 'undefined' &&
			this.selectedCompany !== null &&
			typeof this.selectedCompany.visitAddress !== 'undefined' &&
			this.selectedCompany.visitAddress !== null
		) {
			let countryId = this.selectedCompany.visitAddress.country;

			if (countryId !== '') {
				let obj = countryList.find((o) => o.id === countryId);
				if (typeof obj !== 'undefined' && obj !== null) {
					return obj.code;
				}
			}
		}
		return '';
	}
	addOnChangeSelectedCompany(callBack) {
		// registreer verzoeken van objecten die afhankelijk zijn van de wijziging van selected company
		this.onChangeSelectedCompanyEvents.push(callBack);
	}
	persistentData() {
		return { selectedCompany: { id: this.selectedCompany.id } }; // default niets opslaan
	}
	onChangeSelectedCompany() {
		// uitvoeren van de events die geregistreerd zijn om objecten te informeren
		this.onChangeSelectedCompanyEvents.forEach((event) => {
			event();
		});
	}
	setStore(store) {
		super.setStore(store);
		this._selectedCompany.setStore(store); // ook selectedCompany store toekennen
	}
	get companyList() {
		// als er al een lijst is opgehaald deze als companylist teruggeven
		if (typeof this._list.list !== 'undefined' && this._list.list !== null) {
			return this._list.list;
		} else if (this.listRequest === null) {
			this.list();
		}
		return [];
	}
	get selectedCompany() {
		// als er nog geen company geselecteerd is en er geen ophaalverzoek loopt
		if (this._selectedCompany.id === -1 && this._selectedCompany.itemRequest === null) {
			// defaultcompany van user ophalen

			let defaultCompany = this.store.user.defaultCompany;

			// als user een default company heeft deze instellen en gegevens van die company ophalen
			if (defaultCompany > -1) {
				this._selectedCompany.id = defaultCompany; // voorkom loop. Als defaultcompany niet bestaat zou hij -1 blijven
				this._selectedCompany.getById(defaultCompany, true); // gegevens ophalen
			}
		}

		// teruggeven default company
		return this._selectedCompany;
	}
	setSelectedValue(company, thenCallBack, clearQuotation = false) {
		this.selectedCompany = company;
		// gegevens van company ophalen
		this._selectedCompany.getById(company.id, true, (response) => {
			if (clearQuotation) {
				Store.CURRENT.quotations.clearActiveQuotation();
			}
			// Configuration.CURRENT.countryCode = Store.CURRENT.companies.getDefaultCountryCode();
			// objecten informeren dat company is gewijzigd
			this.saveToLocalStorage();
			this.onChangeSelectedCompany();

			if (typeof thenCallBack === 'function') {
				thenCallBack();
			}
		});
	}
	setSelectedCompanyById(id, thenCallBack) {
		// Ophalen van de company en die zet zichzelf door de fetch functie in data.js/get
		this._selectedCompany.getById(id, true, (response) => {
			if (typeof thenCallBack === 'function') {
				thenCallBack(response);
			}
		});
	}

	set selectedCompany(value) {
		this._selectedCompany.id = value.id;

		// gegevens van company ophalen
		this._selectedCompany.getById(value.id);
		// objecten informeren dat company is gewijzigd
		this.saveToLocalStorage();

		this.onChangeSelectedCompany();
	}
	getCompanyList(searchText) {
		this.list({ searchText: searchText });
	}
	resetCompany() {
		this._selectedCompany = new Company();
		this._selectedCompany.id = -1;
	}

	searchInCompaniesList(data, urlExtension, thenCallBack, catchCallBack) {
		if (typeof urlExtension === 'undefined' || urlExtension === null) {
			urlExtension = '?top=50';
		}
		if (typeof data !== 'undefined' && data !== null) {
			data = data.replace(/&/g, '%26');
			urlExtension += '&searchText=' + data;
		}

		let url = this.baseUrl + this.url + urlExtension;

		this.store.dataAccess
			.get(url)
			.then((result) => {
				this.listOfSearchedCompanies = result;
				if (typeof thenCallBack === 'function') {
					thenCallBack();
				}
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', data: ' + JSON.stringify(data) + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
				if (typeof catchCallBack === 'function') {
					catchCallBack();
				}
			});
	}
	getCount(urlExtension) {
		if (typeof urlExtension === 'undefined' || urlExtension === null) {
			urlExtension = '/Count';
		}
		let url = this.baseUrl + this.url + urlExtension;
		this.store.dataAccess
			.get(url)
			.then((result) => {
				this.amountOfCompanies = result.count;
			})
			.catch((error) => {
				if (this.logError === true) {
					Sentry.captureMessage('url: ' + url + ', objectName: ' + this.objectName + ', error: ' + error.message);
				}
			});
	}

	list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack) {
		if (typeof data === 'undefined' || data === null) {
			data = {};
		}

		data.language = Languages.CURRENT.currentLanguage;
		let list = super.list(data, force, cacheId, urlExtension, thenCallBack, catchCallBack);
		return list;
	}
}
