/* eslint-disable no-useless-constructor */
import { Configuration } from '../../../configurator/configuration';
import { Profiles } from '../../../configurator/profiles';
import { Raster } from '../../../configurator/raster';
import { Canvas3D } from '../../Canvas3D';
import { ProfilesVertical3D } from '../../profiles/ProfilesVertical3D';

export class TrimmingRight3D extends ProfilesVertical3D {
	objectName = 'TrimmingRight3D';
	rotationY = -90;
	rotationX = 180;
	type = 'profile';
	constructor(x, y, length, params) {
		super(x, y, length, params);
	}
	draw(app) {
		let deckingFinishHeight = Configuration.CURRENT.finish.height;
		this.ralColor = Configuration.CURRENT.colors.profiles.ralColor;
		if (Configuration.CURRENT.profiles.mainBeamDirection === Profiles.MB_HORIZONTAL) {
			this.applyHorizontaloffset();
		}

		this.y -= deckingFinishHeight;
		super.draw(app);
	}
	applyHorizontaloffset() {
		let columnWidth = Canvas3D.CURRENT.getModelDataByOid(Configuration.CURRENT.columns.column.oid, 'width');

		if (this.multipleFloors) {
			if (this.rowPositions.includes(Raster.POSITION_TOP)) {
				this.z += this.mainBeamDepth;
				this.z += columnWidth;

				this.width -= this.mainBeamDepth;
				this.width -= columnWidth;

				this.width -= columnWidth / 2;
				this.width -= this.mainBeamDepth;
			}
			if (this.placement === Raster.TYPE_MIDDLERASTER) {
				this.z += this.mainBeamDepth;
				this.z += columnWidth / 2;

				this.width -= this.mainBeamDepth * 2;
				this.width -= columnWidth;
			}
			if (this.rowPositions.includes(Raster.POSITION_BOTTOM)) {
				this.z += this.mainBeamDepth;
				this.z += columnWidth / 2;

				this.width -= this.mainBeamDepth * 2;
				this.width -= columnWidth;
				this.width -= columnWidth / 2;
			}
		} else {
			this.width -= this.mainBeamDepth + columnWidth;
			this.z += columnWidth;
		}
	}
}
